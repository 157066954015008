import React, { useCallback } from 'react';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, GridItem, Typography } from '../layouts';
import useControlled from '../../../hooks/useControlled';

/**
 * @param {import('react').PropsWithChildren<{
 *  opened?: boolean,
 *  onOpenedChange?: (opened: boolean) => void,
 *  startOpened?: boolean,
 *  isForAdvancedSearch?: boolean,
 *  header?: import('react').ReactNode
 * }>} props
 */
export default function Accordion({ opened, onOpenedChange, startOpened, header, children }) {
	const [isOpened, setIsOpened] = useControlled({
		controlledValue: opened,
		defaultValue: startOpened ?? false,
	});

	const toggle = useCallback(() => {
		const next = !isOpened;
		setIsOpened(next);

		if (onOpenedChange) {
			onOpenedChange(next);
		}
	}, [isOpened, setIsOpened, onOpenedChange]);

	return (
		<Card>
			<CardHeader onClick={toggle}>
				<Grid alignItems="center">
					<GridItem span={11}>
						<Typography component="h5">{header}</Typography>
					</GridItem>

					<GridItem justifySelf="end">
						<FontAwesomeIcon icon={isOpened ? faChevronUp : faChevronDown} />
					</GridItem>
				</Grid>
			</CardHeader>
			<Collapse style={{ padding: 0 }} isOpen={isOpened}>
				<CardBody>{children}</CardBody>
			</Collapse>
		</Card>
	);
}
