import React from 'react';

// TODO verify the necessity of this
export default function CustomTextInput(props) {
	const { isEditable, name, value, placeholder, type, onChange, onFocus, onBlur, ...rest } = props

	let effectiveIsEditable = isEditable ?? true;

	return (
		<input
			disabled={!effectiveIsEditable}
			type={type}
			name={name}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			onFocus={onFocus}
			onBlur={onBlur}
			{...rest}
		/>
	);
}
