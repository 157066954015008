import React from 'react';
import { withRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import ErrorBoundary from './errorBoundary';

export function PublicRoute(props) {
	const { component: Component, ...rest } = props;

	return (
		<ErrorBoundary>
			<Route
				{...rest}
				render={(props) => {
					return <Component {...props} />;
				}}
			/>
		</ErrorBoundary>
	);
}

export default withRouter(connect()(PublicRoute));
