import React from 'react';
import FieldControl from './fieldControl';

/**
 * @param {Object} props
 * @param {string} props.id
 * @param {Object} props.error
 * @param {string} props.error.message
 * @param {boolean} props.disabled
 * @param {boolean} props.isFocused
 * @param {boolean} props.isRequired
 * @param {boolean} props.isVisible - If False Component Returns null
 * @param {boolean} props.label
 * @param {boolean} props.labelAfter - If true, label is placed after input (default before)
 * @param {string} props.name
 * @param {Object} props.value
 * @param {Function} props.onBlur
 * @param {Function} props.onChange
 * @param {Function} props.onFocus
 * @param {Array} props.optionList
 * @param {string} props.size - "sm", "md", "lg"
 * @returns {Object}
 */
export function DropdownSelect(props) {
	const {
		allowEmptyValue,
		id,
		error,
		disabled,
		isFocused,
		isRequired,
		isVisible,
		label,
		labelAfter,
		defaultOptionLabel,
		name,
		value,
		onBlur,
		onChange,
		onFocus,
		optionList,
		size,
	} = props;

	const inputType = 'dropdown';
	const defaultOptionValue = '-1';

	const hasValue = !!value || value === 0 || (allowEmptyValue && value === '');
	const selectValue = hasValue ? value : defaultOptionValue;

	const defaultOption = (
		<option value={defaultOptionValue} disabled>
			{defaultOptionLabel}
		</option>
	);
	const grayOnDisabled = disabled ? {
		backgroundColor: "rgba(239, 239, 239, 0.3)"
	} : {}

	return (
		<FieldControl
			disabled={disabled}
			error={error}
			hasValue={hasValue}
			isFocused={isFocused}
			id={id}
			inputType={inputType}
			isRequired={isRequired}
			isVisible={isVisible}
			label={label}
			labelAfter={labelAfter}
			size={size}
		>
			<select
				disabled={disabled}
				id={id}
				name={name}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				placeholder={label}
				type="text"
				value={selectValue}
				style={grayOnDisabled}
			>
				{defaultOption}
				{optionList &&
					optionList.map((currentOption) => {
						return (
							<option key={currentOption.id} value={currentOption.id}>
								{currentOption.name ? currentOption.name : currentOption.id}
							</option>
						);
					})}
			</select>
		</FieldControl>
	);
}
