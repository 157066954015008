import { useState } from 'react';
import * as FieldControl from '../input';
import { CollapsablePanelWithSectionHeader, DisclaimerHtml } from './miscComponents';
import '../../css/views/patientDetailsSection.css';
import { LoadingIndicatorModal } from '../loadingIndicator/loadingIndicatorModal';

const emptyAddressOption = {
	label: "Search for an address...", value: {}
};
export default function PatientDetailsSection(props) {
	const {
		enableSectionValidationIcons,
		sectionConfig,
		patientFieldsInfo,
		handleInputChange,
		stateList,
		isCollapsed,
		handleToggleCollapse,
		handleInputFocus,
		handleInputBlur,
		hasErrors,
		name,
		shouldShowSectionValidation,
		genderList,
		showAddressSearch,
		loadAddressOptions,
		handleAddressChange,
		handleClearPatientField,
		isAddressLoading,
	} = props;

	const [isManualAddressEntry, setIsManualAddressEntry] = useState(!showAddressSearch);
	const handleIsManualAddressEntryChange = (e) => {
		setIsManualAddressEntry(e);
	}

	return (
		<CollapsablePanelWithSectionHeader
			className="main-section"
			enableSectionValidationIcons={enableSectionValidationIcons}
			label={sectionConfig.headerText}
			isCollapsed={isCollapsed}
			isCollapsable={sectionConfig.isCollapsable}
			onToggleCollapse={handleToggleCollapse}
			name={name}
			hasErrors={hasErrors}
			showHeader={sectionConfig.showHeader}
			shouldShowSectionValidation={shouldShowSectionValidation}
		>
			{isAddressLoading && <LoadingIndicatorModal />}
			<div className="section-body">
				<div className="section-row">
					<DisclaimerHtml
						className="form-instructions"
						isVisible={!!sectionConfig.patientDetailsConfigInstructions}
						text={sectionConfig.patientDetailsConfigInstructions}
					/>
				</div>
				<div className="section-row">
					<FieldControl.TextInput
						{...patientFieldsInfo.memberId}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
					/>
					<FieldControl.TextInput
						{...patientFieldsInfo.groupNumber}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
					/>
				</div>
				<div className="section-row">
					<FieldControl.TextInput
						{...patientFieldsInfo.firstName}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
					/>
					<FieldControl.TextInput
						{...patientFieldsInfo.middleName}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
					/>
					<FieldControl.TextInput
						{...patientFieldsInfo.lastName}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
					/>
				</div>
				{showAddressSearch &&
					<AddressCheckbox onChange={handleIsManualAddressEntryChange} isManualEntry={isManualAddressEntry} />
				}
				<div className="section-row">
					{showAddressSearch && !patientFieldsInfo.addressLine1.value && !isManualAddressEntry ? 
						<FieldControl.AsyncDropdownSelect
							{...patientFieldsInfo.addressLine1}
							onChange={handleAddressChange}
							defaultOption={emptyAddressOption}
							onFocus={handleInputFocus}
							onBlur={handleInputBlur}
							size={'lg'}
							disabled={isAddressLoading}
							loadOptions={loadAddressOptions}
						/>
						: <FieldControl.TextInput
							{...patientFieldsInfo.addressLine1}
							onChange={handleInputChange}
							onFocus={handleInputFocus}
							onBlur={handleInputBlur}
							size={'lg'}
							includeClearIndicator={showAddressSearch}
							onClearIndicatorClick={() => {
								handleClearPatientField("addressLine1");
							}}
						/>}
				</div>
				<div className="section-row">
					<FieldControl.TextInput
						{...patientFieldsInfo.addressLine2}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						size={'lg'}
					/>
				</div>
				<div className="section-row">
					<FieldControl.TextInput
						{...patientFieldsInfo.cityName}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
					/>
					<FieldControl.DropdownSelect
						{...patientFieldsInfo.stateId}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						optionList={stateList}
					/>
					<FieldControl.ZipInput
						{...patientFieldsInfo.zipCode}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
					/>
				</div>
				<div className="section-row">
					<FieldControl.GenderSelections
						{...patientFieldsInfo.gender}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						optionList={genderList}
					/>
					<FieldControl.DateInput
						{...patientFieldsInfo.dateOfBirth}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
					/>
					<FieldControl.TextInput
						{...patientFieldsInfo.parentOrGuardianName}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
					/>
				</div>
			</div>
		</CollapsablePanelWithSectionHeader>
	);
}

const AddressCheckbox = ({ onChange, isManualEntry, disabled }) => {
	return (
		<div className="address-search-checkbox field-control checkbox">
			<input
				id="address-checkbox"
				type="checkbox"
				onChange={e => onChange(e.target.checked)}
				value={isManualEntry}
				disabled={disabled}
			/>
			<label htmlFor="address-checkbox">Enter Address Manually?</label>
		</div>
	)
}