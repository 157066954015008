import { useDispatch, useSelector } from "react-redux";
import { changeViewDateThunk, selectActiveDisplayedDates, selectAllowNextViewBaseDate, selectAllowPreviousViewBaseDate, selectNextViewBaseDate, selectPreviousViewBaseDate } from "../availabilitySlice";
import dayjs from "dayjs";
import classNames from "classnames";
import * as Icon from '../../../components/icons';
import { nullifySuppressedDates } from "../../../lib/date";
import { selectShouldSuppressSaturday, selectShouldSuppressSunday } from "../availabilitySlice/availabilitySelectors";

const DateNav = ({ isMobile, selectedDate, setSelectedDate, calendarId }) => {

    const dispatch = useDispatch();

    const allowSearchPrevious = useSelector(selectAllowPreviousViewBaseDate);
    const allowSearchNext = useSelector(selectAllowNextViewBaseDate);

    const previousViewBaseDate = useSelector(selectPreviousViewBaseDate);
    const nextViewBaseDate = useSelector(selectNextViewBaseDate);

    const displayedDates = useSelector(selectActiveDisplayedDates);

    const suppressSaturday = useSelector(selectShouldSuppressSaturday);
    const suppressSunday = useSelector(selectShouldSuppressSunday);

    const containerClassName = classNames('availability-results-week-nav-container sticky-container', {
        provider: !!isMobile,
    });

    return (
        <div className={containerClassName}>
            <div className="availability-results-week-nav">
                <div className="week-nav-toggle">
                    {allowSearchPrevious &&
                        <Icon.IconChevronLeft
                            onClick={() => dispatch(changeViewDateThunk(previousViewBaseDate))}
                        />
                    }
                </div>
                <div className="week-nav-days">
                    {displayedDates.map((date, i) => {
                        const isSelected = date === selectedDate;
                        const dayOfWeekClassName = classNames('day-of-week', { selected: isSelected }, { provider: !!calendarId }, { disabled: !nullifySuppressedDates(date, suppressSaturday, suppressSunday) });
                        return (
                            <div
                                key={i}
                                className={dayOfWeekClassName}
                                onClick={
                                    (setSelectedDate && nullifySuppressedDates(date, suppressSaturday, suppressSunday))
                                        ? () => setSelectedDate(date)
                                        : null
                                }
                            >
                                {isMobile && isSelected && (
                                    <>
                                        <span>
                                            <span className="day-of-week-long">{dayjs(date).format('ddd')}</span>
                                        </span>
                                        <span>
                                            <span className="month-short">{dayjs(date).format('MMM')}</span>
                                            <span className="day-of-month">{dayjs(date).format('D')}</span>
                                        </span>
                                    </>
                                )}
                                {isMobile && !isSelected && (
                                    <>
                                        <span>
                                            <span className="day-of-week-short">{dayjs(date).format('ddd')}</span>
                                        </span>
                                        <span>
                                            <span className="day-of-month">{dayjs(date).format('D')}</span>
                                        </span>
                                    </>
                                )}
                                {!isMobile && (
                                    <>
                                        <span>
                                            <span className="day-of-week-short">{dayjs(date).format('ddd')}</span>
                                        </span>
                                        <span>
                                            <span className="month-short">{dayjs(date).format('MMM')}</span>
                                            <span className="day-of-month">{dayjs(date).format('D')}</span>
                                        </span>
                                    </>
                                )}
                            </div>
                        )
                    })}
                </div>
                <div className="week-nav-toggle">
                    {allowSearchNext &&
                        <Icon.IconChevronRight
                            onClick={() => dispatch(changeViewDateThunk(nextViewBaseDate))}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default DateNav;