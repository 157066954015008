import React from 'react';
import AppliedPathwaysQuestion from './appliedPathwaysQuestion';

const lineFeedsRegex = new RegExp(/(\r\n?|\n)/g);
const replaceLineFeedsWithBreakRegex = (string) => {
	if (string) {
		return string.replace(lineFeedsRegex, '<br />');
	} else {
		return string;
	}
};

export default class AppliedPathwaysForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			asset: {},
			components: [],
		};
	}

	componentDidMount() {
		this.processQuestions(this.props.asset.ap_questions);
	}

	componentDidUpdate(prevProps) {
		if (this.props.asset !== prevProps.asset) {
			this.processQuestions(this.props.asset.ap_questions);
		}
	}

	processQuestions = (questions) => {
		let components = [];
		if (questions) {
			let assetLength = questions.length;
			for (let count = 0; count < assetLength; count++) {
				let ref = React.createRef();
				let asset = questions[count];
				components.push(<AppliedPathwaysQuestion {...this.props} asset={asset} ref={ref} key={asset.id} />);
			}
		}
		this.setState({ components });
	};

	validate() {
		let isValid = true;

		let componentLength = this.state.components.length;
		for (let j = 0; j < componentLength; j++) {
			var component = this.state.components[j];
			if (component.ref && component.ref.current && component.ref.current.validate) {
				isValid = component.ref.current.validate();
				if (!isValid) {
					return isValid;
				}
			}
		}

		return isValid;
	}

	render() {
		let asset = this.props.asset;

		let references =
			asset &&
			asset.ap_references &&
			asset.ap_references.filter(function (reference) {
				return reference.type === 'Instructions';
			});
		return (
			<div>
				{references &&
					references.length > 0 &&
					references.map((item, key) => (
						<p
							key={key}
							dangerouslySetInnerHTML={{
								__html: replaceLineFeedsWithBreakRegex(item.notes),
							}}
						/>
					))}
				{!references && asset && (
					<h5
						dangerouslySetInnerHTML={{
							__html: replaceLineFeedsWithBreakRegex(asset.instructions),
						}}
					/>
				)}
				{this.state.components}
			</div>
		);
	}
}
