/**
 * @format
 */

import React from 'react';
import MultiProviderAvailability from './multiProviderAvailability';
import SingleProviderAvailability from './singleProviderAvailability';

export default function AvailabilitySearch(props) {
	const { availabilityResults } = props;

	return (
		<>
			{availabilityResults.showSingleCalendar && <SingleProviderAvailability {...props} />}
			{!availabilityResults.showSingleCalendar && <MultiProviderAvailability {...props} />}
		</>
	);
}
