import {
    changePageThunk,
    selectNumberOfPages,
    selectPageNumber
} from '../availabilitySlice';
import { useDispatch, useSelector } from 'react-redux';
import * as Button from '../../../components/buttons';
import * as Icon from '../../../components/icons';

import { createUltimatePagination } from 'react-ultimate-pagination';

const Pagination = ({ isMobile }) => {
    const pageNumber = useSelector(selectPageNumber);
    const numberOfPages = useSelector(selectNumberOfPages);
    const dispatch = useDispatch();

    const Page = ({ value, isActive, disabled }) => (
        <Button.ButtonCircle
            isSelected={pageNumber === value ? true : false}
            style={isActive ? { fontWeight: 'bold' } : null}
            onClick={(e) => dispatch(changePageThunk(parseInt(e.target.textContent)))}
            disabled={disabled}
            value={value}
        >
            {value}
        </Button.ButtonCircle>
    );

    function Ellipsis() {
        return (
            <Button.ButtonCircle className="ellipse-button" disabled={true}>
                <Icon.IconEllipsisH size={'sm'} />
            </Button.ButtonCircle>
        );
    }

    function PreviousPageLink() {
        return (
            <Button.ButtonPill
                className="previous-pill"
                onClick={() => dispatch(changePageThunk(pageNumber - 1))}
                disabled={pageNumber === 1}
            >
                <span className="previous-span">
                    <Icon.IconChevronLeft size={'sm'} /> Prev
                </span>
            </Button.ButtonPill>
        );
    }

    function NextPageLink() {
        return (
            <Button.ButtonPill
                className="next-pill"
                onClick={() => dispatch(changePageThunk(pageNumber + 1))}
                disabled={pageNumber === numberOfPages}
            >
                <span className="next-span">
                    Next <Icon.IconChevronRight size={'sm'} />
                </span>
            </Button.ButtonPill>
        );
    }

    function Wrapper(props) {
        return <div className="pagination-wrapper">{props.children}</div>;
    }

    const PaginatedPage = createUltimatePagination({
        itemTypeToComponent: {
            PAGE: Page,
            ELLIPSIS: Ellipsis,
            PREVIOUS_PAGE_LINK: PreviousPageLink,
            NEXT_PAGE_LINK: NextPageLink,
        },
        WrapperComponent: Wrapper,
    });

    return (
        <div className={'availability-results-pagination-container' + (isMobile ? '-mobile' : '')}>
            <PaginatedPage
                totalPages={numberOfPages || 1}
                currentPage={pageNumber}
                onClick={(pageNumber) => dispatch(changePageThunk(pageNumber))}
                hideFirstAndLastPageLinks={true}
                hidePreviousAndNextPageLinks={isMobile || numberOfPages < 2}
            />
            {isMobile && numberOfPages > 1 && (
                <div className="mobile-div">
                    <Button.ButtonPill
                        className="previous-pill"
                        onClick={() => dispatch(changePageThunk(pageNumber - 1))}
                        disabled={pageNumber === 1}
                    >
                        <span className="previous-span">
                            <Icon.IconChevronLeft size={'sm'} /> Prev
                        </span>
                    </Button.ButtonPill>
                    <Button.ButtonPill
                        className="next-pill"
                        onClick={() => dispatch(changePageThunk(pageNumber + 1))}
                        disabled={pageNumber === numberOfPages}
                    >
                        <span className="next-span">
                            Next <Icon.IconChevronRight size={'sm'} />
                        </span>
                    </Button.ButtonPill>
                </div>
            )}
        </div>
    );
}

export default Pagination;