import React from 'react';
import { Button } from './button';
import classNames from 'classnames';

/**
* @param {Object} props
* @param {Object} props.children
* @param {string} props.className
* @param {boolean} props.disabled
* @param {string} props.id
* @param {boolean} props.isLoading
* @param {string} props.size - "sm", "md", "lg"
* @param {string} props.title
* @param {string} props.type
* @returns {Object}
*/
export function ButtonTimeSlot(props) {
	const { children, className, disabled, id, isLoading, onClick, size, title, type, } = props;
	const buttonClassName = classNames(
		"time-slot",
		className,
	);
	return (
		<Button
			className={buttonClassName}
			disabled={disabled}
			id={id}
			isLoading={isLoading}
			onClick={onClick}
			size={size}
			title={title}
			type={type}
		>
			{children}
		</Button>
	);
};
