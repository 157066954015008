import moment from 'moment';
import { Calendar, momentLocalizer, Navigate } from 'react-big-calendar';
import classNames from 'classnames';
import * as Icon from '../../../components/icons';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import {
    changeViewDateThunk,
    DEFAULT_DATE_FORMAT,
    selectAllowNextViewBaseDate,
    selectAllowPreviousViewBaseDate,
    selectNextViewBaseDate,
    selectPreviousViewBaseDate,
    selectSelectedCalendarAvailability,
    selectSelectedCalendarViewBaseDate,
    setSelectedCalendarByIdThunk
} from '../availabilitySlice';
import { formatStartTime } from '../../../lib/availabilityHelper';
import { useDispatch, useSelector } from 'react-redux';
import { nullifySuppressedDates } from '../../../lib/date';
import { selectShouldSuppressSaturday, selectShouldSuppressSunday } from '../availabilitySlice/availabilitySelectors';

const localizer = momentLocalizer(moment);

const SelectedCalendarAvailabilityCalendar = ({ calendarId, isMobile, selectedCalendarSelectedDate, setSelectedCalendarSelectedDate }) => {

    const dispatch = useDispatch();

    const selectedCalendarAvailability = useSelector(state => selectSelectedCalendarAvailability(state, calendarId));
    const allowNextViewBaseDate = useSelector(selectAllowNextViewBaseDate);
    const allowPreviousViewBaseDate = useSelector(selectAllowPreviousViewBaseDate);
    const nextViewBaseDate = useSelector(selectNextViewBaseDate);
    const previousViewBaseDate = useSelector(selectPreviousViewBaseDate);
    const selectedCalendarViewBaseDate = useSelector(selectSelectedCalendarViewBaseDate);

    const suppressSaturday = useSelector(selectShouldSuppressSaturday);
    const suppressSunday = useSelector(selectShouldSuppressSunday);

    const events = selectedCalendarAvailability?.length
        ? selectedCalendarAvailability
            .filter((a) => !!nullifySuppressedDates(a.date, suppressSaturday, suppressSunday))
            .map((x) => {

                return {
                    ...x,
                    key: x.availabilityId,
                    start: x.localStartAt,
                    end: x.localEndAt,
                    title: moment(x.localStartAt).format('h:mm a'),
                    allDay: false,
                    resource: null,
                    availabilityId: x.availabilityId,
                    //TODO: Determine if we still need above repeat code
                    timeSlotId: x.availabilityId,
                    date: x.date,
                    startTime: x.startTime,
                    endTime: x.endTime,
                    formattedStartTime: formatStartTime(x.startTime)
                };
            })
        : [];

    const hasSlots = (date) => {
        let hasSlots = false;
        if (selectedCalendarAvailability?.length) {
            hasSlots = selectedCalendarAvailability.find((x) => {
                return moment(x.date).format(DEFAULT_DATE_FORMAT) === moment(date).format(DEFAULT_DATE_FORMAT);
            });
        }
        return hasSlots;
    };

    const handleDayClick = (event) => {
        let date = moment(event.start).format(DEFAULT_DATE_FORMAT);
        if (hasSlots(date) && !!nullifySuppressedDates(date, suppressSaturday, suppressSunday)) {
            setSelectedCalendarSelectedDate(date);
        }
    };

    const toolbar = (toolbar) => {
        const goToBack = () => {
            toolbar.onNavigate(Navigate.PREVIOUS);
        };

        const goToNext = () => {
            toolbar.onNavigate(Navigate.NEXT);
        };

        const label = () => {
            const date = moment(toolbar.date);
            return (
                <h3>{date.format('MMMM')} {date.format('YYYY')}</h3>
            );
        };

        return (
            <div className="rbc-toolbar">
                <div className="prev-month">
                    {allowPreviousViewBaseDate && (
                        <span onClick={goToBack}>
                            <Icon.IconChevronLeft />
                            <span>Prev</span>
                        </span>
                    )}
                </div>
                <div className="rbc-toolbar-label">
                    <span>{label()}</span>
                </div>
                <div className="next-month">
                    {allowNextViewBaseDate && (
                        <span onClick={goToNext}>
                            <span>Next</span>
                            <Icon.IconChevronRight />
                        </span>
                    )}
                </div>
            </div>
        );
    };

    const dateHeader = ({ date, label }) => {
        let momentDateString = moment(date).format(DEFAULT_DATE_FORMAT);
        let dateEvents = events.filter((event) => momentDateString === moment(event.date).format(DEFAULT_DATE_FORMAT));
        let numOfEvents = dateEvents ? dateEvents.length : null;
        let highlightDate =
            events.find((event) => momentDateString === moment(event.date).format(DEFAULT_DATE_FORMAT)) !== undefined;
        let compareDate = moment(selectedCalendarSelectedDate).format(DEFAULT_DATE_FORMAT);
        let activeDate = momentDateString === compareDate;
        const isToday = momentDateString === moment().format(DEFAULT_DATE_FORMAT);
        const containerClassName = classNames(
            'rbc-date-container',
            { selected: activeDate },
            { active: highlightDate },
            { inactive: !highlightDate },
            { 'rbc-today': isToday },
        );
        const dateHeaderClassName = classNames(
            { 'rbc-selected-date': activeDate },
            { 'rbc-active-date': !activeDate && highlightDate },
            { 'rbc-inactive-date': !activeDate && !highlightDate },
        );
        const appointmentCountClassName = classNames('appointment-count', { selected: activeDate });

        let returnComp =
            numOfEvents && !isMobile ? (
                <div className={containerClassName}>
                    <h3 className={dateHeaderClassName}>{label}</h3>
                    <span className={appointmentCountClassName}>{numOfEvents + ` Appt${numOfEvents > 1 ? 's' : ''}`}</span>
                </div>
            ) : (
                <div className={containerClassName}>
                    <h3 className={dateHeaderClassName}>{label}</h3>
                </div>
            );
        return returnComp;
    };

    return (
        <div className="calendar-container">
            <Calendar
                back={() => dispatch(setSelectedCalendarByIdThunk(null))}
                components={{
                    month: {
                        toolbar: toolbar,
                        dateHeader: dateHeader,
                    },
                }}
                date={selectedCalendarViewBaseDate ? moment(selectedCalendarViewBaseDate).toDate() : new Date()}
                defaultView="month"
                events={events}
                localizer={localizer}
                longPressThreshold={1}
                onDrillDown={handleDayClick}
                onNavigate={(date, view, action) => {
                    switch (action) {
                        case Navigate.PREVIOUS:
                            if (allowPreviousViewBaseDate) {
                                dispatch(changeViewDateThunk(previousViewBaseDate));
                            }
                            break;
                        case Navigate.NEXT:
                            if (allowNextViewBaseDate) {
                                dispatch(changeViewDateThunk(nextViewBaseDate));
                            }
                            break;
                        default:
                            break;
                    }
                }}
                onSelectSlot={handleDayClick}
                selectable={true}
                style={{ height: '100%' }}
                views={['month']}
            />
        </div>
    );
}

export default SelectedCalendarAvailabilityCalendar;