import React from 'react';
import classNames from 'classnames';
import * as Icon from '../icons';

export function AvailabilityResultsWeekNav(props) {
	const {
		availabilityResults,
		calendarId, // don't worry for now, prob single view only?
		handleProviderSelectDate, //
		handleGoToDate,
		isMobile, 
		providersSelectedDate,
	} = props;

	let selectedDateIndex = null;
	if (providersSelectedDate) selectedDateIndex = providersSelectedDate[calendarId] || 0;

	const containerClassName = classNames('availability-results-week-nav-container sticky-container', {
		provider: !!calendarId,
	});

	return (
		<div className={containerClassName}>
			<div className="availability-results-week-nav">
				<div className="week-nav-toggle">
					{availabilityResults.allowSearchPreviousPage && <Icon.IconChevronLeft onClick={() => handleGoToDate(availabilityResults.effectivePreviousPageStartDate)} />}
				</div>
				<div className="week-nav-days">
					{availabilityResults.displayDays.map((day, i) => {
						const isSelected = selectedDateIndex === i;
						const dayOfWeekClassName = classNames('day-of-week', { selected: isSelected }, { provider: !!calendarId });
						return (
							<div
								key={i}
								className={dayOfWeekClassName}
								onClick={handleProviderSelectDate ? () => handleProviderSelectDate(calendarId, i) : null}
							>
								{isMobile && isSelected && (
									<>
										<span>
											<span className="day-of-week-long">{day.format('ddd')}</span>
										</span>
										<span>
											<span className="month-short">{day.format('MMM')}</span>
											<span className="day-of-month">{day.format('D')}</span>
										</span>
									</>
								)}
								{isMobile && !isSelected && (
									<>
										<span>
											<span className="day-of-week-short">{day.format('ddd')}</span>
										</span>
										<span>
											<span className="day-of-month">{day.format('D')}</span>
										</span>
									</>
								)}
								{!isMobile && (
									<>
										<span>
											<span className="day-of-week-short">{day.format('ddd')}</span>
										</span>
										<span>
											<span className="month-short">{day.format('MMM')}</span>
											<span className="day-of-month">{day.format('D')}</span>
										</span>
									</>
								)}
							</div>
						);
					})}
				</div>
				<div className="week-nav-toggle">
					{availabilityResults.allowSearchNextPage && <Icon.IconChevronRight onClick={() => handleGoToDate(availabilityResults.effectiveNextPageStartDate)} />}
				</div>
			</div>
		</div>
	);
}
