import moment from 'moment';
import React from 'react';

import * as FieldControl from '../../components/input';
import { INPUT_TYPES } from '../../constants/inputTypes';
import {
	mapDataToFieldConfig_Checkbox,
	mapDataToFieldConfig_Date,
	mapDataToFieldConfig_Email,
	mapDataToFieldConfig_Phone,
	mapDataToFieldConfig_Text,
	mapDataToFieldConfig_Zip,
} from '../../lib/fieldConfigUtils';
import { buildFieldRefFromParts } from '../../lib/fieldUtils';
import { sortKeys } from '../../lib/misc';
import { buildError } from '../../lib/validationUtils';

export function getEffectiveNotificationValues(values, props) {
	let emailConfirmation = new EmailConfirmationNotificationHelper(props);
	let emailReminder = new EmailReminderNotificationHelper(props);
	let textReminder = new TextReminderNotificationHelper(props);
	let voiceReminder = new VoiceReminderNotificationHelper(props);
	let thirdPartyEmailConfirmation = new ThirdPartyEmailConfirmationNotificationHelper(props);

	var results = {
		emailConfirmation: {
			shouldSendNotification: emailConfirmation.shouldSendNotification(values),
			toEmail: emailConfirmation.email(values),
		},
		emailReminder: {
			reminderLeadTimeHours: emailReminder.leadTimeHours(values),
			shouldSendNotification: emailReminder.shouldSendNotification(values),
			toEmail: emailReminder.email(values),
		},
		textReminder: {
			reminderLeadTimeHours: textReminder.leadTimeHours(values),
			shouldSendNotification: textReminder.shouldSendNotification(values),
			toPhoneNumber: textReminder.phone(values),
		},
		voiceReminder: {
			reminderLeadTimeHours: voiceReminder.leadTimeHours(values),
			shouldSendNotification: voiceReminder.shouldSendNotification(values),
			toPhoneNumber: voiceReminder.phone(values),
		},
		thirdPartyEmailConfirmation: {
			shouldSendNotification: thirdPartyEmailConfirmation.shouldSendNotification(values),
			toEmail: thirdPartyEmailConfirmation.email(values),
		},
	};
	return results;
}

class NotificationHelperBase {
	constructor(props) {
		this.props = props;
	}

	selectionInternal(values, userSelection) {
		let result;
		const validSelections = this.selectionList(values);
		if (validSelections.length === 0) {
			result = ''; // if there are no valid selections, we cannot have a voice reminder selection at all
		} else if (validSelections.length === 1) {
			result = validSelections[0].id; // if the valid selection list only has a single item, use it
		} else {
			const hasValidSelection = !!validSelections.find((listItem) => listItem.id === userSelection);
			if (hasValidSelection) {
				result = userSelection; // if there is a selection that is still valid, use it
			} else {
				result = ''; // decided NOT to implement any "default selection logic in this code, if any defaulting is desired, it must be performed by the UI.
			}
		}
		return result;
	}

	shouldShowSelectionList(values) {
		const hasMultipleOptions = this.selectionList(values).length > 1;
		const shouldSend = this.shouldSendNotification(values);
		return shouldSend && hasMultipleOptions;
	}

	target(values) {
		let target = ''; // TODO: is this empty string or null?
		let currentSelection = this.selection(values);
		if (this.shouldSendNotification(values) && currentSelection) {
			target = values.contactInfo[currentSelection];
		}
		return target;
	}

	validateOtherInputInternal(values, message, ruleName, fieldRef = null) {
		let error = null;
		const otherNumberRequired = this.shouldShowOtherInput(values);
		const otherNumberIsEmpty = !values[fieldRef.fieldGroupName][fieldRef.fieldName];
		if (otherNumberRequired && otherNumberIsEmpty) {
			error = buildError(fieldRef, ruleName, message, false);
		}
		return error;
	}

	validateSelectionInternal(values, message, ruleName, fieldRef = null) {
		// assumes other logic correctly manages the visibility of the checkbox, and the validation of phonenumber requiredness and format for visible phone numbers
		let error = null;
		if (this.shouldSendNotification(values)) {
			let selectionId = this.selection(values);
			if (!selectionId) {
				error = buildError(fieldRef, ruleName, message, false);
			}
		}
		return error;
	}

	shouldShowUserToggle_ReminderInternal(hasValidValue, allowsOtherInput, minLeadTime) {
		const { appointmentStartAt } = this.props.reservationDetails;
		const meetsMinLeadTimeRequirement = meetsMinLeadTimeRequirementRule(appointmentStartAt, minLeadTime);
		return meetsMinLeadTimeRequirement && (allowsOtherInput || hasValidValue);
	}
}

class EmailConfirmationNotificationHelper extends NotificationHelperBase {
	validationMessage_SelectionError = 'Must select an email to use for confirmation.';
	validationMessage_OtherInputError = 'Please provide a valid email.';

	//leadTimeHours(values) { return this.props.schedulingConfig.notification.minEmailReminderLeadTime; }
	email(values) {
		return this.target(values);
	}
	selection(values) {
		return this.selectionInternal(values, values.contactInfo.confirmationEmail);
	}
	selectionList(values) {
		const { email } = values.contactInfo;
		const { allowEmailConfirmationOtherEmail, otherLabel } = this.props.viewConfig.sections.contactDetails;

		let list = [];
		if (email)
			// TODO: consider changing to "allowEmailConfirmationOtherEmail" instead of "has"
			list.push({ name: 'Email', id: 'email' });
		if (allowEmailConfirmationOtherEmail) list.push({ name: otherLabel, id: 'confirmationEmailOther' });
		return list;
	}
	shouldSendNotification(values) {
		return (
			this.props.schedulingConfig.notification.emailConfirmationEnabled && // MUST be enabled to send
			(values.contactInfo.emailConfirmationConsent || // and then EITHER turned on via the checkbox
				!this.props.schedulingConfig.notification.showPatientConfirmationEmailOptIn)
		); // OR auto opt-in via hidding the checkbox
	}
	shouldShowOtherInput(values) {
		return this.shouldSendNotification(values) && this.selection(values) === 'confirmationEmailOther';
	}
	shouldShowUserToggle(values) {
		const hasValidValue = !!values.contactInfo.email;
		const allowsOtherInput = this.props.viewConfig.sections.contactDetails.allowEmailConfirmationOtherEmail;

		const showOptIn = this.props.schedulingConfig.notification.showPatientConfirmationEmailOptIn;
		return showOptIn && (hasValidValue || allowsOtherInput);
	}
	validateOtherInput(values, fieldRef = null) {
		return this.validateOtherInputInternal(
			values,
			this.validationMessage_OtherInputError,
			'emailReminderOtherRequired',
			fieldRef,
		);
	}
	validateSelection(values, fieldRef = null) {
		return this.validateSelectionInternal(
			values,
			this.validationMessage_SelectionError,
			'emailReminderSelectionRequired',
			fieldRef,
		);
	}
}

class EmailReminderNotificationHelper extends NotificationHelperBase {
	validationMessage_SelectionError = 'Must select an email to use for reminders.';
	validationMessage_OtherInputError = 'Please provide a valid email.';

	leadTimeHours(values) {
		return this.props.schedulingConfig.notification.minEmailReminderLeadTime;
	}
	email(values) {
		return this.target(values);
	}
	selection(values) {
		return this.selectionInternal(values, values.contactInfo.reminderEmail);
	}
	selectionList(values) {
		const { email } = values.contactInfo;
		const { allowEmailReminderOtherEmail, otherLabel } = this.props.viewConfig.sections.contactDetails;

		let list = [];
		if (email)
			// TODO: consider changing to "allowEmailReminderOtherEmail" instead of "has"
			list.push({ name: 'Email', id: 'email' });
		if (allowEmailReminderOtherEmail) list.push({ name: otherLabel, id: 'reminderEmailOther' });
		return list;
	}
	shouldSendNotification(values) {
		return this.props.schedulingConfig.notification.emailReminderEnabled && values.contactInfo.emailReminderConsent;
	}
	shouldShowOtherInput(values) {
		return this.shouldSendNotification(values) && this.selection(values) === 'reminderEmailOther';
	}
	shouldShowUserToggle(values) {
		const isEnabled = this.props.schedulingConfig.notification.emailReminderEnabled;
		if (!isEnabled) {
			return false;
		}

		const hasValidValue = !!values.contactInfo.email;
		const allowsOtherInput = this.props.viewConfig.sections.contactDetails.allowEmailReminderOtherEmail;
		const minLeadTime = this.props.schedulingConfig.notification.minEmailReminderLeadTime;

		return this.shouldShowUserToggle_ReminderInternal(hasValidValue, allowsOtherInput, minLeadTime);
	}
	validateOtherInput(values, fieldRef = null) {
		return this.validateOtherInputInternal(
			values,
			this.validationMessage_OtherInputError,
			'emailReminderOtherRequired',
			fieldRef,
		);
	}
	validateSelection(values, fieldRef = null) {
		return this.validateSelectionInternal(
			values,
			this.validationMessage_SelectionError,
			'emailReminderSelectionRequired',
			fieldRef,
		);
	}
}

class TextReminderNotificationHelper extends NotificationHelperBase {
	validationMessage_SelectionError = 'Must select a phone number to use for text reminders.';
	validationMessage_OtherInputError = 'Please provide a valid phone number.';

	leadTimeHours(values) {
		return this.props.schedulingConfig.notification.minTextReminderLeadTime;
	}
	phone(values) {
		return this.target(values);
	}
	selection(values) {
		return this.selectionInternal(values, values.contactInfo.textReminderNumber);
	}
	selectionList(values) {
		const { mobilePhone } = values.contactInfo;
		const { allowTextReminderOtherPhone, otherLabel } = this.props.viewConfig.sections.contactDetails;

		let list = [];
		if (mobilePhone)
			// TODO: consider changing to "allowTextReminderOtherPhone" instead of "has"
			list.push({ name: 'Mobile', id: 'mobilePhone' });
		if (allowTextReminderOtherPhone) list.push({ name: otherLabel, id: 'textReminderPhoneOther' });
		return list;
	}
	shouldSendNotification(values) {
		return this.props.schedulingConfig.notification.textReminderEnabled && values.contactInfo.textReminderConsent;
	}
	shouldShowOtherInput(values) {
		return this.shouldSendNotification(values) && this.selection(values) === 'textReminderPhoneOther';
	}
	shouldShowUserToggle(values) {
		const isEnabled = this.props.schedulingConfig.notification.textReminderEnabled;
		if (!isEnabled) {
			return false;
		}
		const hasValidValue = !!values.contactInfo.mobilePhone;
		const allowsOtherInput = this.props.viewConfig.sections.contactDetails.allowTextReminderOtherPhone;
		const minLeadTime = this.props.schedulingConfig.notification.minTextReminderLeadTime;

		return this.shouldShowUserToggle_ReminderInternal(hasValidValue, allowsOtherInput, minLeadTime);
	}
	validateOtherInput(values, fieldRef = null) {
		return this.validateOtherInputInternal(
			values,
			this.validationMessage_OtherInputError,
			'textReminderOtherRequired',
			fieldRef,
		);
	}
	validateSelection(values, fieldRef = null) {
		return this.validateSelectionInternal(
			values,
			this.validationMessage_SelectionError,
			'textReminderSelectionRequired',
			fieldRef,
		);
	}
}

class VoiceReminderNotificationHelper extends NotificationHelperBase {
	validationMessage_SelectionError = 'Must select a phone number to use for voice reminders.';
	validationMessage_OtherInputError = 'Please provide a valid phone number.';

	leadTimeHours(values) {
		return this.props.schedulingConfig.notification.minVoiceReminderLeadTime;
	}
	phone(values) {
		return this.target(values);
	}
	selection(values) {
		return this.selectionInternal(values, values.contactInfo.voiceReminderNumber);
	}
	selectionList(values) {
		const { homePhone, mobilePhone } = values.contactInfo;
		const { allowVoiceReminderOtherPhone, otherLabel } = this.props.viewConfig.sections.contactDetails;

		let list = [];
		if (homePhone)
			// TODO: consider changing to "allowVoiceReminderOtherPhone" instead of "has"
			list.push({ name: 'Home', id: 'homePhone' });
		if (mobilePhone)
			// TODO: consider changing to "allowsMobilePhone" instead of "has"
			list.push({ name: 'Mobile', id: 'mobilePhone' });
		if (allowVoiceReminderOtherPhone) list.push({ name: otherLabel, id: 'voiceReminderPhoneOther' });
		return list;
	}

	shouldSendNotification(values) {
		return this.props.schedulingConfig.notification.voiceReminderEnabled && values.contactInfo.voiceReminderConsent;
	}

	shouldShowOtherInput(values) {
		return this.shouldSendNotification(values) && this.selection(values) === 'voiceReminderPhoneOther';
	}

	shouldShowUserToggle(values) {
		const isEnabled = this.props.schedulingConfig.notification.voiceReminderEnabled;
		if (!isEnabled) {
			return false;
		}
		const hasValidValue = values.contactInfo.homePhone || values.contactInfo.mobilePhone;
		const allowsOtherInput = this.props.schedulingConfig.notification.allowVoiceReminderOtherPhone;
		const minLeadTime = this.props.schedulingConfig.notification.minVoiceReminderLeadTime;

		return this.shouldShowUserToggle_ReminderInternal(hasValidValue, allowsOtherInput, minLeadTime);
	}
	validateOtherInput(values, fieldRef = null) {
		return this.validateOtherInputInternal(
			values,
			this.validationMessage_OtherInputError,
			'voiceReminderOtherRequired',
			fieldRef,
		);
	}
	validateSelection(values, fieldRef = null) {
		return this.validateSelectionInternal(
			values,
			this.validationMessage_SelectionError,
			'voiceReminderSelectionRequired',
			fieldRef,
		);
	}
}

class ThirdPartyEmailConfirmationNotificationHelper {
	email(values) {
		return values.contactInfo.thirdPartyEmail;
	}
	shouldSendNotification(values) {
		return !!values.contactInfo.thirdPartyEmail;
	}
}

const RULES = {
	HOME_OR_MOBILE: { NAME: 'homeOrMobile', MESSAGE: 'Either a Home or Mobile phone is required', IS_FORM_LEVEL: true },
};

const homeOrMobile = (fieldRef, values) => {
	let error = null;
	const hasHomePhone = values.contactInfo.homePhone.length !== 0;
	const hasMobilePhone = values.contactInfo.mobilePhone.length !== 0;

	if (!hasHomePhone && !hasMobilePhone)
		error = buildError(
			fieldRef,
			RULES.HOME_OR_MOBILE.NAME,
			RULES.HOME_OR_MOBILE.MESSAGE,
			RULES.HOME_OR_MOBILE.IS_FORM_LEVEL,
		);

	return error;
};

const meetsMinLeadTimeRequirementRule = (appointmentStartAt, minLeadTime) =>
	moment.utc(appointmentStartAt) > moment.utc().add(minLeadTime, 'h');

const isMinor = (dateOfBirth) => {
	const MINOR_AGE = 18;
	const patientAge = moment().diff(moment(dateOfBirth), 'years');
	const isPatientAMinor = patientAge - MINOR_AGE < 0;
	return isPatientAMinor;
};

const shouldShowParentOrGuardianName = (errors, values, props) => {
	if (props.suppressParentOrGuardianRequirement) {
		return false;
	}
	const hasDateOfBirth = !!values.patient.dateOfBirth;
	const dateOfBirthFieldRef = buildFieldRefFromParts('patient', 'dateOfBirth');
	const doesDateOfBirthHaveError = !!errors[dateOfBirthFieldRef];
	let isPatientAMinor = hasDateOfBirth && !doesDateOfBirthHaveError && isMinor(values.patient.dateOfBirth);
	return isPatientAMinor;
};

const isParentOrGuardianNameValid = (fieldRef, values, props, errors, fieldConfig) => {
	let error = null;
	if (fieldConfig.isVisible && shouldShowParentOrGuardianName(errors, values, props)) {
		if (!values[fieldRef.fieldGroupName][fieldRef.fieldName]) {
			error = buildError(
				fieldRef,
				'parentOrGuardianRequiredForMinor',
				`${fieldConfig.label} is required for minors`,
				false,
			);
		}
	}

	return error;
};

export const getFieldsConfig = (props) => {
	const { fieldConfig, patientFieldConfig, schedulingConfig, patient, symptomDetails, activeCareOrderDetails } = props;
	const { showPatientConfirmationEmailOptIn } = schedulingConfig.notification;
	const { demographics } = patient;

	let emailConfirmation = new EmailConfirmationNotificationHelper(props);
	let emailReminder = new EmailReminderNotificationHelper(props);
	let textReminder = new TextReminderNotificationHelper(props);
	let voiceReminder = new VoiceReminderNotificationHelper(props);
	//let thirdPartyEmailConfirmation = new ThirdPartyEmailConfirmationNotificationHelper(props);

	let patientStateId = null;
	let patientState = demographics.stateCode ? props.stateList.find((x) => x.code === demographics.stateCode) : null;
	if (patientState) {
		patientStateId = patientState.id;
	} else {
		patientStateId = patientFieldConfig.idPgmStateDefault || '';
	}

	let careOrderReasonForVisit =
		activeCareOrderDetails.appointments && activeCareOrderDetails.appointments.length > 0
			? activeCareOrderDetails.appointments[0].reasonForVisit
			: null;
	let decisionSupportReasonForVisit = symptomDetails.reasonForVisit;
	let effectiveReasonForVisit = decisionSupportReasonForVisit || careOrderReasonForVisit || '';

	let config = {
		patient: {
			addressLine1: {
				...mapDataToFieldConfig_Text({
					fieldConfig: patientFieldConfig.addressLine1,
					inputType: INPUT_TYPES.TEXT,
					maxLength: 50,
				}),
				defaultValue: demographics.addressLine1 || '',
				sampleValue: '111 St',
			},
			addressLine2: {
				...mapDataToFieldConfig_Text({
					fieldConfig: patientFieldConfig.addressLine2,
					inputType: INPUT_TYPES.TEXT,
					maxLength: 50,
				}),
				defaultValue: demographics.addressLine2 || '',
				sampleValue: '222 St',
			},
			cityName: {
				...mapDataToFieldConfig_Text({
					fieldConfig: patientFieldConfig.city,
					inputType: INPUT_TYPES.TEXT,
					maxLength: 50,
				}),
				defaultValue: demographics.cityName || '',
				sampleValue: 'Ooltewah',
			},
			dateOfBirth: {
				...mapDataToFieldConfig_Date({
					fieldConfig: patientFieldConfig.dateOfBirth,
					inputType: INPUT_TYPES.DATE_OF_BIRTH,
					minValue: new Date(1900, 1, 1),
					maxValue: Date.now(),
				}),
				defaultValue: demographics.dateOfBirth || '',
				sampleValue: '01/01/1990',
			},
			firstName: {
				...mapDataToFieldConfig_Text({
					fieldConfig: patientFieldConfig.firstName,
					inputType: INPUT_TYPES.TEXT,
					maxLength: 50,
				}),
				defaultValue: demographics.firstName || '',
				sampleValue: 'Phil',
			},
			gender: {
				...mapDataToFieldConfig_Text({
					fieldConfig: patientFieldConfig.gender,
					inputType: INPUT_TYPES.TEXT,
					maxLength: 50,
				}),
				defaultValue: demographics.gender || '',
				sampleValue: '',
			},
			groupNumber: {
				...mapDataToFieldConfig_Text({
					fieldConfig: patientFieldConfig.groupNumber,
					inputType: INPUT_TYPES.TEXT,
					maxLength: 50,
				}),
				defaultValue: demographics.groupNumber || '',
				sampleValue: 'Grou',
			},
			lastName: {
				...mapDataToFieldConfig_Text({
					fieldConfig: patientFieldConfig.lastName,
					inputType: INPUT_TYPES.TEXT,
					maxLength: 50,
				}),
				defaultValue: demographics.lastName || '',
				sampleValue: 'Patton',
			},
			memberId: {
				...mapDataToFieldConfig_Text({
					fieldConfig: patientFieldConfig.memberId,
					inputType: INPUT_TYPES.TEXT,
					maxLength: 50,
				}),
				defaultValue: demographics.memberId || '',
				sampleValue: '223462345',
			},
			middleName: {
				...mapDataToFieldConfig_Text({
					fieldConfig: patientFieldConfig.middleName,
					inputType: INPUT_TYPES.TEXT,
					maxLength: 50,
				}),
				defaultValue: demographics.middleName || '',
				sampleValue: 'Eugene',
			},
			parentOrGuardianName: {
				...mapDataToFieldConfig_Text({
					fieldConfig: patientFieldConfig.parentOrGuardianName,
					inputType: INPUT_TYPES.TEXT,
					maxLength: 50,
				}),
				defaultValue: demographics.parentOrGuardianName || '',
				sampleValue: 'Dad Collins',
				validationRules: [
					(fieldRef, values, props, errors, fieldConfig) =>
						isParentOrGuardianNameValid(fieldRef, values, props, errors, fieldConfig),
				],
				visibilityRule: (errors, values, allFieldsConfig, props) =>
					shouldShowParentOrGuardianName(errors, values, props),
			},
			stateId: {
				...mapDataToFieldConfig_Text({
					fieldConfig: patientFieldConfig.state,
					inputType: INPUT_TYPES.DROPDOWN,
					maxLength: 50,
				}),
				defaultValue: patientStateId,
				sampleValue: '',
			},
			zipCode: {
				...mapDataToFieldConfig_Zip({ fieldConfig: patientFieldConfig.zipCode, inputType: INPUT_TYPES.ZIP_CODE }),
				defaultValue: demographics.zipCode || '',
				sampleValue: '37204',
			},
		},
		contactInfo: {
			email: {
				...mapDataToFieldConfig_Email({ fieldConfig: patientFieldConfig.email, inputType: INPUT_TYPES.EMAIL }),
				defaultValue: demographics.email || '',
				sampleValue: 'a@a.com',
			},
			alternatePhone: {
				...mapDataToFieldConfig_Phone({
					fieldConfig: patientFieldConfig.alternatePhone,
					inputType: INPUT_TYPES.PHONE_NUMBER,
				}),
				defaultValue: demographics.alternatePhone || '',
				sampleValue: '423-645-9999',
			},
			homePhone: {
				...mapDataToFieldConfig_Phone({
					fieldConfig: patientFieldConfig.homePhone,
					inputType: INPUT_TYPES.PHONE_NUMBER,
				}),
				defaultValue: demographics.homePhone || '',
				sampleValue: '423-645-9999',
				validationRules: [(fieldRef, values, props) => homeOrMobile(fieldRef, values)],
			},
			mobilePhone: {
				...mapDataToFieldConfig_Phone({
					fieldConfig: patientFieldConfig.mobilePhone,
					inputType: INPUT_TYPES.PHONE_NUMBER,
				}),
				defaultValue: demographics.mobilePhone || '',
				sampleValue: '423-645-5234',
				validationRules: [(fieldRef, values, props) => homeOrMobile(fieldRef, values)],
			},

			notificationLanguage: {
				...mapDataToFieldConfig_Text({
					fieldConfig: fieldConfig.notifications.notificationLanguage,
					inputType: INPUT_TYPES.DROPDOWN,
				}),
				defaultValue: demographics.notificationLanguage || '',
				sampleValue: '',
			},

			thirdPartyEmail: {
				...mapDataToFieldConfig_Email({
					fieldConfig: fieldConfig.notifications.thirdPartyEmail,
					inputType: INPUT_TYPES.EMAIL,
				}),
				defaultValue: demographics.thirdPartyEmail || '',
				sampleValue: 't@t.com',
			},

			emailConfirmationConsent: {
				...mapDataToFieldConfig_Checkbox({
					fieldConfig: fieldConfig.notifications.emailConfirmationOptIn,
					inputType: INPUT_TYPES.CHECKBOX,
				}),
				defaultValue: !showPatientConfirmationEmailOptIn,
				sampleValue: false,
				visibilityRule: (errors, values, allFieldsConfig, props) => emailConfirmation.shouldShowUserToggle(values),
			},
			confirmationEmail: {
				...mapDataToFieldConfig_Text({
					fieldConfig: fieldConfig.notifications.emailConfirmationSelection,
					inputType: INPUT_TYPES.DROPDOWN,
				}),
				defaultValue: null,
				sampleValue: null,
				validationRules: [(fieldRef, values, props, errors) => emailConfirmation.validateSelection(values, fieldRef)],
				visibilityRule: (errors, values, allFieldsConfig, props) => emailConfirmation.shouldShowSelectionList(values),
			},
			confirmationEmailOther: {
				...mapDataToFieldConfig_Email({
					fieldConfig: fieldConfig.notifications.emailConfirmationOtherEmail,
					inputType: INPUT_TYPES.EMAIL,
				}),
				defaultValue: null,
				sampleValue: null,
				validationRules: [(fieldRef, values, props) => emailConfirmation.validateOtherInput(values, fieldRef)],
				visibilityRule: (errors, values, allFieldsConfig, props) => emailConfirmation.shouldShowOtherInput(values),
				validationApplies: (errors, values, allFieldsConfig, props) => emailConfirmation.shouldShowOtherInput(values),
			},

			emailReminderConsent: {
				...mapDataToFieldConfig_Checkbox({
					fieldConfig: fieldConfig.notifications.emailReminderOptIn,
					inputType: INPUT_TYPES.CHECKBOX,
				}),
				defaultValue: false,
				sampleValue: false,
				visibilityRule: (errors, values, allFieldsConfig, props) => emailReminder.shouldShowUserToggle(values),
			},
			reminderEmail: {
				...mapDataToFieldConfig_Text({
					fieldConfig: fieldConfig.notifications.emailReminderSelection,
					inputType: INPUT_TYPES.DROPDOWN,
				}),
				defaultValue: null,
				sampleValue: null,
				validationRules: [(fieldRef, values, props, errors) => emailReminder.validateSelection(values, fieldRef)],
				visibilityRule: (errors, values, allFieldsConfig, props) => emailReminder.shouldShowSelectionList(values),
			},
			reminderEmailOther: {
				...mapDataToFieldConfig_Email({
					fieldConfig: fieldConfig.notifications.emailReminderOtherEmail,
					inputType: INPUT_TYPES.EMAIL,
				}),
				defaultValue: null,
				sampleValue: null,
				validationRules: [(fieldRef, values, props) => emailReminder.validateOtherInput(values, fieldRef)],
				visibilityRule: (errors, values, allFieldsConfig, props) => emailReminder.shouldShowOtherInput(values),
				validationApplies: (errors, values, allFieldsConfig, props) => emailReminder.shouldShowOtherInput(values),
			},

			textReminderConsent: {
				...mapDataToFieldConfig_Checkbox({
					fieldConfig: fieldConfig.notifications.textReminderOptIn,
					inputType: INPUT_TYPES.CHECKBOX,
				}),
				defaultValue: false,
				sampleValue: false,
				visibilityRule: (errors, values, allFieldsConfig, props) => textReminder.shouldShowUserToggle(values),
			},
			textReminderNumber: {
				...mapDataToFieldConfig_Text({
					fieldConfig: fieldConfig.notifications.textReminderSelection,
					inputType: INPUT_TYPES.DROPDOWN,
				}),
				defaultValue: null,
				sampleValue: null,
				//getSelectionList: (values) => textReminder.selectionList(values), // WIP: just thinking of ideas for future pass
				validationRules: [(fieldRef, values, props, errors) => textReminder.validateSelection(values, fieldRef)],
				visibilityRule: (errors, values, allFieldsConfig, props) => textReminder.shouldShowSelectionList(values),
			},
			textReminderPhoneOther: {
				...mapDataToFieldConfig_Phone({
					fieldConfig: fieldConfig.notifications.textReminderOtherPhone,
					inputType: INPUT_TYPES.PHONE_NUMBER,
				}),
				defaultValue: '',
				sampleValue: '',
				validationRules: [(fieldRef, values, props) => textReminder.validateOtherInput(values, fieldRef)],
				visibilityRule: (errors, values, allFieldsConfig, props) => textReminder.shouldShowOtherInput(values),
				validationApplies: (errors, values, allFieldsConfig, props) => textReminder.shouldShowOtherInput(values),
			},

			voiceReminderConsent: {
				...mapDataToFieldConfig_Checkbox({
					fieldConfig: fieldConfig.notifications.voiceReminderOptIn,
					inputType: INPUT_TYPES.CHECKBOX,
				}),
				defaultValue: false,
				sampleValue: false,
				visibilityRule: (errors, values, allFieldsConfig, props) => voiceReminder.shouldShowUserToggle(values),
			},
			voiceReminderNumber: {
				...mapDataToFieldConfig_Text({
					fieldConfig: fieldConfig.notifications.voiceReminderSelection,
					inputType: INPUT_TYPES.DROPDOWN,
				}),
				defaultValue: null,
				sampleValue: null,
				validationRules: [(fieldRef, values, props, errors) => voiceReminder.validateSelection(values, fieldRef)],
				visibilityRule: (errors, values, allFieldsConfig, props) => voiceReminder.shouldShowSelectionList(values),
			},
			voiceReminderPhoneOther: {
				...mapDataToFieldConfig_Phone({
					fieldConfig: fieldConfig.notifications.voiceReminderOtherPhone,
					inputType: INPUT_TYPES.PHONE_NUMBER,
				}),
				defaultValue: '',
				sampleValue: '',
				validationRules: [(fieldRef, values, props) => voiceReminder.validateOtherInput(values, fieldRef)],
				visibilityRule: (errors, values, allFieldsConfig, props) => voiceReminder.shouldShowOtherInput(values),
				validationApplies: (errors, values, allFieldsConfig, props) => voiceReminder.shouldShowOtherInput(values),
			},
		},
		symptomDetails: {
			reasonForVisit: {
				...mapDataToFieldConfig_Text({
					fieldConfig: fieldConfig.appointmentFields.reasonForVisit,
					inputType: INPUT_TYPES.TEXT,
					maxLength: schedulingConfig.reasonForVisitCharacterLimit,
				}),
				defaultValue: effectiveReasonForVisit,
				sampleValue: 'Test Reason For Visit',
			},
		},
		customFieldsPreBook:
			props.viewConfig.sections.customFieldsSection.isEnabled && props.customFields.customFields
				? mapCustomFieldsToFieldConfig(props.customFields.customFields, false)
				: {},
		referringProvider: {
			internalReferringProvider: {
				...mapDataToFieldConfig_Text({
					fieldConfig: fieldConfig.appointmentFields.managedReferringProvider,
					inputType: INPUT_TYPES.DROPDOWN,
				}),
				defaultValue: null,
				sampleValue: null,
			},
			externalReferringProvider: {
				...mapDataToFieldConfig_Text({
					fieldConfig: fieldConfig.appointmentFields.unmanagedReferringProvider,
					inputType: INPUT_TYPES.TEXT,
					maxLength: 75,
				}),
				defaultValue: '',
				sampleValue: '',
			},
			orgReferringProvider: {
				...mapDataToFieldConfig_Text({
					fieldConfig: fieldConfig.appointmentFields.organizationReferringProvider,
					inputType: INPUT_TYPES.DROPDOWN,
					maxLength: 75,
				}),
				defaultValue: '',
				sampleValue: '',
			},
		},
	};

	return config;
};

export const getPostBookCustomFieldsConfig = (props) => {
	let config = {
		customFieldsPostBook:
			props.viewConfig.sections.customFieldsSection.isEnabled && props.customFields.customFields
				? mapCustomFieldsToFieldConfig(props.customFields.customFields, true)
				: {},
	};

	return config;
};

export const hasVisiblePostBookCustomFields = (customFieldsPostBook) => {
	let hasVisibleField = false;
	for (let field in customFieldsPostBook) {
		if (customFieldsPostBook[field].isVisible) {
			hasVisibleField = true;
		}
	}
	return hasVisibleField;
};

export const mapCustomFieldsToFieldConfig = (customFields, isPostBookAppointment) => {
	let filteredCustomFields = customFields.filter((x) => {
		return x.isPostBookAppointment === isPostBookAppointment;
	});
	if (!filteredCustomFields || filteredCustomFields.length === 0) {
		return null;
	}
	let customFieldsConfig = {};
	filteredCustomFields.forEach((x) => {
		let inputType = customFieldInputTypeMap(x.dataType);
		if (inputType) {
			customFieldsConfig[x.fieldName] = {
				label: x.fieldLabel,
				inputType: inputType,
				isEnabled: x.isActive && x.printIt,
				isVisible: x.isActive && x.printIt,
				isEditable: x.isActive && x.printIt,
				isRequired: x.isRequired,
				isPostBookAppointment: x.isPostBookAppointment,
				items: x.items.map((x) => {
					return {
						id: x.description,
						name: x.description,
					};
				}),
				maxLength: x.maxLength,
			};
		}
	});

	let orderedCustomFieldsConfig = sortKeys(customFieldsConfig);
	return orderedCustomFieldsConfig;
};

const customFieldInputTypeMap = (inputType) => {
	switch (inputType) {
		case 'String':
			return INPUT_TYPES.TEXT;
		case 'Int32':
			return INPUT_TYPES.NUMBER;
		case 'DateTime':
			return INPUT_TYPES.DATE;
		case 'Boolean':
			return INPUT_TYPES.RADIO_OPTIONS;
		case 'Object':
			return INPUT_TYPES.DROPDOWN;
		default:
			return null;
	}
};

export const generateCustomFieldsComps = (customFields, onChange, onFocus, onBlur) => {
	const customFieldComps = [];

	let fieldKey = 0;
	for (let fieldName in customFields) {
		const customField = customFields[fieldName];

		switch (customField.inputType) {
			case INPUT_TYPES.TEXT:
				customFieldComps.push(
					<FieldControl.TextInput
						key={fieldKey++}
						{...customField}
						onChange={onChange}
						onFocus={onFocus}
						onBlur={onBlur}
					/>,
				);
				break;
			case INPUT_TYPES.NUMBER:
				customFieldComps.push(
					<FieldControl.NumberInput
						key={fieldKey}
						{...customField}
						onChange={onChange}
						onFocus={onFocus}
						onBlur={onBlur}
					/>,
				);
				break;
			case INPUT_TYPES.DATE:
				customFieldComps.push(
					<FieldControl.DateInput {...customField} onChange={onChange} onFocus={onFocus} onBlur={onBlur} />,
				);
				break;
			case INPUT_TYPES.RADIO_OPTIONS:
				customFieldComps.push(
					<FieldControl.RadioSelections
						{...customField}
						onChange={onChange}
						onFocus={onFocus}
						onBlur={onBlur}
						optionList={[
							{ id: 'Yes', name: 'Yes' },
							{ id: 'No', name: 'No' },
						]}
					/>,
				);
				break;
			case INPUT_TYPES.DROPDOWN:
				customFieldComps.push(
					<FieldControl.DropdownSelect
						{...customField}
						onChange={onChange}
						onFocus={onFocus}
						onBlur={onBlur}
						optionList={customField.items}
					/>,
				);
				break;
			default:
				break;
		}
	}

	const customFieldCompsByRow = groupCompsByRow(customFieldComps);
	return customFieldCompsByRow;
};

export const groupCompsByRow = (comps) => {
	const inputRows = [];
	const INPUTS_PER_ROW = 1;
	let numberOfRows = Math.ceil(comps.length / INPUTS_PER_ROW);
	for (let i = 0; i < numberOfRows; i++) {
		inputRows.push(
			<div key={i} className="section-row">
				{comps.slice(i * INPUTS_PER_ROW, i * INPUTS_PER_ROW + INPUTS_PER_ROW)}
			</div>,
		);
	}
	return inputRows;
};
