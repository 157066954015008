import React, { Component, Fragment } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from './telemetryService';
import { withRouter } from 'react-router-dom';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component {
	state = {
		initialized: false,
	};

	componentDidMount() {
		const { history, auth, session } = this.props;
		const { initialized } = this.state;
		const AppInsightsInstrumentationKey = this.props.instrumentationKey;
		let hasAuth = false;
		if (auth && auth.isAuthenticated) {
			hasAuth = true;
		}
		if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
			ai.initialize(AppInsightsInstrumentationKey, history, auth, session);
			this.setState({ initialized: true, hasAuth: hasAuth });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { history, auth, session } = this.props;
		const { initialized } = this.state;
		const AppInsightsInstrumentationKey = this.props.instrumentationKey;
		let hasAuth = false;
		if (auth && auth.isAuthenticated) {
			hasAuth = true;
		}
		if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
			ai.initialize(AppInsightsInstrumentationKey, history, auth, session);
			this.setState({ initialized: true, hasAuth: hasAuth });
		}
		if (hasAuth && prevProps && prevProps.location && (prevProps.location.pathname !== this.props.location.pathname || prevProps.location.search !== this.props.location.search)) {
			ai.updateData(auth, session);
		}
	}

	render() {
		const { children } = this.props;
		return (
			<Fragment>
				{children}
			</Fragment>
		);
	}
}

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider));
