import React from 'react';
import { withRouter, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { verifyToken } from '../../actions/authActions';
import { setValidEntryPointToTrue } from '../../actions/sessionActions';
import AuthWrapper from './authWrapper';
import ErrorBoundary from './errorBoundary';
import { LoadingIndicatorModal } from '..//loadingIndicator/loadingIndicatorModal';

export class PrivateRoute extends React.Component {
	state = {
		loaded: false,
		isAuthValid: false,
	};

	componentDidMount() {
		this.props.verifyToken().then((response) => {
			if (response.error && response.error.status === 401) {
				this.setState({ loaded: true });
			} else {
				this.setState({ loaded: true });
			}
		});
		if (this.props.setValidEntryPoint) {
			this.props.setValidEntryPointToTrue();
		}
	}

	render() {
		const {
			component: Component,
			compelPasswordChange,
			enableRootUrl,
			invalidUrlMessage,
			isAuthenticated,
			isTermsOfUseAccepted,
			validEntryPoint,
			...rest
		} = this.props;

		const { loaded } = this.state;
		if (!loaded)
			return (
				<div className="view-content">
					<LoadingIndicatorModal />
				</div>
			);

		const routePrefix = this.props.computedMatch.params.routePrefix;
		const isNewRoutePrefix =
			(routePrefix ? routePrefix.toLowerCase() : null) !==
			(this.props.config.instance.routePrefix ? this.props.config.instance.routePrefix.toLowerCase() : null);

		// if isAuthenticated and is NewPrefix then actions.logout()

		return (
			<ErrorBoundary>
				<Route
					{...rest}
					render={(props) => {
						const { requiredPermission, alternateDisplay, ...rest } = props;

						if (isAuthenticated && isNewRoutePrefix) {
							if (this.props.setValidEntryPoint) {
								return (
									<Redirect
										to={{
											pathname: routePrefix ? `/${routePrefix}` : '/',
											state: {
												from: props.location,
											},
										}}
									/>
								);
							} else {
								return <Redirect to={{ pathname: routePrefix ? `/${routePrefix}` : '/' }} />;
							}
						} else if (isAuthenticated && validEntryPoint) {
							return (
								<AuthWrapper requiredPermission={requiredPermission} alternateDisplay={alternateDisplay}>
									<Component {...rest} />
								</AuthWrapper>
							);
						} else if (isAuthenticated && enableRootUrl && !validEntryPoint) {
							return <Redirect to={{ pathname: routePrefix ? `/${routePrefix}` : '/' }} />;
						} else if (isAuthenticated && !enableRootUrl && !validEntryPoint) {
							return (
								<Redirect
									to={{
										pathname: '/invalidUrl',
										state: {
											invalidUrlMessage: invalidUrlMessage,
										},
									}}
								/>
							);
						} else {
							return (
								<Redirect
									to={{
										pathname: routePrefix ? `/${routePrefix}` : '/',
										state: {
											from: props.location,
										},
									}}
								/>
							);
						}
					}}
				/>
			</ErrorBoundary>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		compelPasswordChange: state.auth.compelPasswordChange,
		config: state.config,
		enableRootUrl: state.auth.enableRootUrl,
		isAuthenticated: state.auth.isAuthenticated,
		validEntryPoint: state.session.validEntryPoint,
		isTermsOfUseAccepted: state.auth.isTermsOfUseAccepted,
		invalidUrlMessage: state.auth.invalidUrlMessage,
		token: state.auth.token,
		tokenExpiresAt: state.auth.expiresAt,
	};
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			verifyToken,
			setValidEntryPointToTrue,
		},
		dispatch,
	);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));
