import moment from 'moment';

import { INPUT_TYPES } from '../constants/inputTypes';
import {
	mapDataToFieldConfig_Date,
	mapDataToFieldConfig_ButtonOptions,
	mapDataToFieldConfig_Dropdown,
	mapDataToFieldConfig_Text,
} from './fieldConfigUtils';
import { buildError } from '../lib/validationUtils';
import { ASC } from '../constants/availabilitySearchFields';

const RULES = {
	HOME_OR_MOBILE: { NAME: 'homeOrMobile', MESSAGE: 'Either a Home or Mobile phone is required', IS_FORM_LEVEL: true },
	CURRENT_OR_FUTURE_DATE: {
		NAME: 'currentOrFutureDate',
		MESSAGE: 'Please provide a date on or after todays date',
		IS_FORM_LEVEL: false,
	},
	BETWEEN_FIXED_MIN_AND_MAX_DATE: {
		NAME: 'betweenFixedMinAndMaxDate',
		MESSAGE: (fixedMinDate, fixedMaxDate) =>
			fixedMaxDate
				? `Please provide a date between ${fixedMinDate} and ${fixedMaxDate}.`
				: `Please provide a date after ${fixedMinDate}`,
		IS_FORM_LEVEL: false,
	},
};

const currentOrFuture = (fieldRef, values) => {
	let error = null;
	const dateUtc = moment(values.modal[ASC.appointmentStartDate]);
	const isSame = dateUtc.isSame(moment().format('YYYY-MM-DD').toString());
	const isAfter = dateUtc.isAfter(moment().format('YYYY-MM-DD').toString());
	if (!(isSame || isAfter))
		error = buildError(
			fieldRef,
			RULES.CURRENT_OR_FUTURE_DATE.NAME,
			RULES.CURRENT_OR_FUTURE_DATE.MESSAGE,
			RULES.CURRENT_OR_FUTURE_DATE.IS_FORM_LEVEL,
		);

	return error;
};

const betweenFixedMinAndMaxDate = (fieldRef, values, availabilitySearchCriteria) => {
	let error = null;
	const dateUtc = moment.utc(values.modal[ASC.appointmentStartDate]);
	const isBeforeMin = dateUtc.isBefore(moment.utc(availabilitySearchCriteria.minStartDate), 'days');
	const isAfterMax = availabilitySearchCriteria.maxStartDate
		? dateUtc.isAfter(moment.utc(availabilitySearchCriteria.maxStartDate), 'days')
		: false;
	if (isBeforeMin || isAfterMax)
		error = buildError(
			fieldRef,
			RULES.BETWEEN_FIXED_MIN_AND_MAX_DATE.NAME,
			RULES.BETWEEN_FIXED_MIN_AND_MAX_DATE.MESSAGE(
				availabilitySearchCriteria.minStartDate,
				availabilitySearchCriteria.maxStartDate,
			),
			RULES.BETWEEN_FIXED_MIN_AND_MAX_DATE.IS_FORM_LEVEL,
		);

	return error;
};

export const getFieldsConfig = (searchFields) => {
	const {
		appointmentStartDate,
		appointmentModalityFilter,
		days,
		language,
		providerGender,
		searchRadius,
		timeOfDay,
		siteFilter,
		providerFilter,
		sortOrder,
		zipCode,
	} = searchFields;

	let config = {
		modal: {
			[ASC.appointmentStartDate]: {
				...mapDataToFieldConfig_Date({
					fieldConfig: appointmentStartDate,
					inputType: INPUT_TYPES.DATE,
					minValue: Date.now(),
				}),
				validationRules: [
					(fieldRef, values, props) => currentOrFuture(fieldRef, values),
					(fieldRef, values, props) => betweenFixedMinAndMaxDate(fieldRef, values, props.availabilitySearchCriteria),
				],
			},
			[ASC.daysOfWeek]: {
				...mapDataToFieldConfig_ButtonOptions({
					fieldConfig: days,
					inputType: INPUT_TYPES.BUTTON_OPTIONS,
					isMultiSelect: true,
				}),
			},
			[ASC.timeOfDay]: {
				...mapDataToFieldConfig_ButtonOptions({
					fieldConfig: timeOfDay,
					inputType: INPUT_TYPES.BUTTON_OPTIONS,
					isMultiSelect: true,
				}),
			},
			[ASC.providerGender]: {
				...mapDataToFieldConfig_ButtonOptions({
					fieldConfig: providerGender,
					inputType: INPUT_TYPES.BUTTON_OPTIONS,
					isMultiSelect: false,
				}),
			},
			[ASC.languagePreference]: {
				...mapDataToFieldConfig_Dropdown({ fieldConfig: language, inputType: INPUT_TYPES.DROPDOWN }),
			},
			[ASC.searchRadius]: {
				...mapDataToFieldConfig_ButtonOptions({
					fieldConfig: searchRadius,
					inputType: INPUT_TYPES.BUTTON_OPTIONS,
					isMultiSelect: false,
				}),
				isInteger: true,
			},
			[ASC.zipCode]: {
				...mapDataToFieldConfig_Text({ fieldConfig: zipCode, inputType: INPUT_TYPES.ZIP_CODE }),
			},
		},
		navBar: {
			[ASC.sortOrder]: {
				...mapDataToFieldConfig_Dropdown({ fieldConfig: sortOrder, inputType: INPUT_TYPES.DROPDOWN }),
			},
			[ASC.serviceName]: {
				...mapDataToFieldConfig_Dropdown({ fieldConfig: providerFilter, inputType: INPUT_TYPES.DROPDOWN }),
			},
			[ASC.siteFilter]: {
				...mapDataToFieldConfig_Dropdown({ fieldConfig: siteFilter, inputType: INPUT_TYPES.DROPDOWN }),
			},
			[ASC.appointmentModalityFilter]: {
				...mapDataToFieldConfig_Dropdown({ fieldConfig: appointmentModalityFilter, inputType: INPUT_TYPES.DROPDOWN }),
			},
		},
	};

	return config;
};
