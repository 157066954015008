import React, { useState, useEffect } from 'react';
import FieldControl from '../input/fieldControl';
import Message from './guidedResponseMessage';
import { GuidedResponseDropdownSelect } from './guidedResponseSelection';

/**
 * @param {Object} props
 * @param {string} props.id
 * @param {Object} props.error
 * @param {string} props.error.message
 * @param {boolean} props.disabled
 * @param {boolean} props.isFocused
 * @param {boolean} props.isRequired
 * @param {boolean} props.isVisible - If False Component Returns null
 * @param {boolean} props.label
 * @param {boolean} props.labelAfter - If true, label is placed after input (default before)
 * @param {string} props.name
 * @param {Object} props.value
 * @param {Function} props.onBlur
 * @param {Function} props.onChange
 * @param {Function} props.onFocus
 * @param {Array} props.optionList
 * @param {string} props.size - "sm", "md", "lg"
 * @returns {Object}
 */
export function DropdownSelectWithConditionalText(props) {
	const {
		id,
		error,
		disabled,
		isFocused,
		isRequired,
		isVisible,
		label,
		labelAfter,
		defaultOptionLabel,
		name,
		value,
		altValue,
		onBlur,
		onChange,
		onDropdownChange,
		onFocus,
		optionList,
		size,
		conditionalTextTriggerValue,
		conditionalTextInputLabel,
		conditionalTextInputRequired,
	} = props;

	const inputType = 'dropdown';
	const hasValue = !!value || value === 0;
	
	const [toggleState, setToggleState] = useState(false);

	useEffect(() => {
		setToggleState(value === conditionalTextTriggerValue);
	},[value, conditionalTextTriggerValue])
	
	const handleSelectionChange = (selection) => {
		onDropdownChange(selection);
		setToggleState(value === conditionalTextTriggerValue);
	};

	return (
		<div>
			<GuidedResponseDropdownSelect
				optionList={optionList}
				defaultOptionLabel={defaultOptionLabel}
				disabled={disabled}
				error={error}
				hasValue={hasValue}
				isFocused={isFocused}
				isRequired={isRequired}
				id={id}
				inputType={inputType}
				isVisible={isVisible}
				name={name}
				value={value}
				onFocus={onFocus}
				onBlur={onBlur}
				onDropdownChange={handleSelectionChange}
				onChange={onChange}
				label={label}
				labelAfter={labelAfter}
				size={size}
			/>

			{toggleState ? (
				<div style={{marginTop:"2em"}}>
					<Message message={conditionalTextInputLabel} isRequired={conditionalTextInputRequired} />
					<FieldControl
						disabled={disabled}
						error={error}
						hasValue={hasValue}
						isFocused={isFocused}
						isRequired={isRequired}
						id={id}
						inputType={inputType}
						isVisible={isVisible}
						label={label}
						labelAfter={labelAfter}
						size={size}
					>
						<input
							disabled={disabled}
							className="conditionalTextInput"
							style={{margin: 0}}
							id={id}
							type={inputType}
							name={name}
							value={altValue}
							onChange={(e) => {
								onChange(e) 
							}}
							onFocus={onFocus}
							onBlur={onBlur}
						/>
					</FieldControl>
				</div>
			) : null}
		</div>
	);
}
