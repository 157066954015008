//3rd party
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//actions
import { setEntryPointUrl, redirectToBookingProcess, initializeBookingProcess } from '../../actions/workflowActions';
import { getBookingTransitionDetails } from '../../actions/appointmentActions';

//constants
import * as flowTypes from '../../constants/flowTypeEnum';

//lib

//comps
import { LoadingIndicatorModal } from '../../components/loadingIndicator/loadingIndicatorModal';

export class StartBookingTransitionRedirector extends React.Component {
	constructor(props) {
		super(props);
		this.bookingTransitionId = this.props.match.params.bookingTransitionId;
		this.state = {
			alertMessage: '',
			showLoadingIndicator: true,
			validDataLoaded: false,
			showAlert: false,
		};
	}

	dismissAlert = () => {
		this.setState({ showAlert: false });
	};

	componentDidMount() {
		if (this.bookingTransitionId) {
			this.loadBookingTransitionDetails();
			this.props.setEntryPointUrl(flowTypes.BOOKING_TRANSITION_FLOW, this.bookingTransitionId);
		} else {
			this.setState({
				showAlert: true,
				showLoadingIndicator: false,
				alertMessage: 'No identifier found.  Please try again.',
			});
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.session.bookingInitialized) {
			let { useDecisionSupport } = nextProps.config.decisionSupport;
			let { auth, patientAuthContext, bookingContext } = nextProps;
			nextProps.redirectToBookingProcess(
				auth,
				patientAuthContext,
				useDecisionSupport,
				bookingContext,
				nextProps.config.instance.routePrefix,
			);
		} else if (
			nextProps.reservation.reservationId &&
			(!this.props.reservation.reservationId ||
				this.props.reservation.reservationId !== nextProps.reservation.reservationId)
		) {
			let { useDecisionSupport } = nextProps.config.decisionSupport;
			let { availabilitySearchConfig, correlationKey, activeCareOrder, decisionSupportOutput, activePatient } =
				nextProps;
			nextProps.initializeBookingProcess(
				useDecisionSupport,
				correlationKey,
				activeCareOrder,
				decisionSupportOutput,
				availabilitySearchConfig,
				activePatient,
			);
		}
	}

	loadBookingTransitionDetails = () => {
		this.props
			.getBookingTransitionDetails(this.bookingTransitionId)
			.then((response) => {
				if (response.error) {
					this.setState({
						showAlert: true,
						showLoadingIndicator: false,
						alertMessage: 'An error occurred while trying to retrieve details.',
					});
				} else {
					this.setState({ showLoadingIndicator: false, validDataLoaded: true });
				}
			})
			.catch((err) => {
				this.setState({
					alertMessage: 'An error occurred while trying to retrieve details.',
					showLoadingIndicator: false,
				});
			});
	};

	render() {
		let { showLoadingIndicator, validDataLoaded } = this.state;
		return (
			<div>
				{!showLoadingIndicator && !validDataLoaded && (
					<div className={'alert alert-info closed-message'}>
						There was a problem loading your details. Please try again.
					</div>
				)}
				{showLoadingIndicator && <LoadingIndicatorModal />}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		activeCareOrder: state.careOrder,
		activePatient: state.patient,
		availabilitySearchConfig: state.config.availabilitySearch,
		auth: state.auth,
		bookingContext: state.session.bookingContext,
		careOrderClosedMessage: state.auth.careOrderClosedMessage,
		config: state.config,
		correlationKey: state.session.correlationKey,
		decisionSupportOutput: state.decisionSupport,
		patientAuthContext: state.patient.patientAuthContext,
		reservation: state.appointment.reservation,
		session: state.session,
		token: state.auth.token,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setEntryPointUrl,
			redirectToBookingProcess,
			initializeBookingProcess,
			getBookingTransitionDetails,
		},
		dispatch,
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(StartBookingTransitionRedirector);
