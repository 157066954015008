import React from 'react';
import { Modal } from '../../../components/modal/modal';
import * as Button from '../../../components/buttons';
import * as Icon from '../../../components/icons';

export function PostBookAppointmentCustomFieldsForm(props) {
	const { isLoading, handlePostBookModalConfirmation, postBookCustomFields, togglePostBookModal } = props;
	return (
		<Modal
			containerId="postBookCustomFieldsModalContainer"
			modalId="postBookCustomFieldsModal"
			onClickOffModalHandler={togglePostBookModal}
		>
			<div className="filter-modal-header">
				<h1>Additional Questions</h1>
				<Icon.IconClose onClick={(e) => togglePostBookModal(e, true)} />
			</div>
			<form onSubmit={handlePostBookModalConfirmation} className="post-book-custom-fields-form">
				{postBookCustomFields}
				<div className="section-row">
					<Button.ButtonPrimary
						isLoading={isLoading}
						disabled={isLoading}
						type="submit"
						onClick={handlePostBookModalConfirmation}
					>
						Confirm
					</Button.ButtonPrimary>
				</div>
			</form>
		</Modal>
	);
}
