import React from 'react';
import * as Icon from '../icons';

export function SiteNameAndDistance(props) {
	const { providerSiteDisplayName, distanceMiles, zipCode, showDistanceMiles } = props;

	const distance = distanceMiles?.toFixed(1);

	return (
		<div className="site-name-and-distance-container">
			<span className="site-name">{providerSiteDisplayName}</span>
			{showDistanceMiles && (
				<span className="distance-and-zip">
					<Icon.IconMapMarker size={'sm'} className={'map-marker'} />
					<span className="distance">{distance}</span>
					<span> mi from </span>
					<span className="zip">{zipCode}</span>
				</span>
			)}
		</div>
	);
}
