import moment from 'moment';
import { isValidPhoneNumber } from 'react-phone-number-input';

const emailRegexBuilder = () => {
	const localPartFirstCharacter = "[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]";
	const localPartCharacters = "[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]";
	const domain = "[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?";
	const additionalDomain = "(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)";

	return new RegExp(`^${localPartFirstCharacter}{1}${localPartCharacters}*@${domain}${additionalDomain}+$`);
};

const emailRegex = emailRegexBuilder();
const yearCodeRegex = /^\d{4}$/;
const zipCodeRegex = /^\d{5}$/;
// const phoneNumberRegex = /(\d{3})-(\d{3})-(\d{4})/;
const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const validateYear = value => yearCodeRegex.test(value);
export const validateZipCode = value => zipCodeRegex.test(value);
export const validatePhoneNumber = value => isValidPhoneNumber(value, 'US');
export const validateEmail = value => emailRegex.test(value);

export const validateDate = (dateString) => {
	if (dateString && dateString.trim().length < 10) {
		return false;
	}

	if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
		return false;

	var parts = dateString.split("/");
	var day = parseInt(parts[1], 10);
	var month = parseInt(parts[0], 10);
	var year = parseInt(parts[2], 10);

	if (year < 1900 || year > 3000 || month === 0 || month > 12)
		return false;

	var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

	if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
		monthLength[1] = 29;

	return day > 0 && day <= monthLength[month - 1];
};

export function validateDateOfBirth(value) {
	let isValid = validateDate(value);
	if (!isValid) {
		return isValid;
	}
	let dateUtc = moment.utc(value);
	if (moment.utc() < dateUtc) {
		isValid = false;
	}
	return isValid;
}

export const validatePastDate = (value) => {
	const now = moment.utc();
	const dateUtc = moment.utc(value);
	return dateUtc.isValid() && dateUtc.isSameOrBefore(now, 'day');
}

export const validateFutureDate = (value) => {
	const now = moment.utc();
	const dateUtc = moment.utc(value);
	return dateUtc.isValid() && dateUtc.isSameOrAfter(now, 'day');
}

export const validateAnyDate = (value) => {
	const dateUtc = moment.utc(value);
	return dateUtc.isValid();
}

export const validateGuid = (value) => {
	let result = guidRegex.test(value);
	return result;
}