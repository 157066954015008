import React from 'react';
import './NotFound.css';

export const NotFound = () => {
	return (
		<div className="NotFound">
			<h3>Sorry, page not found!</h3>
		</div>
	);
};

export default NotFound;
