import * as types from '../actions/actionTypes';

const initialState = {
	isAuthenticated: false,
	token: '',
	expiresAt: null,
	userId: '',
	role: '',
	referralSystemId: 0,
	referralSiteId: 0,
	name: '',
	productInstanceId: 0,
	permissions: [],
	logo: '',
	headerColor: '',
	backgroundColor: '',
	footerLogo: '/resources/eh-logo-white.svg',
	headerFooterFontColor: '',
	careOrderClosedMessage: '', // refactor and remove this
	customStyles: '',
	enableRootUrl: false,
	invalidRootUrlMessage: '',
	overrideSiteHomeUrl: false,
	forceQueryStringLowerCase: false,
	allowStartBookingUrlAdditionalInputs: false,
	siteHomeUrl: '',
	applicationInsightsKey: '',
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.AUTHENTICATE_USER_SUCCESS: {
			return {
				backgroundImageFileName: action.payload.data.config.branding.backgroundImageFileName,
				faviconFileName: action.payload.data.config.branding.faviconFileName,
				isAuthenticated: true,
				token: action.payload.data.token,
				expiresAt: new Date(action.payload.data.expiresAt),
				userId: action.payload.data.config.anonymousAuth.userId,
				role: action.payload.data.role,
				referralSystemId: action.payload.data.referralSystemId,
				referralSiteId: action.payload.data.referralSiteId,
				permissions: action.payload.data.permissions,
				name: action.payload.data.config.name,
				productInstanceId: action.payload.data.config.id,
				logo: action.payload.data.config.branding.logo,
				headerColor: action.payload.data.config.branding.headerColor,
				backgroundColor: action.payload.data.config.branding.backgroundColor,
				footerLogo: action.payload.data.config.footerLogo,
				headerFooterFontColor: action.payload.data.config.branding.headerFooterFontColor,
				careOrderClosedMessage: action.payload.data.config.scheduling.careOrderClosedMessage,
				customStyles: action.payload.data.config.branding.customStyles,
				enableRootUrl: action.payload.data.config.routing.enableRootUrl,
				invalidRootUrlMessage: action.payload.data.config.routing.invalidRootUrlMessage,
				overrideSiteHomeUrl: action.payload.data.config.routing.overrideSiteHomeUrl,
				siteHomeUrl: action.payload.data.config.routing.siteHomeUrl,
				forceQueryStringLowerCase: action.payload.data.config.routing.forceQueryStringLowerCase,
				allowStartBookingUrlAdditionalInputs: action.payload.data.config.routing.allowStartBookingUrlAdditionalInputs,
				applicationInsightsKey: action.payload.data.config.applicationInsightsKey,
			};
		}
		case types.AUTHENTICATE_USER_ERROR:
			return {
				isAuthenticated: false,
			};
		case types.VERIFY_TOKEN_SUCCESS:
			return {
				...state,
				isAuthenticated: true,
				token: action.payload.data.token,
				expiresAt: new Date(action.payload.data.expiresAt),
				userId: action.payload.data.config.anonymousAuth.userId,
				role: action.payload.data.role,
				referralSystemId: action.payload.data.referralSystemId,
				referralSiteId: action.payload.data.referralSiteId,
				permissions: action.payload.data.permissions,
				name: action.payload.data.config.name,
				productInstanceId: action.payload.data.config.id,
				logo: action.payload.data.config.branding.logo,
				headerColor: action.payload.data.config.branding.headerColor,
				backgroundColor: action.payload.data.config.branding.backgroundColor,
				footerLogo: action.payload.data.config.footerLogo,
				headerFooterFontColor: action.payload.data.config.branding.headerFooterFontColor,
				careOrderClosedMessage: action.payload.data.config.scheduling.careOrderClosedMessage,
				customStyles: action.payload.data.config.branding.customStyles,
				enableRootUrl: action.payload.data.config.routing.enableRootUrl,
				invalidRootUrlMessage: action.payload.data.config.routing.invalidRootUrlMessage,
				overrideSiteHomeUrl: action.payload.data.config.routing.overrideSiteHomeUrl,
				siteHomeUrl: action.payload.data.config.routing.siteHomeUrl,
				forceQueryStringLowerCase: action.payload.data.config.routing.forceQueryStringLowerCase,
				allowStartBookingUrlAdditionalInputs: action.payload.data.config.routing.allowStartBookingUrlAdditionalInputs,
				applicationInsightsKey: action.payload.data.config.applicationInsightsKey,
			};
		default:
			return state;
	}
}
