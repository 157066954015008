import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextInput from './textInput';
import moment from 'moment';

class DateInput extends React.Component {
	handleChange = ({ target: { name, value } }) => {
		const { validate, onChange } = this.props;
		var error = false;

		var date = moment(value);
		if (!date.isValid() || date.year() <= 1900 || date.year() >= 2100) {
			error = true;
		} else {
			error = validate(value);
		}

		return onChange({ target: { name, value } }, error);
	};

	render() {
		const { id, name, value, type, className, validate, onChange, ...rest } = this.props;

		return (
			<NumberFormat
				format="##/##/####"
				customInput={TextInput}
				id={id}
				name={name}
				type={type}
				onChange={this.handleChange}
				value={value}
				mask="_"
				className={className}
				{...rest}
			/>
		);
	}
}

DateInput.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	type: PropTypes.string.isRequired,
	validate: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.string]),
};

DateInput.defaultProps = {
	validate: (value) => !(value && value.length),
};

export default DateInput;
