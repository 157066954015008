import React from 'react';
import FieldControl from './fieldControl';
import * as multiValue from '../../lib/multiValueUtils';

/**
* @param {Object} props
* @param {string} props.id
* @param {Object} props.error
* @param {string} props.error.message
* @param {boolean} props.disabled
* @param {boolean} props.isFocused
* @param {boolean} props.isRequired
* @param {boolean} props.isVisible - If False Component Returns null
* @param {boolean} props.label
* @param {boolean} props.labelAfter - If true, label is placed after input (default before)
* @param {string} props.name
* @param {Object} props.value
* @param {Function} props.onBlur
* @param {Function} props.onChange
* @param {Function} props.onFocus
* @param {Array} props.optionList
* @param {string} props.size - "sm", "md", "lg"
* @returns {Object}
*/
export function CheckboxMultiSelect(props) {
	const {
		id,
		error,
		disabled,
		isFocused,
		isRequired,
		isVisible,
		label,
		labelAfter,
		name,
		value,
		onBlur,
		onChange,
		onFocus,
		optionList,
		size,
	} = props;
	const values = multiValue.split(value);

	return (
		<FieldControl
			disabled={disabled}
			error={error}
			hasValue={!!value}
			isFocused={isFocused}
			id={id}
			inputType="multi-select"
			isRequired={isRequired}
			isVisible={isVisible}
			label={label}
			labelAfter={labelAfter}
			size={size}
		>
			{optionList && optionList.map(option =>
				<div className="checkbox-option" key={option.id}>
					<input
						checked={!!values.find(id => id === option.id)}
						disabled={disabled}
						id={option.id}
						name={name}
						onChange={onChange}
						onFocus={onFocus}
						onBlur={onBlur}
						type="checkbox"
						value={option.id}
					/>
					<label htmlFor={option.id}>{option.name}</label>
				</div>
			)}
		</FieldControl>
	);
}
