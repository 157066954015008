export const CARE_ORDER_FLOW = 'CARE_ORDER_FLOW';
export const SURVEY_ORDER_FLOW = 'SURVEY_ORDER_FLOW';
export const AVAILABILITY_SURVEY_FLOW = 'AVAILABILITY_SURVEY_FLOW';
export const BOOKING_TRANSITION_FLOW = 'BOOKING_TRANSITION_FLOW';
export const START_BOOKING_FLOW = 'START_BOOKING_FLOW';
export const CANCEL_APPOINTMENT_FLOW = 'CANCEL_APPOINTMENT_FLOW';
export const DEFAULT_FLOW = 'DEFAULT_FLOW';

export const URLS = {
	[BOOKING_TRANSITION_FLOW]: (guid) => `start/bookingtransition/${guid}`,
	[CANCEL_APPOINTMENT_FLOW]: (guid) => `appointment/cancel/${guid}`,
	[CARE_ORDER_FLOW]: (guid) => `start/careordervisit/${guid}/book`,
	[START_BOOKING_FLOW]: (guid) => `start/booking`,
	[SURVEY_ORDER_FLOW]: (guid) => `start/surveyorder/${guid}`,
	[DEFAULT_FLOW]: (guid) => '',
};
