import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const AuthWrapper = (props) => {
	const { requiredPermission, currentPermissions, alternateDisplay, children } = props;

	if (!requiredPermission || (currentPermissions && currentPermissions.includes(requiredPermission))) {
		return children;
	} else {
		return alternateDisplay || null;
	}
};

const mapStateToProps = (state, ownProps) => {
	return {
		currentPermissions: state.auth.permissions,
	};
};

export default connect(mapStateToProps)(AuthWrapper);

AuthWrapper.propTypes = {
	requiredPermission: PropTypes.string,
	alternateDisplay: PropTypes.element,
};
