import React from 'react';
import { Button } from './button';
import classNames from 'classnames';

/**
* @param {Object} props
* @param {Object} props.children
* @param {string} props.className
* @param {boolean} props.disabled
* @param {string} props.id
* @param {boolean} props.isLoading
* @param {boolean} props.isSelected
 * @param {string} props.name
* @param {Function} props.onClick
* @param {string} props.size - "sm", "md", "lg"
* @param {string} props.type
 * @param {Object} props.value
* @returns {Object}
*/
export function ButtonSelectionOption(props) {
	const { children, className, disabled, id, isLoading, isSelected, name, onClick, size, type, value, } = props;
	const buttonClassName = classNames(
		"selection-option",
		className,
	);
	return (
		<Button
			className={buttonClassName}
			disabled={disabled}
			id={id}
			isLoading={isLoading}
			isSelected={isSelected}
			name={name}
			onClick={onClick}
			size={size}
			type={type}
			value={value}
		>
			{children}
		</Button>
	);
};
