import React from 'react';
import moment from 'moment';
import { Calendar, momentLocalizer, Navigate } from 'react-big-calendar';
import classNames from 'classnames';
import * as Icon from '../icons';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

export function AvailabilityCalendar(props) {
	const { availabilityResults, handleSelectCalendarDetails, isMobile, handleGoToDate, selectedDate, setSelectedDate } =
		props;

	let events = availabilityResults.hasSingleCalendarResults
		? availabilityResults.singleCalendarResults.map((x) => {
				return {
					...x,
					key: x.timeSlotAppointmentTypeId, // was uuidv4(), why?
					start: x.localStartAt,
					end: x.localEndAt,
					title: moment(x.localStartAt).format('h:mm a'),
					allDay: false,
					resource: null,
					availabilityId: x.timeSlotAppointmentTypeId,

					//TODO: Determine if we still need above repeat code
					timeSlotId: x.timeSlotAppointmentTypeId,
					//TODO: verify with Patrick, was localStartAt
					date: x.date,
					startTime: x.startTime,
					endTime: x.endTime,

					formattedStartTime: x.formattedTime,
				};
		  })
		: [];

	const hasSlots = (date) => {
		let hasSlots = false;
		if (availabilityResults.hasSingleCalendarResults) {
			hasSlots = availabilityResults.singleCalendarResults.find((x) => {
				return moment(x.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD');
			});
		}
		return hasSlots;
	};

	const handleDayClick = (event) => {
		let date = moment(event.start).format();
		if (hasSlots(date)) {
			setSelectedDate(moment(date));
		}
	};

	const handleNavigateMonth = (newDate) => {
		//handles react-big-calendar and slot display
		setSelectedDate(moment(newDate));

		// handles actual searches
		handleGoToDate(newDate);
	};

	const toolbar = (toolbar) => {
		const goToBack = () => {
			toolbar.onNavigate(Navigate.PREVIOUS);
		};

		const goToNext = () => {
			toolbar.onNavigate(Navigate.NEXT);
		};

		const label = () => {
			const date = moment(toolbar.date);
			return (
				<h3>{date.format('MMMM')} {date.format('YYYY')}</h3>
			);
		};

		return (
			<div className="rbc-toolbar">
				<div className="prev-month">
					{availabilityResults.allowSearchPreviousPage && (
						<span onClick={goToBack}>
							<Icon.IconChevronLeft />
							<span>Prev</span>
						</span>
					)}
				</div>
				<div className="rbc-toolbar-label">
					<span>{label()}</span>
				</div>
				<div className="next-month">
					{availabilityResults.allowSearchNextPage && (
						<span onClick={goToNext}>
							<span>Next</span>
							<Icon.IconChevronRight />
						</span>
					)}
				</div>
			</div>
		);
	};

	//TODO moment.utc?
	const dateHeader = ({ date, label }) => {
		let momentDateString = moment(date).format('YYYY-MM-DD');
		let dateEvents = events.filter((event) => momentDateString === moment(event.date).format('YYYY-MM-DD'));
		let numOfEvents = dateEvents ? dateEvents.length : null;
		let highlightDate =
			events.find((event) => momentDateString === moment(event.date).format('YYYY-MM-DD')) !== undefined;
		let compareDate = moment(selectedDate).format('YYYY-MM-DD');
		let activeDate = momentDateString === compareDate;
		const isToday = momentDateString === moment().format('YYYY-MM-DD');
		const containerClassName = classNames(
			'rbc-date-container',
			{ selected: activeDate },
			{ active: highlightDate },
			{ inactive: !highlightDate },
			{ 'rbc-today': isToday },
		);
		const dateHeaderClassName = classNames(
			{ 'rbc-selected-date': activeDate },
			{ 'rbc-active-date': !activeDate && highlightDate },
			{ 'rbc-inactive-date': !activeDate && !highlightDate },
		);
		const appointmentCountClassName = classNames('appointment-count', { selected: activeDate });

		let returnComp =
			numOfEvents && !isMobile ? (
				<div className={containerClassName}>
					<h3 className={dateHeaderClassName}>{label}</h3>
					<span className={appointmentCountClassName}>{numOfEvents + ` Appt${numOfEvents > 1 ? 's' : ''}`}</span>
				</div>
			) : (
				<div className={containerClassName}>
					<h3 className={dateHeaderClassName}>{label}</h3>
				</div>
			);
		return returnComp;
	};

	return (
		<div className="calendar-container">
			<Calendar
				back={() => handleSelectCalendarDetails(null, availabilityResults.minAllowedMonthStartDate)}
				components={{
					month: {
						toolbar: toolbar,
						dateHeader: dateHeader,
					},
				}}
				date={selectedDate ? selectedDate.toDate() : new Date()}
				defaultView="month"
				events={events}
				localizer={localizer}
				longPressThreshold={1}
				onDrillDown={handleDayClick}
				onNavigate={(date, view, action) => {
					switch (action) {
						case Navigate.PREVIOUS:
							if (availabilityResults.allowSearchPreviousPage) {
								handleNavigateMonth(availabilityResults.effectivePreviousPageStartDate);
							}
							break;
						case Navigate.NEXT:
							if (availabilityResults.allowSearchNextPage) {
								handleNavigateMonth(availabilityResults.effectiveNextPageStartDate);
							}
							break;
						default:
							break;
					}
				}}
				onSelectSlot={handleDayClick}
				selectable={true}
				style={{ height: '100%' }}
				views={['month']}
			/>
		</div>
	);
}
