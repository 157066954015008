import { useEffect, useState } from "react"
import DateNav from "./dateNav";
import Availability from "./availability";
import Calendar from "./calendar";
import { useSelector } from "react-redux";
import { selectViewBaseDate } from "../availabilitySlice";
import AvailabilityMobile from "./availabilityMobile";
import { skipOverSuppressedDates } from "../../../lib/date";
import { selectFirstDisplayableDateWithSlotForCalendarById, selectShouldSuppressSaturday, selectShouldSuppressSunday } from "../availabilitySlice/availabilitySelectors";

const ResultRow = ({ isMobile, calendarId }) => {
    const viewBaseDate = useSelector(selectViewBaseDate);
    const firstDisplayableDateWithSlot = useSelector(state => selectFirstDisplayableDateWithSlotForCalendarById(state, calendarId));
    const suppressSaturday = useSelector(selectShouldSuppressSaturday);
    const suppressSunday = useSelector(selectShouldSuppressSunday);
    const [selectedDate, setSelectedDate] = useState(skipOverSuppressedDates(firstDisplayableDateWithSlot ?? viewBaseDate, suppressSaturday, suppressSunday));

    useEffect(() => {
        setSelectedDate(skipOverSuppressedDates(firstDisplayableDateWithSlot ?? viewBaseDate, suppressSaturday, suppressSunday));
    }, [setSelectedDate, firstDisplayableDateWithSlot, viewBaseDate, suppressSaturday, suppressSunday])

    return (
        <div className="provider-result-row">
            <Calendar
                calendarId={calendarId}
            />
            {isMobile &&
                <>
                    <DateNav
                        isMobile={isMobile}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        calendarId={calendarId}
                    />
                    <AvailabilityMobile
                        calendarId={calendarId}
                        date={selectedDate}
                    />
                </>
            }
            {!isMobile &&
                <Availability
                    calendarId={calendarId}
                />
            }
        </div>
    )
}

export default ResultRow;
