import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { showModal } from '../../actions/modalActions';

import { MODAL_TYPE_NOTIFICATION } from '../../constants/modalTypes';

import TermsOfUse from '../legal/termsOfUse'

import './footer.css'

import {
	Col,
	Row,
} from 'reactstrap';

export class Footer extends React.Component {
	constructor() {
		super();

		var today = new Date();
		var year = today.getFullYear();

		this.state = {
			year: year
		};
	}

	showTermsOfUse = (event) => {
		event.preventDefault();
		this.props.showModal(MODAL_TYPE_NOTIFICATION, {
			title: 'Terms of Use',
			children: <TermsOfUse />,
			contentClassName: 'scrollable',
			size: 'lg'
		});
	};

	render() {
		let headerFooterFont = this.props.fontColor ? this.props.fontColor : '#FFFFFF';
		return (
			<Row className="footer2">
				<Col className="text-center">
					<span style={{ color: headerFooterFont }}>© {this.state.year} <a style={{ color: headerFooterFont }} href="http://www.myhealthdirect.com">Experian Health. All Rights Reserved.</a></span>
					{false && 
						<React.Fragment>
							<span className="nh-seperator">|</span>
							<Link to="/terms-of-use" onClick={this.showTermsOfUse} style={{ color: headerFooterFont }} className="nh-terms-of-service">Terms of Use</Link>
						</React.Fragment>
					}
				</Col>
			</Row>
		)
	}
}

export default connect(null, { showModal })(Footer);
