import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from "react-router-dom";

export function analyticsTracker(WrappedComponent, options) {
	const initializeTracker = (analyticsToken, analyticsTrackerName) => {
		var options = {
			allowLinker: true
		};
		if (analyticsTrackerName) {
			options.name = analyticsTrackerName; 
		}
		ReactGA.initialize(analyticsToken, {
			gaOptions: options
		});
	}

	const trackPage = page => {
		ReactGA.set({
			page,
			...options
		});
		ReactGA.pageview(page);
	};

	const HOC = props => {
		const location = useLocation();
		const [analyticsInitialized, setAnalyticsInitialized] = useState(false);

		useEffect(() => {
			if (props.analyticsToken && props.analyticsToken.trim() !== "" && !analyticsInitialized) {
				initializeTracker(props.analyticsToken, props.analyticsTrackerName);
				setAnalyticsInitialized(true);
			}
		}, [props.analyticsToken, props.analyticsTrackerName, analyticsInitialized, setAnalyticsInitialized]);

		useEffect(() => {
			if (analyticsInitialized) {
				let page = window.location.origin + location.pathname + location.search;
				trackPage(page);
			}
		}, [analyticsInitialized, location]);

		return <WrappedComponent {...props} />;
	};

	const mapDispatchToProps = (dispatch) => bindActionCreators({

	}, dispatch);

	const mapStateToProps = (state, ownProps) => {
		return {
			analyticsToken: state.config.analytics.analyticsToken,
			analyticsTrackerName: state.config.analytics.analyticsTrackerName
		};
	}

	return connect(mapStateToProps, mapDispatchToProps)(HOC);
};

export default analyticsTracker;