
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import {
	searchCalendarsThunk,
	selectFilteredCalendarCount,
	selectFilteredPreferredCalendarCount,
	selectFirstDisplayableDateWithSlotForCalendarById,
	selectIsScanningFirstAvailabilityNotPreferred,
	selectIsScanningFirstAvailabilityPreferred,
	selectIsSearchingCalendars,
	selectSelectedCalendarId,
	selectShowReturnToQuestionsButton,
	flushAvailabilityAndScanResults,
} from './availabilitySlice';

import ActiveErrors from '../../components/misc/activeErrors';
import BackToGuidedResponseButton from './comps/backToGuidedResponseButton';
import { AvailabilityResultsHeader } from './comps/availabilityResultsHeader';
import { LoadingIndicatorModal } from '../../components/loadingIndicator/loadingIndicatorModal';
import AvailabilityResultsFilters from './comps/availabilityResultsFilters';
import { scrollToPageTop } from '../../lib/misc';
import PreferredResults from './comps/preferredResults';
import Results from './comps/results';
import SelectedCalendarAvailabilityCalendar from './comps/selectedCalendarAvailabilityCalendar';
import SelectedCalendarDetails from './comps/selectedCalendarDetails';
import SelectedCalendarExitButtons from './comps/selectedCalendarExitButtons';
import Pagination from './comps/pagination';
import { NoResultsDisplay } from '../../components/misc/miscComponents';
import AvailabilitySelectedCalendar from './comps/availabilitySelectedCalendar';
import { nullifySuppressedDates } from '../../lib/date';
import { selectShouldSuppressSaturday, selectShouldSuppressSunday } from './availabilitySlice/availabilitySelectors';

const MAX_MOBILE_WIDTH = 992;

const AvailabilitySearchView = (props) => {
	const dispatch = useDispatch();

	const isMobile = useMediaQuery({ query: `(max-width: ${MAX_MOBILE_WIDTH}px)` });

	const shouldAutoSearch = useSelector(state => state.careOrder?.activeCareOrderDetails?.appointments || state.config.decisionSupport.useDecisionSupport);
	const selectedCalendarId = useSelector(selectSelectedCalendarId);
	const isSearchingCalendars = useSelector(selectIsSearchingCalendars);
	const isScanningFirstAvailabilityPreferred = useSelector(selectIsScanningFirstAvailabilityPreferred);
	const isScanningFirstAvailabilityNotPreferred = useSelector(selectIsScanningFirstAvailabilityNotPreferred);
	const filteredPreferredCalendarCount = useSelector(selectFilteredPreferredCalendarCount);
	const errors = useSelector(state => state.apiErrors?.activeErrors);
	const showReturnToQuestionsButton = useSelector(selectShowReturnToQuestionsButton);
	const headerConfig = useSelector(state => state.config.clientBranding.availabilitySearchHeader);
	const filteredCalendarCount = useSelector(selectFilteredCalendarCount);
	const firstDisplayableDateWithSlotForSelectedCalendar = useSelector(state => selectFirstDisplayableDateWithSlotForCalendarById(state, selectedCalendarId));
	const isReserving = useSelector(state => state.appointment.isLoading.reserveAppointment);

	const suppressSaturday = useSelector(selectShouldSuppressSaturday);
	const suppressSunday = useSelector(selectShouldSuppressSunday);

	const [selectedCalendarSelectedDate, setSelectedCalendarSelectedDate] = useState(nullifySuppressedDates(firstDisplayableDateWithSlotForSelectedCalendar, suppressSaturday, suppressSunday));

	const showHeader = !!filteredCalendarCount && headerConfig?.isVisible;
	const showCalendarSearchFirstScanLoadingIndicator =
		isSearchingCalendars
		|| (filteredPreferredCalendarCount && isScanningFirstAvailabilityPreferred)
		|| (!filteredPreferredCalendarCount && isScanningFirstAvailabilityNotPreferred);

	useEffect(() => {
		dispatch(flushAvailabilityAndScanResults())
	}, [dispatch])

	useEffect(() => {
		if (shouldAutoSearch) {
			dispatch(searchCalendarsThunk())
		}
	}, [shouldAutoSearch, dispatch])

	useEffect(() => {
		if (errors?.length) {
			scrollToPageTop();
		}
	}, [errors])

	useEffect(() => {
		setSelectedCalendarSelectedDate(nullifySuppressedDates(firstDisplayableDateWithSlotForSelectedCalendar, suppressSaturday, suppressSunday));
	}, [firstDisplayableDateWithSlotForSelectedCalendar, suppressSaturday, suppressSunday])

	return (
		<div className={`view-content ${selectedCalendarId ? 'single-provider-availability' : 'multi-provider-availability'}`}>
			<ActiveErrors />
			{showReturnToQuestionsButton && !selectedCalendarId && <BackToGuidedResponseButton />}
			<main className={'main-content'}>
				{!selectedCalendarId &&
					<>
						{showHeader && <AvailabilityResultsHeader />}
						{(showCalendarSearchFirstScanLoadingIndicator || isReserving) && <LoadingIndicatorModal />}
						<>
							<AvailabilityResultsFilters
								isMobile={isMobile}
							/>
							<PreferredResults
								isMobile={isMobile}
							/>
							<Results
								isMobile={isMobile}
							/>
							{!isSearchingCalendars && filteredCalendarCount === 0 && <NoResultsDisplay />}
							<Pagination
								isMobile={isMobile}
							/>
						</>
					</>
				}
				{selectedCalendarId &&
					<>
						{(showCalendarSearchFirstScanLoadingIndicator || isReserving) && <LoadingIndicatorModal />}
						<>
							<SelectedCalendarAvailabilityCalendar
								calendarId={selectedCalendarId}
								selectedCalendarSelectedDate={selectedCalendarSelectedDate}
								setSelectedCalendarSelectedDate={setSelectedCalendarSelectedDate}
								isMobile={isMobile}
							/>
							<div className="provider-and-slots-container">
								<SelectedCalendarDetails
									calendarId={selectedCalendarId}
								/>
								<AvailabilitySelectedCalendar
									calendarId={selectedCalendarId}
									date={selectedCalendarSelectedDate}
									isMobile={isMobile}
								/>
								<SelectedCalendarExitButtons
									calendarId={selectedCalendarId}
								/>
							</div>
						</>

					</>
				}
			</main>
		</div>
	);
};

export default AvailabilitySearchView;