import React, { Component } from 'react';
import moment from 'moment';
import DateInput from './input/dateInput';

const dateFields = {
	Month: 'ad76f288-e107-43ab-9515-41aa0f0c13a2',
	Day: '9dd46a68-9441-4db1-8381-cd8d164ef189',
	Year: '514a662e-07e7-4975-88a8-8c145dc2cb67',
};

class AppliedPathwaysQuestion_Date extends Component {
	constructor(props) {
		super(props);
		this.state = {
			asset: {},
			value: '',
		};
	}

	handleChange = ({ target: { name, value } }) => {
		const { onChange } = this.props;
		this.setState({ value });
		let unitOfMeasurementValue = this.props.asset.ap_measure_units[0].id;
		return onChange({ target: { name, value, unitOfMeasurementValue } });
	};

	static getCurrentDate(asset) {
		var currentDate = '';
		if (
			asset.value_user_response_measure_unit_values !== undefined &&
			asset.value_user_response_measure_unit_values !== null
		) {
			let year = asset.value_user_response_measure_unit_values[dateFields.Year];
			let month = parseInt(asset.value_user_response_measure_unit_values[dateFields.Month]) - 1;
			let day = asset.value_user_response_measure_unit_values[dateFields.Day];
			currentDate = moment().year(year).month(month).date(day).format('MM/DD/YYYY');
		}
		return currentDate;
	}

	componentDidMount() {
		var currentDate = AppliedPathwaysQuestion_Date.getCurrentDate(this.props.asset);
		this.setState({ asset: this.props.asset, value: currentDate });
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.asset === prevState.asset) {
			return null;
		}
		var currentDate = AppliedPathwaysQuestion_Date.getCurrentDate(nextProps.asset);
		if (currentDate === prevState.value) {
			return null;
		}
		return {
			asset: nextProps.asset,
			value: currentDate,
		};
	}

	render() {
		var isRequired = this.props.asset.is_required;
		return (
			<DateInput
				className="ap-question inputfield date-mask form-control"
				type="tel"
				id={this.props.asset.id}
				name={this.props.asset.id}
				required={isRequired}
				placeholder="mm/dd/yyyy"
				value={this.state.value}
				onChange={this.handleChange}
			/>
		);
	}
}

export default AppliedPathwaysQuestion_Date;
