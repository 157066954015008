import PropTypes from 'prop-types';

const SearchCriteriaEnums = (props) => {};

const behaviorFields = {
	Visible: 1,
	Hidden: 2,
	ReadOnly: 3,
};

const weekDays = [
	{ id: '1', name: 'Sunday', abbr: 'Sun', apiId: 0 },
	{ id: '2', name: 'Monday', abbr: 'Mon', apiId: 1 },
	{ id: '3', name: 'Tuesday', abbr: 'Tue', apiId: 2 },
	{ id: '4', name: 'Wednesday', abbr: 'Wed', apiId: 3 },
	{ id: '5', name: 'Thursday', abbr: 'Thurs', apiId: 4 },
	{ id: '6', name: 'Friday', abbr: 'Fri', apiId: 5 },
	{ id: '7', name: 'Saturday', abbr: 'Sat', apiId: 6 },
];

SearchCriteriaEnums.propTypes = {
	behaviorFields: PropTypes.oneOf(Object.keys(behaviorFields)),
};

SearchCriteriaEnums.behaviorFields = behaviorFields;
SearchCriteriaEnums.weekDays = weekDays;

export default SearchCriteriaEnums;
