import * as actionTypes from './actionTypes';

export function verifyPatientForCareOrder(patientInfo, careOrderVisitIdentifier) {
	return {
		type: actionTypes.VERIFY_PATIENT_FOR_CAREORDER,
		payload: {
			request: {
				method: 'post',
				url: `careordervisit/${careOrderVisitIdentifier}/verifypatient`,
				data: patientInfo,
			},
		},
	};
}

export function verifyPatientForSurveyOrder(patientInfo, surveyIdentifier) {
	return {
		type: actionTypes.VERIFY_PATIENT_FOR_SURVEY_ORDER,
		payload: {
			request: {
				method: 'post',
				url: `surveyorder/${surveyIdentifier}/verifypatient`,
				data: patientInfo,
			},
		},
	};
}

export function verifyPatientForAvailabilitySurvey(patientInfo, surveyIdentifier) {
	return {
		type: actionTypes.VERIFY_PATIENT_FOR_AVAILABILITY_SURVEY,
		payload: {
			request: {
				method: 'post',
				url: `survey/verify/${surveyIdentifier}`,
				data: patientInfo,
			},
		},
	};
}

export function setPatientDemographics(decisionSupportPatientDemographics) {
	return function (dispatch) {
		dispatch(setPatientDemographicsAction(decisionSupportPatientDemographics));
		return Promise.resolve();
	};
}

function setPatientDemographicsAction(decisionSupportPatientDemographics) {
	let result = {
		type: actionTypes.SET_PATIENT_DEMOGRAPHICS,
		demographics: { ...decisionSupportPatientDemographics },
	};

	return result;
}
