/**
 * @format
 */

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { LoadingIndicatorModal } from '../loadingIndicator/loadingIndicatorModal';
import { AvailabilityCalendar } from './availabilityCalendar';
import { ProviderDetailsAndSlots } from '../provider/providerDetailsAndSlots';
import { ProviderBiographyModal } from '../provider/providerBiographyModal';
import { ActiveErrors } from '../misc/activeErrors';
import { NoResultsDisplay } from '../misc/miscComponents';
import { getAvailabilityResult } from '../../lib/singleProviderAvailabilityHelper';
import { ASC } from '../../constants/availabilitySearchFields';

export default function SingleProviderAvailability(props) {
	const {
		availabilityResults,
		calendarSearchResults,
		effectiveInputCriteria,
		isLoading,
		hasDoneFirstCalendarSearch,
	} = props;

	const pageIsBusy = isLoading.anySearch || isLoading.isReserving;
	const hasCalendars = calendarSearchResults.calendars && calendarSearchResults.calendars.length > 0;
	const [showBiography, setShowBiography] = useState(false);
	const [selectedDate, setSelectedDate] = useState(
		moment(effectiveInputCriteria[ASC.appointmentStartDate])
	);

	const singleCalendarFirstAvailabilityDate = availabilityResults.singleCalendarFirstAvailabilityDate;

	/* eslint-disable */
	const selectFirstAvailableTrigger = useEffect(() => {
		if (singleCalendarFirstAvailabilityDate) {
			setSelectedDate(moment(singleCalendarFirstAvailabilityDate));
		}
	}, [singleCalendarFirstAvailabilityDate]);
	/* eslint-enable */

	const availabilityResult = getAvailabilityResult(selectedDate, availabilityResults);

	let searchPage = (
		<>
			{pageIsBusy && <LoadingIndicatorModal />}
			{hasDoneFirstCalendarSearch && !hasCalendars && <NoResultsDisplay />}
			{hasDoneFirstCalendarSearch && hasCalendars && (
				<>
					<AvailabilityCalendar selectedDate={selectedDate} setSelectedDate={setSelectedDate} {...props} />
					<ProviderDetailsAndSlots
						toggleBioModal={() => setShowBiography((x) => !x)}
						availabilityResult={availabilityResult}
						{...props}
					/>
					{showBiography && <ProviderBiographyModal toggleModal={() => setShowBiography((x) => !x)} {...props} />}
				</>
			)}
		</>
	);

	return (
		<div>
			<div className="view-content single-provider-availability">
				<ActiveErrors />
				<main className={'main-content'}>{searchPage}</main>
			</div>
		</div>
	);
}
