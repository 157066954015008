import React from 'react';

/**
 *
 * @param {import('react').PropsWithChildren<{
 *  component?: string,
 *  noWrap?: boolean,
 *  italize?: boolean,
 *  bold?: boolean
 *  textTransform?: 'uppercase' | 'lowercase' | 'capitalize',
 *  textDecoration?: string,
 *  textAlign?: 'left' | 'center' | 'right' | 'justify',
 *  whiteSpace?: import('react').CSSProperties['whiteSpace']
 *  className?: string
 * }>} props
 */
export default function Typography({
	component,
	noWrap,
	italize,
	bold,
	textAlign,
	textTransform,
	textDecoration,
	whiteSpace,
	children,
	className,
}) {
	const classesList = [
		className,
		noWrap ? 'text-nowrap' : '',
		italize ? 'font-italic' : '',
		bold ? 'fw-bold' : '',
		textAlign ? `text-${textAlign}` : '',
		textTransform ? `text-${textTransform}` : '',
	]
		.filter((x) => !!x)
		.join(' ');

	const styles = {
		textDecoration,
		whiteSpace,
	};
	const Component = component ?? 'div';

	return (
		<Component style={styles} className={classesList}>
			{children}
		</Component>
	);
}
