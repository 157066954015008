import React from 'react';
import NumberFormat from 'react-number-format';
import FieldControl from './fieldControl';
import CustomTextInput from './customTextInput';

/**
* @param {Object} props
* @param {string} props.id
* @param {Object} props.error
* @param {string} props.error.message
* @param {boolean} props.disabled
* @param {boolean} props.isFocused
* @param {boolean} props.isRequired
* @param {boolean} props.isVisible - If False Component Returns null
* @param {boolean} props.label
* @param {boolean} props.labelAfter
* @param {string} props.name
* @param {Object} props.value
* @param {Function} props.onBlur
* @param {Function} props.onChange
* @param {Function} props.onFocus
* @param {string} props.placeholder
* @param {string} props.size - "sm", "md", "lg"
* @returns {Object}
*/
export function YearInput(props) {
	const {
		id,
		error,
		disabled,
		isFocused,
		isRequired,
		isVisible,
		label,
		labelAfter,
		name,
		value,
		onBlur,
		onChange,
		onFocus,
		placeholder,
		size,
	} = props;

	const hasValue = value && (value.length > 0);
	const inputType = "year";

	return (
		<FieldControl
			disabled={disabled}
			error={error}
			hasValue={hasValue}
			isFocused={isFocused}
			isRequired={isRequired}
			id={id}
			inputType={inputType}
			isVisible={isVisible}
			label={label}
			labelAfter={labelAfter}
			size={size}
		>
			<NumberFormat
				disabled={disabled}
				format="####"
				customInput={CustomTextInput}
				id={id}
				name={name}
				type={"text"}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				value={value}
				mask="_"
				placeholder={placeholder}
			/>
		</FieldControl>
	);
}
