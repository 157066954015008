import React from 'react';

export function GuidedResponseResult(props) {
	const { fieldName, fieldValue } = props;

	return (
		<tr>
			<td>{fieldName}</td>
			<td>{fieldValue}</td>
		</tr>
	);
}

export function GuidedResponseResultList(props) {
	const { results } = props;
	const fields = results.fields;

	return (
		<div>
			<table className="results">
				<thead>
					<tr>
						<th>Field Name</th>
						<th>Field Value</th>
					</tr>
				</thead>
				<tbody>
					{fields.map((field) => (
						<GuidedResponseResult key={field.fieldName} fieldName={field.fieldName} fieldValue={field.currentValue} />
					))}
				</tbody>
			</table>
		</div>
	);
}
