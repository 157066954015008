import React from 'react';
import { Modal } from '../modal/modal';
import * as Icon from '../icons';

export function ProviderBiographyModal(props) {
	const { serviceBio, toggleModal } = props;

	const handleCloseModal = (e) => {
		let target = e.target;
		if (target.id === 'biographyModalContainer') {
			toggleModal();
		}
	};

	return (
		<Modal containerId="biographyModalContainer" modalId="biographyModal" onClickOffModalHandler={handleCloseModal}>
			<div className="filter-modal-header">
				<h1>Provider Biography</h1>
				<Icon.IconClose className="close-icon" onClick={toggleModal} />
			</div>
			<div className={'service-bio'} dangerouslySetInnerHTML={{ __html: serviceBio }}></div>
		</Modal>
	);
}
