import React from 'react';
import PropTypes from 'prop-types';
import TextInput from './textInput';
import { validateEmail } from '../../../lib/validation';

export default function EmailInput(props) {
	const { onChange, ...rest } = props;
	return <TextInput {...rest} onChange={onChange} validate={validateEmail} type="email" />;
}

EmailInput.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	value: PropTypes.string,
};
