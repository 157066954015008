/**
 * @format
 */

import moment from 'moment';
import { ASC } from '../constants/availabilitySearchFields';
import { greaterOfTwoDates, lesserOfTwoDates } from './dateUtils';
import { stringContains } from './misc';
import SearchCriteriaEnums from '../constants/searchCriteriaEnums';

const NUM_SEARCH_DAYS = 7; //TODO
const DATE_FORMAT = 'YYYY-MM-DD';

const sortOrders = {
	firstAvailable: '1',
	distance: '2',
	alphabetical: '3',
};

const mapCalendar = (cal) => {
	let mappedCal = {
		assetTag: cal.equipment?.assetTag,
		bio: cal.provider?.bio,
		calendarId: cal.calendarId,
		calendarType: cal.serviceProviderType,
		informationForPatient: cal.serviceProviderSettings?.informationForPatient,
		isSelected: cal.isPreferredCalendar,
		siteName: cal.site.locationName,
		serviceName: cal.serviceProviderName,
		serviceType: cal.serviceProviderSettings?.serviceType,
		specialtyName: cal.serviceProviderSettings?.primarySpecialtyName,
		address1: cal.site.address.addressLine1,
		address2: cal.site.address.addressLine2,
		npi: cal.serviceProviderSettings?.npi,
		languages: cal.serviceProviderSettings?.languages,
		phone: cal.site.telephone,
		city: cal.site.address.cityName,
		state: cal.site.address.stateCode,
		zipCode: cal.site.address.zipCode,
		imageFile: cal.serviceProviderSettings?.imageFilename,
		distanceInMiles: cal.site.distanceInMiles,
		firstAvailabilityDate: cal.firstAvailabilityDate,
		serialNumber: cal.equipment?.serialNumber,
		suppressAddress: cal.site.suppressAddress,
		suppressPhoneNumber: cal.site.suppressTelephone,
		genderCode: cal.provider?.genderCode,
		referrerNotes: cal.serviceProviderSettings?.referrerNotes,
		isAppointmentRequestEnabled: cal.serviceProviderSettings?.isAppointmentRequestEnabled,
		isAppointmentRequestWithAvailabilityEnabled:
			cal.serviceProviderSettings?.isAppointmentRequestWithAvailabilityEnabled,
		isAppointmentRequestWithImmediateAvailabilityEnabled:
			cal.serviceProviderSettings?.isAppointmentRequestWithImmediateAvailabilityEnabled,
	};
	return mappedCal;
};

const mapTimeSlot = (ts) => {
	let mappedTs = {
		calendarId: ts.calendarId,
		date: ts.date,
		startAt: ts.startAtUtc,
		localStartAt: moment(`${ts.date} ${ts.startTime}`).format(),
		startTime: ts.startTime,
		endAt: ts.endAtUtc,
		localEndAt: moment(`${ts.date} ${ts.endTime}`).format(),
		endTime: ts.endTime,
		timeSlotAppointmentTypeId: ts.availabilityId, //I am guessing that's what this is possible flex slot id?
		appointmentTypeAbbr: ts.appointmentTypeAbbreviation,
		appointmentTypeId: ts.appointmentTypeId,
		appointmentTypeName: ts.appointmentTypeName,
		formattedTime: formatStartTime(ts.startTime),
	};

	return mappedTs;
};

export const getDatesToDisplay = (startDate, numDaysToDisplay) => {
	let weekDays = [];
	
	const cleanStartDate = moment.utc(startDate.format('YYYY-MM-DD'));
	for (var i = 0; i < numDaysToDisplay; i++) {
		let curDay = cleanStartDate.clone();
		curDay.add(i, 'd');
		weekDays.push(curDay);
	}

	return weekDays;
};

export const formatStartTime = (startTime) => {
	let timeHour = startTime.substring(0, 2).replace(/^0+/, '');
	let timePart = timeHour < 12 ? 'am' : 'pm';
	timeHour = timeHour === '' ? '12' : timeHour;
	let timeMin = startTime.substring(3, 5);
	return timeHour > 12 ? `${timeHour - 12}:${timeMin} ${timePart}` : `${timeHour}:${timeMin} ${timePart}`;
};

const firstAvailableSort = (ascending) => {
	return function (a, b) {
		// equal items sort equally
		if (a?.firstAvailability?.startAtUtc === b?.firstAvailability?.startAtUtc) {
			return 0;
		}

		// nulls sort after anything else
		if (!a?.firstAvailability?.startAtUtc) {
			return 1;
		}
		if (!b?.firstAvailability?.startAtUtc) {
			return -1;
		}

		// otherwise, if we're ascending, lowest sorts first
		if (ascending) {
			return a.firstAvailability.startAtUtc < b.firstAvailability.startAtUtc ? -1 : 1;
		}

		// if descending, highest sorts first
		return a.firstAvailability.startAtUtc < b.firstAvailability.startAtUtc ? 1 : -1;
	};
};

const sortCalendarIds = (sortOrder, firstAvailabilityScanResults, calendarSearchResults) => {
	const strSortOrder = String(sortOrder);
	switch (strSortOrder) {
		case sortOrders.alphabetical:
			return calendarSearchResults
				.map((x) => x)
				.sort(
					(a, b) =>
						a.provider.lastName.localeCompare(b.provider.lastName) ||
						a.provider.firstName.localeCompare(b.provider.firstName),
				)
				.map((x) => x.calendarId);
		case sortOrders.distance:
			return calendarSearchResults
				.map((x) => x)
				.sort((a, b) => (a.site.distanceInMiles > b.site.distanceInMiles ? 1 : -1))
				.map((x) => x.calendarId);
		case sortOrders.firstAvailable:
		default:
			return firstAvailabilityScanResults
				.map((x) => x)
				.sort(firstAvailableSort(true))
				.map((x) => x.calendarId);
	}
};

const filterCalendars = (calendars, inputCriteria, availabilitySearchSupportData) => {
	let filteredCalendars = calendars;

	if (inputCriteria[ASC.providerGender]) {
		filteredCalendars = filteredCalendars.filter((x) => x.provider?.genderCode === inputCriteria[ASC.providerGender]);
	}

	if (inputCriteria[ASC.languagePreference]) {
		filteredCalendars = filteredCalendars.filter((x) =>
			x.serviceProviderSettings.languages.some((y) => y.id === inputCriteria[ASC.languagePreference]),
		);
	}

	if (inputCriteria[ASC.serviceName]) {
		filteredCalendars = filteredCalendars.filter((x) =>
			stringContains(x.serviceProviderName, inputCriteria[ASC.serviceName]),
		);
	}

	if (inputCriteria[ASC.siteName]) {
		filteredCalendars = filteredCalendars.filter((x) =>
			stringContains(x.site.locationName, inputCriteria[ASC.siteName]),
		);
	}

	if (inputCriteria[ASC.siteFilter]) {
		filteredCalendars = filteredCalendars.filter((x) => x.site.locationName === inputCriteria[ASC.siteFilter]);
	}

	return filteredCalendars;
};

const getCurrentPageItems = (items, pageNumber, itemsPerPage) => {
	const indexStart = (pageNumber - 1) * itemsPerPage;
	const indexEnd = indexStart + itemsPerPage;
	return items.slice(indexStart, indexEnd);
};

const mapCalendarsToResults = (
	calendars,
	displayDays,
	availabilitySearchResults,
	nextAvailabilityScanResults,
	availabilitySearchConfig,
) => {
	const mappedCalendars = calendars.map((x) => {
		const calendarDetails = mapCalendar(x);
		const calendarTimeSlots = availabilitySearchResults.timeSlots
			.filter((y) => y.calendarId === x.calendarId)
			.map(mapTimeSlot);
		const calendarTimeSlotDays = displayDays.map((day, i) => {
			const dayOfWeek = day.format('dddd');
			return {
				day: i + 1,
				appointments:
					(availabilitySearchConfig.suppressSunday && dayOfWeek === 'Sunday') ||
					(availabilitySearchConfig.suppressSaturday && dayOfWeek === 'Saturday')
						? []
						: calendarTimeSlots.filter((y) => day.diff(moment.utc(y.localStartAt).startOf('day'), 'days') === 0),
			};
		});
		const hasDisplayableAvailability = calendarTimeSlotDays.some((day) => day.appointments.length > 0);
		const nextAvailabilityDate = nextAvailabilityScanResults.calendars.find((y) => y.calendarId === x.calendarId)
			?.firstAvailability?.date;

		return {
			calendarDetails: calendarDetails,
			calendarTimeSlotDays: calendarTimeSlotDays,
			hasDisplayableAvailability: hasDisplayableAvailability,
			nextAvailabilityDate: nextAvailabilityDate,
			hasNextAvailabilityDate: !!nextAvailabilityDate,
		};
	});
	return mappedCalendars;
};

/* eslint-disable */
//commented out for now in case we go back to using this
const greaterNumber = (num1, num2) => {
	if (num1 > num2) {
		return num1;
	} else {
		return num2;
	}
};
/* eslint-enable */

const lesserNumber = (num1, num2) => {
	if (!num1 || (num2 && num2 < num1)) {
		return num2;
	} else {
		return num1;
	}
};

export const mapResults = (inputCriteria, props) => {
	const helper = mapperHelper(inputCriteria, props);
	let debugStartTime = moment();
	let debug = false;

	const numSelectedCalendars = helper.filteredSortedSelectedCalendars?.length || 0;
	const numResultCalendars =
		(helper.filteredSortedSelectedCalendars?.length || 0) + (helper.filteredSortedRecommendedCalendars?.length || 0);
	const firstShownResultNum =
		inputCriteria.pageNumber === 1
			? 1
			: numSelectedCalendars + (props.availabilitySearchConfig.defaultPageSize * (inputCriteria.pageNumber - 1) + 1);
	const lastShownResultNum =
		inputCriteria.pageNumber === 1
			? lesserNumber(numSelectedCalendars + props.availabilitySearchConfig.defaultPageSize, numResultCalendars)
			: lesserNumber(firstShownResultNum + props.availabilitySearchConfig.defaultPageSize - 1, numResultCalendars);

	const displayDays = getDatesToDisplay(helper.startDate, NUM_SEARCH_DAYS /*this.availabilitySearchConfig.chunkSize*/);

	const selectedResults = mapCalendarsToResults(
		helper.pagedFilteredSortedSelectedCalendars,
		displayDays,
		props.availabilitySearchResults,
		props.nextAvailabilityScanResults,
		props.availabilitySearchConfig,
	);
	const recommendedResults = mapCalendarsToResults(
		helper.pagedFilteredSortedRecommendedCalendars,
		displayDays,
		props.availabilitySearchResults,
		props.nextAvailabilityScanResults,
		props.availabilitySearchConfig,
	);

	const numDaysScanned = helper.effectiveScanMaxStartDate.diff(helper.startDate, 'days') + 1;

	const minAllowedStartDate = moment(props.availabilitySearchCriteria.minStartDate);
	const minAllowedMonthStartDate = greaterOfTwoDates(helper.startDate.clone().startOf('month'), minAllowedStartDate);

	const numCalendarPages = Math.ceil(
		(helper.filteredSortedRecommendedCalendars?.length || 0) / props.availabilitySearchConfig.defaultPageSize,
	);

	const allowSearchPreviousPage = minAllowedStartDate.isBefore(helper.startDate, 'days');
	const previousPageStartDate = helper.showSingleCalendar
		? helper.startDate.clone().add(-1, 'd').startOf('month')
		: helper.startDate.clone().add(-NUM_SEARCH_DAYS, 'd');
	const effectivePreviousPageStartDate = greaterOfTwoDates(previousPageStartDate, minAllowedStartDate);

	const effectiveNextPageStartDate = helper.showSingleCalendar
		? helper.startDate.clone().endOf('month').add(1, 'd').startOf('day')
		: helper.startDate.clone().add(NUM_SEARCH_DAYS, 'd');
	const allowSearchNextPage = helper.maxAllowedStartDate
		? helper.maxAllowedStartDate.isSameOrAfter(effectiveNextPageStartDate, 'days')
		: true;

	const singleCalendarDetails = helper.showSingleCalendar
		? mapCalendar(
				props.calendarSearchResults.calendars.find((x) => x.calendarId === inputCriteria.singlePageCalendarId),
		  )
		: null;

	const singleCalendarResults = props.availabilitySearchResults.timeSlots
		.filter(y => {
			if(y.calendarId === inputCriteria.singlePageCalendarId)
			{
				//filter out Saturdays and Sundays, if those options are set to true.
				//	* for multiProviderAvailability, this filtering handled by mapCalendarsToResults and ProviderAvailabilitySlots
				var momentObj = moment.utc(y.date); //y.date is a string in the format YYYY-MM-DD
				var weekdayString = momentObj.format('dddd');
				//return false if the y.date is a day of week that we're filtering
				return !((weekdayString === 'Saturday' && props.availabilitySearchConfig.suppressSaturday) || (weekdayString === 'Sunday' && props.availabilitySearchConfig.suppressSunday));
			}
			return false;
		})
		.map(mapTimeSlot);
	const hasSingleCalendarResults = singleCalendarResults?.length > 0;
	const singleCalendarFirstAvailabilityDate = hasSingleCalendarResults
		? singleCalendarResults.sort((a, b) => {
				return new Date(a.date) - new Date(b.date);
		  })[0].date
		: null;

	const optionLists = mapOptionLists(inputCriteria, props);

	const result = {
		showSingleCalendar: helper.showSingleCalendar,
		hasAvailabilityResults: helper.displayedCalendars?.length > 0,
		calendarsPerPage: props.availabilitySearchConfig.defaultPageSize,
		numResultsCurrentPage: helper.displayedCalendars?.length || 0,
		firstShownResultNum: firstShownResultNum,
		lastShownResultNum: lastShownResultNum,
		numResultCalendars: numResultCalendars,
		hasSelectedResults: selectedResults?.length > 0,
		selectedResults: selectedResults,
		numDaysScanned: numDaysScanned,
		minAllowedMonthStartDate: minAllowedMonthStartDate,
		hasRecommendedResults: recommendedResults?.length > 0,
		recommendedResults: recommendedResults,
		numCalendarPages: numCalendarPages,
		allowSearchPreviousPage: allowSearchPreviousPage,
		effectivePreviousPageStartDate: effectivePreviousPageStartDate,
		displayDays: displayDays,
		allowSearchNextPage: allowSearchNextPage,
		effectiveNextPageStartDate: effectiveNextPageStartDate,
		singleCalendarDetails: singleCalendarDetails,
		hasSingleCalendarResults: hasSingleCalendarResults,
		singleCalendarResults: singleCalendarResults,
		singleCalendarFirstAvailabilityDate,
		startDate: helper.startDate,
		optionLists: optionLists,
	};

	if (debug) {
		console.log('result: ', result);
		console.log('result took: ', moment().diff(debugStartTime, 'milliseconds'), ' milliseconds');
	}

	return result;
};

export const mapCalendarSearchCriteria = (inputCriteria, props, sessionId) => {
	/* eslint-disable */
	//commented out for now in case we go back to using this
	const helper = mapperHelper(inputCriteria, props);
	/* eslint-enable */
	let debugStartTime = moment();
	let debug = false;

	const searchCriteria = {
		productInstanceId: props.productInstanceId,
		correlationKey: props.correlationKey,
		...props.calendarSearchCriteria,
		distanceFilter: {
			...props.calendarSearchCriteria.distanceFilter,
			maxDistanceInMiles: inputCriteria[ASC.searchRadius] > 0 ? inputCriteria[ASC.searchRadius] : 0,
			zipCode: inputCriteria[ASC.zipCode],
		},
		patientCriteria: {
			...props.calendarSearchCriteria.patientCriteria,
			payorType: inputCriteria[ASC.payorType],
			insuranceProviderId: inputCriteria[ASC.insuranceCarrier],
		},
		providerCriteria: {
			...props.calendarSearchCriteria.providerCriteria,
			specialtyName: props.availabilitySearchSupportData.specialtyList.find(
				(x) => x.idPgmSpecialty === inputCriteria[ASC.specialty],
			)?.pgmSpecialtyName,
		},
		sessionId: sessionId
	};

	if (debug) {
		console.log('calendarSearchCriteria: ', searchCriteria);
		console.log('calendarSearchCriteria took: ', moment().diff(debugStartTime, 'milliseconds'), ' milliseconds');
	}

	return searchCriteria;
};

export const mapFirstAvailabilityScanCriteria = (inputCriteria, props, sessionId) => {
	const helper = mapperHelper(inputCriteria, props);
	let debugStartTime = moment();
	let debug = false;

	const hasCalendars = props.calendarSearchResults?.calendars?.length > 0;
	const calendarIds = hasCalendars ? props.calendarSearchResults.calendars.map((x) => x.calendarId) : [];

	const scanCriteria = {
		productInstanceId: props.productInstanceId,
		correlationKey: props.correlationKey,
		...props.availabilitySearchCriteria,
		returnAllCalendars: false,
		calendarIds: calendarIds,
		minStartDate: helper.startDate.format(DATE_FORMAT),
		maxStartDate: helper.effectiveScanMaxStartDate.format(DATE_FORMAT),
		minStartTime: null, //TODO is this right?
		maxStartTime: null, //TODO is this right?
		returnAllDays: true, //TODO is this right?
		daysOfWeek: [], //TODO is this right?
		returnAllAppointmentTypes: false, //TODO
		//appointmentTypeIds: null, //TODO IS THIS TRUE, don't filter calendar search by appointment type
		appointmentTypeNames: [], //TODO do we care about this?
		returnAllAppointmentModalities: true,
		payorTypeName: null, //TODO IS THIS TRUE?
		insuranceProviderId: null, //TODO IS THIS TRUE?
		maxTimeSlotsPerDay: null, //TODO
		sessionId: sessionId
	};

	if (debug) {
		console.log('firstAvailabilityScanCriteria: ', scanCriteria);
		console.log('firstAvailabilityScanCriteria took: ', moment().diff(debugStartTime, 'milliseconds'), ' milliseconds');
	}

	return scanCriteria;
};

const resolveStartTime = (timeFilter, minMax) => {
	const pmIncluded = timeFilter.find((x) => x === 'PM');
	const amIncluded = timeFilter.find((x) => x === 'AM');

	if ((!amIncluded && !pmIncluded) || (amIncluded && pmIncluded)) {
		return null;
	}

	if ((!amIncluded && minMax === 'min') || (!pmIncluded && minMax === 'max')) {
		return '12:00:00';
	}

	return null;
};

export const mapNextAvailabilityScanCriteria = (inputCriteria, props, sessionId) => {
	const helper = mapperHelper(inputCriteria, props);
	const timeSlots = props.availabilitySearchResults.timeSlots;

	let calendarIdsFromTimeSlots = [];
	if(timeSlots !== null && timeSlots.length > 0)
	{
		calendarIdsFromTimeSlots = helper.displayedCalendarIds.filter(calendarId => !timeSlots.some(ts => ts.calendarId === calendarId));
	}

	const calendarIdsFromFirstScan = helper.displayedCalendarIds.filter(id => {
		return props.firstAvailabilityScanResults.calendars.find(cal => {
			return cal.calendarId === id
				&& (!cal.hasAvailability
				|| moment(props.lastFinishedSearchCriteria.availability?.minStartDate).isAfter(cal.firstAvailability?.date));
		})
	})

	const calendarIdsForNextScan = Array.from(new Set(calendarIdsFromTimeSlots.concat(calendarIdsFromFirstScan)));

	let debugStartTime = moment();
	let debug = false;

	const scanCriteria = {
		productInstanceId: props.productInstanceId,
		correlationKey: props.correlationKey,
		...props.availabilitySearchCriteria,
		returnAllCalendars: false,
		calendarIds: calendarIdsForNextScan,
		minStartDate: helper.effectiveScanMinStartDate.format(DATE_FORMAT),
		maxStartDate: helper.effectiveScanMaxStartDate.format(DATE_FORMAT),
		minStartTime: resolveStartTime(inputCriteria[ASC.timeOfDay], 'min'),
		maxStartTime: resolveStartTime(inputCriteria[ASC.timeOfDay], 'max'),
		returnAllDays: !helper.daysOfWeekFilterList?.length,
		daysOfWeek: helper.daysOfWeekFilterList,
		returnAllAppointmentTypes: false, //TODO will this ever be true?
		appointmentTypeIds: helper.effectiveSearchAppointmentTypeIds,
		appointmentTypeNames: [], //TODO do we care about this?
		appointmentModalityIds: helper.effectiveSearchAppointmentModalityIds,
		returnAllAppointmentModalities: helper.returnAllAppointmentModalities,
		payorTypeName: props.availabilitySearchSupportData.payorTypeList.find(
			(x) => x.idPgmPayorType === inputCriteria[ASC.payorType],
		)?.idPgmPayorType,
		insuranceProviderId: inputCriteria[ASC.insuranceCarrier],
		maxTimeSlotsPerDay: null, //TODO
		sessionId: sessionId
	};

	if (debug) {
		console.log('nextAvailabilityScanCriteria: ', scanCriteria);
		console.log('nextAvailabilityScanCriteria took: ', moment().diff(debugStartTime, 'milliseconds'), ' milliseconds');
	}

	return scanCriteria;
};

export const mapAvailabilitySearchCriteria = (inputCriteria, props, sessionId) => {
	const helper = mapperHelper(inputCriteria, props);
	let debugStartTime = moment();
	let debug = false;

	const calendarIdsForSearch = helper.displayedCalendarIds.filter(id => {
		return props.firstAvailabilityScanResults.calendars.find(cal => {
			if(cal.calendarId === id)
			{
				const previousScanDoesNotApply = helper.effectiveSearchMaxStartDate.isAfter(helper.maxDateScanned);
				const calendarFirstAvailabilityIsBeforeMaxSearchDate = cal.hasAvailability && moment(cal.firstAvailabilityDate).isBefore(helper.effectiveSearchMaxStartDate);
				return previousScanDoesNotApply || calendarFirstAvailabilityIsBeforeMaxSearchDate;
			}
			return false;
		})
	});

	const searchCriteria = {
		productInstanceId: props.productInstanceId,
		correlationKey: props.correlationKey,
		...props.availabilitySearchCriteria,
		returnAllCalendars: false,
		calendarIds: calendarIdsForSearch,
		minStartDate: helper.startDate.format(DATE_FORMAT),
		maxStartDate: helper.effectiveSearchMaxStartDate.format(DATE_FORMAT),
		minStartTime: resolveStartTime(inputCriteria[ASC.timeOfDay], 'min'),
		maxStartTime: resolveStartTime(inputCriteria[ASC.timeOfDay], 'max'),
		returnAllDays: !helper.daysOfWeekFilterList?.length,
		daysOfWeek: helper.daysOfWeekFilterList,
		returnAllAppointmentTypes: false, //TODO - will this ever be true?
		appointmentTypeIds: helper.effectiveSearchAppointmentTypeIds,
		appointmentTypeNames: [], //TODO do we care about this?
		appointmentModalityIds: helper.effectiveSearchAppointmentModalityIds,
		returnAllAppointmentModalities: helper.returnAllAppointmentModalities,
		payorTypeName: props.availabilitySearchSupportData.payorTypeList.find(
			(x) => x.idPgmPayorType === inputCriteria[ASC.payorType],
		)?.idPgmPayorType,
		insuranceProviderId: inputCriteria[ASC.insuranceCarrier],
		maxTimeSlotsPerDay: null, //TODO
		sessionId: sessionId
	};

	if (debug) {
		console.log('availabilitySearchCriteria: ', searchCriteria);
		console.log('availabilitySearchCriteria took: ', moment().diff(debugStartTime, 'milliseconds'), ' milliseconds');
	}

	return searchCriteria;
};

const mapperHelper = (inputCriteria, props) => {
	const sortedCalendarIds = sortCalendarIds(
		inputCriteria[ASC.sortOrder],
		props.firstAvailabilityScanResults.calendars,
		props.calendarSearchResults.calendars,
	);
	const sortedCalendars = props.calendarSearchResults.calendars
		.map((x) => x)
		.sort((a, b) => sortedCalendarIds.indexOf(a.calendarId) - sortedCalendarIds.indexOf(b.calendarId));

	const enableFilterSelectedProvider = props.availabilitySearchConfig.enableFilterSelectedProvider;
	const sortedSelectedCalendars = sortedCalendars.filter((x) => x.isPreferredCalendar);
	const sortedRecommendedCalendars = sortedCalendars.filter((x) => !x.isPreferredCalendar);

	const filteredSortedSelectedCalendars = enableFilterSelectedProvider
		? filterCalendars(sortedSelectedCalendars, inputCriteria, props.availabilitySearchSupportData)
		: sortedSelectedCalendars;

	const filteredSortedRecommendedCalendars = filterCalendars(
		sortedRecommendedCalendars,
		inputCriteria,
		props.availabilitySearchSupportData,
	);

	const pagedFilteredSortedSelectedCalendars = inputCriteria.pageNumber === 1 ? filteredSortedSelectedCalendars : [];
	const pagedFilteredSortedRecommendedCalendars = getCurrentPageItems(
		filteredSortedRecommendedCalendars,
		inputCriteria.pageNumber,
		props.availabilitySearchConfig.defaultPageSize,
	);

	const showSingleCalendar = !!inputCriteria.singlePageCalendarId;

	const displayedCalendars = showSingleCalendar
		? [sortedCalendars.find((x) => x.calendarId === inputCriteria.singlePageCalendarId)]
		: [...pagedFilteredSortedSelectedCalendars, ...pagedFilteredSortedRecommendedCalendars];

	const startDate = inputCriteria[ASC.appointmentStartDate];
	const scanWindowMaxStartDate = startDate
		.clone()
		.add(props.availabilitySearchConfig.availabilitySearchWindow - 1, 'd');
	const maxAllowedStartDate = props.availabilitySearchCriteria.maxStartDate ? moment(props.availabilitySearchCriteria.maxStartDate) : null;

	const maxDateSearched = props.lastFinishedSearchCriteria.availability?.maxStartDate ? moment(props.lastFinishedSearchCriteria.availability?.maxStartDate) : null;

	// NOTE: This logic assumes the max date scanned will be cleared out if no longer applicable
	const effectiveScanMinStartDate =  maxDateSearched && maxDateSearched.isValid() ? maxDateSearched.add(1, "d") : null;
	const effectiveScanMaxStartDate = lesserOfTwoDates(scanWindowMaxStartDate, maxAllowedStartDate);
	
	const searchWindowMaxStartDate = showSingleCalendar
		? startDate.clone().endOf('month')
		: startDate.clone().add(NUM_SEARCH_DAYS - 1, 'd');
	const effectiveSearchMaxStartDate = lesserOfTwoDates(searchWindowMaxStartDate, maxAllowedStartDate);

	const displayedCalendarIds = displayedCalendars.map((x) => x.calendarId);

	const effectiveSearchAppointmentTypeIds = inputCriteria[ASC.appointmentType]
		? [inputCriteria[ASC.appointmentType]]
		: props.availabilitySearchCriteria.appointmentTypeIds;

	const selectedAppointmentModality = inputCriteria[ASC.appointmentModalityFilter]
		? parseInt(inputCriteria[ASC.appointmentModalityFilter])
		: 0;

	const effectiveSearchAppointmentModalityIds = [selectedAppointmentModality];

	const returnAllAppointmentModalities = selectedAppointmentModality > 0 ? false : true;

	const daysOfWeekFilterList = SearchCriteriaEnums.weekDays
		.filter((x) => inputCriteria[ASC.daysOfWeek]?.includes(x.id))
		?.map((y) => y.apiId);

	const commonHelper = {
		daysOfWeekFilterList,
		sortedCalendarIds,
		sortedCalendars,
		sortedSelectedCalendars,
		sortedRecommendedCalendars,
		filteredSortedSelectedCalendars,
		filteredSortedRecommendedCalendars,
		pagedFilteredSortedSelectedCalendars,
		pagedFilteredSortedRecommendedCalendars,
		showSingleCalendar,
		displayedCalendars,
		startDate,
		maxAllowedStartDate,
		effectiveScanMinStartDate,
		effectiveScanMaxStartDate,
		effectiveSearchMaxStartDate,
		displayedCalendarIds,
		effectiveSearchAppointmentTypeIds,
		effectiveSearchAppointmentModalityIds,
		returnAllAppointmentModalities,
	};

	return commonHelper;
};

export const mapOptionLists = (inputCriteria, props) => {
	const appointmentTypeList = props.availabilitySearchCriteria?.appointmentTypeIds.length
		? props.availabilitySearchSupportData.appointmentTypeList
				.filter((x) => props.availabilitySearchCriteria.appointmentTypeIds.includes(x.idPgmAppointmentType))
				.map((x) => {
					return {
						idPgmAppointmentType: x.idPgmAppointmentType,
						pgmAppointmentTypeName: x.pgmAppointmentTypeName,
						pgmAppointmentTypeAbbr: x.pgmAppointmentTypeAbbr,
						idPgmAppointmentModality: x.idPgmAppointmentModality,
						pgmAppointmentModalityName: x.pgmAppointmentModalityName,
					};
				})
		: props.availabilitySearchSupportData.appointmentTypeList.map((x) => {
				return {
					idPgmAppointmentType: x.idPgmAppointmentType,
					pgmAppointmentTypeName: x.pgmAppointmentTypeName,
					pgmAppointmentTypeAbbr: x.pgmAppointmentTypeAbbr,
					idPgmAppointmentModality: x.idPgmAppointmentModality,
					pgmAppointmentModalityName: x.pgmAppointmentModalityName,
				};
		  });
	const appointmentTypeListAlphabetical = appointmentTypeList.sort((a, b) => {
		return a.pgmAppointmentTypeName.localeCompare(b.pgmAppointmentTypeName);
	});
	
	
	const resolveAppointmentModalityList = (appointmentTypeList) => {
		const all = appointmentTypeList.map((x) => {
			return {
				id: x.idPgmAppointmentModality,
				name: x.pgmAppointmentModalityName,
			};
		});

		const result = all.reduce((unique, o) => {
			if (!unique.some((obj) => obj.idPgmAppointmentModality === o.idPgmAppointmentModality)) {
				unique.push(o);
			}
			return unique;
		}, []);

		return result;
	};

	const appointmentModalityList = resolveAppointmentModalityList(appointmentTypeList);

	const genderList = [
		{ gender: 'Female', value: 'F', id: 'F', name: 'Female' },
		{ gender: 'Male', value: 'M', id: 'M', name: 'Male' },
	];

	const insuranceProviderList = props.availabilitySearchSupportData.insuranceTypeList.map((x) => {
		return {
			idPgmInsuranceProvider: x.idPgmInsuranceProvider,
			idPgmState: x.idPgmState,
			pgmInsuranceProviderName: x.pgmInsuranceProviderName,
			activatesOn: x.activatesOn,
			expiresOn: x.expiresOn,
		};
	});

	const stateList = props.availabilitySearchSupportData.stateList.map((x) => {
		return { idPgmState: x.idPgmState, state: x.state };
	});

	let calendarLanguages = [];
	props.calendarSearchResults.calendars.forEach((x) => {
		calendarLanguages = calendarLanguages.concat(x.serviceProviderSettings.languages);
	});
	const uniqueCalendarLanguages = [...new Set(calendarLanguages)];
	let languageList = props.availabilitySearchSupportData.languageList.map((x) => {
		return { idPgmLanguage: x.idPgmLanguage, id: x.idPgmLanguage, language: x.language, name: x.language };
	});
	if (uniqueCalendarLanguages?.length > 0) {
		languageList = languageList.filter((x) => uniqueCalendarLanguages.some((y) => y.id === x.idPgmLanguage));
	}

	const payorTypeList = props.availabilitySearchSupportData.payorTypeList.map((x) => {
		return {
			idPgmPayorType: x.idPgmPayorType,
			pgmPayorTypeName: x.pgmPayorTypeName,
			usesInsurance: x.usesInsurance,
		};
	});

	const searchRadiusList = props.availabilitySearchSupportData.radiusMileList.map((x) => {
		return {
			idRadiusMiles: Number(x.radiusMiles),
			id: Number(x.radiusMiles),
			radiusMiles: x.radiusMiles,
			name: x.radiusMiles,
		};
	});

	const serviceTypeList = props.availabilitySearchSupportData.serviceTypeList.map((x) => {
		return {
			idPgmServiceType: x.idPgmServiceType,
			pgmServiceTypeName: x.pgmServiceTypeName,
		};
	});

	const sites = props.calendarSearchResults.calendars.map((x) => x.site.locationName);
	const uniqueSites = [...new Set(sites)];
	const siteList = uniqueSites.map((x) => {
		return { id: x, name: x };
	});
	const siteListAlphabetical = siteList.sort((a, b) => {
		return a.id.localeCompare(b.id);
	});

	const services = props.calendarSearchResults.calendars.map((x) => x.serviceProviderName);
	const uniqueServices = [...new Set(services)];
	const serviceList = uniqueServices.map((x) => {
		return { id: x, name: x };
	});

	const sortOrderList = props.availabilitySearchSupportData.sortOrderList.map((x) => {
		return {
			availabilitySortOrderId: x.availabilitySortOrderId,
			id: x.availabilitySortOrderId,
			name: x.name,
			displayName: x.displayName,
		};
	});

	const calendarPrimarySpecialtyNames = props.calendarSearchResults.calendars.map(
		(x) => x.serviceProviderSettings.primarySpecialtyName,
	);

	/* eslint-disable */
	const primarySpecialtyList = props.availabilitySearchSupportData.specialtyList
		.filter((w) => calendarPrimarySpecialtyNames.includes(w.pgmSpecialtyName))
		.map((x) => {
			return {
				idPgmSpecialty: x.idPgmSpecialty,
				pgmSpecialtyName: x.pgmSpecialtyName,
				idPgmServiceType: x.idPgmServiceType,
			};
		});
	/* eslint-enable */

	const specialtyList = props.availabilitySearchSupportData.specialtyList.map((x) => {
		return {
			idPgmSpecialty: x.idPgmSpecialty,
			pgmSpecialtyName: x.pgmSpecialtyName,
			idPgmServiceType: x.idPgmServiceType,
		};
	});

	const daysOfWeekList = SearchCriteriaEnums.weekDays.filter((x) => {
		return (
			!(props.availabilitySearchConfig.suppressSaturday && x.name === 'Saturday') &&
			!(props.availabilitySearchConfig.suppressSunday && x.name === 'Sunday')
		);
	});

	const timeOfDayList = [
		{ id: 'AM', name: 'AM' },
		{ id: 'PM', name: 'PM' },
	];

	const lists = {
		appointmentTypeList: appointmentTypeListAlphabetical,
		appointmentModalityList,
		genderList,
		insuranceProviderList,
		stateList,
		languageList,
		payorTypeList,
		searchRadiusList,
		serviceTypeList,
		serviceList,
		siteList: siteListAlphabetical,
		sortOrderList,
		specialtyList,
		daysOfWeekList,
		timeOfDayList,
	};

	return lists;
};

export const shouldShowPreReserveModal = (props, calId) => {
	const shouldShow = false; //TODO: Setting this to false, because CD2 doesn't support preserve modal yet. We may in the future.
	//shouldShowReferrerNotesModal(props, calId) ||
	//shouldShowAgentInstructionsModal(props) ||
	//shouldShowPatientNotesModal(props);
	return shouldShow;
};

//const shouldShowReferrerNotesModal = (props, calId) => {
//	const shouldShow =
//		mapCalendar(props.calendarSearchResults.calendars.find((x) => x.calendarId === calId))?.referrerNotes &&
//		props.schedulingConfig.enableProviderReferrerInformationPopUp;
//	return shouldShow;
//};

//const shouldShowAgentInstructionsModal = (props) => {
//	const shouldShow = !!props.agentInstructions.agentAvailabilityInstructions;
//	return shouldShow;
//};

//const shouldShowPatientNotesModal = (props) => {
//	const shouldShow =
//		props.patientConfig.notes.isVisible &&
//		props.activePatient.details.notes &&
//		props.schedulingConfig.enablePatientNotesPopUp;
//	return shouldShow;
//};
