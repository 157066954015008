import React, { Component } from 'react';
import EmailInput from './input/emailInput';

class AppliedPathwaysQuestion_Email extends Component {
	constructor(props) {
		super(props);
		this.state = {
			asset: {},
			value: '',
			invalid: false,
		};
	}

	handleChange = ({ target: { name, value } }, error) => {
		const { onChange } = this.props;
		this.setState({ value, invalid: error });
		return onChange({ target: { name, value } }, error);
	};

	componentDidMount() {
		this.setState({ asset: this.props.asset, value: this.props.asset.value });
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.asset === prevState.asset) {
			return null;
		}
		if (nextProps.asset.value === prevState.value) {
			return null;
		}
		return {
			asset: nextProps.asset,
			value: nextProps.asset.value || '',
		};
	}

	render() {
		var isRequired = this.props.asset.is_required;

		return (
			<EmailInput
				className="ap-question inputfield form-control"
				type="tel"
				id={this.props.asset.id}
				name={this.props.asset.id}
				required={isRequired}
				defaultValue={this.state.value}
				onChange={this.handleChange}
				invalid={this.state.invalid}
			/>
		);
	}
}

export default AppliedPathwaysQuestion_Email;
