import React from 'react';
import FieldControl from './fieldControl';
import "./textInput.css";
import { ClearIndicator } from '../misc/miscComponents';

/**
 * @param {Object} props
 * @param {string} props.id
 * @param {Object} props.error
 * @param {string} props.error.message
 * @param {boolean} props.disabled
 * @param {boolean} props.isFocused
 * @param {boolean} props.isRequired
 * @param {boolean} props.isVisible - If False Component Returns null
 * @param {boolean} props.label
 * @param {boolean} props.labelAfter
 * @param {number} props.minLength
 * @param {number} props.maxLength
 * @param {string} props.name
 * @param {Object} props.value
 * @param {Function} props.onBlur
 * @param {Function} props.onChange
 * @param {Function} props.onFocus
 * @param {string} props.placeholder
 * @param {string} props.size - "sm", "md", "lg"
 * @returns {Object}
 */
export function TextInput(props) {
	const {
		id,
		error,
		disabled,
		isFocused,
		isRequired,
		isVisible,
		label,
		labelAfter,
		minLength,
		maxLength,
		name,
		value,
		onBlur,
		onChange,
		onFocus,
		placeholder,
		size,
		includeClearIndicator,
		onClearIndicatorClick
	} = props;

	const inputType = 'text';
	const hasValue = value && value.length > 0;

	return (
		<FieldControl
			disabled={disabled}
			error={error}
			hasValue={hasValue}
			isFocused={isFocused}
			isRequired={isRequired}
			id={id}
			inputType={inputType}
			isVisible={isVisible}
			label={label}
			labelAfter={labelAfter}
			size={size}
		>
			<input
				disabled={disabled}
				id={id}
				minLength={minLength}
				maxLength={maxLength}
				type={inputType}
				name={name}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
			{includeClearIndicator && hasValue &&
				<ClearIndicator onClick={() => onClearIndicatorClick()} />
			}
		</FieldControl>
	);
}
