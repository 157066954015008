import * as types from '../actions/actionTypes';
import moment from 'moment';

moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss';

const initialState = {
	patientReferenceId: null,
	demographics: {
		dateOfBirth: null,
		gender: null,
	},
	patientAuthContext: {
		isVerified: true,
		authMessage: '',
		isLockedOut: false,
		patientAuthConfig: {},
		patientAlreadyAuthenticated: false,
		patientIdentification: {},
	},
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.AUTHENTICATE_USER_SUCCESS: {
			return {
				...state,
				patientAuthContext: {
					...state.patientAuthContext,
					patientAuthConfig: action.payload.data.config.patientAuth,
				},
			};
		}
		case types.GET_SURVEY_ORDER_SUCCESS: {
			return {
				...state,
				patientAuthContext: {
					...state.patientAuthContext,
					isVerified:
						state.patientAuthContext.patientAuthConfig.enablePatientAuth &&
						!state.patientAuthContext.patientAlreadyAuthenticated
							? false
							: true,
					patientIdentification: {
						...state.patientIdentification,
						patientIdentifier: action.payload.data.patientId,
						patientIdentifierType: action.payload.data.patientIdType,
					},
				},
				patient: {
					patientFirstName: action.payload.data.contactFirstName,
				},
			};
		}
		case types.LOAD_AVAILABILITY_SURVEY_SUCCESS: {
			return {
				...state,
				patientAuthContext: {
					...state.patientAuthContext,
					isVerified:
						state.patientAuthContext.patientAuthConfig.enablePatientAuth &&
						!state.patientAuthContext.patientAlreadyAuthenticated
							? false
							: true,
				},
				patient: {
					patientFirstName: action.payload.data.contactFirstName,
				},
			};
		}

		case types.GET_ACTIVE_CAREORDERVISIT_DETAILS_SUCCESS: {
			return {
				...state,
				patientAuthContext: {
					...state.patientAuthContext,
					isVerified:
						state.patientAuthContext.patientAuthConfig.enablePatientAuth &&
						!state.patientAuthContext.patientAlreadyAuthenticated
							? false
							: true,
					patientIdentification: {
						...state.patientIdentification,
						patientIdentifier: action.payload.data.patientIdentifier,
						patientIdentifierType: action.payload.data.patientIdentifierType,
					},
					isLockedOut: action.payload.data.isLockedOut,
					authMessage: action.payload.data.lockoutMessage,
				},
				patientReferenceId: action.payload.data.patientReferenceId,
				patient: {
					patientFirstName: action.payload.data.patientFirstName,
				},
			};
		}
		case types.VERIFY_PATIENT_FOR_CAREORDER_SUCCESS:
		case types.VERIFY_PATIENT_FOR_AVAILABILITY_SURVEY_SUCCESS:
		case types.VERIFY_PATIENT_FOR_SURVEY_ORDER_SUCCESS: {
			return {
				...state,
				patientAuthContext: {
					...state.patientAuthContext,
					isVerified: action.payload.data.isPatientVerified,
					authMessage: action.payload.data.message,
					isLockedOut: action.payload.data.isLockedOut ? true : false,
					patientAlreadyAuthenticated: true,
				},
			};
		}
		case types.GET_BOOKING_TRANSITION_DETAILS_SUCCESS:
			let demographics = action.payload.data.patient;
			demographics.dateOfBirth = demographics.dateOfBirth
				? moment.utc(demographics.dateOfBirth).format('MM/DD/YYYY')
				: '';
			return {
				...state,
				demographics: demographics,
			};

		case types.SET_PATIENT_DEMOGRAPHICS: {
			let dob = action.demographics.dateOfBirth
				? moment.utc(action.demographics.dateOfBirth).format('MM/DD/YYYY')
				: state.demographics.dateOfBirth;
			return {
				...state,
				isBookAnotherAppointment: action.demographics.isBookAnotherAppointment ?? false,
				demographics: {
					...state.demographics,
					dateOfBirth: dob,
					gender: action.demographics.gender,
					firstName: action.demographics.firstName,
					lastName: action.demographics.lastName,
					parentOrGuardianName: action.demographics.parentOrGuardianName,
					email: action.demographics.email,
					homePhone: action.demographics.homePhone,
					mobilePhone: action.demographics.mobilePhone,
					memberId: action.demographics.memberId,
					addressLine1: action.demographics.address1,
					addressLine2: action.demographics.address2,
					cityName: action.demographics.city,
					stateCode: action.demographics.stateCode,
					zipCode: action.demographics.zipCode,
				},
			};
		}

		case types.APPOINTMENT_RESERVE_DETAILS_SUCCESS:
			const hasValidPatientData =
				action.payload.data &&
				action.payload.data.patient &&
				typeof action.payload.data.patient === 'object' &&
				action.payload.data.patient.referenceId &&
				action.payload.data.patient.referenceId !== '00000000-0000-0000-0000-000000000000';

			demographics = hasValidPatientData
				? action.payload.data.patient
				: state.demographics;

			return {
				...state,
				patientReferenceId: demographics?.referenceId,
				demographics: demographics,
			};

		default:
			return state;
	}
}
