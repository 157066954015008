export const AVAILABILITY_SEARCH_CRITERIA = {
	startDate: 'minAvailabilityStartAt',
	endDate: 'maxAvailabilityStartAt',
	siteIdList: 'siteIdList',
	additionalCalendarList: 'additionalCalendarList',
	serviceTypeId: 'idPgmServiceType',
	appointmentTypeIdList: 'idPgmAppointmentTypeList',
	zipCode: 'zipCode',
	serviceNpiList: 'serviceNpiList',
	selectedProviderNpiList: 'selectedProviderNpiList',
	selectedProviderCalendarList: 'selectedProviderCalendarList',
	selectedSiteIdList: 'selectedSiteIdList',
	serviceIdList: 'serviceIdList',
	specialtyId: 'idPgmSpecialty',
	assetTags: 'assetTags',
	minStartTime: 'minStartTime',
	maxStartTime: 'maxStartTime',
};
