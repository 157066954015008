import React from 'react';

export default function BookingProcessPanel(props) {
	//const { currentStepName, steps } = props;
	// TODO: Implement BookingProcessPanel Nighthawk behavior
	return (
		<div>
			<ol className="">
				<li className="">Find Appointment</li>
				<li className="">Select Appointment</li>
				<li className="">Confirm Appointment</li>
			</ol>
		</div>
	);
};
