import { useSelector } from "react-redux";
import { selectPreferredDisplayedCalendarIds } from "../availabilitySlice";
import DateNav from "./dateNav";
import ResultRow from "./resultRow";

const PreferredResults = ({ isMobile }) => {
    const preferredDisplayedCalendarIds = useSelector(selectPreferredDisplayedCalendarIds);

    return (
        <>
            {preferredDisplayedCalendarIds?.length > 0 &&
                <>
                    <div className="availability-results-provider-header">
                        <h5>Selected Provider</h5>
                    </div>
                    {!isMobile &&
                        <DateNav />
                    }
                    <div className="availability-results-providers">
                        {preferredDisplayedCalendarIds.map((calendarId, index, array) =>
                            <ResultRow
                                key={calendarId}
                                calendarId={calendarId}
                                isMobile={isMobile}
                            />
                        )}
                    </div>

                </>
            }
        </>
    )
}

export default PreferredResults;