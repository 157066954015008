//NOTE: This component is only intended for use for dropdowns with
//extremely large sets of data, and that must query the server.
//It is NOT intended to be a 1:1 replacement for our standard
//dropdownSelect field control component

import React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import './dropdownWithServerTypeahead.css';

export function DropdownWithServerTypeahead(props) {
	const { id, name, isLoading, labelKey, minLength, onSearch, options, placeholder, onChange, onBlur, onFocus, isVisible } = props;

	return (
		<div className="typeahead-container">
			{isVisible &&
				<>
					<label className="sr-only field-label" htmlFor={id}>{placeholder}</label>
					<AsyncTypeahead
						filterBy={() => true}
						id={id}
						name={name}
						isLoading={isLoading}
						labelKey={labelKey}
						minLength={minLength}
						onChange={onChange}
						onSearch={onSearch}
						options={options}
						onFocus={onFocus}
						onBlur={onBlur}
						placeholder={placeholder}
					/>
				</>
			}
		</div>
	);
}
