import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import './stepper.css';
import { StepConnector } from '@mui/material';

export interface ProgressStepperStep {
    label: string,
    icon: IconDefinition,
}

interface ProgressStepperProps {
    activeStepIndex: number,
    activeStepPercentageComplete: number,
    steps: ProgressStepperStep[],
    displayAllCompleteStepper?: boolean,
}

const ProgressStepper = ({
    steps,
    activeStepIndex,
    activeStepPercentageComplete,
    displayAllCompleteStepper = false
}: ProgressStepperProps) => {
    // HACK ALERT: shifting the connector to be active when it is AFTER the active step
    const activeStepForMui = activeStepIndex + 1;

    if (activeStepIndex === steps.length - 1 && !displayAllCompleteStepper) {
        return null;
    }

    return (
        <>
            <div className="progressStepperContainer">
                <div className='progressStepper'>
                    <Stepper
                        activeStep={activeStepForMui}
                        connector={<StepConnector sx={{ background: `linear-gradient(to right, var(--primary-button-background-color) ${activeStepPercentageComplete}%, var(--disabled-grey-light) ${activeStepPercentageComplete}%)` }} />}
                        alternativeLabel
                        >
                        {steps.map((step, index) => {
                            const stepIsCompletedForMui = activeStepForMui > index;
                            const stepIsActiveForMui = activeStepForMui === index;

                            const stepIsActive = activeStepIndex === index;
                            const stepIsCompleted = activeStepIndex > index;

                            return (
                                <Step
                                    key={index}
                                    index={index}
                                    active={stepIsActiveForMui}
                                    completed={stepIsCompletedForMui}
                                    component={'div'}
                                >
                                    <StepLabel
                                        className={stepIsActive ? 'mhd-active' : ''}
                                        icon={<FontAwesomeIcon icon={stepIsCompleted ? faCheck : step.icon} />}
                                    >
                                        {(steps[activeStepIndex].label === step.label) && 
                                            <span>{step.label}</span>
                                        }
                                    </StepLabel>
                                </Step>
                            )
                        })}
                    </Stepper>
                </ div>
            </div>
        </>
    );
}

export default ProgressStepper;