import * as actionTypes from './actionTypes';

export function getActiveCareOrderDetails(careOrderVisitIdentifier, productInstanceId) {
	let route = `?productInstanceid=${productInstanceId}`;
	return {
		type: actionTypes.GET_ACTIVE_CAREORDERVISIT_DETAILS,
		payload: {
			request: {
				method: 'get',
				url: `careordervisit/${careOrderVisitIdentifier}/workflowDetails${route}`,
			},
		},
	};
}
