import { useSelector } from "react-redux";
import {
    createCalendarDateAvailabilitySelector,
    selectFirstDisplayableDateWithSlotForCalendarById,
    selectIsCalendarScanningById,
    selectIsCalendarSearchingById
} from "../availabilitySlice";
import { useMemo, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import LoadingSpinner from "../../../components/loadingIndicator/spinner";
import { MoreLessAvailabilityToggler, NoAvailabilityPane, Slot } from "./availability";
import { nullifySuppressedDates } from "../../../lib/date";
import { selectShouldSuppressSaturday, selectShouldSuppressSunday } from "../availabilitySlice/availabilitySelectors";

const AvailabilitySelectedCalendar = ({ calendarId, date, isMobile }) => {
    const [isExpandedToShowAllSlots, setIsExpandedToShowAllSlots] = useState(false);

    const isSearching = useSelector(state => selectIsCalendarSearchingById(state, calendarId));
    const isScanning = useSelector(state => selectIsCalendarScanningById(state, calendarId));

    const initialSlotsPerDay = useSelector((state) => state.config.availabilitySearch.slotsPerDayMobile);

    const slotColumnsPerDay = useSelector((state) => state.config.availabilitySearch.slotColumnsPerDayMobile);

    const isScanningOrSearching = isSearching || isScanning;

    const selectCalendarAvailabilityByDate = useMemo(createCalendarDateAvailabilitySelector, []);

    //This creates a unique selector instance to cache by calendarId/date
    //https://redux.js.org/usage/deriving-data-selectors#creating-unique-selector-instances
    const slots = useSelector((state) => selectCalendarAvailabilityByDate(state, calendarId, date));

    const hasMoreAvailabilityThanInitialLimit = slots?.length > initialSlotsPerDay;

    const firstDisplayableDateWithSlot = useSelector(state => selectFirstDisplayableDateWithSlotForCalendarById(state, calendarId));

    const suppressSaturday = useSelector(selectShouldSuppressSaturday);
    const suppressSunday = useSelector(selectShouldSuppressSunday)

    return (
        <>
            {!!slots?.length &&
                <div className={'availability-container'}>
                    {nullifySuppressedDates(date, suppressSaturday, suppressSunday)
                        ? <AvailabilityDateSelectedCalendar
                            key={date}
                            slots={slots}
                            date={date}
                            isExpanded={isExpandedToShowAllSlots}
                            initialSlotsPerDay={initialSlotsPerDay}
                            numSlotColumns={slotColumnsPerDay}
                            isMobile={isMobile}
                        />
                        : <AvailabilityDateSelectedCalendarDisabled isMobile={isMobile} />

                    }

                    {(isSearching || (isScanning && !firstDisplayableDateWithSlot)) && (
                        <LoadingSpinner message="Loading availability..." />
                    )}
                </div>
            }
            {!slots?.length &&
                (isSearching || (isScanning && !firstDisplayableDateWithSlot)) && (
                    <div className={'availability-container'}>
                        <LoadingSpinner message="Loading availability..." />
                    </div>
                )
            }
            {hasMoreAvailabilityThanInitialLimit &&
                <MoreLessAvailabilityToggler
                    isExpanded={isExpandedToShowAllSlots}
                    setIsExpanded={setIsExpandedToShowAllSlots}
                />
            }
            {!slots?.length && !isScanningOrSearching && (
                <NoAvailabilityPane calendarId={calendarId} />
            )}
        </>
    );
}

const AvailabilityDateSelectedCalendarDisabled = ({ isMobile }) => {
    const availabilityDayClassName = classNames(
        'availability-day',
        { isMobile: isMobile },
        'no-availability',
        'disabled'
    );

    return (
        <div className={availabilityDayClassName}></div>
    )
}


const AvailabilityDateSelectedCalendar = ({ slots, date, isExpanded, initialSlotsPerDay, numSlotColumns, isMobile }) => {

    const dayDate = moment.utc(date);
    const dateString = moment(dayDate).format('dddd') + ", " + moment(dayDate).format('MMMM Do YYYY');

    const hasMoreAvailabilityThanInitialLimit = slots.length > initialSlotsPerDay;
    const numSlotsToShow =
        isExpanded || !hasMoreAvailabilityThanInitialLimit ? slots.length : initialSlotsPerDay;
    const slotsToShow = slots.slice(0, numSlotsToShow);
    const slotsToShowByRow = [];
    const slotsPerColumn = Math.ceil(numSlotsToShow / numSlotColumns);
    const numRowsToShow = slotsPerColumn;

    for (let i = 0; i < numRowsToShow; i++) {
        const rowIndex = i * numSlotColumns;
        const nextRowIndex = rowIndex + numSlotColumns;
        const rowSlots = slotsToShow.slice(rowIndex, nextRowIndex);
        slotsToShowByRow.push(rowSlots);
    }

    const availabilityDayClassName = classNames(
        'availability-day',
        { isMobile: isMobile },
        { 'no-availability': !slots.length },
    );

    return (
        <div className={availabilityDayClassName}>
            <h5>{dateString}</h5>
            {slots?.length > 0 &&
                slotsToShowByRow.map((row, i) => {
                    return (
                        <div className="slot-row" key={i}>
                            {row.map((appt, i) => <Slot appointment={appt} key={i} />)}
                        </div>
                    );
                })
            }
        </div>
    )
}

export default AvailabilitySelectedCalendar;