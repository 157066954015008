export const PATIENT_DEMOGRAPHICS = {
	dateOfBirth: 'dateOfBirth',
	gender: 'gender',
	firstName: 'firstName',
	lastName: 'lastName',
	parentOrGuardianName: 'parentOrGuardianName',
	email: 'email',
	homePhone: 'homePhone',
	mobilePhone: 'mobilePhone',
	memberId: 'memberId',
	address1: 'address1',
	address2: 'address2',
	city: 'city',
	stateCode: 'stateCode',
	zipCode: 'zipCode',
};
