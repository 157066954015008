import React from 'react';
import classNames from 'classnames';
import { getFieldSizeByLabel } from '../../lib/fieldConfigUtils';

export default function FieldControl(props) {
	const { className, disabled, error, hasValue, isFocused, isRequired, id, inputType, isVisible, label, labelAfter, size, elementRef, style } = props;
	let comp = null;
	let sizeClassName = size;
	if (!size && !!label) {
		sizeClassName = getFieldSizeByLabel(label);
	}
	let effectiveLabel = label;
	if (isRequired) {
		effectiveLabel = <><span className="mandatory">*</span>{label}</>
	}

	const fieldControlClassName = classNames(
		"field-control",
		className,
		inputType,
		sizeClassName,
		{ error: error },
		{ filled: hasValue },
		{ focused: isFocused },
		{ disabled: disabled },
	);

	if (isVisible)
		comp =
			<div className={fieldControlClassName} ref={elementRef} style={style}>
				{label && !labelAfter &&
					<label className="field-label" htmlFor={id}>
						{effectiveLabel}
					</label>
				}
				{props.children}
				{label && labelAfter &&
					<label className="field-label" htmlFor={id}>
						{effectiveLabel}
					</label>
				}
				{error &&
					<span className="field-error">
						{error.message}
					</span>
				}
			</div>
	return comp;
}
