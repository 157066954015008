import React from 'react';
import PropTypes from 'prop-types';
import './earlierAppointmentRequestSection.css';

export default function EarlierAppointmentRequestSection(props) {
	const { name, value, isMobile, requestEarlierAppointmentFieldConfig, onChange } = props;
	const className = !isMobile ? "earlier-appointment-section-no-margin" : "earlier-appointment-section-bottom-margin";

	return (
		<section id="earlierAppointmentSection" className={className}>
			<div className="section-row earlier-appointment-section">
				<div className="field-control checkbox xl">
					<input
						checked={value}
						className="input-checkbox"
						id="earlierAppointmentInput"
						name={name}
						onChange={onChange}
						type="checkbox"
						value={value}
					/>
					<label id="earlierAppointment" htmlFor="earlierAppointmentInput" className="field-label">
						<div>
							{requestEarlierAppointmentFieldConfig.fieldLabel ?
								(<span>
									<strong>{ requestEarlierAppointmentFieldConfig.fieldLabel }</strong>
								</span>) :
								(<span>
									<strong>Request Earlier Appointment</strong>
								</span>)
							}
							
						</div>
					</label>
				</div>
			</div>
		</section>
	);
};

EarlierAppointmentRequestSection.propTypes = {
	onChange: PropTypes.func,
	name: PropTypes.string,
	value: PropTypes.bool,
}