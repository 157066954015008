import * as types from '../actions/actionTypes';
import moment from 'moment';

moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss';

const initialState = {
	//#region Search Criteria
	availabilitySearchCriteria: {
		returnAllCalendars: true,
		calendarIds: [],
		minStartDate: '',
		maxStartDate: '',
		minStartTime: null,
		maxStartTime: null,
		returnAllDays: true,
		daysOfWeek: [],
		returnAllAppointmentTypes: false,
		appointmentTypeIds: [],
		appointmentTypeNames: [],
		appointmentModalityIds: [],
		returnAllAppointmentModalities: true,
		payorTypeName: '',
		insuranceProviderId: null,
		maxTimeSlotsPerDay: null,
	},
	calendarSearchCriteria: {
		identityCriteria: {
			calendarsByIdentities: [],
			applyCalendarIdentityFilter: true, // always true?
			applyCriteriaToPreferredCalendars: true, //TODO make this config based
			ignoreInvalidCalendars: true,
			practiceSystemIdentities: null,
		},
		providerCriteria: {},
		patientCriteria: {},
		distanceFilter: {},
		responseConfig: {},
	},
	//#endregion

	//#region Search Results
	firstAvailabilityScanResults: {
		calendars: [],
		hasAvailability: false,
		firstAvailability: {},
	},
	nextAvailabilityScanResults: {
		calendars: [],
		hasAvailability: false,
		firstAvailability: {},
	},
	availabilitySearchResults: {
		timeSlots: [],
	},
	calendarSearchResults: {
		hasSearched: false,
		calendars: [],
	},
	//#endregion

	//#region Misc
	isLoading: {
		calendarSearch: false,
		firstAvailabilityScan: false,
		nextAvailabilityScan: false,
		availabilitySearch: false,
		loadingMessage: '',
	},
	//#endregion
	lastFinishedSearchCriteria: {
		calendar: null,
		firstScan: null,
		availability: null,
		nextScan: null,
	}
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		//#region Initialize
		case types.SET_INITIAL_AVAILABILITY_SEARCH_STATE: {
			return {
				...state,
				availabilitySearchCriteria: action.availability.availabilitySearchCriteria,
				calendarSearchCriteria: action.availability.calendarSearchCriteria,
			};
		}

		//#endregion

		//#region Calendar Search
		case types.SEARCH_CALENDARS: {
			return {
				...state,
				isLoading: {
					...state.isLoading,
					calendarSearch: true,
					loadingMessage: 'Searching for availability, please wait.',
				},
				firstAvailabilityScanResults: {
					calendars: [],
					hasAvailability: false,
					firstAvailability: {},
				},
				nextAvailabilityScanResults: {
					calendars: [],
					hasAvailability: false,
					firstAvailability: {},
				},
				availabilitySearchResults: {
					timeSlots: [],
				},
				calendarSearchResults: {
					calendars: [],
				},
				lastFinishedSearchCriteria: {
					...state.lastFinishedSearchCriteria,
					calendar: null
				}
			};
		}

		case types.SEARCH_CALENDARS_SUCCESS: {
			const result = action.payload.data;
			const request = action.meta.previousAction.payload.request.data;
			const sessionId = action.meta.previousAction.payload.sessionId;
			return {
				...state,
				calendarSearchResults: {
					calendars: result.calendars,
					hasSearched: true,
				},
				isLoading: {
					...state.isLoading,
					calendarSearch: false,
				},
				lastFinishedSearchCriteria: {
					...state.lastFinishedSearchCriteria,
					calendar: {
						sessionId: sessionId,
						...request,
					}
				}
			};
		}

		case types.SEARCH_CALENDARS_ERROR: {
			return {
				...state,
				isLoading: {
					...state.isLoading,
					calendarSearch: false,
				},
			};
		}
		//#endregion

		//#region First Availability Scan
		case types.SCAN_FIRST_AVAILABILITY: {
			return {
				...state,
				isLoading: {
					...state.isLoading,
					firstAvailabilityScan: true,
					loadingMessage: 'Searching for availability, please wait.',
				},
				lastFinishedSearchCriteria: {
					...state.lastFinishedSearchCriteria,
					firstScan: null
				}
			};
		}

		case types.SCAN_FIRST_AVAILABILITY_SUCCESS: {
			const result = action.payload.data;
			const request = action.meta.previousAction.payload.request.data;
			const sessionId = action.meta.previousAction.payload.sessionId;
			return {
				...state,
				firstAvailabilityScanResults: {
					calendars: result.calendars,
					hasAvailability: result.hasAvailability,
					firstAvailability: result.firstAvailability,
				},
				isLoading: {
					...state.isLoading,
					firstAvailabilityScan: false,
				},
				lastFinishedSearchCriteria: {
					...state.lastFinishedSearchCriteria,
					firstScan: {
						sessionId: sessionId,
						...request
					},
				}
			};
		}

		case types.SCAN_FIRST_AVAILABILITY_ERROR: {
			return {
				...state,
				isLoading: {
					...state.isLoading,
					firstAvailabilityScan: false,
				},
			};
		}
		//#endregion

		//#region Next Availability Scan
		case types.SCAN_NEXT_AVAILABILITY: {
			return {
				...state,
				isLoading: {
					...state.isLoading,
					nextAvailabilityScan: true,
					loadingMessage: 'Fetching timeslots, please wait.',
				},
				nextAvailabilityScanResults: {
					calendars: [],
					hasAvailability: false,
					firstAvailability: {},
				},
				lastFinishedSearchCriteria: {
					...state.lastFinishedSearchCriteria,
					nextScan: null
				}
			};
		}

		case types.SCAN_NEXT_AVAILABILITY_SUCCESS: {
			const result = action.payload.data;
			const request = action.meta.previousAction.payload.request.data;
			const sessionId = action.meta.previousAction.payload.sessionId;
			return {
				...state,
				nextAvailabilityScanResults: {
					calendars: result.calendars,
					hasAvailability: result.hasAvailability,
					firstAvailability: result.firstAvailability,
				},
				isLoading: {
					...state.isLoading,
					nextAvailabilityScan: false,
				},
				lastFinishedSearchCriteria: {
					...state.lastFinishedSearchCriteria,
					nextScan: {
						sessionId: sessionId,
						...request,
					}
				}
			};
		}

		case types.SCAN_NEXT_AVAILABILITY_ERROR: {
			return {
				...state,
				isLoading: {
					...state.isLoading,
					nextAvailabilityScan: false,
				},
			};
		}

		case types.SET_SCAN_NEXT_AVAILABILITY_RESULTS: {
			const result = action.payload;
			return {
				...state,
				nextAvailabilityScanResults: result
			}
		}
		//#endregion

		//#region Availability Search
		case types.SEARCH_AVAILABILITY: {
			return {
				...state,
				isLoading: {
					...state.isLoading,
					availabilitySearch: true,
					loadingMessage: 'Fetching timeslots, please wait.',
				},
				availabilitySearchResults: {
					timeSlots: [],
				},
				lastFinishedSearchCriteria: {
					...state.lastFinishedSearchCriteria,
					availability: null
				}
			};
		}

		case types.SEARCH_AVAILABILITY_SUCCESS: {
			const result = action.payload.data;
			const request = action.meta.previousAction.payload.request.data;
			const sessionId = action.meta.previousAction.payload.sessionId;
			return {
				...state,
				availabilitySearchResults: {
					availSearchBatchClientId: sessionId,
					timeSlots: result.timeSlots,
				},
				isLoading: {
					...state.isLoading,
					availabilitySearch: false,
					loadingMessage: '',
				},
				lastFinishedSearchCriteria: {
					...state.lastFinishedSearchCriteria,
					availability: {
						sessionId: sessionId,
						...request,
					}
				}
			};
		}

		case types.SEARCH_AVAILABILITY_ERROR: {
			return {
				...state,
				isLoading: {
					...state.isLoading,
					availabilitySearch: false,
					loadingMessage: '',
				},
			};
		}
		//#endregion

		//#region Misc
		case types.APPOINTMENT_DETAILS_SUCCESS:
		case types.SET_ACTIVE_PATIENT:
		case types.RESET_AVAILABILITY_STATE: {
			return initialState;
		}

		default: {
			return state;
		}
		//#endregion
	}
}
