export const DEFAULT_LABELS = {
	PATIENT: {
		GENDER: 'Gender',
		MEMBERID: 'Member Id',
		GROUPNUMBER: 'Group Number',
		FIRSTNAME: 'First Name',
		MIDDLENAME: 'Middle Name',
		LASTNAME: 'Last Name',
		ADDRESSLINE1: 'Address (Line 1)',
		ADDRESSLINE2: 'Address (Line 2)',
		CITY: 'City',
		STATE: 'State',
		ZIPCODE: 'Zip Code',
		DATEOFBIRTH: 'Date of Birth',
		EMAIL: 'Email Address',
		HOMEPHONE: 'Home Phone',
		MOBILEPHONE: 'Mobile Phone',
		ALTERNATEPHONE: 'Alternate Phone',
		PARENT_OR_GUARDIAN_NAME: 'Parent or Guardian',
	}
}