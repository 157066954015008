import React, { Component } from 'react';

class AppliedPathwaysErrorItem extends Component {
	render() {
		return (
			<div>
				<ul>
					<li>
						<strong>{this.props.name}</strong> - {this.props.errorMessage}
					</li>
				</ul>
			</div>
		);
	}
}

export default AppliedPathwaysErrorItem;
