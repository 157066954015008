import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

const LoadingSpinner = ({ message }) => {
    // Get the CSS variable
    const primaryButtonBackgroundColor = getComputedStyle(document.documentElement)
        .getPropertyValue('--primary-button-background-color').trim();

    // Function to determine whether a color is dark
    const isDark = (color) => {
        if (!color)
            return false;

        // Convert hex color to RGB
        if (color.startsWith('#')) {
            const hex = color.replace('#', '');
            const r = parseInt(hex.substring(0, 2), 16);
            const g = parseInt(hex.substring(2, 4), 16);
            const b = parseInt(hex.substring(4, 6), 16);
            color = `rgb(${r}, ${g}, ${b})`;
        }

        const [r, g, b] = color.match(/\d+/g).map(Number);
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        return brightness < 186;
    };

    // Determine the color of the FontAwesomeIcon
    const iconColor = isDark(primaryButtonBackgroundColor) ? primaryButtonBackgroundColor : '#426DA9';

    return (
        <div className="loading-spinner-container" style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <FontAwesomeIcon icon={faSpinnerThird} spin size="lg" style={{ "--fa-primary-color": iconColor, "--fa-secondary-color": "#575859", }} />
            <p style={{ margin: 0 }}>{message || "Loading..."}</p>
        </div>
    );
}

export default LoadingSpinner;