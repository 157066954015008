import React from 'react';

import {
	TextInput,
	DateInput,
	EmailInput,
	PhoneInput,
	RadioSelections,
	TextAreaInput,
	NumberInput,
	CheckboxMultiSelect,
	ZipInput,
} from '../input';
import { GuidedResponseDropdownSelect } from './guidedResponseSelection';
import { DropdownSelectWithConditionalText } from './guidedResponseSelectionWithConditionalText';

function getPlaceholderForInputTypeRef(inputType) {
	let placeholder;
	switch (inputType) {
		case 'Text':
		case 'MultiLineText':
		case 'Bool':
			break;
		case 'Date':
			placeholder = 'mm/dd/yyyy';
			break;
		case 'DateAndTime':
		case 'TimeOfDay':
		case 'Integer':
		case 'Decimal':
		case 'Currency':
			break;
		case 'PhoneNumber':
			placeholder = 'XXX-XXX-XXXX';
			break;
		case 'Email':
		case 'Selection':
		case 'MultiSelection':
		case 'SelectionWithConditionalText':
		case 'ZipCode':
		default:
			break;
	}
	return placeholder;
}

function buildInputData(question, answer, altAnswer, error, onChange, onDropdownChange, onFocus, onBlur, isFocusedLookup) {
	return {
		id: question.elementRef,
		error: error,
		disabled: false,
		isFocused: isFocusedLookup,
		isVisible: true,
		label: null,
		labelAfter: false,
		defaultOptionLabel: 'Please make a selection',
		minLength: question.minLength,
		maxLength: question.maxLength,
		minValue: question.minValue,
		maxValue: question.maxValue,
		name: question.elementRef.toString(),
		value: answer ? answer : '',
		altValue: altAnswer ? altAnswer : '',
		onBlur,
		onChange,
		onDropdownChange,
		onFocus,
		placeholder: getPlaceholderForInputTypeRef(question.inputType),
		size: 'md',
		conditionalTextTriggerValue: question.conditionalTextTriggerValue,
		conditionalTextInputRequired: question.conditionalTextInputRequired,
		conditionalTextInputLabel: question.conditionalTextInputLabel,
	};
}

function buildBooleanOptionList(question) {
	let optionList = [
		{
			id: 'true',
			value: 'true',
			name: question.trueLabel,
		},
		{
			id: 'false',
			value: 'false',
			name: question.falseLabel,
		},
	];
	return optionList;
}

function buildSelectionOptionList(question) {
	return question.selectOptions.map((selectionOption) => ({
		id: selectionOption.value,
		name: selectionOption.label,
		value: selectionOption.value,
		elementRef: question.elementRef,
	}));
}

function getComponentNameforInputTypeRef(inputType) {
	let ComponentName;
	switch (inputType) {
		case 'Text':
			ComponentName = TextInput;
			break;
		case 'MultiLineText':
			ComponentName = TextAreaInput;
			break;
		case 'Bool':
			ComponentName = RadioSelections;
			break;
		case 'Date':
			ComponentName = DateInput;
			break;
		case 'DateAndTime':
			ComponentName = TextInput;
			break;
		case 'TimeOfDay':
			ComponentName = TextInput;
			break;
		case 'Integer':
			ComponentName = NumberInput;
			break;
		case 'Decimal':
			ComponentName = TextInput;
			break;
		case 'Currency':
			ComponentName = TextInput;
			break;
		case 'PhoneNumber':
			ComponentName = PhoneInput;
			break;
		case 'Email':
			ComponentName = EmailInput;
			break;
		case 'Selection':
			ComponentName = GuidedResponseDropdownSelect;
			break;
		case 'MultiSelection':
			ComponentName = CheckboxMultiSelect;
			break;
		case 'SelectionWithConditionalText':
			ComponentName = DropdownSelectWithConditionalText;
			break;
		case 'ZipCode':
			ComponentName = ZipInput;
			break;
		default:
			ComponentName = TextInput;
			break;
	}
	return ComponentName;
}

const GuidedResponseQuestion =  ({ question, answer, altAnswer, error, onChange, onDropdownChange, onFocus, onBlur, isFocusedLookup }) => {
	const ComponentName = getComponentNameforInputTypeRef(question.inputType);

	const inputData = buildInputData(question, answer, altAnswer, error, onChange, onDropdownChange, onFocus, onBlur, isFocusedLookup);
	inputData.optionList =
		question.inputType === 'Bool'
			? buildBooleanOptionList(question)
			: question.inputType === 'Selection' ||
			  question.inputType === 'SelectionWithConditionalText' ||
			  question.inputType === 'MultiSelection'
			? buildSelectionOptionList(question) 	
			: null;

	return <ComponentName {...inputData} />;
};

export default GuidedResponseQuestion