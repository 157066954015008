import React from 'react';
import { GuidedResponsePreviousButton } from './guidedResponsePreviousButton';
import { GuidedResponseFlowDebugPathButton } from "./guidedResponseFlowDebugPathButton";

const GuidedResponseMessage = ({ message, isRequired, className, handlePrevious, enablePreviousButton, isLoading, debugMode, getFlowDebugPathFile }) => {
	const style = className ?? 'message-text';
	return (
		<div>
			{isRequired && <span className="mandatory">*</span>}
			<span className={style} dangerouslySetInnerHTML={{ __html: message }} />
			<div style={{marginTop: "2em"}}>
				{enablePreviousButton && (
					<GuidedResponsePreviousButton handler={handlePrevious} isLoading={isLoading} />
				)}
				{debugMode && <GuidedResponseFlowDebugPathButton isLoading={isLoading} handler={getFlowDebugPathFile} />}
			</div>
		</div>
	);
};

export default GuidedResponseMessage
