import dayjs, { Dayjs } from 'dayjs';
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export const minDateFromRange = (dates: string[]): string => {
    return dates.sort((a, b) => dayjs(a).diff(dayjs(b)))[0];
}

export const maxDateFromRange = (dates: string[]): string => {
    return dates.sort((a, b) => dayjs(b).diff(dayjs(a)))[0];
}

export const getMinDateFromCalendarDates = (calendarDates: string[][]): string => {
    const result = calendarDates.reduce((prevValue, dates) => {
        const curr = minDateFromRange(dates);
        if (!prevValue)
            return curr;
        return dayjs(prevValue) < dayjs(curr) ? dayjs(prevValue).format(DEFAULT_DATE_FORMAT) : dayjs(curr).format(DEFAULT_DATE_FORMAT);
    }, '')
    return result;
}

export const getMaxDateFromCalendarDates = (calendarDates: string[][]): string => {
    const result = Object.values(calendarDates).reduce((prevValue, dates) => {
        const curr = maxDateFromRange(dates);
        if (!prevValue)
            return curr;
        return dayjs(prevValue) > dayjs(curr) ? dayjs(prevValue).format(DEFAULT_DATE_FORMAT) : dayjs(curr).format(DEFAULT_DATE_FORMAT);
    }, '')
    return result;
}

export const isSaturday = (date: string | Dayjs): boolean => {
    return dayjs(date).format('dddd') === 'Saturday';
}

export const isSunday = (date: string | Dayjs): boolean => {
    return dayjs(date).format('dddd') === 'Sunday';
}

export const nullifySuppressedDates = (date: Dayjs | string, suppressSaturday: boolean, suppressSunday: boolean): Dayjs | string | null => {
    if (suppressSaturday && isSaturday(date)) {
        return null;
    }
    if (suppressSunday && isSunday(date)) {
        return null;
    }
    return date;
}

export const skipOverSuppressedDates = (date: Dayjs | string, suppressSaturday: boolean, suppressSunday: boolean): Dayjs | string => {
    let tempDate = date;
    while (!nullifySuppressedDates(tempDate, suppressSaturday, suppressSunday)) {
        tempDate = dayjs(tempDate).add(1, 'd').format(DEFAULT_DATE_FORMAT);
    }
    return tempDate;
} 