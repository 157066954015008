import React, { useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import { Modal } from '../modal/modal';
import * as FieldControl from '../input';
import * as Button from '../buttons';
import * as Icon from '../icons';
import { getFieldsConfig } from '../../lib/multiProviderAvailabilityHelper';
import { getFieldGroupsInfoFromFieldsConfig } from '../../lib/fieldConfigUtils';
import { ASC } from '../../constants/availabilitySearchFields';

import { buildFieldRefFromId, filterArray } from '../../lib/fieldUtils';
import { INPUT_TYPES } from '../../constants/inputTypes';
import { DATE_FORMATS } from '../../constants/dateFormats';
import { validateSection } from '../../lib/validationUtils';

// FILTER
export function AvailabilityResultsFilters(props) {
	const {
		availabilitySearchConfig,
		availabilitySearchCriteria,
		effectiveInputCriteria,
		initialInputCriteria,
		handleConfirmFilters,
		handleImmediateFilter,
		handleGoBackToGuidedResponse,
		showReturnToQuestionsButton,
	} = props;

	const { searchFields } = availabilitySearchConfig;

	const fieldsConfig = useMemo(() => getFieldsConfig(searchFields), [searchFields]);

	const [formFieldValues, setFormFieldValues] = useState({
		...initialInputCriteria,
		[ASC.appointmentStartDate]: initialInputCriteria[ASC.appointmentStartDate].format(DATE_FORMATS.standard),
	});

	const [formErrors, setFormErrors] = useState([]);

	const isFocusedLookup = {}; //no support this page, naming a blank obj for clarity

	const immediateFieldsInfo = getFieldGroupsInfoFromFieldsConfig(
		formErrors,
		effectiveInputCriteria,
		fieldsConfig,
		props,
		isFocusedLookup,
		true,
	).navBar;
	let modalFieldsInfo = getFieldGroupsInfoFromFieldsConfig(
		formErrors,
		formFieldValues,
		fieldsConfig,
		props,
		isFocusedLookup,
		true,
	);
	modalFieldsInfo = { ...modalFieldsInfo.navBar, ...modalFieldsInfo.modal };

	const handleInputChange = (e) => {
		e.preventDefault();

		const fieldRef = buildFieldRefFromId(e.target.name);
		let fieldConfig = modalFieldsInfo[fieldRef.fieldName];
		let newValue = null;

		if (fieldConfig.inputType === INPUT_TYPES.CHECKBOX) {
			newValue = !formFieldValues[fieldRef.fieldName];
		} else if (fieldConfig.inputType === INPUT_TYPES.DROPDOWN) {
			newValue = parseInt(e.target.value, 10) || e.target.value;
		} else if (fieldConfig.inputType === INPUT_TYPES.BUTTON_OPTIONS) {
			newValue = filterArray(
				e.target.value,
				formFieldValues[fieldRef.fieldName],
				fieldConfig.isMultiSelect,
				fieldConfig.isInteger,
			);
		} else {
			newValue = e.target.value;
		}

		setFormFieldValues((x) => {
			return { ...x, [fieldRef.fieldName]: newValue };
		});
	};

	const startDate = formFieldValues[ASC.appointmentStartDate];
	const zipVal = formFieldValues[ASC.zipCode];

	/* eslint-disable */
	const validateFormFieldsTrigger = useEffect(() => {
		const newErrors = validateSection(
			'modal',
			formErrors,
			{ modal: { ...formFieldValues } },
			{ availabilitySearchCriteria: availabilitySearchCriteria },
			fieldsConfig,
		);
		setFormErrors(newErrors);
	}, [availabilitySearchCriteria, startDate, zipVal]);

	const effectiveInputCriteriaChangeTrigger = useEffect(() => {
		setFormFieldValues((x) => {
			return {
				...effectiveInputCriteria,
				[ASC.appointmentStartDate]: effectiveInputCriteria[ASC.appointmentStartDate].format(DATE_FORMATS.standard),
			};
		});
	}, [effectiveInputCriteria]);
	/* eslint-enable */

	const submitForm = () => {
		if (validateSection('modal', formErrors, { modal: { ...formFieldValues } }, props, fieldsConfig).length < 1) {
			handleConfirmFilters({
				...formFieldValues,
				[ASC.appointmentStartDate]: moment(formFieldValues[ASC.appointmentStartDate]),
			});
		}
	};

	const resetForm = () => {
		let updatedFormFieldValues = props.isMobile
			? {
					...initialInputCriteria,
					[ASC.appointmentStartDate]: initialInputCriteria[ASC.appointmentStartDate].format(DATE_FORMATS.standard),
			  }
			: {
					...initialInputCriteria,
					[ASC.appointmentStartDate]: initialInputCriteria[ASC.appointmentStartDate].format(DATE_FORMATS.standard),
					[ASC.appointmentModalityFilter]: effectiveInputCriteria[ASC.appointmentModalityFilter],
					[ASC.sortOrder]: effectiveInputCriteria[ASC.sortOrder],
					[ASC.serviceName]: effectiveInputCriteria[ASC.serviceName],
					[ASC.siteFilter]: effectiveInputCriteria[ASC.siteFilter],
			  };

		setFormFieldValues(updatedFormFieldValues);
	};

	return (
		<div className="availability-results-filters">
			<AvailabilityResultsFilterNavBar
				{...props}
				navBarFieldsInfo={immediateFieldsInfo}
				handleImmediateFilter={handleImmediateFilter}
				handleInputChange={handleInputChange}
				showReturnToQuestionsButton={showReturnToQuestionsButton}
				handleGoBackToGuidedResponse={handleGoBackToGuidedResponse}
			/>
			{props.isShowing.filterModal && (
				<AvailabilityResultsFilterModal
					{...props}
					modalFieldsInfo={modalFieldsInfo}
					handleInputChange={handleInputChange}
					resetForm={resetForm}
					submitForm={submitForm}
				/>
			)}
		</div>
	);
}

export function AvailabilityResultsFilterNavBar(props) {
	const {
		availabilityResults,
		handleImmediateFilter,
		handleInputChange,
		isMobile,
		navBarFieldsInfo,
		setIsShowing,
		isLoading,
		showScanNextRangeButton,
		numberOfDaysToSearch,
		handleScanNextRange,
	} = props;

	const { siteList, serviceList, sortOrderList, appointmentModalityList } = availabilityResults.optionLists;

	return (
		<div className="availability-results-filter-navbar">
			<div className="dropdown-filters">
				{!isMobile && (
					<>
						<FieldControl.DropdownSelect
							{...navBarFieldsInfo[ASC.sortOrder]}
							onChange={(e) => {
								handleInputChange(e);
								handleImmediateFilter(ASC.sortOrder, e?.target?.value);
							}}
							optionList={sortOrderList.map(({ id, displayName }) => ({ id: id, name: displayName }))}
							size="md"
						/>
						<FieldControl.DropdownSelect
							{...navBarFieldsInfo[ASC.serviceName]}
							allowEmptyValue={true}
							onChange={(e) => {
								handleImmediateFilter(ASC.serviceName, e?.target?.value);
							}}
							optionList={[{ id: '', name: 'All' }, ...serviceList]}
							size="md"
						/>
						<FieldControl.DropdownSelect
							{...navBarFieldsInfo[ASC.siteFilter]}
							allowEmptyValue={true}
							onChange={(e) => {
								handleInputChange(e);
								handleImmediateFilter(ASC.siteFilter, e?.target?.value);
							}}
							optionList={[{ id: '', name: 'All' }, ...siteList]}
							size="md"
						/>
						<FieldControl.DropdownSelect
							{...navBarFieldsInfo[ASC.appointmentModalityFilter]}
							allowEmptyValue={true}
							onChange={(e) => {
								handleInputChange(e);
								handleImmediateFilter(ASC.appointmentModalityFilter, e?.target?.value);
							}}
							optionList={[{ id: '', name: 'All' }, ...appointmentModalityList]}
							size="md"
						/>
					</>
				)}
			</div>
			
			<div className="availability-filter-button">
				<div className="scan-next-range-button">
					{showScanNextRangeButton && (
						<Button.ButtonSecondary
							color="secondary"
							onClick={handleScanNextRange}
							disabled={isLoading.anySearch}>
							<span className="filter-button">Search Next {numberOfDaysToSearch} Days <Icon.IconChevronRight size={'sm'} /></span>

						</Button.ButtonSecondary>
					)}
				</div>
				<div>
					<Button.ButtonPrimary
						color="primary"
						onClick={() =>
							setIsShowing((x) => {
								return { ...x, filterModal: !x.filterModal };
							})
						}>
						<span className="filter-button"><Icon.IconFilter className="filter-icon" size={'sm'} /> &nbsp;Filter</span>
					</Button.ButtonPrimary>
				</div>
			</div>
		</div>
	);
}

export function AvailabilityResultsFilterModal(props) {
	const {
		availabilityResults,
		handleInputChange,
		isLoading,
		isMobile,
		isShowing,
		modalFieldsInfo,
		resetForm,
		setIsShowing,
		submitForm,
	} = props;

	const {
		siteList,
		serviceList,
		sortOrderList,
		timeOfDayList,
		daysOfWeekList,
		genderList,
		languageList,
		searchRadiusList,
		appointmentModalityList,
	} = availabilityResults.optionLists;

	const handleCloseModal = (e) => {
		let target = e.target;
		if (isShowing.filterModal && target.id === 'availabilityFilterModalContainer') {
			setIsShowing((x) => {
				return { ...x, filterModal: false };
			});
		}
	};

	return (
		<Modal
			containerId="availabilityFilterModalContainer"
			modalId="availabilityFilterModal"
			onClickOffModalHandler={handleCloseModal}
		>
			<AvailabilityResultsFilterModalHeader
				toggleFilter={() => {
					setIsShowing((x) => {
						return { ...x, filterModal: false };
					});
				}}
			/>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					submitForm();
					setIsShowing((x) => {
						return { ...x, filterModal: false };
					});
				}}
				className="availability-filter-modal-form"
			>
				<FieldControl.DateInput {...modalFieldsInfo[ASC.appointmentStartDate]} onChange={handleInputChange} />
				{isMobile && (
					<>
						<FieldControl.DropdownSelect
							{...modalFieldsInfo[ASC.sortOrder]}
							onChange={handleInputChange}
							optionList={sortOrderList}
							size="md"
						/>
						<FieldControl.DropdownSelect
							{...modalFieldsInfo[ASC.serviceName]}
							allowEmptyValue={true}
							onChange={handleInputChange}
							optionList={[{ id: '', name: 'All' }, ...serviceList]}
							size="md"
						/>
						<FieldControl.DropdownSelect
							{...modalFieldsInfo[ASC.siteFilter]}
							allowEmptyValue={true}
							onChange={handleInputChange}
							optionList={[{ id: '', name: 'All' }, ...siteList]}
							size="md"
						/>
						<FieldControl.DropdownSelect
							{...modalFieldsInfo[ASC.appointmentModalityFilter]}
							allowEmptyValue={true}
							onChange={handleInputChange}
							optionList={[{ id: '', name: 'All' }, ...appointmentModalityList]}
							size="md"
						/>
					</>
				)}
				<FieldControl.ButtonOptionsRow
					{...modalFieldsInfo[ASC.timeOfDay]}
					className={'time-of-day'}
					onChange={handleInputChange}
					optionList={timeOfDayList}
				/>
				<FieldControl.ButtonOptionsRow
					{...modalFieldsInfo[ASC.daysOfWeek]}
					className={'days'}
					onChange={handleInputChange}
					optionList={daysOfWeekList}
				/>
				<FieldControl.ButtonOptionsRow
					{...modalFieldsInfo[ASC.providerGender]}
					className={'gender'}
					onChange={handleInputChange}
					optionList={genderList}
				/>
				<FieldControl.DropdownSelect
					{...modalFieldsInfo[ASC.languagePreference]}
					allowEmptyValue={true}
					onChange={handleInputChange}
					optionList={[{ id: '', name: 'All' }, ...languageList]}
				/>
				<FieldControl.ButtonOptionsRow
					{...modalFieldsInfo[ASC.searchRadius]}
					className={'search-radius'}
					onChange={handleInputChange}
					optionList={searchRadiusList.filter((x) => x.id !== 0 && x.id !== '0')}
				/>
				<FieldControl.ZipInput {...modalFieldsInfo[ASC.zipCode]} onChange={handleInputChange} />
				<div className="submit-buttons-container">
					<Button.ButtonPrimary
						className="apply-filters"
						type="submit"
						disabled={isLoading.anySearch || isLoading.isReserving}
					>
						Apply
					</Button.ButtonPrimary>
					<Button.ButtonSecondary
						className="reset-filters"
						type="button"
						onClick={resetForm}
						disabled={isLoading.anySearch || isLoading.isReserving}
					>
						Reset
					</Button.ButtonSecondary>
				</div>
			</form>
		</Modal>
	);
}

export function AvailabilityResultsFilterModalHeader(props) {
	const { toggleFilter } = props;
	return (
		<div className="filter-modal-header">
			<h1>More Filters</h1>
			<Icon.IconClose className="close-icon" onClick={toggleFilter} />
		</div>
	);
}
