import * as types from '../actions/actionTypes';
import { AVAILABILITY_SEARCH_CRITERIA } from '../constants/availabilitySearchCriteria';
import { APPOINTMENT_CRITERIA } from '../constants/appointmentCriteria';
import { PATIENT_DEMOGRAPHICS } from '../constants/patientDemographics';

const initialState = {
	appointmentCriteria: {},
	availabilitySearchCriteria: {},
	customFields: [],
	patientDemographics: {},
};

//TODO - Consolidate all care order reducer logic into this reducer in future story
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.PROCESS_DECISION_SUPPORT_SUBPOINTS: {
			let asc = mapFieldsToObject(action.subpoints, availabilitySearchCriteriaMappings);
			let appt = mapFieldsToObject(action.subpoints, appointmentCriteriaMappings);
			let customFields = mapNonCriteriaFieldsToCustomFieldsArray(action.subpoints);
			let pd = mapFieldsToObject(action.subpoints, patientDemographicsMappings);

			return {
				...state,
				appointmentCriteria: appt,
				availabilitySearchCriteria: asc,
				customFields: customFields,
				patientDemographics: pd,
				endedInGuidedResponse: action.endedInGuidedResponse
			};
		}
		case types.CLEAR_DECISION_SUPPORT: {
			return initialState;
		}

		default: {
			return state;
		}
	}
}

let availabilitySearchCriteriaMappings = [
	defineOutputMapping('npi', AVAILABILITY_SEARCH_CRITERIA.serviceNpiList, valueToStringList),
	defineOutputMapping('siteid', AVAILABILITY_SEARCH_CRITERIA.siteIdList, valueToIntList),
	defineOutputMapping('servicetype', AVAILABILITY_SEARCH_CRITERIA.serviceTypeId, valueToInt),
	defineOutputMapping('assettags', AVAILABILITY_SEARCH_CRITERIA.assetTags, valueToStringList),
	defineOutputMapping('additionalcalendarids', AVAILABILITY_SEARCH_CRITERIA.additionalCalendarList, valueToIntList),
	defineOutputMapping('appointmenttypeid', AVAILABILITY_SEARCH_CRITERIA.appointmentTypeIdList, valueToIntList),
	defineOutputMapping('zipcode', AVAILABILITY_SEARCH_CRITERIA.zipCode, valueToString),
	defineOutputMapping('startdate', AVAILABILITY_SEARCH_CRITERIA.startDate, valueToString),
	defineOutputMapping('enddate', AVAILABILITY_SEARCH_CRITERIA.endDate, valueToString),
	defineOutputMapping('minstarttime', AVAILABILITY_SEARCH_CRITERIA.minStartTime, valueToString),
	defineOutputMapping('maxstarttime', AVAILABILITY_SEARCH_CRITERIA.maxStartTime, valueToString),
	defineOutputMapping('selectedprovidernpi', AVAILABILITY_SEARCH_CRITERIA.selectedProviderNpiList, valueToStringList),
	defineOutputMapping(
		'selectedprovidercalendarid',
		AVAILABILITY_SEARCH_CRITERIA.selectedProviderCalendarList,
		valueToIntList,
	),
	defineOutputMapping('selectedsiteid', AVAILABILITY_SEARCH_CRITERIA.selectedSiteIdList, valueToIntList),
];

let appointmentCriteriaMappings = [
	defineOutputMapping('reasonforvisit', APPOINTMENT_CRITERIA.reasonForVisit, valueToString),
	defineOutputMapping('externalreferralorderid', APPOINTMENT_CRITERIA.externalReferralOrderId, valueToString),
];

let patientDemographicsMappings = [
	defineOutputMapping('dateofbirth', PATIENT_DEMOGRAPHICS.dateOfBirth, valueToString),
	defineOutputMapping('gender', PATIENT_DEMOGRAPHICS.gender, valueToString),
	defineOutputMapping('firstname', PATIENT_DEMOGRAPHICS.firstName, valueToString),
	defineOutputMapping('lastname', PATIENT_DEMOGRAPHICS.lastName, valueToString),
	defineOutputMapping('parentorguardianname', PATIENT_DEMOGRAPHICS.parentOrGuardianName, valueToString),
	defineOutputMapping('email', PATIENT_DEMOGRAPHICS.email, valueToString),
	defineOutputMapping('homephone', PATIENT_DEMOGRAPHICS.homePhone, valueToString),
	defineOutputMapping('mobilephone', PATIENT_DEMOGRAPHICS.mobilePhone, valueToString),
	defineOutputMapping('memberid', PATIENT_DEMOGRAPHICS.memberId, valueToString),
	defineOutputMapping('address1', PATIENT_DEMOGRAPHICS.address1, valueToString),
	defineOutputMapping('address2', PATIENT_DEMOGRAPHICS.address2, valueToString),
	defineOutputMapping('city', PATIENT_DEMOGRAPHICS.city, valueToString),
	defineOutputMapping('state', PATIENT_DEMOGRAPHICS.stateCode, valueToString),
	defineOutputMapping('zip', PATIENT_DEMOGRAPHICS.zipCode, valueToString),
];

function mapFieldsToObject(subpoints, mappingArray) {
	let criteria = subpoints.reduce((result, subpoint) => {
		let mapping = mappingArray.find((m) => m.fieldName === subpoint.key.toLowerCase());
		if (mapping) result[mapping.criteriaName] = mapping.conversionFunc(subpoint.value);
		return result;
	}, {});
	return criteria;
}

function mapNonCriteriaFieldsToCustomFieldsArray(subpoints) {
	let nonCriteriaSubpoints = subpoints.filter((subpoint) => {
		return (
			!availabilitySearchCriteriaMappings.find((m) => m.fieldName === subpoint.key.toLowerCase()) &&
			!appointmentCriteriaMappings.find((m) => m.fieldName === subpoint.key.toLowerCase()) &&
			!patientDemographicsMappings.find((m) => m.fieldName === subpoint.key.toLowerCase())
		);
	});

	return nonCriteriaSubpoints;
}

function defineOutputMapping(fieldName, criteriaName, conversionFunc) {
	return { fieldName: fieldName, criteriaName: criteriaName, conversionFunc: conversionFunc };
}

function valueToString(valueString) {
	if (!valueString) {
		return '';
	}
	let trimmedValue = valueString.trim();
	return trimmedValue;
}

function valueToInt(valueString) {
	if (!valueString) {
		return 0;
	}
	let intValue = valueString * 1;
	return intValue;
}

function valueToStringList(valueString) {
	if (!valueString) {
		return [];
	}
	let arrayOfStrings = valueString.split(',');
	let trimmedList = arrayOfStrings.map((s) => s.trim());
	return trimmedList;
}

function valueToIntList(valueString) {
	if (!valueString) {
		return [];
	}
	let arrayOfStrings = valueString.split(',');
	let intList = arrayOfStrings.map((s) => s * 1);
	return intList;
}
