import React from 'react';
import {
	formatCityStateZip,
	//formatPhone,
	formatPhoneNoLabel,
} from '../../lib/misc';

export function ProviderSiteAddressAndPhone(props) {
	const {
		city,
		distanceMiles,
		phoneLabel,
		phoneNumber,
		providerAddressLine1,
		providerAddressLine2,
		providerSiteDisplayName,
		showAddress,
		showDistanceMiles,
		showPhone,
		state,
		zipCode,
	} = props;

	let cityStateZip = formatCityStateZip(city, state, zipCode);
	//let formattedPhoneNumber = formatPhone(phoneNumber);
	let formattedPhoneNumber = formatPhoneNoLabel(phoneNumber);

	return (
		<div className="provider-address">
			<address>
				<span className="site-name">{providerSiteDisplayName}</span>
				<br />
				{showAddress && (
					<>
						{providerAddressLine1}
						<br />
						{providerAddressLine2 && providerAddressLine2.trim() !== '' && (
							<>
								{providerAddressLine2}
								<br />
							</>
						)}
						{cityStateZip}
						<br />
						{showDistanceMiles && (
							<>
								<span>
									{distanceMiles ? +(Math.round(distanceMiles + 'e+1') + 'e-1') + ' miles away' : 'distance unknown'}
								</span>
								<br />
							</>
						)}
					</>
				)}
				{showPhone && (
					<>
						{phoneLabel && <span className="phone-label">{phoneLabel + ' '}</span>}
						<a href={`tel:${phoneNumber}`}>
							<span className="phone-number">{formattedPhoneNumber}</span>
						</a>
						<br />
					</>
				)}
			</address>
		</div>
	);
}
