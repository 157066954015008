class RouteTemplates {
    private static routes: any = {
        root: '/',
        decisionSupport: '/decisionSupport/workflow',
        availability: '/availability',
        bookAppointment: '/bookAppointment',
        appointmentDetails: '/appointment/:referenceId/details',

        bookAnotherAppointment: '/bookAnotherAppointment',
    }

    static get root() {
        return this.routes.root;
    }

    static get rootWithRoutePrefix() {
        return withRoutePrefix(''); // handled special for root because we don't want a trailing slash
    }

    static get decisionSupport() {
        return this.routes.decisionSupport;
    }

    static get decisionSupportWithRoutePrefix() {
        return withRoutePrefix(this.decisionSupport);
    }

    static get availability() {
        return this.routes.availability;
    }

    static get availabilityWithRoutePrefix() {
        return withRoutePrefix(this.availability);
    }

    static get bookAppointment() {
        return this.routes.bookAppointment;
    }

    static get bookAppointmentWithRoutePrefix() {
        return withRoutePrefix(this.bookAppointment)
    }

    static get appointmentDetails() {
        return this.routes.appointmentDetails;
    }

    static get appointmentDetailsWithRoutePrefix() {
        return withRoutePrefix(this.appointmentDetails)
    }

    static get bookAnotherAppointment() {
        return this.routes.bookAnotherAppointment;
    }

    static get bookAnotherAppointmentWithRoutePrefix() {
        return withRoutePrefix(this.bookAnotherAppointment)
    }
}

const withRoutePrefix = (route: string): string => {
    return `/:routePrefix${route}`;
}

export default RouteTemplates;