import React from 'react';
import MhdErrorDisplay from './mhdErrorDisplay';
import { connect } from 'react-redux';
import { getAppInsights } from './telemetryService';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
			errorInfo: null,
		};
	}

	componentDidCatch(error, info) {
		let appInsights = getAppInsights();
		appInsights.trackException(error);
		this.setState({ hasError: true, error: error, errorInfo: info });
	}

	render() {
		if (this.state.hasError) {
			return (
				<MhdErrorDisplay
					error={this.state.error}
					errorInfo={this.state.errorInfo}
					errorMessageOverride={this.props.errorMessageOverride}
					childName={this.props.childName}
				/>
			);
		} else {
			return this.props.children;
		}
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errorMessageOverride: state.config.instance.errorMessageOverride,
	};
}

export default connect(mapStateToProps)(ErrorBoundary);
