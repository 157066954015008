import React from 'react';
import FieldControl from './fieldControl';

/**
* @param {Object} props
* @param {string} props.id
* @param {Object} props.error
* @param {string} props.error.message
* @param {boolean} props.disabled
* @param {boolean} props.isFocused
* @param {boolean} props.isRequired
* @param {boolean} props.isVisible - If False Component Returns null
* @param {boolean} props.label
* @param {boolean} props.labelAfter - If true, label is placed after input (default before)
* @param {string} props.name
* @param {Object} props.value
* @param {Function} props.onBlur
* @param {Function} props.onChange
* @param {Function} props.onFocus
* @param {string} props.size - "sm", "md", "lg"
* @returns {Object}
*/
export function CheckboxInput(props) {
	const {
		id,
		error,
		disabled,
		isFocused,
		isRequired,
		isVisible,
		label,
		labelAfter,
		name,
		value,
		onBlur,
		onChange,
		onFocus,
		size,
	} = props;

	const inputType = "checkbox";
	const noLabelPlacementSpecified = (labelAfter !== true && labelAfter !== false) // Deviation from default "label before", this input defaults to after if no specification

	return (
		<FieldControl
			disabled={disabled}
			error={error}
			isFocused={isFocused}
			isRequired={isRequired}
			id={id}
			inputType={inputType}
			isVisible={isVisible}
			label={label}
			labelAfter={noLabelPlacementSpecified ? true : labelAfter}
			size={size}
		>
			<input
				checked={!!value}
				disabled={disabled}
				id={id}
				name={name}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				type={inputType}
				value={value}
			/>
		</FieldControl>
	);
}
