import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { completeSurveyOrder } from '../../actions/surveyOrderActions';
import { LoadingIndicatorModal } from '../../components/loadingIndicator/loadingIndicatorModal';
import { scrollToPageTop } from '../../lib/misc';

export class SurveyCompleteView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			error: '',
		};
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		scrollToPageTop();
		if (this.props.surveyOrderContext.surveyOrderStatusReasonId !== 5) {
			this.props
				.completeSurveyOrder(this.props.surveyOrderContext.referenceId, this.props.decisionSupportSessionId)
				.then((response) => {
					if (response.error) {
						this.setState({ error: 'An error occurred while trying to update survey.' });
					}
					this.setState({ isLoading: false });
				})
				.catch((err) => {
					this.setState({ error: 'Error' });
				});
		}
	}

	render() {
		let message = this.props.flowSessionResponse.results.fields[0].currentValue;
		let isLoading = this.state.isLoading;

		return (
			<div className="view-content">
				{isLoading ? (
					<LoadingIndicatorModal />
				) : (
					<main className={'main-content'}>
						<h2>Survey Complete</h2>
						<div>
							<span dangerouslySetInnerHTML={{ __html: message }} />
						</div>
					</main>
				)}
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		decisionSupportSessionId: state.session.decisionSupportSessionId,
		flowSessionResponse: state.guidedResponse.flowSessionResponse,
		surveyOrderContext: state.surveyOrder,
	};
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			completeSurveyOrder,
		},
		dispatch,
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyCompleteView);
