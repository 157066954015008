import React, { useEffect, useRef } from 'react';
import FieldControl from './fieldControl';
import AsyncSelect from 'react-select/async';
import "./asyncDropdownSelect.css";

/**
 * @param {Object} props
 * @param {string} props.id
 * @param {Object} props.error
 * @param {string} props.error.message
 * @param {boolean} props.disabled
 * @param {boolean} props.isFocused
 * @param {boolean} props.isRequired
 * @param {boolean} props.isVisible - If False Component Returns null
 * @param {boolean} props.label
 * @param {boolean} props.labelAfter - If true, label is placed after input (default before)
 * @param {Object} props.defaultOption
 * @param {string} props.name
 * @param {Object} props.value
 * @param {Function} props.onChange
 * @param {string} props.size - "sm", "md", "lg"
 * @param {Object} props.styles
 * @param {Function} props.loadOptions
 * @returns {Object}
 */
export function AsyncDropdownSelect(props) {
    const {
        allowEmptyValue,
        defaultOption,
        disabled,
        error,
        id,
        isFocused,
        isRequired,
        isVisible,
        label,
        labelAfter,
        loadOptions,
        name,
        onBlur,
        onChange,
        onFocus,
        size,
        value,
    } = props;

    const selectRef = useRef(null);

    useEffect(() => {
        if (selectRef.current) {
            const input = selectRef.current.querySelector("input");
            if(input) {
                input.name = name;
            }
        }
    }, [name])

    const inputType = 'text';

    const hasValue = !!value || value === 0 || (allowEmptyValue && value === '');
    const selectValue = hasValue ? {label: value, value: value} : {label: "", value: ""}; 

    return (
        <FieldControl
            disabled={disabled}
            error={error}
            hasValue={hasValue}
            isFocused={isFocused}
            isRequired={isRequired}
            id={id}
            inputType={inputType}
            isVisible={isVisible}
            elementRef={selectRef}
            label={label}
            labelAfter={labelAfter}
            size={size}
        >
            <AsyncSelect
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        background: "transparent",
                        height: "3rem",
                        fontSize: "1rem",
                        marginBottom: "1.5rem",
                        borderColor: "var(--field-control-border-color)",
                        borderBottomColor: "var(--field-control-border-bottom-color)",
                        transition: "none !important"
                    }),
                    menuList: (baseStyles) => ({
                        ...baseStyles,
                        maxHeight: "7rem",
                        overflowY: "scroll"
                    }),
                    dropdownIndicator: (baseStyles) => ({
                        display: "none"
                    }),
                    indicatorSeparator: (baseStyles) => ({
                        display: "none"
                    }),
                    loadingIndicator: (baseStyles) => ({
                        ...baseStyles,
                        marginBottom: "2rem"
                    }),
                    singleValue: (baseStyles) => ({
                        display: "none"
                    }),
                    placeholder: (baseStyles) => ({
                        display: "none"
                    }),
                    clearIndicator: (baseStyles) => ({
                        ...baseStyles,
                        marginBottom: "2rem",
                        fontSize: "1.5rem"
                    })
                }}
                defaultOption={defaultOption}
                classNamePrefix="async-select"
                loadOptions={loadOptions}
                menuPortalTarget={document.body}
                inputRef={selectRef}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                value={selectValue}
                placeholder="Search for an address..."
                cacheOptions
            />
        </FieldControl>
    );
}