/**
 * @format
 */

import React, { useState } from 'react';
import { LoadingIndicatorModal } from '../loadingIndicator/loadingIndicatorModal';
import { AvailabilityResultsHeader } from './availabilityResultsHeader';
import { AvailabilityResultsFilters } from './availabilityResultsFilters';
import { AvailabilityResultsWeekNav } from './availabilityResultsWeekNav';
import { AvailabilitySearchResults } from './availabilitySearchResults';
import { AvailabilityResultsPaginationNavigation } from './availabilityResultsPaginationNavigation';
import ActiveErrors from '../misc/activeErrors';
import { NoResultsDisplay } from '../misc/miscComponents';
import * as Icon from '../icons';

export default function MultiProviderAvailability(props) {
	const {
		activeCareOrder,
		availabilityResults,
		calendarSearchResults,
		clientBrandingConfig,
		effectiveInputCriteria,
		handleGoToPage,
		hasDoneFirstCalendarSearch,
		isLoading,
		isMobile,
	} = props;

	const hasCalendars = calendarSearchResults.calendars && calendarSearchResults.calendars.length > 0;
	const pageIsBusy = isLoading.anySearch || isLoading.isReserving;
	const showSelectedProviders = availabilityResults.hasSelectedResults;
	const showHeader =
		hasCalendars &&
		clientBrandingConfig.availabilitySearchHeader &&
		clientBrandingConfig.availabilitySearchHeader.isVisible;

	const [providersSelectedDate, setProvidersSelectedDate] = useState({});

	const handleProviderSelectDate = (calendarId, dateIndex) => {
		const newProvidersSelectedDate = { ...providersSelectedDate };
		newProvidersSelectedDate[calendarId] = dateIndex;
		setProvidersSelectedDate(newProvidersSelectedDate);
	};

	return (
		<div className="view-content multi-provider-availability">
			<ActiveErrors />
			{props.showReturnToQuestionsButton &&
				<a className="back-to-questions" href='javascript:void(0)' aria-label="Back to Questions" onClick={props.handleGoBackToGuidedResponse}>
					<span><Icon.IconChevronLeft className="left-arrow-icon" size={"sm"} />Back to Questions</span>
				</a>
				//<div className="return-to-questions-icon">
				//	<Icon.IconArrowLeft className="left-arrow-icon" onClick={props.handleGoBackToGuidedResponse} />
				//	<span>&nbsp;Return to Questions</span>
				//</div>
			}
			<main className={'main-content'}>
				{showHeader && (
					<>
						<AvailabilityResultsHeader
							activeCareOrderDetails={activeCareOrder.activeCareOrderDetails}
							headerText={clientBrandingConfig.availabilitySearchHeader.fieldLabel}
						/>
					</>
				)}
				{pageIsBusy && <LoadingIndicatorModal />}
				{hasDoneFirstCalendarSearch && (
					<>
						<AvailabilityResultsFilters {...props} />
						{showSelectedProviders && (
							<>
								<div className="availability-results-provider-header">
									<h5>Selected Provider</h5>
								</div>
								{hasCalendars && !isMobile && (
									<>
										<AvailabilityResultsWeekNav
											handleProviderSelectDate={handleProviderSelectDate}
											providersSelectedDate={providersSelectedDate}
											{...props}
										/>
									</>
								)}
								{!isLoading.anySearch && !hasCalendars && <NoResultsDisplay />}
								{hasCalendars && (
									<>
										<AvailabilitySearchResults
											handleProviderSelectDate={handleProviderSelectDate}
											providersSelectedDate={providersSelectedDate}
											selectedResults={true}
											{...props}
										/>
										<div className="availability-results-provider-header">
											<h5>Additional Recommended Providers</h5>
										</div>
									</>
								)}
							</>
						)}
						{hasCalendars && !isMobile && (
							<AvailabilityResultsWeekNav
								handleProviderSelectDate={handleProviderSelectDate}
								providersSelectedDate={providersSelectedDate}
								{...props}
							/>
						)}
						{!isLoading.anySearch && !hasCalendars && <NoResultsDisplay />}

						{hasCalendars && (
							<>
								<AvailabilitySearchResults
									handleProviderSelectDate={handleProviderSelectDate}
									providersSelectedDate={providersSelectedDate}
									selectedResults={false}
									{...props}
								/>
								<AvailabilityResultsPaginationNavigation
									currentPage={effectiveInputCriteria.pageNumber}
									handleGoToPage={handleGoToPage}
									numberOfPages={availabilityResults.numCalendarPages}
									{...props}
								/>
							</>
						)}
					</>
				)}
			</main>
		</div>
	);
}
