import { useDispatch, useSelector } from "react-redux";
import { selectCalendarById, selectSuppressCalendarTelephoneById, setSelectedCalendarByIdThunk } from "../availabilitySlice";
import { ProviderBiographyModal } from "../../../components/provider/providerBiographyModal";
import { formatCityStateZip, formatPhoneNoLabel } from "../../../lib/misc";
import { useState } from "react";

const Calendar = ({ calendarId }) => {
    const dispatch = useDispatch();
    const calendar = useSelector(state => selectCalendarById(state, calendarId));
    const suppressPhoneNumber = useSelector(state => selectSuppressCalendarTelephoneById(state, calendarId));
    const showDistanceMiles = useSelector(state => state.config.scheduling.showAvailabilitySearchDistance)
    const phoneLabel = useSelector(state => state.config.provider.phone.fieldLabel)

    const [showBioModal, setShowBioModal] = useState(false);

    return (
        <div className="provider-details-card">
            <div className="provider-image-container">
                <img
                    src={calendar.imageFileName || '/resources/generic-provider-photo.jpg'}
                    alt={calendar.serviceName + ' Provider'}
                />
            </div>
            <div className="provider-name-and-details">
                <div className="provider-name-designation-specialty">
                    <div className="provider-name-designation">
                        <span
                            onClick={(e) => {
                                e.preventDefault();
                                dispatch(setSelectedCalendarByIdThunk(calendarId))
                            }}
                        >
                            {calendar.displayName}
                        </span>
                    </div>
                    <div className="provider-specialty">{calendar.specialtyName}</div>
                    {calendar.bio && (
                        <>
                            <div className="biography">
                                <span onClick={() => setShowBioModal(x => !x)}>Biography</span>
                            </div>
                            {showBioModal &&
                                <ProviderBiographyModal
                                    serviceBio={calendar.bio}
                                    toggleModal={() => setShowBioModal(x => !x)}
                                />
                            }
                        </>
                    )}
                </div>
                <div className="provider-address">
                    <address>
                        <span className="site-name">{calendar.locationName}</span>
                        <br />
                        {!calendar.suppressAddress && (
                            <>
                                {calendar.address.addressLine1}
                                <br />
                                {calendar.address?.providerAddressLine2?.trim() && (
                                    <>
                                        {calendar.address.providerAddressLine2.trim()}
                                        <br />
                                    </>
                                )}
                                {formatCityStateZip(calendar.address.cityName, calendar.address.stateCode, calendar.address.zipCode)}
                                <br />
                                {showDistanceMiles && (
                                    <>
                                        <span>
                                            {calendar.distanceInMiles ? +(Math.round(calendar.distanceInMiles + 'e+1') + 'e-1') + ' miles away' : 'distance unknown'}
                                        </span>
                                        <br />
                                    </>
                                )}
                            </>
                        )}
                        {!suppressPhoneNumber && (
                            <>
                                {phoneLabel && <span className="phone-label">{phoneLabel + ' '}</span>}
                                <a href={`tel:${calendar.telephone}`}>
                                    <span className="phone-number">{formatPhoneNoLabel(calendar.telephone)}</span>
                                </a>
                                <br />
                            </>
                        )}
                    </address>
                </div>


            </div>
        </div>
    )
}

export default Calendar;