export const AVAILABILITY_SEARCH_FIELD_GROUP = 'CD2.AvailabilitySearchCriteria';
export const AVAILABILITY_SEARCH_APPOINTMENT_START_DATE = `${AVAILABILITY_SEARCH_FIELD_GROUP}.AppointmentStartDate`;
export const AVAILABILITY_SEARCH_INSURANCE_STATE = `${AVAILABILITY_SEARCH_FIELD_GROUP}.InsuranceState`;
export const AVAILABILITY_SEARCH_INSURANCE_CARRIER = `${AVAILABILITY_SEARCH_FIELD_GROUP}.InsuranceCarrier`;
export const AVAILABILITY_SEARCH_SERVICE_CATEGORY = `${AVAILABILITY_SEARCH_FIELD_GROUP}.ServiceCategory`;
export const AVAILABILITY_SEARCH_SPECIALTY = `${AVAILABILITY_SEARCH_FIELD_GROUP}.Specialty`;
export const AVAILABILITY_SEARCH_APPOINTMENT_TYPE = `${AVAILABILITY_SEARCH_FIELD_GROUP}.AppointmentType`;
export const AVAILABILITY_SEARCH_ZIP_CODE = `${AVAILABILITY_SEARCH_FIELD_GROUP}.ZipCode`;
export const AVAILABILITY_SEARCH_SEARCH_RADIUS = `${AVAILABILITY_SEARCH_FIELD_GROUP}.SearchRadius`;
export const AVAILABILITY_SEARCH_SERVICE_NAME = `${AVAILABILITY_SEARCH_FIELD_GROUP}.ServiceName`;
export const AVAILABILITY_SEARCH_PROVIDER_GENDER = `${AVAILABILITY_SEARCH_FIELD_GROUP}.ProviderGender`;
export const AVAILABILITY_SEARCH_LANGUAGE = `${AVAILABILITY_SEARCH_FIELD_GROUP}.LanguagePreference`;
export const AVAILABILITY_SEARCH_DAYS_OF_WEEK = `${AVAILABILITY_SEARCH_FIELD_GROUP}.DaysOfWeek`;
export const AVAILABILITY_SEARCH_TIME_OF_DAY = `${AVAILABILITY_SEARCH_FIELD_GROUP}.TimeOfDay`;
export const AVAILABILITY_SEARCH_PAYOR_TYPE = `${AVAILABILITY_SEARCH_FIELD_GROUP}.PayorType`;
export const AVAILABILITY_SEARCH_SORT_ORDER = `${AVAILABILITY_SEARCH_FIELD_GROUP}.SortOrder`;
export const AVAILABILITY_SEARCH_SITE_NAME = `${AVAILABILITY_SEARCH_FIELD_GROUP}.SiteName`;
export const AVAILABILITY_SEARCH_SITE_FILTER = `${AVAILABILITY_SEARCH_FIELD_GROUP}.SiteFilter`;
export const AVAILABILITY_SEARCH_PROVIDER_FILTER = `${AVAILABILITY_SEARCH_FIELD_GROUP}.ProviderFilter`;
export const AVAILABILITY_SEARCH_APPOINTMENT_TYPE_FILTER = `${AVAILABILITY_SEARCH_FIELD_GROUP}.AppointmentTypeFilter`;
export const AVAILABILITY_SEARCH_APPOINTMENT_MODALITY_FILTER = `${AVAILABILITY_SEARCH_FIELD_GROUP}.AppointmentModalityFilter`;

export const ASC = {
	appointmentStartDate: AVAILABILITY_SEARCH_APPOINTMENT_START_DATE,
	insuranceState: AVAILABILITY_SEARCH_INSURANCE_STATE,
	insuranceCarrier: AVAILABILITY_SEARCH_INSURANCE_CARRIER,
	serviceCategory: AVAILABILITY_SEARCH_SERVICE_CATEGORY,
	specialty: AVAILABILITY_SEARCH_SPECIALTY,
	appointmentType: AVAILABILITY_SEARCH_APPOINTMENT_TYPE,
	zipCode: AVAILABILITY_SEARCH_ZIP_CODE,
	searchRadius: AVAILABILITY_SEARCH_SEARCH_RADIUS,
	serviceName: AVAILABILITY_SEARCH_SERVICE_NAME,
	providerGender: AVAILABILITY_SEARCH_PROVIDER_GENDER,
	languagePreference: AVAILABILITY_SEARCH_LANGUAGE,
	daysOfWeek: AVAILABILITY_SEARCH_DAYS_OF_WEEK,
	timeOfDay: AVAILABILITY_SEARCH_TIME_OF_DAY,
	payorType: AVAILABILITY_SEARCH_PAYOR_TYPE,
	sortOrder: AVAILABILITY_SEARCH_SORT_ORDER,
	appointmentTypeFilter: AVAILABILITY_SEARCH_APPOINTMENT_TYPE_FILTER,
	appointmentModalityFilter: AVAILABILITY_SEARCH_APPOINTMENT_MODALITY_FILTER,
	siteName: AVAILABILITY_SEARCH_SITE_NAME,
	siteFilter: AVAILABILITY_SEARCH_SITE_FILTER,
};
