import React, { Component } from 'react';

export default class AppliedPathwaysMultipleChoiceSingleAnswer extends Component {
	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.asset === prevState.asset) {
			return null;
		}
		var selectedAnswer = AppliedPathwaysMultipleChoiceSingleAnswer.getSelectedAnswer(nextProps.asset);
		if (selectedAnswer === prevState.value) {
			return null;
		}
		return {
			asset: nextProps.asset,
			value: selectedAnswer,
		};
	}

	static getSelectedAnswer(asset) {
		var selectedAnswer = [];
		asset.ap_answers.map((item) => {
			if (item.value) {
				selectedAnswer.push(item.id);
			}
			return null;
		});
		return selectedAnswer;
	}

	constructor(props) {
		super(props);
		this.state = {
			asset: {},
			value: [],
		};
	}

	componentDidMount() {
		var selectedAnswer = AppliedPathwaysMultipleChoiceSingleAnswer.getSelectedAnswer(this.props.asset);
		this.setState({ asset: this.props.asset, value: selectedAnswer });
	}

	handleChange = (event) => {
		const { name, value, checked } = event.target;
		const { onChange } = this.props;

		let checkedList = this.state.value;
		let valueIndex = checkedList.indexOf(value);
		let existsInList = valueIndex !== -1;

		if (checked && !existsInList) {
			checkedList.push(value);
			this.setState({ value: checkedList });
		}
		if (!checked && existsInList) {
			checkedList = checkedList.filter((key) => key !== value);
			this.setState({ value: checkedList });
		}
		return onChange({ target: { name: name, value: checkedList } });
	};

	render() {
		var questionControlName = this.props.asset.id;
		var isRequired = this.props.asset.is_required;
		var className = 'ap-question form-check-input';
		if (isRequired) {
			className += ' required';
		}

		let visibleAnswers = this.props.asset.ap_answers.filter(function (item, index, arr) {
			return item.is_hidden === false;
		});
		let answers = visibleAnswers.map((item, key) => {
			return (
				<div key={key} className="form-check">
					<input
						id={item.id}
						name={questionControlName}
						type="checkbox"
						className={className}
						checked={this.state.value.indexOf(item.id) !== -1}
						onChange={this.handleChange}
						value={item.id}
					/>
					<label
						className="form-check-label"
						htmlFor={item.id}
						dangerouslySetInnerHTML={{
							__html: item.ap_literacy.base,
						}}
					/>
				</div>
			);
		});

		return <React.Fragment>{answers}</React.Fragment>;
	}
}
