import React, { useMemo } from 'react';
import * as moment from 'moment';
import classNames from 'classnames';
import * as Button from '../buttons';
import * as Icon from '../icons';

export function ProviderAvailabilitySlots(props) {
	const {
		availabilityResult,
		enableAppointmentTypeTooltips,
		handleGoToDate,
		hasAvailabilityResultsCurrentWeek,
		isExpanded,
		isMobile,
		isLoading,
		handleSelectSlot,
		providersSelectedDate,
		searchDaysUsed,
		setIsExpanded,
		slotColumnsPerDay,
		slotColumnsPerDayMobile,
		slotsPerDay,
		slotsPerDayMobile,
		showSingleCalendar,
	} = props;

	const calendarId = availabilityResult.calendarDetails.calendarId;
	const selectedDateIndex = !providersSelectedDate ? null : providersSelectedDate[calendarId] || 0;
	const isMultiDay = availabilityResult.calendarTimeSlotDays.length > 1;
	const showMultiDay = isMultiDay && !isMobile;
	const maxNumberOfSlotsToShowInitial = isMobile ? slotsPerDayMobile : slotsPerDay;

	let hasMoreAvailabilityThanInitialLimit = null;

	if (isMultiDay && !isMobile) {
		hasMoreAvailabilityThanInitialLimit = availabilityResult.calendarTimeSlotDays.find((val, i) => {
			return val.appointments && val.appointments.length > maxNumberOfSlotsToShowInitial;
		});
	} else if (isMultiDay && isMobile) {
		hasMoreAvailabilityThanInitialLimit = !!availabilityResult.calendarTimeSlotDays.find((val, i) => {
			return i === selectedDateIndex && val.appointments.length > maxNumberOfSlotsToShowInitial;
		});
	} else {
		hasMoreAvailabilityThanInitialLimit =
			availabilityResult.calendarTimeSlotDays[0]?.appointments &&
			availabilityResult.calendarTimeSlotDays[0].appointments.length > maxNumberOfSlotsToShowInitial;
	}

	const slotsToShowByDay =
		!isMultiDay || !isMobile
			? availabilityResult.calendarTimeSlotDays
			: availabilityResult.calendarTimeSlotDays.filter((val, i) => {
					return i === selectedDateIndex;
			});

	const hasDisplayedAvailability = slotsToShowByDay.find(x => x.appointments.length > 0);

	const availabilityRowWeekClassName = classNames('availability-row-week', {
		'no-availability': !availabilityResult.hasDisplayableAvailability,
	});

	return (
		<SlotContainer availabilityRowWeekClassName={availabilityRowWeekClassName} isMultiDay={isMultiDay}>
			{hasDisplayedAvailability && (
				<div className={showMultiDay ? 'availability-row' : 'availability-container'}>
					{slotsToShowByDay.map((day, i) => {
						return (
							<AvailabilityDay
								day={day}
								slots={day.appointments}
								enableAppointmentTypeTooltips={enableAppointmentTypeTooltips}
								key={i}
								handleSelectSlot={handleSelectSlot}
								provider={availabilityResult.calendarDetails}
								calendarId={calendarId}
								isExpanded={isExpanded}
								isSelected={isMobile && isMultiDay}
								isMobile={isMobile}
								maxNumberOfSlotsToShowInitial={maxNumberOfSlotsToShowInitial}
								numAvailabilityColumns={showMultiDay ? 1 : isMobile ? slotColumnsPerDayMobile : slotColumnsPerDay}
								showSingleCalendar={showSingleCalendar}
								availabilitySearchSupportData={props.availabilitySearchSupportData}
							/>
						);
					})}
				</div>
			)}
			{hasMoreAvailabilityThanInitialLimit && (
				<ShowMoreLessAvailability
					provider={availabilityResult.providerDetails}
					isExpanded={isExpanded}
					setIsExpanded={setIsExpanded}
				/>
			)}
			{!isLoading.anySearch && !hasDisplayedAvailability && (
				<NoAvailabilityPane
					availabilitySearchWindow={searchDaysUsed}
					firstAvailabilityDate={availabilityResult.nextAvailabilityDate}
					handleGoToDate={handleGoToDate}
					hasAvailabilityResultsCurrentWeek={hasAvailabilityResultsCurrentWeek}
				/>
			)}
		</SlotContainer>
	);
}

export function SlotContainer(props) {
	const { availabilityRowWeekClassName, children, isMultiDay } = props;

	return (
		<>
			{isMultiDay && (
				<SlotWeekContainer availabilityRowWeekClassName={availabilityRowWeekClassName}>{children}</SlotWeekContainer>
			)}
			{!isMultiDay && <>{children}</>}
		</>
	);
}

//TODO: refactor
export function AvailabilityDay(props) {
	const {
		day,
		slots,
		enableAppointmentTypeTooltips,
		isMobile,
		maxNumberOfSlotsToShowInitial,
		numAvailabilityColumns,
		handleSelectSlot,
		calendarId,
		isExpanded,
		showSingleCalendar,
		availabilitySearchSupportData,
	} = props;

	const hasMoreAvailabilityThanInitialLimit = slots.length > maxNumberOfSlotsToShowInitial;
	const numSlotsToShow =
		isExpanded || !hasMoreAvailabilityThanInitialLimit ? slots.length : maxNumberOfSlotsToShowInitial;
	const slotsToShow = slots.slice(0, numSlotsToShow);
	const dayHasSlots = slotsToShow.length > 0;
	const slotsToShowByRow = [];
	const slotsPerColumn = Math.ceil(numSlotsToShow / numAvailabilityColumns);
	const numRowsToShow = slotsPerColumn;

	let dayDate = moment.utc(day.date);
	let dateString = moment(dayDate).format('dddd') + ", " + moment(dayDate).format('MMMM Do YYYY');

	for (let i = 0; i < numRowsToShow; i++) {
		const rowIndex = i * numAvailabilityColumns;
		const nextRowIndex = rowIndex + numAvailabilityColumns;
		const rowSlots = slotsToShow.slice(rowIndex, nextRowIndex);
		slotsToShowByRow.push(rowSlots);
	}

	const availabilityDayClassName = classNames(
		'availability-day',
		{ mobile: isMobile },
		{ 'no-availability': !dayHasSlots },
	);

	return (
		<div className={availabilityDayClassName}>
			{showSingleCalendar && <h5>{dateString}</h5>}
			{dayHasSlots &&
				slotsToShowByRow.map((row, i) => {
					return (
						<div className="slot-row" key={i}>
							{row.map((appt, i) => {
								let timeSlotInfo = mapAppointmentToTimeSlotInfo(appt, calendarId);
								return (
									<AvailabilitySlot
										appointment={appt}
										enableAppointmentTypeTooltips={enableAppointmentTypeTooltips}
										key={i}
										handleSelectSlot={handleSelectSlot}
										timeSlotInfo={timeSlotInfo}
										appointmentTypeList={availabilitySearchSupportData.appointmentTypeList}
									/>
								);
							})}
						</div>
					);
				})
			}
		</div>
	);
}

export function AvailabilitySlot(props) {
	const { appointment, enableAppointmentTypeTooltips, handleSelectSlot, timeSlotInfo, appointmentTypeList } = props;

	const modality = useMemo(() => {
		return appointmentTypeList.find(apptType => apptType.idPgmAppointmentType === appointment.appointmentTypeId)?.pgmAppointmentModalityName ?? ''
	}, [appointment.appointmentTypeId, appointmentTypeList])

	return (
		<>
			{appointment.disabled ? (
				<Button.ButtonTimeSlot
					disabled={true}
					id={null}
					isLoading={false}
					size={'md'}
					title={enableAppointmentTypeTooltips ? appointment.appointmentTypeName : null}
					type={'button'}
				>
					{(modality === "Telephonic") &&
						<span><Icon.IconTelephonic className="icon-telephonic" size={"xs"} /></span>
					}
					{appointment.formattedTime}
				</Button.ButtonTimeSlot>
			) : (
				<Button.ButtonTimeSlot
					disabled={false}
					id={null}
					isLoading={false}
					onClick={() => handleSelectSlot(timeSlotInfo)}
					size={'md'}
					title={enableAppointmentTypeTooltips ? appointment.appointmentTypeName : null}
				>
					{(modality === "Telephonic") &&
						<span><Icon.IconTelephonic className="icon-telephonic" size={"xs"} /></span>
					}
					{appointment.formattedTime}
				</Button.ButtonTimeSlot>
			)}
		</>
	);
}

export function NoAvailabilityPane(props) {
	const { availabilitySearchWindow, firstAvailabilityDate, handleGoToDate, hasAvailabilityResultsCurrentWeek } = props;
	return (
		<>
			{hasAvailabilityResultsCurrentWeek && (
				<div className="no-availability-pane">No availability, please select a different date.</div>
			)}
			{!hasAvailabilityResultsCurrentWeek && handleGoToDate && firstAvailabilityDate && (
				<div className="no-availability-pane">
					This provider has no appointments available this week. Their first available appointment is{' '}
					<span className="first-available-go-to-date" onClick={() => handleGoToDate(moment(firstAvailabilityDate))}>
						{moment(firstAvailabilityDate).format('MMM D YYYY')}
					</span>
				</div>
			)}
			{!hasAvailabilityResultsCurrentWeek && availabilitySearchWindow && !firstAvailabilityDate && (
				<div className="no-availability-pane">
					This provider matched your search but has no appointments available in the next {availabilitySearchWindow}{' '}
					days.
				</div>
			)}
			{!hasAvailabilityResultsCurrentWeek && !availabilitySearchWindow && (
				<div className="no-availability-pane">
					This provider matched your search but has no appointments available this month.
				</div>
			)}
		</>
	);
}

export function ShowMoreLessAvailability(props) {
	const { isExpanded, setIsExpanded } = props;

	return (
		<div className="availability-row-more-less-container">
			{!isExpanded && (
				<span className="show-more-availability-btn" onClick={() => setIsExpanded((x) => !x)}>
					SHOW MORE
				</span>
			)}
			{isExpanded && (
				<span className="show-less-availability-btn" onClick={() => setIsExpanded((x) => !x)}>
					SHOW LESS
				</span>
			)}
		</div>
	);
}

export const mapAppointmentToTimeSlotInfo = (appt, calendarId) => {
	return {
		timeSlotId: appt.timeSlotAppointmentTypeId,
		serviceSiteId: calendarId,
		appointmentTypeId: appt.appointmentTypeId,
		date: appt.date,
		startTime: appt.startTime,
		endTime: appt.endTime,
	};
};

export function SlotWeekContainer(props) {
	const { availabilityRowWeekClassName, children } = props;

	return (
		<div className="availability-row-container">
			<div className="availability-row-spacer" />
			<div className={availabilityRowWeekClassName}>{children}</div>
			<div className="availability-row-spacer" />
		</div>
	);
}
