//@ts-check

import React, { isValidElement } from 'react';
import GridItem, { getJustificationStyle, getAlignmentStyle } from './gridItem';

/**
 * Layout immediate children within a grid
 * @param {import('react').PropsWithChildren<{
 *  className?: string,
 *  numberOfColumns?: number,
 *  justifyContent?: import('./gridItem').Justification,
 *  justifyItems?: import('./gridItem').Justification,
 *  alignItems?: import('./gridItem').Alignment,
 *  style?: Object,
 *  onClick?: import('react').MouseEventHandler<HTMLDivElement>,
 *  gap?: number
 * }>} props
 */
export default function Grid({
	className = '',
	numberOfColumns = 12,
	justifyContent,
	justifyItems,
	alignItems,
	style,
	onClick,
	gap = 1,
	children,
}) {
	let offsetAccumulator = 0;

	gap = gap * 8;
	return (
		<div
			className={`layout-grid ${className}`}
			onClick={onClick}
			style={{
				...style,
				width: '100%',
				display: 'flex',
				flexWrap: 'wrap',
				...getJustificationStyle('justifyContent', justifyContent),
				...getAlignmentStyle('alignItems', alignItems),
			}}
		>
			{React.Children.toArray(children)
				.filter((child) => !!child)
				.map((child, index) => {
					if (!child) return false;
					const span = isValidElement(child) && child.type === GridItem && child.props.span ? child.props.span : 1;
					const offset = offsetAccumulator;
					offsetAccumulator +=
						typeof span === 'number'
							? span
							: span === 'fill'
							? numberOfColumns - (offsetAccumulator % numberOfColumns)
							: 0;
					const paddingLeft = offset % numberOfColumns !== 0 ? gap / 2 : 0;
					const paddingRight = offsetAccumulator % numberOfColumns !== 0 ? gap / 2 : 0;
					return isValidElement(child) && child.type === GridItem ? (
						React.cloneElement(child, {
							key: index,
							basis: numberOfColumns,
							span,
							offset,
							justifySelf: child.props.justifySelf || justifyItems,
							paddingLeft,
							paddingRight,
						})
					) : (
						<GridItem
							key={index}
							basis={numberOfColumns}
							span={span}
							offset={offset}
							justifySelf={justifyItems}
							paddingLeft={paddingLeft}
							paddingRight={paddingRight}
						>
							{child}
						</GridItem>
					);
				})}
		</div>
	);
}
