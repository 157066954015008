import { useDispatch, useSelector } from "react-redux"
import * as Button from "../../../components/buttons"
import { selectShowReturnToQuestionsButton, setSelectedCalendarByIdThunk } from "../availabilitySlice";
import { decisionSupport as routeToDecisionSupport } from '../../../routes';

const SelectedCalendarExitButtons = () => {
    const dispatch = useDispatch();

    const routePrefix = useSelector(state => state.config.instance.routePrefix);
    const showReturnToQuestionsButton = useSelector(selectShowReturnToQuestionsButton);

    //TODO what should drive this
    const shouldShowBackButton = true;

    const handleGoBackToGuidedResponse = () => {
        dispatch(setSelectedCalendarByIdThunk(null));
        dispatch(routeToDecisionSupport(routePrefix));
    };

    return (
        <div className="back-to-search-results-container">
            {shouldShowBackButton && (
                <Button.ButtonSecondary
                    className="back-to-search-results-btn"
                    onClick={() => dispatch(setSelectedCalendarByIdThunk(null))}
                >
                    Return to Search Results
                </Button.ButtonSecondary>
            )}
            {showReturnToQuestionsButton && (
                <Button.ButtonSecondary
                    className="return-to-questions-btn"
                    onClick={handleGoBackToGuidedResponse}
                >
                    Return to Questions
                </Button.ButtonSecondary>
            )}
        </div>
    )
}

export default SelectedCalendarExitButtons;