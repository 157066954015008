import { SUCCESS_SUFFIX, ERROR_SUFFIX } from 'redux-axios-middleware';
import * as types from '../actions/actionTypes';

function isAxiosRequest(action) {
	return action.payload && (action.payload.request || (action.meta && action.meta.previousAction));
}

function isBeginCall(action) {
	return isAxiosRequest(action) && !action.type.endsWith(SUCCESS_SUFFIX) && !action.type.endsWith(ERROR_SUFFIX);
}

function isEndCall(action) {
	return action.type.endsWith(SUCCESS_SUFFIX) || action.type.endsWith(ERROR_SUFFIX);
}

export default function ajaxStatusReducer(state = 0, action) {
	if (isBeginCall(action)) {
		state += 1;
	}

	if (isEndCall(action)) {
		state -= 1;
	}

	if (action.type === types.AUTHENTICATE_USER_SUCCESS) {
		state = 0;
	}

	return state;
}
