import React from 'react';
import * as FieldControl from '../input';
import { CollapsablePanelWithSectionHeader, DisclaimerHtml } from './miscComponents';

export default function SymptomDetailsSection(props) {
	const {
		enableSectionValidationIcons,
		handleInputChange,
		handleInputFocus,
		handleInputBlur,
		handleToggleCollapse,
		hasErrors,
		isCollapsed,
		name,
		sectionConfig,
		shouldShowSectionValidation,
		symptomFieldsInfo,
	} = props;

	const checkHasDisclaimer = (disclaimerText) => ((disclaimerText && disclaimerText.length) > 0 ? true : false);

	return (
		<CollapsablePanelWithSectionHeader
			className="main-section"
			enableSectionValidationIcons={enableSectionValidationIcons}
			hasErrors={hasErrors}
			isCollapsed={isCollapsed}
			isCollapsable={sectionConfig.isCollapsable}
			label={sectionConfig.headerText}
			name={name}
			onToggleCollapse={handleToggleCollapse}
			shouldShowSectionValidation={shouldShowSectionValidation}
			showHeader={sectionConfig.showHeader}
		>
			<div className="section-body">
				{checkHasDisclaimer(sectionConfig.symptomDetailsConfigInstructions) && (
					<div className="section-row">
						<DisclaimerHtml
							className="form-instructions"
							isVisible={!!sectionConfig.symptomDetailsConfigInstructions}
							text={sectionConfig.symptomDetailsConfigInstructions}
						/>
					</div>
				)}
				<div className="section-row">
					<FieldControl.TextAreaInput
						{...symptomFieldsInfo.reasonForVisit}
						onChange={handleInputChange}
						onBlur={handleInputBlur}
						onFocus={handleInputFocus}
					/>
				</div>
			</div>
		</CollapsablePanelWithSectionHeader>
	);
}
