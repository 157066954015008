//3rd party
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//actions
import { getActiveCareOrderDetails } from '../../actions/careOrderActions';
import { setEntryPointUrl, redirectToBookingProcess, initializeBookingProcess } from '../../actions/workflowActions';

//constants
import * as flowTypes from '../../constants/flowTypeEnum';

//lib

//comps
import { LoadingIndicatorModal } from '../../components/loadingIndicator/loadingIndicatorModal';
import { DisclaimerHtml } from '../../components/misc/miscComponents';

const DEFAULT_INVALID_CARE_ORDER_MESSAGE =
	'The link you have used is no longer active. To book an appointment please use an active link.';

export class StartCareOrderVisitBookRedirector extends React.Component {
	constructor(props) {
		super(props);
		this.careOrderVisitIdentifier = this.props.match.params.careOrderVisitId;
		this.state = {
			showLoadingIndicator: true,
			isCareOrderLoaded: false,
			showAlert: false,
		};
	}

	dismissAlert = () => {
		this.setState({ showAlert: false });
	};

	componentDidMount() {
		if (this.careOrderVisitIdentifier) {
			this.props.setEntryPointUrl(flowTypes.CARE_ORDER_FLOW, this.careOrderVisitIdentifier);
			this.loadActiveCareOrderDetails();
		} else {
			this.setState({
				showAlert: true,
				showLoadingIndicator: false,
			});
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.session.bookingInitialized && !nextProps.activeCareOrderDetails.isInvalid) {
			let useDecisionSupport =
				nextProps.config.decisionSupport.useDecisionSupport &&
				nextProps.config.scheduling.useDecisionSupportWithCareOrders;
			let { auth, patientAuthContext, bookingContext } = nextProps;
			nextProps.redirectToBookingProcess(
				auth,
				patientAuthContext,
				useDecisionSupport,
				bookingContext,
				this.props.config.instance.routePrefix,
			);
		} else if (
			nextProps.activeCareOrderDetails.careOrderVisitIdentifier &&
			(!this.props.activeCareOrderDetails.careOrderVisitIdentifier ||
				this.props.activeCareOrderDetails.careOrderVisitIdentifier !==
					nextProps.activeCareOrderDetails.careOrderVisitIdentifier)
		) {
			let useDecisionSupport =
				nextProps.config.decisionSupport.useDecisionSupport &&
				nextProps.config.scheduling.useDecisionSupportWithCareOrders;
			let { availabilitySearchConfig, correlationKey, activeCareOrder, decisionSupportOutput, activePatient } =
				nextProps;
			nextProps.initializeBookingProcess(
				useDecisionSupport,
				correlationKey,
				activeCareOrder,
				decisionSupportOutput,
				availabilitySearchConfig,
				activePatient,
			);
		}
	}

	loadActiveCareOrderDetails = () => {
		this.props
			.getActiveCareOrderDetails(this.careOrderVisitIdentifier, this.props.auth.productInstanceId)
			.then((response) => {
				if (response.error) {
					this.setState({
						showAlert: true,
						showLoadingIndicator: false,
					});
				} else {
					this.setState({ showLoadingIndicator: false, isCareOrderLoaded: true });
				}
			})
			.catch((err) => {
				this.setState({ showLoadingIndicator: false });
			});
	};

	render() {
		let { showLoadingIndicator, isCareOrderLoaded } = this.state;
		let invalidCareOrderMessage = this.props.activeCareOrderDetails.errorMessage || DEFAULT_INVALID_CARE_ORDER_MESSAGE;
		return (
			<>
				{isCareOrderLoaded && this.props.activeCareOrderDetails.isInvalid && (
					<div className="view-content">
						<main className={'main-content invalid-care-order'}>
							<DisclaimerHtml isVisible={true} text={invalidCareOrderMessage} />
						</main>
					</div>
				)}
				{showLoadingIndicator && (
					<div>
						<LoadingIndicatorModal />
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		activeCareOrder: state.careOrder,
		activeCareOrderDetails: state.careOrder.activeCareOrderDetails,
		activePatient: state.patient,
		availabilitySearchConfig: state.config.availabilitySearch,
		auth: state.auth,
		bookingContext: state.session.bookingContext,
		careOrderClosedMessage: state.auth.careOrderClosedMessage,
		config: state.config,
		correlationKey: state.session.correlationKey,
		decisionSupportOutput: state.decisionSupport,
		patientAuthContext: state.patient.patientAuthContext,
		session: state.session,
		token: state.auth.token,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setEntryPointUrl,
			redirectToBookingProcess,
			initializeBookingProcess,
			getActiveCareOrderDetails,
		},
		dispatch,
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(StartCareOrderVisitBookRedirector);
