import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'reactstrap';
import AppliedPathwaysErrorList from './appliedPathwaysErrorList';
import AppliedPathwaysQuestionDate from './appliedPathwaysQuestion_Date';
import AppliedPathwaysQuestionEmail from './appliedPathwaysQuestion_Email';
import AppliedPathwaysQuestionPhoneNumber from './appliedPathwaysQuestion_PhoneNumber';
import AppliedPathwaysQuestionText from './appliedPathwaysQuestion_Text';
import AppliedPathwaysQuestionYesNo from './appliedPathwaysQuestion_YesNo';
import AppliedPathwaysMultipleChoiceMultipleAnswer from './appliedPathwaysMultipleChoiceMultipleAnswer';
import AppliedPathwaysMultipleChoiceSingleAnswer from './appliedPathwaysMultipleChoiceSingleAnswer';
import AppliedPathwaysMultipleChoiceSingleAnswerTypeAhead from './appliedPathwaysMultipleChoiceSingleAnswerTypeAhead';

const assetSubTypes = {
	yes_no: 'yes_no',
	text: 'default',
	multiple_choice_single_answer: 'multiple_choice_single_answer',
	multiple_choice_multiple_answers: 'multiple_choice_multiple_answers',
};

const categoryTypes = {
	email: 'Email',
	phone_number: 'PhoneNumber',
	ssn: 'SSN',
	type_ahead: 'TypeAhead',
};

export default class AppliedPathwaysQuestion extends React.Component {
	constructor(props) {
		super(props);
		this.asset = {};
		this.ref = this.props.ref || React.createRef();
	}

	validate = () => {
		if (this.ref.current.validate) {
			return this.ref.current.validate();
		}
		return true;
	};

	renderSwitch(asset) {
		let categories = [];
		switch (asset.sub_type) {
			case assetSubTypes.yes_no:
				return <AppliedPathwaysQuestionYesNo {...this.props} asset={asset} ref={this.ref} />;
			case assetSubTypes.text:
				if (asset.ap_measure_units !== null && asset.ap_measure_units.length === 1) {
					return <AppliedPathwaysQuestionDate {...this.props} asset={asset} ref={this.ref} />;
				} else if (asset.ap_categories && asset.ap_categories.length > 0) {
					categories = asset.ap_categories.filter((item) => {
						return item.name === 'InputType';
					});
					if (categories.length > 0) {
						let category = categories[0].ap_categories[0];
						switch (category.name) {
							case categoryTypes.email:
								return <AppliedPathwaysQuestionEmail key={asset.id} {...this.props} asset={asset} ref={this.ref} />;
							case categoryTypes.phone_number:
								return (
									<AppliedPathwaysQuestionPhoneNumber key={asset.id} {...this.props} asset={asset} ref={this.ref} />
								);
							case categoryTypes.ssn:
							default:
								break;
						}
					}
					return <AppliedPathwaysQuestionText key={asset.id} {...this.props} asset={asset} ref={this.ref} />;
				} else {
					return <AppliedPathwaysQuestionText key={asset.id} {...this.props} asset={asset} ref={this.ref} />;
				}
			case assetSubTypes.multiple_choice_single_answer:
				if (asset.ap_categories && asset.ap_categories.length > 0) {
					categories = asset.ap_categories.filter((item) => {
						return item.name === 'InputType';
					});
					if (asset.ap_categories && asset.ap_categories.length > 0) {
						if (
							categories &&
							categories.length > 0 &&
							categories[0].ap_categories &&
							categories[0].ap_categories.length > 0
						) {
							let category = categories[0].ap_categories[0];
							switch (category.name) {
								case categoryTypes.type_ahead:
									return (
										<AppliedPathwaysMultipleChoiceSingleAnswerTypeAhead
											key={asset.id}
											{...this.props}
											asset={asset}
											ref={this.ref}
										/>
									);
								default:
									break;
							}
						}
					}
				}
				return (
					<AppliedPathwaysMultipleChoiceSingleAnswer key={asset.id} {...this.props} asset={asset} ref={this.ref} />
				);
			case assetSubTypes.multiple_choice_multiple_answers:
				return (
					<AppliedPathwaysMultipleChoiceMultipleAnswer key={asset.id} {...this.props} asset={asset} ref={this.ref} />
				);
			default:
				return 'NOT SUPPORTED ASSET!';
		}
	}

	render() {
		this.asset = this.props.asset;
		let questionLabel = '';
		if (this.asset.is_required) {
			questionLabel += '<span class="mandatory">*</span>';
		}
		questionLabel += this.asset.ap_literacy.base;

		return (
			<div className="form-group">
				{this.asset.ap_result_validation_errors && (
					<Alert color="danger">
						{this.asset.ap_result_validation_errors && this.asset.ap_result_validation_errors.length > 0 && (
							<AppliedPathwaysErrorList list={this.asset.ap_result_validation_errors} />
						)}
					</Alert>
				)}
				<div className="question">
					<label
						htmlFor={this.props.asset.id}
						dangerouslySetInnerHTML={{
							__html: questionLabel,
						}}
					/>

					{this.asset.help_text && (
						<FontAwesomeIcon
							icon="info-circle"
							data-toggle="tooltip"
							title={this.asset.help_text}
							className="tooltip-image"
						/>
					)}
				</div>
				<div className="answer field-control">
					{this.renderSwitch(this.asset)}
					<div className="invalid-feedback" />
				</div>
			</div>
		);
	}
}
