import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import * as routes from '../../routes';

import GuidedResponseComponent from '../../components/guidedResponse/guidedResponseComponent';

export class SurveyView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: '',
			questions: [],
		};
	}

	buildSurveyOrderInputData = (surveyOrder) => {
		var fields = [];
		this.mapSurveyOrderInputFields(fields, surveyOrder);
		return { inputFields: fields };
	};

	mapSurveyOrderInputFields = (fields, surveyOrder) => {
		if (surveyOrder) {
			this.addFieldIfPresent(fields, 'CampaignName', 'String', surveyOrder.campaign);
			this.addFieldIfPresent(fields, 'ClientTrackingNumber', 'String', surveyOrder.clientTrackingNumber);
			this.addFieldIfPresent(fields, 'ExternalReferenceNumber', 'String', surveyOrder.externalReferenceNumber);
			this.addFieldIfPresent(fields, 'ExpirationDate', 'String', surveyOrder.expirationDate);

			this.addFieldIfPresent(fields, 'PatientReferenceId', 'String', surveyOrder.patient.referenceId);
			this.addFieldIfPresent(fields, 'PatientMemberId', 'String', surveyOrder.patient.memberId);
			this.addFieldIfPresent(fields, 'PatientGroupNumber', 'String', surveyOrder.patient.groupNumber);
			this.addFieldIfPresent(fields, 'PatientFirstName', 'String', surveyOrder.patient.firstName);
			this.addFieldIfPresent(fields, 'PatientMiddleName', 'String', surveyOrder.patient.middleName);
			this.addFieldIfPresent(fields, 'PatientLastName', 'String', surveyOrder.patient.lastName);
			this.addFieldIfPresent(fields, 'PatientDateOfBirth', 'Date', this.getDateString(surveyOrder.patient.dateOfBirth));
			this.addFieldIfPresent(fields, 'PatientGenderCode', 'String', surveyOrder.patient.gender);
			this.addFieldIfPresent(fields, 'PatientGuardianName', 'String', surveyOrder.patient.guardianName);
			this.addFieldIfPresent(fields, 'PatientEmail', 'String', surveyOrder.patient.email);
			this.addFieldIfPresent(fields, 'PatientHomePhone', 'String', surveyOrder.patient.homePhone);
			this.addFieldIfPresent(fields, 'PatientMobilePhone', 'String', surveyOrder.patient.mobilePhone);
			this.addFieldIfPresent(fields, 'PatientAlternatePhone', 'String', surveyOrder.patient.alternatePhone);
			this.addFieldIfPresent(fields, 'PatientAddressLine1', 'String', surveyOrder.patient.addressLine1);
			this.addFieldIfPresent(fields, 'PatientAddressLine2', 'String', surveyOrder.patient.addressLine2);
			this.addFieldIfPresent(fields, 'PatientCityName', 'String', surveyOrder.patient.cityName);
			this.addFieldIfPresent(fields, 'PatientStateCode', 'String', surveyOrder.patient.stateCode);
			this.addFieldIfPresent(fields, 'PatientZipCode', 'String', surveyOrder.patient.zipCode);
			this.addFieldIfPresent(fields, 'PatientPoolPrefix', 'String', surveyOrder.patient.patientPoolPrefix);

			if (surveyOrder.customFields !== null && surveyOrder.customFields.length) {
				surveyOrder.customFields.forEach((x) => {
					this.addFieldIfPresent(fields, `${x.name}`, 'String', `${x.value}`);
				});
			}
		}
	};

	getDateString = (date) => {
		if (date !== null && date !== undefined) {
			const d = moment(date).format('YYYY-MM-DD');
			return d;
		}
	};

	addFieldIfPresent = (fields, fieldName, valueType, currentValue) => {
		if (currentValue) {
			let field = this.createField(fieldName, valueType, currentValue.trim());
			fields.push(field);
		}
	};

	createField = (fieldName, valueType, currentValue) => {
		var field = {
			fieldName: fieldName,
			valueType: valueType,
			currentValue: currentValue,
		};
		return field;
	};

	redirectToSurveyComplete = () => {
		this.props.dispatch(routes.surveyComplete(this.props.instance.routePrefix));
	};

	render() {
		let inputData = this.buildSurveyOrderInputData(this.props.surveyOrder);

		return (
			<>
				<GuidedResponseComponent
					contextData={inputData}
					correlationKey={this.props.correlationKey}
					decisionSupportConfig={this.props.decisionSupportConfig}
					isLoading={this.props.isLoading}
					onCompleted={this.redirectToSurveyComplete}
					productInstanceId={this.props.auth.productInstanceId}
				/>
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		auth: state.auth,
		correlationKey: state.session.correlationKey,
		decisionSupportConfig: state.config.decisionSupport,
		decisionSupportSessionId: state.session.decisionSupportSessionId,
		flowSessionResponse: state.guidedResponse.flowSessionResponse,
		instance: state.config.instance,
		isLoading: state.ajaxCallsInProgress > 0,
		surveyOrder: state.surveyOrder,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {},
		dispatch,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyView);
