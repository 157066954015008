import { useDispatch, useSelector } from "react-redux";
import {
    changeViewDateThunk,
    createCalendarDateAvailabilitySelector,
    selectActiveDisplayedDates,
    selectFirstDisplayableDateWithSlotForCalendarById,
    selectIsCalendarScanningById,
    selectIsCalendarSearchingById
} from "../availabilitySlice";
import { useMemo, useState } from "react";
import {
    selectFirstAvailabilityDateByCalendarId,
    selectMaxSlotCountAnyDisplayedDate,
    selectNextAvailabilityDateByCalendarId,
    selectScanDayCount,
    selectSelectedCalendarId,
    selectShouldSuppressSaturday,
    selectShouldSuppressSunday
} from "../availabilitySlice/availabilitySelectors";
import classNames from "classnames";
import * as Button from "../../../components/buttons";
import { reserveAppointment } from "../../../actions/appointmentActions";
import { routeToBooking } from "../../../routes";
import moment from "moment";
import { formatStartTime } from "../../../lib/availabilityHelper";
import LoadingSpinner from "../../../components/loadingIndicator/spinner";
import { nullifySuppressedDates } from "../../../lib/date";
import * as Icon from '../../../components/icons';

const Availability = ({ calendarId }) => {
    const [isExpandedToShowAllSlots, setIsExpandedToShowAllSlots] = useState(false);

    const isSearching = useSelector(state => selectIsCalendarSearchingById(state, calendarId));
    const isScanning = useSelector(state => selectIsCalendarScanningById(state, calendarId));
    const isScanningOrSearching = isSearching || isScanning;

    const initialSlotsPerDay = useSelector((state) => state.config.availabilitySearch.slotsPerDay);
    // const slotColumnsPerDay = useSelector((state) => state.config.availabilitySearch.slotColumnsPerDayMobile);

    const displayedDates = useSelector(selectActiveDisplayedDates);

    const firstDisplayableDateWithSlot = useSelector(state => selectFirstDisplayableDateWithSlotForCalendarById(state, calendarId));
    const maxSlotCountAnyDisplayedDate = useSelector((state) => selectMaxSlotCountAnyDisplayedDate(state, calendarId));

    const suppressSaturday = useSelector(selectShouldSuppressSaturday);
    const suppressSunday = useSelector(selectShouldSuppressSunday);

    const hasMoreAvailabilityThanInitialLimit = maxSlotCountAnyDisplayedDate > initialSlotsPerDay;

    const availabilityRowWeekClassName = classNames('availability-row-week', {
        'no-availability': !maxSlotCountAnyDisplayedDate,
    });

    return (
        <div className="availability-row-container">
            <div className="availability-row-spacer" />
            <div className={availabilityRowWeekClassName}>
                {!!firstDisplayableDateWithSlot && (
                    <div className={'availability-row'}>
                        {displayedDates.map(date => {
                            if (!nullifySuppressedDates(date, suppressSaturday, suppressSunday)) {
                                return <AvailabilityDateDisabled key={date} />
                            }

                            return <AvailabilityDate
                                key={date}
                                calendarId={calendarId}
                                date={date}
                                isExpanded={isExpandedToShowAllSlots}
                                numSlotColumns={1}
                                initialSlotsPerDay={initialSlotsPerDay}
                            />
                        })}
                    </div>
                )}
                {(isSearching || (isScanning && !firstDisplayableDateWithSlot)) && (
                    <LoadingSpinner message="Loading availability..." />
                )}
                {hasMoreAvailabilityThanInitialLimit &&
                    <MoreLessAvailabilityToggler
                        isExpanded={isExpandedToShowAllSlots}
                        setIsExpanded={setIsExpandedToShowAllSlots}
                    />
                }
                {!firstDisplayableDateWithSlot && !isScanningOrSearching && (
                    <NoAvailabilityPane
                        calendarId={calendarId}
                    />
                )}
            </div>
            <div className="availability-row-spacer" />
        </div>
    )
}

const AvailabilityDateDisabled = () => {
    const availabilityDayClassName = classNames(
        'availability-day',
        'no-availability',
        'disabled',
    );

    return (
        <div className={availabilityDayClassName}></div>
    )
}

const AvailabilityDate = ({ calendarId, date, isExpanded, initialSlotsPerDay, numSlotColumns, isMobile }) => {

    const selectCalendarAvailabilityByDate = useMemo(createCalendarDateAvailabilitySelector, []);
    //This creates a unique selector instance to cache by calendarId/date
    //https://redux.js.org/usage/deriving-data-selectors#creating-unique-selector-instances
    const slots = useSelector((state) => selectCalendarAvailabilityByDate(state, calendarId, date));

    const selectedCalendarId = useSelector(selectSelectedCalendarId);

    const dayDate = moment.utc(date);
    const dateString = moment(dayDate).format('dddd') + ", " + moment(dayDate).format('MMMM Do YYYY');

    const hasMoreAvailabilityThanInitialLimit = slots.length > initialSlotsPerDay;
    const numSlotsToShow =
        isExpanded || !hasMoreAvailabilityThanInitialLimit ? slots.length : initialSlotsPerDay;
    const slotsToShow = slots.slice(0, numSlotsToShow);
    const dayHasSlots = slotsToShow.length > 0;
    const slotsToShowByRow = [];
    const slotsPerColumn = Math.ceil(numSlotsToShow / numSlotColumns);
    const numRowsToShow = slotsPerColumn;

    for (let i = 0; i < numRowsToShow; i++) {
        const rowIndex = i * numSlotColumns;
        const nextRowIndex = rowIndex + numSlotColumns;
        const rowSlots = slotsToShow.slice(rowIndex, nextRowIndex);
        slotsToShowByRow.push(rowSlots);
    }

    const availabilityDayClassName = classNames(
        'availability-day',
        { mobile: isMobile },
        { 'no-availability': !dayHasSlots },
    );

    return (
        <div className={availabilityDayClassName}>
            {!!selectedCalendarId && <h5>{dateString}</h5>}
            {dayHasSlots &&
                slotsToShowByRow.map((row, i) => {
                    return (
                        <div className="slot-row" key={i}>
                            {row.map((appt, i) => <Slot appointment={appt} key={i} />)}
                        </div>
                    );
                })
            }
        </div>
    )
}

export const Slot = ({ appointment }) => {
    const dispatch = useDispatch();

    const availabilitySearchSupportData = useSelector((state) => state.config.availabilitySearchSupportData);
    const appointmentTypeList = availabilitySearchSupportData.appointmentTypeList;

    const modality = useMemo(() => {
        return appointmentTypeList?.find(apptType => apptType.idPgmAppointmentType === appointment.appointmentTypeId)?.pgmAppointmentModalityName ?? ''
    }, [appointment.appointmentTypeId, appointmentTypeList])

    const correlationKey = useSelector(state => state.session.correlationKey);
    const reservationDurationInMinutes = useSelector(state => state.config.scheduling.reservationDurationInMinutes);

    const enableAppointmentTypeTooltips = useSelector(state => state.config.availabilitySearch.enableAppointmentTypeTooltips);
    const routePrefix = useSelector(state => state.config.instance.routePrefix);

    return (
        <Button.ButtonTimeSlot
            disabled={appointment.disabled}
            id={null}
            isLoading={false}
            onClick={() => {
                let mappedSlot = mapSlotToAppointmentRequest(appointment);
                dispatch(reserveAppointment(
                    mappedSlot,
                    reservationDurationInMinutes,
                    correlationKey
                )).then((response) => {
                    if (response.payload?.data?.reservationId) {
                        dispatch(routeToBooking(routePrefix))
                    }
                });
            }}
            size={'md'}
            title={enableAppointmentTypeTooltips ? appointment.appointmentTypeName : null}
            type={'button'}
        >
            {(modality === "Telephonic") &&
                <span style={{marginRight: "5px"}}><Icon.IconTelephonic className="icon-telephonic" size={"xs"} /></span>
            }
            {formatStartTime(appointment.startTime)}
        </Button.ButtonTimeSlot>)
}

export const MoreLessAvailabilityToggler = ({ isExpanded, setIsExpanded }) => {
    return (
        <div className="availability-row-more-less-container">
            {!isExpanded && (
                <span className="show-more-availability-btn" onClick={() => setIsExpanded((x) => !x)}>
                    SHOW MORE
                </span>
            )}
            {isExpanded && (
                <span className="show-less-availability-btn" onClick={() => setIsExpanded((x) => !x)}>
                    SHOW LESS
                </span>
            )}
        </div>
    )
}

export const NoAvailabilityPane = ({ calendarId }) => {
    const dispatch = useDispatch();
    const maxSlotCountAnyDisplayedDate = useSelector((state) => selectMaxSlotCountAnyDisplayedDate(state, calendarId));

    const scanDayCount = useSelector(selectScanDayCount);

    const firstAvailabilityDate = useSelector(state => selectFirstAvailabilityDateByCalendarId(state, calendarId));
    const nextAvailabilityDate = useSelector(state => selectNextAvailabilityDateByCalendarId(state, calendarId));
    const effectiveNextAvailabilityDate = firstAvailabilityDate || nextAvailabilityDate;

    return (
        <>
            {!!maxSlotCountAnyDisplayedDate && (
                <div className="no-availability-pane">No availability, please select a different date.</div>
            )}
            {!maxSlotCountAnyDisplayedDate && effectiveNextAvailabilityDate && (
                <div className="no-availability-pane">
                    This provider has no appointments available this week. Their next available appointment is{' '}
                    <span className="first-available-go-to-date" onClick={() => dispatch(changeViewDateThunk(effectiveNextAvailabilityDate))}>
                        {moment(effectiveNextAvailabilityDate).format('MMM D YYYY')}
                    </span>
                </div>
            )}
            {!maxSlotCountAnyDisplayedDate && scanDayCount && !effectiveNextAvailabilityDate && (
                <div className="no-availability-pane">
                    This provider matched your search but has no appointments available in the next {scanDayCount}{' '}
                    days.
                </div>
            )}
            {!maxSlotCountAnyDisplayedDate && !scanDayCount && (
                <div className="no-availability-pane">
                    This provider matched your search but has no appointments available this month.
                </div>
            )}
        </>
    );
}

export const mapSlotToAppointmentRequest = (slot) => {
    return {
        timeSlotId: slot.availabilityId,
        serviceSiteId: slot.calendarId,
        appointmentTypeId: slot.appointmentTypeId,
        date: slot.date,
        startTime: slot.startTime,
        endTime: slot.endTime,
    };
}

export default Availability;