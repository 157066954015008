import React from 'react';
import './smsTermsAndPolicies.css';

export function SmsTermsAndPolicies(props) {
	return (
		<>
			<div className="sms-terms-and-policies">
				<a href="SMSTextingPrivacyPolicy">SMS Texting Privacy Policy</a>
				&nbsp;|&nbsp;<a href="SMSTextingTermsOfService"> SMS Texting Terms of Service</a>
			</div>
			<br />
		</>
	);
}
