export const INPUT_TYPES = {
	BUTTON_OPTIONS: 'buttonOptions',
	CHECKBOX: 'checkbox',
	DATE_OF_BIRTH: 'dateOfBirth',
	DROPDOWN: 'dropdown',
	EMAIL: 'email',
	NUMBER: 'number',
	PHONE_NUMBER: 'phoneNumber',
	TEXT: 'text',
	ZIP_CODE: 'zipCode',
	DATE: 'date',
	RADIO_OPTIONS: 'radioOptions',
	YEAR: 'year',
}
