import React from 'react';
import PropTypes from 'prop-types';
import { NavHeader } from './NavHeader';

export default class Header extends React.Component {
	render() {
		return (
			<React.Fragment>
				<NavHeader {...this.props} />
			</React.Fragment>
		);
	}
}

Header.propTypes = {
	auth: PropTypes.object.isRequired,
	onLogoClick: PropTypes.func,
};
