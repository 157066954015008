import React, { useEffect, useState } from "react";
import { browserName, BrowserTypes } from 'react-device-detect';
import UnsupportedBrowser from '../misc/UnsupportedBrowser';

const withSupportedBrowser = (WrappedComponent) => {

	const checkSupportedBrowser = () => {
		let supported = true;
		if (browserName === BrowserTypes.Ie || browserName === BrowserTypes.InternetExplorer) {
			supported = false;
		}
		return supported;
	};

	const HOC = (props) => {
		let [isBrowserSupported, setIsBrowserSupported] = useState(true);

		useEffect(() => {
			const isSupported = checkSupportedBrowser();
			setIsBrowserSupported(isSupported);
		}, []);

		if (!isBrowserSupported) {
			return (
				<>
				<UnsupportedBrowser />
				<WrappedComponent {...props} />
				</>
			);
		}

		return (
			<WrappedComponent {...props} />
		);
	}

	return HOC;
}

export default withSupportedBrowser;
