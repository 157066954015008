import React from 'react';

export default function ConditionsOfAccess() {
	return (
		<div id="conditionsOfAccess">
			<div>
				<p>
					THESE CONDITIONS OF ACCESS (these <b>“Conditions”</b>) are agreed to by you (<b>“User”</b>) in favor of My
					Health Direct, Inc., a Delaware corporation (<b>“MHD”</b>), as a condition to User’s access to and use of
					MyHealthDirect, a web-based solution that functions as a portal for the purposes of scheduling health care
					appointments (the <b>“Scheduling Service”</b>). By clicking the “Submit” button to confirm your appointment
					through the Scheduling Service, User agrees to these Conditions. USER ACKNOWLEDGES AND AGREES THAT THESE
					CONDITIONS SUPERSEDE AND REPLACE ANY PRIOR AGREEMENTS, TERMS, OR CONDITIONS WITH MHD, WHICH SHALL NO LONGER BE
					IN EFFECT. BY ACCESSING OR USING ANY PART OF THE SCHEDULING SERVICES, USER AGREES THAT USER HAS READ,
					UNDERSTOOD, AND AGREED TO BE BOUND BY THESE CONDITIONS. IF USER DOES NOT AGREE TO BE SO BOUND OR IS YOUNGER
					THAN THIRTEEN (13) YEARS OLD, DO NOT ACCESS OR USE THE SCHEDULING SERVICES.
				</p>
				<p>
					IF USER HAS AN EMERGENCY, DO NOT USE THE SCHEDULING SERVICES TO CONTACT USER’S PHYSICIAN – USER SHOULD CALL
					911 AND REQUEST EMERGENCY CARE ASSISTANCE.
				</p>
				<ol>
					<li>
						<p>
							<i>Eligibility</i>. User acknowledges and agrees that these Conditions apply to any individual, groups of
							individuals, or entity attempting to use the Scheduling Service to schedule an appointment with a
							healthcare service provider (the <b>“Purpose”</b>). In order to gain access to the Scheduling Service and
							to achieve the Purpose, User must be 13 years of age or older or an emancipated minor. Parents of
							unemancipated minors may access and use the Scheduling Services for themselves on behalf of such minors,
							as may other personal representatives under applicable law. The Scheduling Service is for use in
							connection with scheduling services with healthcare providers operating in the United States only. The
							Scheduling Service is not marketed to or intended for use by providers in foreign jurisdictions. All
							references to “User” in these Conditions include you and any other person for whom you are authorized in
							writing or under applicable law to act on behalf of, if any, in order to access and use the Scheduling
							Services to achieve the Purpose.
						</p>
						<p>
							User shall provide to MHD certain information regarding User, including, without limitation, User’s name,
							mailing address, phone number, and e-mail address, in order to access and use the Scheduling Service.
							Because User does not have a registered user account, User will be required to submit such information
							each time User accesses and uses the Scheduling Service. MHD may use User’s e-mail address or phone number
							to, among other things, correspond with User regarding User’s use of the Scheduling Service via e-mail,
							text message, or SMS message, and User hereby consents to such use. User is solely responsible for any and
							all activity that occurs in association with User’s use of the Scheduling Services. In addition to these
							Conditions, User shall comply with such operational procedures and policies as may be posted by MHD on its
							web site, as the same may be updated from time to time by MHD.
						</p>
					</li>
					<li>
						<p>
							<i>Access to the Scheduling Service.</i> Subject to User’s compliance with these Conditions, MHD hereby
							grants User permission to use the Scheduling Services, provided that: (i) User is not permitted to resell,
							redistribute, or sublicense the Scheduling Services, or use the Scheduling Services in any other manner
							inconsistent with these Conditions; (ii) User does not attempt to reverse engineer, decompile,
							disassemble, attempt to derive the source code of, modify, create derivative works of, alter or modify any
							part of the Scheduling Services; and (iii) User fully complies with the terms and conditions of these
							Conditions in all respects.
						</p>
						<p>
							To the extent that User provides any information, including but not limited to personal information, to
							MHD or its representatives, User warrants that (a) User is providing or obtaining only User’s own
							information or the information of others which User is authorized to provide to third parties and/or
							obtain from third parties on their behalf; and (b) the use of such information by MHD and its
							representatives will not infringe upon or misappropriate the intellectual property rights or otherwise
							violate the rights of any third parties.{' '}
							<b>
								USER EXPRESSLY ACKNOWLEDGES AND AGREES THAT TO THE EXTENT USER SUBMITS, PROCESSES, OR TRANSMITS ANY
								PATIENT DATA OR PROTECTED HEALTH INFORMATION (AS SUCH TERM IS DEFINED IN 45 CFR § 160.103) IN OR THROUGH
								THE SCHEDULING SERVICES, USER HAS OBTAINED ALL NECESSARY PERMISSIONS, CONSENTS, LICENSES, AND
								AUTHORIZATIONS NECESSARY TO TRANSMIT, UPLOAD, AND OR/USE THE PATIENT DATA AND/OR PROTECTED HEALTH
								INFORMATION IN CONNECTION WITH THE SERVICES PROVIDED HEREUNDER OR OTHERWISE IN CONNECTION WITH THESE
								CONDITIONS.
							</b>{' '}
							User understands and agrees that MHD will store or otherwise retain patient data and other protected
							health information provided by User for use in providing the Scheduling Services to the extent permitted
							by applicable law or these Terms.
						</p>
						<p>
							User warrants that User will not use the Scheduling Services for any purpose that is unlawful or
							prohibited by these Conditions. User acknowledges that data conversion and transmission is subject to the
							likelihood of human and machine errors, omissions, delays, and losses, including inadvertent loss of data
							or damage to media, that may give rise to loss or damage. MHD shall not be liable for any such errors,
							omissions, delays, or losses. All information transmitted and received through the Internet is subject to
							unauthorized interception, diversion, corruption, loss, access, and disclosure. MHD shall not be
							responsible for any adverse consequences whatsoever of User’s connection to or use of the Internet, and
							shall not be responsible for any use by User of an Internet connection in violation of any law, rule, or
							regulation or any violation of the intellectual property rights of another.
						</p>
					</li>
					<li>
						<p>
							<i>Intellectual Property.</i> The Subscription Services and any and all intellectual property, trademarks,
							service marks, information, data, or other materials made available to User in connection with these
							Conditions are the sole and exclusive property of MHD and its licensors, and are available to User solely
							for purposes of User’s use of and access to the Subscription Services in accordance with these Conditions
						</p>
					</li>
					<li>
						<p>
							<i>Termination.</i> User’s rights under these Conditions will terminate automatically without notice from
							MHD if User fails to comply with any term(s) of these Conditions. In addition, the Scheduling Service is
							being provided to you as a convenience. MHD has the right to terminate, limit, suspend, or revoke a User’s
							access at any time for any reason, with or without notice. User may stop using the Scheduling Service at
							any time
						</p>
					</li>
					<li>
						<p>
							<i>Modifications.</i> At any time and from time to time MHD may modify the Scheduling Service and may add
							or delete functions. MHD reserves the right, at its sole discretion, to make changes to all or part of
							these Conditions at any time. Because User does not have a registered user account, User is required to
							accept these Conditions, as may be changed from time to time, each time that User clicks the “Submit”
							button when submitting a request for an appointment through the Scheduling Services.
						</p>
					</li>
					<li>
						<p>
							<i>Warranties and Covenants.</i> User warrants and covenants to MHD that (i) User has all required
							consents and authorizations necessary, if any, in order to submit a request for an appointment through the
							Scheduling Service, and (ii) User will exercise due care at all times in its use of the Scheduling
							Service.
						</p>
					</li>
					<li>
						<p>
							<i>IP Tracking (Log Files); Cookies.</i> User acknowledges and agrees that MHD may track IP addresses and
							use any information stored on User’s hard drive (“Cookies”) to track, among other things, User’s content
							preferences and traffic patterns in connection with the Scheduling Service, the number of times User has
							utilized the Schedules Service in a given period of time and any other functions of the Scheduling Service
							utilized by User; provided, however, that User may disable Cookies by adjusting User’s web browser
							settings.
						</p>
					</li>
					<li>
						<p>
							<i>Privacy.</i> User acknowledges and agrees that (i) MHD may, from time to time, communicate with User by
							electronic mail, online newsletters (in the form of electronic mail), pop-up windows and confirmation
							letters, (ii) MHD may notify User if there are any new or deleted features of the Scheduling Service,
							(iii) MHD strives to use commercially acceptable means to protect the personal information of User, but
							MHD does not guarantee the confidentiality of any communication or material transmitted to or from the
							Scheduling Service, transmitted by the internet or other global computer networks, or transmitted by
							e-mail or facsimile transmission between User and MHD, (iv) any e-mail or facsimile transmission between
							User and MHD may be shared with a representative, contractor, employee, agent, or advisor of MHD that is
							most able to address the inquiry, (v) MHD may conduct voluntary surveys from time to time for the purpose
							of improving the Scheduling Service, which MHD may share with third parties to illustrate, among other
							things, samplings of users and general user preferences, but participation in any such survey is
							voluntary, the results of such surveys will be displayed as aggregate results and User’s individual
							identity will not be released to any third party without User’s prior consent, and (vi) MHD may share with
							MHD’s partners aggregate statistical information regarding, among other things, site usage of User. Any
							information available through the Scheduling Service that is not identified as having been prepared by MHD
							(including, without limitation, any information accessed through a third party website referenced or
							linked from the Scheduling Service), is not endorsed by MHD and is provided for the convenience of User
							only and MHD has not evaluated and does not guarantee the accuracy or completeness of any such
							information. User further acknowledges and agrees that third parties may obtain unauthorized access to the
							Scheduling Service and gain access to information provided to MHD by User. Notwithstanding anything to the
							contrary in these Conditions, MHD may use or disclose personally identifiable health information as
							otherwise permitted by law and MHD’s obligations under its contractual obligations with its customers,
							such as for treatment, payment, and health care operation purposes.
						</p>
						<p>
							Without limiting the foregoing paragraph, MHD also may use or disclose any data or information provided by
							User in connection with the Scheduling Service to MHD’s third-party partners as necessary to provide the
							Scheduling Service to User, or if MHD is required to do so by law or MHD in good faith believes that such
							action is necessary to (i) comply with the law or with legal process, (ii) protect and defend MHD’s rights
							and property, (iii) protect against misuse or unauthorized use of the Scheduling Service, or (iv) protect
							the personal safety or property of MHD’s users or the public. Please note that MHD may not provide User
							with notice prior to disclosure in such case
						</p>
					</li>
					<li>
						<p>
							<i>Disclaimers.</i> TO THE FULLEST EXTENT PERMITTED BY LAW, MHD, ITS SUPPLIERS, AND AFFILIATES, AND THEIR
							OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH
							THE SCHEDULING SERVICES AND USER’S USE THEREOF, INCLUDING WITHOUT LIMITATION ALL IMPLIED WARRANTIES OR
							CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TIMELINESS, ACCURACY, COMPLETENESS,
							TITLE, AND NON-INFRINGEMENT. THE SCHEDULING SERVICES ARE PROVIDED “AS AVAILABLE,” “AS IS,” AND “WITH ALL
							FAULTS,” WITHOUT WARRANTY OR CONDITION OF ANY KIND. THE SCHEDULING SERVICES ARE A MERE CONDUIT TO THE
							RECEIPT OF HEALTHCARE SERVICES, AND MHD DOES NOT PROVIDE MEDICAL ADVICE, DIAGNOSES, OR RECOMMENDATIONS
							ABOUT MEDICAL TREATMENT, AND DOES NOT RECOMMEND OR ENDORSE ANY PRODUCTS OR INFORMATION FOR ANY PARTICULAR
							CIRCUMSTANCES.
						</p>
						<p>
							SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS
							ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS
							MAY NOT APPLY TO USER. IF USER IS A RESIDENT OF A JURISDICTION THAT REQUIRES A SPECIFIC STATEMENT
							REGARDING RELEASE, THEN THE FOLLOWING APPLIES. FOR EXAMPLE, CALIFORNIA RESIDENTS MUST, AS A CONDITION OF
							THIS AGREEMENT, WAIVE THE APPLICABILITY OF CALIFORNIA CIVIL CODE SECTION 1542, WHICH STATES, “A GENERAL
							RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR
							AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR
							HER SETTLEMENT WITH THE DEBTOR.” USER HEREBY WAIVED THIS SECTION OF THE CALIFORNIA CIVIL CODE. USER HEREBY
							WAIVES ANY SIMILAR PROVISION IN LAW, REGULATION, OR CODE THAT HAS THE SAME INTENT OR EFFECT AS THE
							AFOREMENTIONED RELEASE.
						</p>
					</li>
					<li>
						<p>
							<i>Limitation of Liability.</i> IN NO EVENT SHALL MHD, ITS SUPPLIERS, AFFILIATES, OR THEIR RESPECTIVE
							OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR
							CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE
							FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. IF ANY EXCLUSION, DISCLAIMER OR OTHER
							PROVISION CONTAINED IN THESE CONDITIONS IS HELD TO BE INVALID FOR ANY REASON BY A COURT OF COMPETENT
							JURISDICTION, AND MHD, OR ONE OF ITS AFFILIATES, OFFICERS, DIRECTORS, AGENTS OR EMPLOYEES BECOMES LIABLE
							FOR LOSS OR DAMAGE THAT COULD OTHERWISE BE LIMITED, SUCH LIABILITY WHETHER IN CONTRACT, TORT OR OTHERWISE
							WILL NOT EXCEED IN THE AGGREGATE $100.00. IF USER IS A RESIDENT OF THE STATE OF NEW JERSEY, TO THE EXTENT
							NEW JERSEY LAW PROHIBITS THE LIMITATIONS AND/OR EXCLUSIONS OF LIABILITY SET FORTH IN THESE CONDITIONS,
							SUCH LIMITATIONS AND/OR EXCLUSIONS SHALL NOT APPLY TO USER. Any claim or cause of action arising out of or
							related to User’s use of the Scheduling Services or these Conditions must be filed within one (1) year
							after such claim or cause of action arose or it shall forever be barred, notwithstanding any statute of
							limitations or other law to the contrary.
						</p>
					</li>
					<li>
						<p>
							<i>Disputes.</i> These Conditions shall be governed by the internal substantive laws of the State of
							Tennessee, without respect to its conflict of laws principles. Any claim or dispute between User and MHD
							that arises in whole or in part from the Scheduling Services shall be decided exclusively by a court of
							competent jurisdiction located in the State of Tennessee; provided, that User hereby agrees that any
							dispute arising out of or relating in any way to these Conditions or User’s use of the Scheduling Services
							and/or any information, materials or services User obtains from MHD requires that such claim be resolved
							exclusively by confidential binding arbitration, except that, to the extent User has in any manner
							violated or threatened to violate MHD’s intellectual property rights or confidential information, MHD may
							seek injunctive or other appropriate relief in a court of competent jurisdiction in the State of
							Tennessee. The arbitration shall be conducted before three neutral arbitrators in Nashville, Tennessee,
							U.S.A., in accordance with the rules of the American Arbitration Association (<b>“AAA”</b>), as then in
							effect. No claims of any other parties may be joined or otherwise combined in the arbitration proceeding.
							Unless otherwise expressly required by applicable law, each party shall bear its own attorneys’ fees
							without regard to which party is deemed the prevailing party in the arbitration proceeding. Except for
							punitive and consequential damages (which may not be awarded), and subject to these Conditions, the
							arbitrators shall be authorized to award either party any provisional or equitable remedy permitted by
							applicable law. BECAUSE THE USE OF THE SCHEDULING SERVICES REQUIRES THE ARBITRATION OF ANY CLAIMS OR
							DISPUTES EXISTING BETWEEN THE PARTIES, NEITHER PARTY WILL HAVE THE RIGHT TO PURSUE THAT CLAIM IN COURT OR
							BEFORE A JUDGE OR JURY OR TO PARTICIPATE IN A CLASS ACTION OR ANY OTHER COLLECTIVE OR REPRESENTATIVE
							PROCEEDING. THE ARBITRATORS’ DECISION WILL BE FINAL AND BINDING. OTHER RIGHTS THAT EITHER PARTY WOULD HAVE
							IF SUCH PARTY WENT TO COURT, INCLUDING WITHOUT LIMITATION THE RIGHT TO CONDUCT DISCOVERY OR TO APPEAL, MAY
							BE LIMITED OR UNAVAILABLE IN ARBITRATION. The award of the arbitrators may be enforced in any court having
							jurisdiction thereof. Each party hereby consents (i) to the nonexclusive jurisdiction of the state or
							federal courts located in Nashville, Tennessee for any action (a) to compel arbitration, (b) to enforce
							any award of the arbitrators, (c) at any time prior to the qualification and appointment of the
							arbitrators, for temporary, interim or provisional equitable remedies, or (d) to enforce MHD’s
							intellectual property rights or rights in confidential information, and (ii) to service of process in any
							such action by registered mail or any other means provided by law. Should this section be deemed invalid
							or otherwise unenforceable for any reason, it shall be severed and the parties agree that sole and
							exclusive jurisdiction and venue for any claims will be in the state or federal courts in Nashville,
							Tennessee.
						</p>
					</li>
					<li>
						<p>
							<i>General</i>. User may not assign these Conditions without the prior written consent of MHD, and any
							assignment in contravention of the foregoing shall be null and void. If any provision of these Conditions
							is deemed invalid or unenforceable by a court of competent jurisdiction, such provision shall not affect
							the validity or enforceability of the remaining provisions of these Conditions, which shall remain in full
							force and effect. No waiver of any term of these Conditions shall be deemed a further or continuing waiver
							of such term or any other term, and MHD’s failure to assert any right or provision under these Conditions
							shall not constitute a waiver of such right or provision. These Conditions, as revised from time to time
							by MHD, constitute the entire agreement between the parties with regard to the subject matter in these
							Conditions and supersede all prior understandings and agreements, whether written or oral, as to such
							subject matter.
						</p>
					</li>
					<li>
						<p>
							<i>Contact Information.</i> If User has any questions about these Conditions, MHD’s practices, or User’s
							dealings with the Scheduling Services, Please contact us at (629) 777-5552. User also may contact us at
							the address set forth below:
						</p>
						<p>
							Experian Health
							<br />
							720 Cool Springs Blvd., Suite 200
							<br />
							Franklin, TN 37067
							<br />
							Attn: Client Experience Manager
							<br />
						</p>
					</li>
				</ol>
				<p>
					<i>These Conditions were last modified May 15, 2024.</i>
					<br />
					25522344.v8
				</p>
			</div>
		</div>
	);
}
