import * as types from '../actions/actionTypes';

const initialState = {
	referringProviders: [],
	orgReferringProviders: [],
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.GET_REFERRING_PROVIDERS_SUCCESS:
			return {
				...state,
				referringProviders: action.payload.data,
			};

		case types.GET_ORG_REFERRING_PROVIDERS:
			return {
				...state,
				orgReferringProviders: [],
			};

		case types.GET_ORG_REFERRING_PROVIDERS_SUCCESS:
			return {
				...state,
				orgReferringProviders: action.payload.data,
			};

		default:
			return state;
	}
}
