import moment from 'moment';
import * as flowTypes from '../constants/flowTypeEnum';
import * as routes from '../routes';
import * as actionTypes from './actionTypes';
import { cancelReservation, setInitialAppointmentDetails } from './appointmentActions';
import { setInitialAvailabilitySearchState } from './availabilitySearchActions';
import { setInitialCustomFieldAnswers } from './customFieldActions';
import { setPatientDemographics } from './patientActions';
import { startWorkflow } from '../features/schedulingWorkflow/schedulingWorkflowSlice';

moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss';

// this is called by App.js
export function resetWorkflow(token, reservationId, url, isExternal) {
	return (dispatch) => {
		if (reservationId) {
			dispatch(cancelReservation(token, reservationId));
		}
		clearDecisionSupport(url);
		if (!isExternal) {
			dispatch(routes.resetWorkflow(url));
		}
	};
}

//optionally sets npi, site, and specialty from url params
export function setBookingContext(bookingContextDetails) {
	const contextCriteria = {
		npi: bookingContextDetails.npi,
		siteId: bookingContextDetails.siteId,
		specialtyId: bookingContextDetails.specialtyId,
		specialtyName: bookingContextDetails.specialtyName,
	};

	return function (dispatch) {
		dispatch(setBookingContextAction(contextCriteria));
		return Promise.resolve();
	};
}

export function setGRDebugContext(contextDetails) {
	const contextCriteria = {
		debug: contextDetails.debug
	};

	return function (dispatch) {
		dispatch(setGRDebugContextAction(contextCriteria));
		return Promise.resolve();
	};
}

//called by cancel view and logins
export function setEntryPointUrl(flowType, guid) {
	return function (dispatch) {
		dispatch(setEntryPointUrlAction(flowType, guid));
	};
}

//called by login
export function initializeBookingProcess(
	useDecisionSupport,
	correlationKey,
	activeCareOrder,
	decisionSupportOutput,
	availabilitySearchConfig,
	activePatient,
) {
	return function (dispatch) {
		if (!correlationKey) {
			correlationKey = generateCorrelationKey();
		}

		if (!useDecisionSupport) {
			// if no decision support initialize avail search, otherwise set booking initialized so we move to decision support
			dispatch(
				startAvailabilitySearch(activeCareOrder, decisionSupportOutput, correlationKey, availabilitySearchConfig, activePatient),
			);
		} else {
			dispatch(setBookingInitialized(correlationKey));
		}
	};
}

//called internally when no decision support, or called by decision support when its through
export function startAvailabilitySearch(
	activeCareOrder,
	decisionSupportOutput,
	correlationKey,
	availabilitySearchConfig,
	activePatient,
) {
	return function (dispatch) {
		let initialAvailabilitySearchState = dispatch(
			setInitialAvailabilitySearchState(
				activeCareOrder.availabilitySearchCriteria,
				decisionSupportOutput.availabilitySearchCriteria,
				availabilitySearchConfig,
				null,
			),
		);
		let appointmentDetails = dispatch(
			setInitialAppointmentDetails(activeCareOrder.appointmentCriteria, decisionSupportOutput.appointmentCriteria),
		);
		let customFieldAnswers = dispatch(
			setInitialCustomFieldAnswers(activeCareOrder.customFields, decisionSupportOutput.customFields),
		);

		let patientDemographics;

		if (!activePatient.isBookAnotherAppointment) {
			patientDemographics = dispatch(setPatientDemographics(decisionSupportOutput.patientDemographics));
		}

		return Promise.all([
			initialAvailabilitySearchState,
			appointmentDetails,
			customFieldAnswers,
			patientDemographics,
		]).then(() => {
			return dispatch(setBookingInitialized(correlationKey));
		});
	};
}

//called by login or patient authentication
export function redirectToBookingProcess(auth, patientAuthContext, useDecisionSupport, bookingContext, routePrefix) {
	return function (dispatch) {
		let { activeCareOrderDetails } = bookingContext;

		if (patientAuthContext.patientAuthConfig.enablePatientAuth && !patientAuthContext.isVerified) {
			dispatch(routes.patientAuthentication(routePrefix));
		} else if (bookingContext.hasReservation) {
			dispatch(routes.bookAppointment(routePrefix));
		} else {

			if (useDecisionSupport) {
				dispatch(startWorkflow('withDecisionSupport'));
			} else {
				dispatch(startWorkflow('noDecisionSupport'));
			}

			//TODO in this PR: this should change to the new startBookingProcessAction down below
			dispatch(
				routes.startBookingProcess(
					useDecisionSupport,
					activeCareOrderDetails.useMultiAppointmentWorkflow,
					activeCareOrderDetails.careOrderVisitIdentifier,
					bookingContext,
					routePrefix,
				),
			);
		}
	};
}

//called by login or patient authentication
export function redirectToSurveyProcess(patientAuthContext, surveyOrderContext, routePrefix) {
	return function (dispatch) {
		if (patientAuthContext.patientAuthConfig.enablePatientAuth && !patientAuthContext.isVerified) {
			dispatch(routes.patientAuthentication(routePrefix));
		} else {
			dispatch(routes.startSurveyGRFlow(surveyOrderContext, routePrefix));
		}
	};
}

//called by login
export function initializeSurveyOrderProcess(patientAuthContext, surveyOrderIdentifier, routePrefix) {
	return function (dispatch) {
		let correlationKey = generateCorrelationKey();
		dispatch(setCorrelationKeyAction(correlationKey));

		if (patientAuthContext.patientAuthConfig.enablePatientAuth && !patientAuthContext.isVerified) {
			dispatch(routes.patientAuthentication(routePrefix));
			return null;
		}

		dispatch(routes.startSurveyOrder(surveyOrderIdentifier, patientAuthContext.isVerified, routePrefix));
		return Promise.resolve();
	};
}

export function clearDecisionSupport() {
	return function (dispatch) {
		dispatch(clearDecisionSupportAction());
	};
}

//internal

function clearDecisionSupportAction() {
	return {
		type: actionTypes.CLEAR_DECISION_SUPPORT,
	};
}

function setBookingContextAction(contextCriteria) {
	return {
		type: actionTypes.SET_BOOKING_CONTEXT,
		contextCriteria: contextCriteria,
	};
}

function setGRDebugContextAction(contextCriteria) {
	return {
		type: actionTypes.SET_GUIDED_RESPONSE_DEBUG_CONTEXT,
		contextCriteria: contextCriteria,
	};
}

function setCorrelationKeyAction(correlationKey) {
	return {
		type: actionTypes.SET_CORRELATION_KEY_FOR_SURVEY,
		correlationKey: correlationKey,
	};
}

function setEntryPointUrlAction(flowType, guid) {
	let url = flowTypes.URLS[flowType] ? flowTypes.URLS[flowType](guid) : '';
	return {
		type: actionTypes.SET_ENTRY_POINT_URL,
		entryPointUrl: url,
	};
}

function generateCorrelationKey() {
	let key = crypto.randomUUID();
	return key;
}

function setBookingInitialized(correlationKey) {
	return function (dispatch) {
		dispatch(setBookingInitializedAction(correlationKey));
		return Promise.resolve();
	};
}

function setBookingInitializedAction(correlationKey) {
	return {
		type: actionTypes.SET_BOOKING_INITIALIZED,
		correlationKey: correlationKey,
	};
}
