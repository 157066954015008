import {
	validateDateOfBirth,
	validateDate,
	validateEmail,
	validatePhoneNumber,
	validateZipCode,
	validateYear,
} from './validation';
import { INPUT_TYPES } from '../constants/inputTypes';
import { buildFieldRefFromParts } from './fieldUtils';

export const DEFAULT_ERROR = (label) => `Please provide a valid ${label}`;
export const DECISION_SUPPORT_DEFAULT_ERROR = 'This question is required';

export const TYPE_VALIDATORS = {
	[INPUT_TYPES.DATE_OF_BIRTH]: validateDateOfBirth,
	[INPUT_TYPES.EMAIL]: validateEmail,
	[INPUT_TYPES.PHONE_NUMBER]: validatePhoneNumber,
	[INPUT_TYPES.ZIP_CODE]: validateZipCode,
	[INPUT_TYPES.DATE]: validateDate,
	[INPUT_TYPES.YEAR]: validateYear,
};

export const COMMON_RULE_NAMES = {
	REQUIRED: 'required',
	INPUT_TYPE: 'inputType',
};

export const buildError = (fieldRef, ruleName, message, isFormLevel = false) => {
	let error = {
		fieldId: fieldRef.fieldId,
		fieldGroupName: fieldRef.fieldGroupName,
		fieldName: fieldRef.fieldName,
		ruleName,
		message,
		isFormLevel,
	};
	return error;
};

export const validateField = (errors, values, props, fieldsConfig, fieldRef) => {
	let error = null;
	let value = values[fieldRef.fieldGroupName][fieldRef.fieldName]; // is Trim appropriate here?
	let hasValue = value || value === 0 ? true : false;

	if (typeof value === 'string' || value instanceof String) {
		value = value.trim();
		hasValue = value.length > 0;
	}

	let fieldConfig = fieldsConfig[fieldRef.fieldGroupName][fieldRef.fieldName];

	if (fieldConfig.isRequired) {
		error = !hasValue ? buildError(fieldRef, COMMON_RULE_NAMES.REQUIRED, DEFAULT_ERROR(fieldConfig.label)) : null;
	}

	if (!error && hasValue && fieldConfig.inputType) {
		const typeValidator = TYPE_VALIDATORS[fieldConfig.inputType];
		error =
			typeValidator && !typeValidator(value)
				? buildError(fieldRef, COMMON_RULE_NAMES.INPUT_TYPE, DEFAULT_ERROR(fieldConfig.label))
				: null;
	}

	if (!error && fieldConfig.validationRules) {
		fieldConfig.validationRules.forEach((rule) => {
			if (!error) error = rule(fieldRef, values, props, errors, fieldConfig);
		});
	}

	let newErrors = errors.filter((x) => x.fieldId !== fieldRef.fieldId);
	if (error) {
		newErrors.push(error);
	}

	return newErrors;
};

export const validateSection = (fieldGroupName, errors, values, props, fieldsConfig) => {
	let newErrors = errors;
	for (let fieldName in fieldsConfig[fieldGroupName]) {
		const fieldRef = buildFieldRefFromParts(fieldGroupName, fieldName);
		newErrors = validateField(newErrors, values, props, fieldsConfig, fieldRef);
	}
	return newErrors;
};

export const validateForm = (errors, values, props, fieldsConfig) => {
	let newErrors = errors;
	for (let fieldGroupName in fieldsConfig) {
		newErrors = validateSection(fieldGroupName, newErrors, values, props, fieldsConfig);
	}
	return newErrors;
};
