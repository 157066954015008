/**
 * @format
 */

import moment from 'moment';
import { formatStartTime } from './availabilityHelper';

export const getAvailabilityResult = (selectedDate, availabilityResults) => {
	let selectedDateString = moment(selectedDate).format('YYYY-MM-DD');
	let hasDisplayableAvailability = availabilityResults.singleCalendarResults?.length > 0;
	const availabilityResult = {
		calendarDetails: availabilityResults.singleCalendarDetails,
		calendarTimeSlotDays: [
			{
				day: 1,
				date: selectedDateString,
				appointments: availabilityResults.singleCalendarResults
					.filter((slot) => moment(slot.date).format('YYYY-MM-DD') === selectedDateString)
					.map((s) => {
						return {
							...s,
							formattedStartTime: formatStartTime(s.startTime),
						};
					}),
			},
		],
		hasDisplayableAvailability,
	};
	return availabilityResult;
};
