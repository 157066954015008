import * as actionTypes from './actionTypes';

export function login(instanceName, routePrefix) {
	let route = 'login';

	if (instanceName || routePrefix) {
		route += '?';
		if (instanceName && routePrefix) {
			route += `instanceName=${instanceName}&routePrefix=${routePrefix}`;
		} else if (instanceName) {
			route += `instanceName=${instanceName}`;
		} else {
			route += `routePrefix=${routePrefix}`;
		}
	}

	return {
		type: actionTypes.AUTHENTICATE_USER,
		payload: {
			request: {
				method: 'post',
				url: route,
			},
		},
	};
}

export function logoutUser() {
	return {
		type: actionTypes.LOGOUT_USER,
		payload: {
			request: {
				method: 'get',
				url: 'logout',
			},
		},
	};
}

export function verifyToken() {
	return {
		type: actionTypes.VERIFY_TOKEN,
		payload: {
			request: {
				method: 'get',
				url: 'token/verify',
			},
		},
	};
}
