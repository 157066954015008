import React from 'react';
import { displayProviderNameWithProfessionalDesignation, formatServiceName } from '../../lib/misc';

export function ProviderNameAndSpecialty(props) {
	const {
		availabilityResults,
		handleSelectCalendarDetails,
		isProviderClickable,
		serviceSiteId,
		providerDisplayName,
		providerFirstName,
		providerLastName,
		assetTag,
		providerProfessionalDesignation,
		providerSpecialty,
		showBiographyLink,
		toggleBioModal,
	} = props;
	let displayName =
		providerDisplayName ||
		getProviderDisplayName(providerFirstName, providerLastName, assetTag, providerProfessionalDesignation);
	return (
		<div className="provider-name-designation-specialty">
			<div className="provider-name-designation">
				{isProviderClickable && handleSelectCalendarDetails && serviceSiteId && (
					<span
						onClick={(e) => {
							e.preventDefault();
							handleSelectCalendarDetails(serviceSiteId, availabilityResults.minAllowedMonthStartDate);
						}}
					>
						{displayName}
					</span>
				)}
				{!isProviderClickable && displayName}
			</div>
			<div className="provider-specialty">{providerSpecialty}</div>
			{showBiographyLink && (
				<div className="biography">
					<span onClick={toggleBioModal}>Biography</span>
				</div>
			)}
		</div>
	);
}

const getProviderDisplayName = (firstName, lastName, assetTag, professionalDesignation) => {
	const formattedName = formatServiceName(firstName, lastName, assetTag);
	const formattedNameWithDesignation = displayProviderNameWithProfessionalDesignation(
		formattedName,
		professionalDesignation,
	);
	return formattedNameWithDesignation;
};
