import {
    SearchRequestType,
} from '../types/localTypes';
import {
    getMinDateFromCalendarDates,
    getMaxDateFromCalendarDates
} from '../../../lib/date';
import {
    greaterOfTwoDates,
    lesserOfTwoDates
} from '../../../lib/dateUtils';
import { scrollToPageTop } from '../../../lib/misc';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from "uuid";
import {
    flushCalendars,
    logNoSearchOrNextScanNeededBatchCompleted,
    searchCalendars,
    setActivePage,
    setAppliedFilters,
    setSelectedCalendarId,
    setSelectedCalendarViewBaseDate,
    setSkipAheadBehaviorSettled,
    setViewBaseDate,
    searchAvailability,
    scanAvailability,
    DEFAULT_DATE_FORMAT,
} from './availabilitySlice';
import {
    selectAppliedFilters,
    selectDisplayedCalendarIds,
    selectEffectiveAvailabilitySearchCriteria,
    selectEffectiveCalendarSearchCriteria,
    selectFilteredCalendarIds,
    selectFilteredNotPreferredCalendarIds,
    selectFilteredPreferredCalendarCount,
    selectFilteredPreferredCalendarIds,
    selectHasDoneFirstCalendarSearch,
    selectHasNotPreferredFirstScanCompleted,
    selectHasPreferredFirstScanCompleted,
    selectHasSkipAheadToDateBehaviorSettled,
    selectNextAvailabilityDatesNeedingScannedForCalendarById,
    selectNotPreferredFirstScanFirstAvailabilityDate,
    selectNotSearchingDatesForCalendarById,
    selectPreferredFirstScanFirstAvailabilityDate,
    selectScanDayCount,
    selectScanResults,
    selectSelectedCalendarId,
    selectShouldSearchPreferredProvidersFirst,
    selectShouldSkipToFirstAvailableEnabled,
    selectSortOrderDatesNeedingScannedForCalendarById,
    selectUnsearchedDatesForCalendarById,
    selectViewBaseDate
} from './availabilitySelectors';

export const searchCalendarsThunk = () => {
    return (dispatch, getState) => {
        dispatch(flushCalendars());
        const state = getState();
        dispatch(searchCalendars({
            request: {
                method: 'post',
                url: 'calendars/search',
                data: selectEffectiveCalendarSearchCriteria(state)
            }
        })).then(() => {
            dispatch(firstAvailabilityScanThunk())
        })
    }
}

export const searchNextScanRangeWithReSortThunk = () => {
    return (dispatch, getState) => {
        let state = getState();
        const viewBaseDate = selectViewBaseDate(state);
        const scanDayCount = selectScanDayCount(state);
        const newBaseDate = dayjs(viewBaseDate).add(scanDayCount, 'd').format(DEFAULT_DATE_FORMAT);
        const payload = {
            viewBaseDate: newBaseDate,
            sortOrderBaseDate: newBaseDate,
        }
        dispatch(setViewBaseDate(payload));
        dispatch(firstAvailabilityScanThunk())
    }
}

export const changeViewDateThunk = (date, repage = false) => {
    return (dispatch, getState) => {
        let state = getState();

        if (selectSelectedCalendarId(state)) {
            dispatch(setSelectedCalendarViewBaseDate(date));
        } else {
            dispatch(setViewBaseDate({ viewBaseDate: date }));
        }

        if (repage) {
            dispatch(setActivePage(1));
        }

        state = getState();
        dispatch(fulfillAvailabilityNeedsThunk(selectDisplayedCalendarIds(state), false, false));
    }
};

export const changePageThunk = (pageNumber) => {
    return (dispatch, getState) => {
        dispatch(setActivePage(pageNumber));
        scrollToPageTop();
        const state = getState();
        dispatch(fulfillAvailabilityNeedsThunk(selectDisplayedCalendarIds(state), false, false));
    }
};

export const applyFiltersThunk = (intendedFilters) => {
    return (dispatch, getState) => {

        const payload = {
            appliedFilters: intendedFilters
        }

        let state = getState();

        const appliedFilters = selectAppliedFilters(state);

        const filterConsequences = getFilterChangeConsequences(
            intendedFilters,
            appliedFilters,
            FILTER_CONSEQUENCE_ACTION_GATHERERS
        )

        applyFilterChangeConsequenceActionsToPayload(
            filterConsequences,
            payload,
            intendedFilters
        );

        dispatch(setAppliedFilters(payload));

        if (!selectHasDoneFirstCalendarSearch(state) || filterConsequences.findIndex(action => action === consequenceActions.flushCalendars) >= 0) {
            dispatch(searchCalendarsThunk());
        } else {
            dispatch(firstAvailabilityScanThunk());
        }
    }
}

export const setSelectedCalendarByIdThunk = (calendarId) => {
    return (dispatch, getState) => {
        let state = getState();

        if (calendarId) {
            dispatch(
                setSelectedCalendarId(
                    {
                        calendarId,
                        selectedCalendarViewBaseDate: dayjs(
                            greaterOfTwoDates(
                                state.availability.searchContext.availabilitySearchCriteria.minStartDate,
                                dayjs(state.availability.appliedFilters.viewBaseDate).startOf('month')
                            )
                        ).format(DEFAULT_DATE_FORMAT)
                    }
                )
            )
        } else {
            dispatch(setSelectedCalendarId({ calendarId: null, selectedCalendarViewBaseDate: null }));
        }

        state = getState();
        dispatch(fulfillAvailabilityNeedsThunk(selectDisplayedCalendarIds(state), false, false));

    }
}

const firstAvailabilityScanThunk = () => {
    return (dispatch, getState) => {
        const state = getState();
        if (selectShouldSearchPreferredProvidersFirst(state)) {
            dispatch(fulfillAvailabilityNeedsThunk(selectFilteredPreferredCalendarIds(state), true, true, true));
            dispatch(fulfillAvailabilityNeedsThunk(selectFilteredNotPreferredCalendarIds(state), false, true, true));
        }
        else {
            dispatch(fulfillAvailabilityNeedsThunk(selectFilteredCalendarIds(state), false, true));
        }
    }
}

const fulfillAvailabilityNeedsThunk = (calendarIds, isPreferredBatch = false, isFirstScan = false, hasPreferredBatch = false) => {
    return (dispatch, getState) => {
        let state = getState();

        const logInfo = {
            hasPreferredBatch,
            isPreferredBatch,
            isFirstScan,
        }

        const availabilityNeeds = getCalendarDatesNeedingSearchedAndScanned(state, calendarIds, isFirstScan);

        const apiRequests = batchRequests(
            state,
            availabilityNeeds,
            logInfo
        );

        apiRequests.forEach(request => {
            dispatch(searchOrScanCallSwitch(request)).then(() => {
                if (isFirstScan) {
                    let state = getState();
                    if (selectHasSkipAheadToDateBehaviorSettled(state)) {
                        dispatch(fulfillAvailabilityNeedsThunk(calendarIds, isPreferredBatch, false))
                    } else {
                        dispatch(settleSkipAheadBehaviorThunk(calendarIds, isPreferredBatch))
                    }
                }
            });

        })

        if (apiRequests.length === 0) {
            if (isFirstScan) {
                dispatch(fulfillAvailabilityNeedsThunk(calendarIds, isPreferredBatch, false));
            }
            else {
                dispatch(logNoSearchOrNextScanNeededBatchCompleted({ isPreferredBatch }));
            }
        }
    }
}

const settleSkipAheadBehaviorThunk = (calendarIds, isPreferredBatch) => {
    return (dispatch, getState) => {

        const state = getState();

        if (!selectShouldSkipToFirstAvailableEnabled(state)) {
            dispatch(setSkipAheadBehaviorSettled({ settled: true }));
            dispatch(fulfillAvailabilityNeedsThunk(calendarIds, isPreferredBatch, false));
        } else {
            if (selectFilteredPreferredCalendarCount(state)) {

                if (selectHasPreferredFirstScanCompleted(state)) {

                    let date = selectPreferredFirstScanFirstAvailabilityDate(state)
                        || (
                            selectHasNotPreferredFirstScanCompleted(state) && selectNotPreferredFirstScanFirstAvailabilityDate(state)
                        )

                    if (date) {

                        dispatch(setSkipAheadBehaviorSettled({ settled: true, viewBaseDate: date }));

                        if (selectHasNotPreferredFirstScanCompleted(state)) {
                            // not preferred was 'sleeping' so use All calendars
                            dispatch(firstAvailabilityScanThunk());
                        } else {
                            // not preferred isn't finished with first scan, it will re-evaluate on its own calendarIds
                            // when it sees skip behavior has already settled
                            dispatch(fulfillAvailabilityNeedsThunk(calendarIds), true, false)
                        }

                    } else if (selectHasNotPreferredFirstScanCompleted(state)) {
                        // both searches are done and no date to skip to
                        dispatch(setSkipAheadBehaviorSettled({ settled: true }));
                    }
                }

            } else {
                if (selectHasNotPreferredFirstScanCompleted(state)) {

                    let date = selectNotPreferredFirstScanFirstAvailabilityDate(state)

                    if (date) {
                        dispatch(setSkipAheadBehaviorSettled({ settled: true, viewBaseDate: date }));
                        dispatch(fulfillAvailabilityNeedsThunk(calendarIds), false, false)
                    } else {
                        dispatch(setSkipAheadBehaviorSettled({ settled: true }));
                    }
                }
            }
        }
    }
}

const getCalendarDatesNeedingSearchedAndScanned = (state, calendarIds, isFirstScan) => {

    let resultNeeds = {
        searchDates: {},
        scanDates: {},
    };

    const selectedCalendarId = selectSelectedCalendarId(state);

    if (selectedCalendarId) {
        const dates = selectUnsearchedDatesForCalendarById(state, selectedCalendarId);
        if (dates?.length) {
            resultNeeds.searchDates[selectedCalendarId] = dates;
        }
    } else if (isFirstScan) {
        calendarIds.forEach(calendarId => {
            const dates = selectSortOrderDatesNeedingScannedForCalendarById(state, calendarId);
            if (dates?.length) {
                resultNeeds.scanDates[calendarId] = dates;
            }
        })
    } else {

        //since first scan for sort order has occurred, we assume the calendars are sortable because we are only considering first scan
        //if we wanted to delay sorting of the unsortable (no bad guessing or out of order r        //if we wanted to delay sorting of the unsortable (no bad guessing or out of order results after skip) until the skipesults after skip) unti the skip
        //we could branch off here and do the below block of code for the sortable calendars, and run another block for
        //the other calendars that did sort order scans that considered the original sort order scan window PLUS the skipped days count
        //to determine sortability post skip sort order

        //for now, we only care about the paged providers from the first scan

        calendarIds
            .filter(calendarId => selectDisplayedCalendarIds(state)?.find(id => id === calendarId))
            .forEach(calendarId => {

                const unsearchedDatesForCalendar = selectUnsearchedDatesForCalendarById(state, calendarId)
                    .filter(date => selectNotSearchingDatesForCalendarById(state).includes(date));

                const unsearchedDatesThatCouldHaveAvailability = [];

                let shouldHaveAvailabilityInSearchDays = false;

                let calendarScanResults = selectScanResults(state)[calendarId];

                unsearchedDatesForCalendar.forEach(date => {
                    const dateHasntBeenScanned = calendarScanResults?.[date] === undefined;
                    const dateHasAvailabilityFromScan = !!calendarScanResults?.[date];

                    if (dateHasntBeenScanned || dateHasAvailabilityFromScan) {
                        unsearchedDatesThatCouldHaveAvailability.push(date);
                    }

                    if (dateHasAvailabilityFromScan) {
                        shouldHaveAvailabilityInSearchDays = true;
                    }
                })

                if (unsearchedDatesThatCouldHaveAvailability.length) {
                    resultNeeds.searchDates[calendarId] = unsearchedDatesThatCouldHaveAvailability;
                }

                if (!shouldHaveAvailabilityInSearchDays) {
                    let datesNeedingScannedForCalendar = selectNextAvailabilityDatesNeedingScannedForCalendarById(state, calendarId);
                    datesNeedingScannedForCalendar = datesNeedingScannedForCalendar.filter(scanDate => {
                        //don't do next scan for dates we're already going to search for
                        return unsearchedDatesThatCouldHaveAvailability.findIndex(searchDate => { return searchDate === scanDate }) < 0;
                    })

                    if (datesNeedingScannedForCalendar.length) {
                        resultNeeds.scanDates[calendarId] = datesNeedingScannedForCalendar;
                    }
                }
            });
    };

    return resultNeeds;
}

const batchRequests = (
    state,
    dataNeeds,
    logInfo
) => {
    let requests = [];
    const { searchDates, scanDates } = dataNeeds;
    const searchCriteria = selectEffectiveAvailabilitySearchCriteria(state);
    const calendarIdsForScan = Object.keys(scanDates).map(x => Number(x));
    const calendarIdsForSearch = Object.keys(searchDates).map(x => Number(x));

    if (!logInfo.isFirstScan) {
        logInfo.searchRequired = calendarIdsForSearch > 0;
        logInfo.nextScanRequired = calendarIdsForScan > 0;
    }

    if (calendarIdsForScan.length) {
        requests.push(
            buildRequest(
                SearchRequestType.Scan,
                calendarIdsForScan,
                Object.values(scanDates),
                searchCriteria,
                logInfo
            )
        )
    }

    if (calendarIdsForSearch.length) {
        requests.push(
            buildRequest(
                SearchRequestType.Search,
                calendarIdsForSearch,
                Object.values(searchDates),
                searchCriteria,
                logInfo
            )
        )
    }

    return requests;
}

const buildRequest = (
    requestType,
    calendarIds,
    dates,
    searchCriteria,
    logInfo
) => {

    return {
        type: requestType,
        payload: {
            request: {
                method: 'post',
                url: `availability/${requestType}`,
                data: {
                    ...searchCriteria,
                    calendarIds: calendarIds,
                    minStartDate: dayjs(
                        greaterOfTwoDates(
                            getMinDateFromCalendarDates(Object.values(dates)),
                            searchCriteria.minStartDate
                        )
                    ).format(DEFAULT_DATE_FORMAT),
                    maxStartDate: dayjs(
                        lesserOfTwoDates(
                            getMaxDateFromCalendarDates(Object.values(dates)),
                            searchCriteria.maxStartDate
                        )
                    ).format(DEFAULT_DATE_FORMAT)
                }
            },

            logInfo,
        }
    }

}

const searchOrScanCallSwitch = (request) => {
    let action =
        request.type === SearchRequestType.Search ?
            searchAvailability
            : scanAvailability;

    return action(request.payload);
}

//#region misc funcs
function getFilterChangeConsequences(intendedFilters, appliedFilters, actionGatherers) {
    const consequences = [];
    for (let key in actionGatherers) {
        const [consequenceActions, changeEval] = actionGatherers[key];
        if (changeEval(intendedFilters[key], appliedFilters[key])) {
            consequenceActions.forEach(action => {
                if (consequences.indexOf(action) < 0) {
                    consequences.push(action);
                }
            });
        }
    }
    return consequences;
}

function applyFilterChangeConsequenceActionsToPayload(consequences, payload, intendedFilters) {

    consequences.forEach(action => {
        if (action === consequenceActions.flushCalendars) {
            payload.calendars = {};
        }
        if (action === consequenceActions.flushAvailability) {
            payload.scanResults = {};
            payload.availability = {};
            payload.appliedFilters.availabilityValidRefId = uuidv4();
        }
        if (action === consequenceActions.resetPaging) {
            payload.pageNumber = 1;
        }
        if (action === consequenceActions.setSortOrderBaseDate) {
            payload.sortOrderBaseDate = intendedFilters.viewBaseDate;
        }
    });

}

const consequenceActions = {
    flushCalendars: "flushCalendars",
    resetPaging: "resetPaging",
    flushAvailability: "flushAvailability",
    setSortOrderBaseDate: "setSortOrderBaseDate",
}

const changeEval_standard = (intendedUpdate, currentCache) => {
    return (intendedUpdate !== currentCache)
};

const changeEval_stringify = (intendedUpdate, currentCache) => {
    return (JSON.stringify(intendedUpdate) !== JSON.stringify(currentCache))
};

const FILTER_CONSEQUENCE_ACTION_GATHERERS = {
    zipCode: [[consequenceActions.flushCalendars, consequenceActions.resetPaging], changeEval_standard],
    radius: [[consequenceActions.flushCalendars, consequenceActions.resetPaging], changeEval_standard],
    serviceCategoryId: [[consequenceActions.flushCalendars, consequenceActions.resetPaging], changeEval_standard],
    payorType: [[consequenceActions.flushCalendars, consequenceActions.resetPaging], changeEval_standard],
    specialtyId: [[consequenceActions.flushCalendars, consequenceActions.resetPaging], changeEval_standard],

    gender: [[consequenceActions.resetPaging], changeEval_standard],
    sortOrder: [[consequenceActions.resetPaging], changeEval_standard],
    services: [[consequenceActions.resetPaging], changeEval_standard],
    serviceName: [[consequenceActions.resetPaging], changeEval_standard],
    sites: [[consequenceActions.resetPaging], changeEval_standard],
    siteName: [[consequenceActions.resetPaging], changeEval_standard],
    language: [[consequenceActions.resetPaging], changeEval_standard],

    viewBaseDate: [[consequenceActions.setSortOrderBaseDate, consequenceActions.resetPaging], changeEval_standard],

    insuranceCarrierId: [[consequenceActions.flushAvailability, consequenceActions.resetPaging], changeEval_standard],
    insuranceStateId: [[consequenceActions.flushAvailability, consequenceActions.resetPaging], changeEval_standard],
    daysOfWeek: [[consequenceActions.flushAvailability, consequenceActions.resetPaging], changeEval_stringify],
    minStartTime: [[consequenceActions.flushAvailability, consequenceActions.resetPaging], changeEval_standard],
    maxStartTime: [[consequenceActions.flushAvailability, consequenceActions.resetPaging], changeEval_standard],
    appointmentTypeIds: [[consequenceActions.flushAvailability, consequenceActions.resetPaging], changeEval_stringify],
    appointmentTypeModalityIds: [[consequenceActions.flushAvailability, consequenceActions.resetPaging], changeEval_stringify],
}
//#endregion misc funcs