import * as actionTypes from '../actions/actionTypes';

export function startFlow(initialData) {
	return {
		type: actionTypes.GUIDED_RESPONSE_START_FLOW,
		initialData,
		payload: {
			request: {
				method: 'post',
				url: 'guidedresponse/runtime/start',
				data: initialData,
			},
		},
	};
}

export function continueFlow(flowSessionId, userResponses) {
	return {
		type: actionTypes.GUIDED_RESPONSE_CONTINUE_FLOW,
		flowSessionId,
		userResponses,
		payload: {
			request: {
				method: 'post',
				url: `guidedresponse/runtime/continue?flowSessionId=${flowSessionId}`,
				data: userResponses,
			},
		},
	};
}

export function previous(flowSessionId) {
	return {
		type: actionTypes.GUIDED_RESPONSE_PREVIOUS,
		flowSessionId,
		payload: {
			request: {
				method: 'post',
				url: `guidedresponse/runtime/previous?flowSessionId=${flowSessionId}`,
				data: {},
			},
		},
	};
}

export function getSession(flowSessionId) {
	return {
		type: actionTypes.GUIDED_RESPONSE_GET_FLOW_SESSION,
		payload: {
			request: {
				method: 'post',
				url: `guidedresponse/runtime/session?flowSessionId=${flowSessionId}`
			}
		}
	};
}

//TODO: The below methods will be deprecated when new Resume endpoint is implemented
function saveAnsweredQuestionsAction(questions) {
	return {
		type: actionTypes.GUIDED_RESPONSE_SAVE_FINISHED_QUESTIONS,
		data: questions,
	};
}

export function saveAnsweredQuestions(questions) {
	return function (dispatch) {
		dispatch(saveAnsweredQuestionsAction(questions));
	};
}

export function getFlowDebugPathFile(flowSessionId, token) {
	return {
		type: actionTypes.GUIDED_RESPONSE_DEBUG_PATH,
		payload: {
			request: {
				method: 'get',
				url: `guidedresponse/debugpath/${flowSessionId}`,
			},
		},
		headers: {
			token: token
		},
	};
}

export function getDSVResults(flowSessionId) {
	return {
		type: actionTypes.GET_GUIDED_RESPONSE_DSV_RESULTS,
		payload: {
			request: {
				method: 'get',
				url: `guidedresponse/dsv/verbiageresults/${flowSessionId}`,
			},
		}
	};
}