import { useSelector } from 'react-redux';
import { WorkflowName, WorkflowStep, WorkflowStepSubSteps, workflowSteps, workflows } from './schedulingWorkflowSlice';
import ProgressStepper, { ProgressStepperStep } from '../../components/misc/stepper';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';

const SchedulingWorkflowStepper = () => {
    const activeWorkflowName: WorkflowName = useSelector((state: any) => state.workflow.activeWorkflowName);
    const activeStepIndex = useSelector((state: any) => state.workflow.activeStepIndex);
    const workflowStepsSubSteps = useSelector((state: any) => state.workflow.workflowStepsSubSteps);

    const activeWorkflowStepNames = workflows[activeWorkflowName];

    const steps: ProgressStepperStep[] | undefined = activeWorkflowStepNames?.map((stepName: string) => {
        const step: WorkflowStep | undefined = workflowSteps.find((step: WorkflowStep) => step.name === stepName);

        return {
            label: step?.label ?? "",
            icon: step?.icon ?? faClipboard,
        }
    });

    const workflowStepSubSteps: WorkflowStepSubSteps | null = workflowStepsSubSteps?.[activeStepIndex];

    const activeStepPercentageComplete: number = (workflowStepSubSteps !== null && workflowStepSubSteps !== undefined)
        ? ((workflowStepSubSteps.currentStep / workflowStepSubSteps.totalSteps) * 100)
        : 0;

    if (steps?.length) {
        return <ProgressStepper
            steps={steps}
            activeStepIndex={activeStepIndex}
            activeStepPercentageComplete={activeStepPercentageComplete}
            displayAllCompleteStepper={false}
        />
    } else {
        return null;
    }
}

export default SchedulingWorkflowStepper;