import React from 'react';
import { CollapsablePanelWithSectionHeader, DisclaimerHtml } from './miscComponents';

export default function CustomFieldsSection(props) {
	const {
		children, enableSectionValidationIcons, handleToggleCollapse,
		hasErrors, isCollapsed, name, sectionConfig, shouldShowSectionValidation,
	} = props;

	return (
		<CollapsablePanelWithSectionHeader
			className="main-section"
			enableSectionValidationIcons={enableSectionValidationIcons}
			hasErrors={hasErrors}
			isCollapsed={isCollapsed}
			isCollapsable={sectionConfig.isCollapsable}
			label={sectionConfig.headerText}
			name={name}
			onToggleCollapse={handleToggleCollapse}
			shouldShowSectionValidation={shouldShowSectionValidation}
			showHeader={sectionConfig.showHeader}
		>
			<div className="section-body">
				<div className="section-row">
					<DisclaimerHtml className="form-instructions" isVisible={!!sectionConfig.customFieldsConfigInstructions} text={sectionConfig.customFieldsConfigInstructions} />
				</div>
				{children}
			</div>
		</CollapsablePanelWithSectionHeader>
	);
};
