import * as types from '../actions/actionTypes';

const initialState = {
	accessUrl: '',
	campaign: '',
	clientTrackingNumber: '',
	customFields: null,
	expirationDate: null,
	externalReferenceNumber: '',
	idSurvey: 0,
	idSystemReferral: 0,
	patient: {
		addressLine1: '',
		addressLine2: '',
		alternatePhone: '',
		cityName: '',
		dateOfBirth: '',
		email: '',
		firstName: '',
		gender: '',
		groupNumber: '',
		guardianName: '',
		homePhone: '',
		lastName: '',
		memberId: '',
		middleName: '',
		mobilePhone: '',
		patientPoolPrefix: '',
		referenceId: '',
		stateCode: '',
		stateId: '',
		zipCode: '',
	},
	patientReferenceId: null,
	referenceId: null,
	statusCode: '',
	surveyFlowRefValue: null,
	surveyOrderStatusReasonId: 0,
	surveyStatus: '',
	userId: null,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.START_SURVEY_ORDER_SUCCESS:
		case types.GET_SURVEY_ORDER_SUCCESS: {
			return {
				...state,
				expirationDate: action.payload.data.expirationDate,
				referenceId: action.payload.data.referenceId,
				statusCode: action.payload.data.statusCode,
				surveyFlowRefValue: action.payload.data.surveyFlowRefValue ?? action.payload.data.surveyFlowRefValue,
				surveyOrderStatusReasonId: action.payload.data.surveyOrderStatusReasonId,
				surveyStatus: action.payload.data.surveyStatus,
			};
		}
		case types.GET_FULL_SURVEY_ORDER_DETAILS_SUCCESS: {
			return {
				...state,
				accessUrl: action.payload.data.surveyOrderDetails.accessUrl,
				campaign: action.payload.data.surveyOrderDetails.campaign,
				clientTrackingNumber: action.payload.data.surveyOrderDetails.clientTrackingNumber,
				customFields: action.payload.data.surveyOrderDetails.customFields,
				expirationDate: action.payload.data.surveyOrderDetails.expirationDate,
				externalReferenceNumber: action.payload.data.surveyOrderDetails.externalReferenceNumber,
				idSurvey: action.payload.data.surveyOrderDetails.idSurvey,
				idSystemReferral: action.payload.data.surveyOrderDetails.idSystemReferral,
				patient: {
					addressLine1: action.payload.data.patientDetails.addressLine1,
					addressLine2: action.payload.data.patientDetails.addressLine2,
					alternatePhone: action.payload.data.patientDetails.alternatePhone,
					cityName: action.payload.data.patientDetails.cityName,
					dateOfBirth: action.payload.data.patientDetails.dateOfBirth,
					email: action.payload.data.patientDetails.email,
					firstName: action.payload.data.patientDetails.firstName,
					gender: action.payload.data.patientDetails.gender,
					groupNumber: action.payload.data.patientDetails.groupNumber,
					guardianName: action.payload.data.patientDetails.guardianName,
					homePhone: action.payload.data.patientDetails.homePhone,
					lastName: action.payload.data.patientDetails.lastName,
					memberId: action.payload.data.patientDetails.memberId,
					middleName: action.payload.data.patientDetails.middleName,
					mobilePhone: action.payload.data.patientDetails.mobilePhone,
					patientPoolPrefix: action.payload.data.patientDetails.patientPoolPrefix,
					referenceId: action.payload.data.patientDetails.referenceId,
					stateCode: action.payload.data.patientDetails.stateCode,
					stateId: action.payload.data.patientDetails.stateId,
					zipCode: action.payload.data.patientDetails.zipCode,
				},
				patientReferenceId: action.payload.data.surveyOrderDetails.patientReferenceId,
				referenceId: action.payload.data.surveyOrderDetails.referenceId,
				statusCode: action.payload.data.surveyOrderDetails.statusCode,
				surveyFlowRefValue: action.payload.data.surveyOrderDetails.surveyFlowRefValue,
				surveyOrderStatusReasonId: action.payload.data.surveyOrderDetails.surveyOrderStatusReasonId,
				surveyStatus: action.payload.data.surveyOrderDetails.surveyStatus,
				userId: action.payload.data.surveyOrderDetails.userId,
			};
		}
		case types.COMPLETE_SURVEY_ORDER_SUCCESS:
			return {
				...initialState,
			};

		default: {
			return state;
		}
	}
}
