import React, { useState, useRef, useEffect } from 'react';
import './guidedResponseSelection.css';
import FieldControl from '../input/fieldControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function GuidedResponseDropdownSelect(props) {
	const {
		id,
		error,
		disabled,
		hasValue,
		inputType,
		isFocused,
		isRequired,
		isVisible,
		label,
		labelAfter,
		defaultOptionLabel,
		name,
		value,
		onBlur,
		onChange,
		onDropdownChange,
		onFocus,
		optionList,
		size,
	} = props;

	const selectedValue = !!value ? optionList.find(option => option.value === value) : null
	const [inputValue, setInputValue] = useState("");
	const [showDropdown, setShowDropdown] = useState(false);
	const [filteredOptions, setFilteredOptions] = useState(optionList);
	const [highlightedOption, setHighlightedOption] = useState(-1);
	const inputRef = useRef();
	const [noResults, setNoResults] = useState(false);

	useEffect(() => {
		if(selectedValue?.name) setInputValue(selectedValue.name)
	},[selectedValue])

	useEffect(() => {
		if (filteredOptions.length < 1) {
			setNoResults(true);
		}
		else {
			setNoResults(false);
		}
	}, [filteredOptions]);

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
		setShowDropdown(true);
		setFilteredOptions(optionList.filter((option) => option.name.toLowerCase().includes(e.target.value.toLowerCase())));
		onChange(e);
	};

	const handleKeyDown = (e) => {
		if (e.key === 'ArrowDown') {
			e.preventDefault();
			if (!showDropdown) {
				setShowDropdown(true);
			} else {
				setHighlightedOption((highlightedOption + 1) % filteredOptions.length);
			}
		} else if (e.key === 'ArrowUp') {
			e.preventDefault();
			setHighlightedOption((highlightedOption - 1) % filteredOptions.length);
		} else if (e.key === 'Enter') {
			e.preventDefault();
			if (filteredOptions[highlightedOption]) {
				setInputValue(filteredOptions[highlightedOption].name);
				onDropdownChange(filteredOptions[highlightedOption])
			}
			setShowDropdown(false);
		} else if (e.key === 'Tab' && showDropdown && highlightedOption >= 0) {
			e.preventDefault();
			if (filteredOptions.length) {
				setInputValue(filteredOptions[highlightedOption].name);
				onDropdownChange(filteredOptions[highlightedOption]);
			}
			setShowDropdown(false);
		} else if (filteredOptions.length) {
			setHighlightedOption(0);
		}
	};

	const handleListKeyDown = (e) => {
		handleKeyDown(e);
	}

	const handleFocus = (e) => {
		e.target.removeAttribute('readonly');
		setShowDropdown(true);
		onFocus(e);
	};

	const handleBlur = (e) => {
		if (
			!!e.relatedTarget &&
			(e.relatedTarget.className?.toString().includes('typeaheadOption') ||
				e.relatedTarget.className?.baseVal?.toString().includes('fa-chevron'))
		) {
			e.preventDefault();
		} else {
			setShowDropdown(false);
			setInputValue('');
			setNoResults(false);
			setFilteredOptions(optionList);
		}

		onBlur(e);
	};

	const handleOptionClick = (e, option) => {
		setInputValue(option.name);
		setShowDropdown(false);
		onDropdownChange(option);
	};

	const handleInputClick = (e) => {
		if (document.activeElement === e.target && !showDropdown) {
			setShowDropdown(true);
			setHighlightedOption(null);
		}
	};

	const handleIconClick = (e) => {
		setShowDropdown(!showDropdown);
		setHighlightedOption(null);
		//set focus on input field to trigger phone keyboard
		inputRef.current.focus();

	};

	const handleIconBlur = (e) => {
		if (!e.relatedTarget?.className.toString().includes('typeaheadOption')) {
			setShowDropdown(false);
			setInputValue('');
			setNoResults(false);
			setFilteredOptions(optionList);
		}
	};

	return (
		<FieldControl
			disabled={disabled}
			error={error}
			hasValue={hasValue}
			isFocused={isFocused}
			id={id}
			inputType={inputType}
			isRequired={isRequired}
			isVisible={isVisible}
			label={label}
			labelAfter={labelAfter}
			size={size}
		>
			<div className="guidedResponseDropdown">
				<div>
					<input
						type="text"
						value={inputValue}
						error={error}
						onClick={handleInputClick}
						onChange={handleInputChange}
						onKeyDown={handleKeyDown}
						onFocus={handleFocus}
						onBlur={handleBlur}
						id={id}
						name={name}
						placeholder={defaultOptionLabel}
						style={{ flex: 0, padding: 0, margin: 0 }}
						ref={inputRef}
						autoComplete="off"
						readOnly
					/>
					<FontAwesomeIcon
						icon="chevron-down"
						style={{ cursor: 'pointer' }}
						onClick={handleIconClick}
						onBlur={handleIconBlur}
					/>
				</div>
				{showDropdown && (
					<ul className="typeaheadOptions"
						onBlur={() => setShowDropdown(false)}
						onKeyDown={handleListKeyDown}
					>
						{noResults && (<li key="noResult" className="typeaheadOption">No Results</li>)}
						{filteredOptions.length > 0 && (filteredOptions.map((option, index) => (
							<li
								key={[option.value, '-', option.name].join()}
								name={name}
								value={option.value}
								className={`typeaheadOption ${highlightedOption === index ? 'highlighted' : ''}`}
								onClick={(e) => {
									handleOptionClick(e, option);
								}}
								tabIndex="0"
							>
								{option.name}
							</li>
						)))}
					</ul>
				)}
			</div>
		</FieldControl>
	);
}
