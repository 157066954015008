import React from 'react';
import { connect } from 'react-redux';

import { clearError, clearAllErrors } from '../../actions/errorActions';

import { DEFAULT_ERROR_MESSAGE } from '../../constants/errorMessage';
import { DisclaimerHtml } from './miscComponents';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
export class ActiveErrors extends React.Component {
	dismiss = (e, actionId) => {
		e.preventDefault();
		this.props.dispatch(clearError(actionId));
	};

	dismissAll = () => {
		this.props.dispatch(clearAllErrors());
	};

	render() {
		let className = this.props.errorMessageOverride ? 'page-error' : 'page-error default';

		let effectiveErrorMessage = this.props.errorMessageOverride || DEFAULT_ERROR_MESSAGE;
		if (this.props.bookingErrorCorrelationKey)
			effectiveErrorMessage += ` [ERROR REF: ${this.props.bookingErrorCorrelationKey}]`;
		if (this.props.detailedErrors)
			return (
				this.props.activeErrors && this.props.activeErrors.length > 0 ? (
					this.props.activeErrors.map((currentError, i) =>
					<Alert key={i} color="danger" isOpen={true} toggle={(event) => this.dismiss(event, currentError.actionId)}>
						{currentError.error.detail}
					</Alert>
				)
				) : null);
		else
			return (
				this.props.activeErrors && this.props.activeErrors.length > 0 ? (
					<>
						<DisclaimerHtml
							className={className}
							text={effectiveErrorMessage}
							isVisible={true}
						/>
						{this.props.errorLink && 
							<div className={className}>
								<Link to={this.props.errorLink}>{this.props.errorLinkText}</Link>
							</div>
						}
					</>
				) :	null
			);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		activeErrors: state.apiErrors.activeErrors,
		bookingErrorCorrelationKey: state.apiErrors.bookingErrorCorrelationKey,
		errorMessageOverride: state.config.instance.errorMessageOverride,
	};
}

export default connect(mapStateToProps)(ActiveErrors);
