import { useSelector } from "react-redux";
import { selectAppliedFilters, selectCalendarById } from "../availabilitySlice";
import { ProviderBiographyModal } from "../../../components/provider/providerBiographyModal";
import { SiteNameAndDistance } from "../../../components/misc/siteNameAndDistance";
import { ProviderNameAndSpecialty } from "../../../components/provider/providerNameAndSpecialty";
import { ProviderSiteAddressAndPhone } from "../../../components/provider/providerSiteAddressAndPhone";
import { useState } from "react";

const SelectedCalendarDetails = ({ calendarId }) => {

    const [showBioModal, setShowBioModal] = useState(false);

    const appliedFilters = useSelector(selectAppliedFilters);
    const calendarDetails = useSelector(state => selectCalendarById(state, calendarId));

    const providerFieldConfig = useSelector(state => state.config.provider);

    const shouldShowProviderAddress = calendarDetails && !calendarDetails.suppressAddress;
    const shouldShowProviderPhone =
        providerFieldConfig.phone &&
        providerFieldConfig.phone.isVisible &&
        calendarDetails &&
        !calendarDetails.suppressTelephone;

    const showProviderDetailsDistance = useSelector(state => state.config.scheduling.showProviderDetailsDistance);

    return (
        <>
            <SiteNameAndDistance
                distanceMiles={calendarDetails?.distanceInMiles}
                providerSiteDisplayName={calendarDetails?.locationName}
                zipCode={appliedFilters.zipCode}
                showDistanceMiles={showProviderDetailsDistance}
            />
            <div className="provider-details-container">
                <div className="provider-image-container">
                    <img
                        src={calendarDetails?.imageFileName || '/resources/generic-provider-photo.jpg'}
                        alt={calendarDetails?.displayName + ' Provider'}
                    />
                </div>
                <div>
                    <ProviderNameAndSpecialty
                        isProviderClickable={false}
                        providerDisplayName={calendarDetails?.displayName}
                        providerSpecialty={calendarDetails?.specialtyName}
                        serviceSiteId={calendarDetails?.id}
                        showBiographyLink={!!calendarDetails?.bio}
                        toggleBioModal={() => setShowBioModal(x => !x)}
                    />
                    <ProviderSiteAddressAndPhone
                        city={calendarDetails?.address?.cityName}
                        phoneLabel={providerFieldConfig.phone.fieldLabel}
                        phoneNumber={calendarDetails?.telephone}
                        providerAddressLine1={calendarDetails?.address?.addressLine1}
                        providerAddressLine2={calendarDetails?.address?.addressLine2}
                        providerSiteDisplayName={calendarDetails?.locationName}
                        showAddress={shouldShowProviderAddress}
                        showPhone={shouldShowProviderPhone}
                        state={calendarDetails?.address?.stateCode}
                        zipCode={calendarDetails?.address?.zipCode}
                    />
                </div>
            </div>
            {showBioModal &&
                <ProviderBiographyModal
                    serviceBio={calendarDetails?.bio}
                    toggleModal={() => setShowBioModal(x => !x)}
                />
            }
        </>
    )
}

export default SelectedCalendarDetails;