import React from 'react';
import { connect } from 'react-redux';

export class SMSTextingPrivacyPolicyView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: '',
		};
	}

	render() {
		return (
			<>
				<h2>Privacy Policy</h2>
				<br />
				<div>
					<p>
						Experian Health’s Patient Schedule SMS Texting Service (the “Service”) is a text message service that
						enables patients of Experian Health’s ("Experian Health", "we", "us", "our") customers to send and receive
						communications via short message service ("SMS") relating to healthcare appointments. We respect your
						privacy and are committed to protecting it through our compliance with this privacy policy ("Privacy
						Policy").
					</p>
					<p>
						This Privacy Policy is entered into by and between you and Experian Health. This Privacy Policy describes
						the information we collect from you or that you may provide when you use the Service, and our practices for
						collecting, using, maintaining, protecting, and disclosing that information.
					</p>
				</div>
				<br />
				<h3>Acceptance of the Privacy Policy and Terms of Use</h3>
				<br />
				<div>
					<p>
						This Privacy Policy is effective with respect to any data that we have collected, or collect, about and/or
						from you, according to our terms of use, found at&nbsp;
						<a href="SMSTextingTermsOfService">SMS Texting Terms of Service</a>, which are incorporated herein by
						reference. Please read this Privacy Policy carefully before you use the Services. By using the Service you
						accept and agree to be bound and abide by this Privacy Policy. If you do not want to agree to the Terms of
						Use or this Privacy Policy, you must not access or use the Services.
					</p>
				</div>
				<br />
				<h3>Contact Information</h3>
				<br />
				<div>
					<p>
						If you have any questions or comments or you have a concern about the way in which we have handled any
						privacy matter, Please contact us at (629) 777-5552.
					</p>
				</div>
				<br />
				<h3>No Collection of Personal Information</h3>
				<br />
				<div>
					<p>
						We do not knowingly collect any personally identifiable information about you through your use of the SMS
						text messaging service. We may collect information from you through our Websites, which are governed by
						applicable privacy policies appearing on these sites. We reserve the right to delete any information we
						believe to be in violation of this Privacy Policy.
					</p>
				</div>
				<br />
				<h3>Security</h3>
				<br />
				<div>
					<p>
						If a security breach causes an unauthorized intrusion into our system that materially affects you, then we
						will promptly notify you in accordance with applicable legal requirements.
					</p>
				</div>
				<br />
				<h3>Other Web Sites; Links</h3>
				<br />
				<div>
					<p>
						Our Service or Website links may contain links to other websites. We are not responsible for the privacy
						practices or the content of such websites. Third parties may independently collect information about you
						when you view communications from third parties through our products or services. If you do not want those
						companies to collect information about you, please contact them directly.
					</p>
				</div>
				<br />
				<h3>No Liability</h3>
				<br />
				<div>
					<p>
						You understand that electronic communications may be accessed by unauthorized parties when communicated
						across the Internet, network communications facilities, or other electronic means. You agree that we are not
						responsible for any electronic communication and/or any of your data which may be lost, altered, intercepted
						or stored without authorization during the transmission of any data whatsoever across networks not owned
						and/or operated by us. We do not guarantee that your information will not be misused or disclosed to third
						parties. We will not have any liability to you for any such misuse or disclosure.
					</p>
					<p>
						Except as required or allowed by law, we will not use or disclose your information for any purpose for which
						you refuse us consent or later withdraw your consent. If you withdraw consent, you agree that in spite of
						this withdrawal we may continue to use the information previously provided to us to the extent that we are
						legally or contractually obligated to do so and to the extent necessary to enforce any contractual
						obligations you may have to us.
					</p>
				</div>
				<br />
				<h3>Changes to this Privacy Policy</h3>
				<br />
				<div>
					<p>
						We reserve the right to make changes to this Privacy Policy and to our information gathering and
						dissemination practices. If we decide to change this Privacy Policy, which we may do at any time without
						notice to you, we will post those changes. We suggest that you periodically check the site for changes to
						this Privacy Policy.
					</p>
				</div>
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		correlationKey: state.session.correlationKey,
		isLoading: state.ajaxCallsInProgress > 0,
		token: state.auth.token,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {},
		dispatch,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SMSTextingPrivacyPolicyView);
