import React from 'react';
import { DEFAULT_ERROR_MESSAGE } from '../../constants/errorMessage';

export default function MhdErrorDisplay(props) {
	const effectiveErrorMessage = props.errorMessageOverride || DEFAULT_ERROR_MESSAGE;

	return (
		<div className="error-display alert alert-danger" dangerouslySetInnerHTML={{ __html: effectiveErrorMessage }} />
	);
}
