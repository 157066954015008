import React from 'react';
import * as FieldControl from '../input';
//import DropdownWithServerTypeahead from '../input/dropdownWithServerTypeahead';
import { CollapsablePanelWithSectionHeader, DisclaimerHtml } from './miscComponents';

function getErrorMessage(config) {
	// this function exist just to change the error message wording between "select", "enter" or "select or enter"
	let errorMessage;

	if (config.allowInternalReferringProviders && config.allowExternalReferringProviders) {
		errorMessage = 'Please select or enter a referring provider';
	} else if (
		config.allowInternalReferringProviders ||
		(config.allowOrgReferringProviders && !config.enableServerTypeAhead)
	)
		errorMessage = 'Please select a referring provider';
	else if (
		config.allowExternalReferringProviders ||
		(config.allowOrgReferringProviders && config.enableServerTypeAhead)
	)
		errorMessage = 'Please enter a referring provider';
	else errorMessage = '';
	return errorMessage;
}

function buildReferringProviderOptionItem(item, isOrgProvider) {
	return { id: isOrgProvider ? item.organizationProviderId : item.serviceId, name: item.displayName };
}

export default function ReferringProviderSection(props) {
	const {
		enableSectionValidationIcons,
		enableServerTypeAhead,
		handleInputBlur,
		handleInputChange,
		handleInputFocus,
		handleOrgReferringProviderChange,
		handleOrgProviderServerTypeAheadSearch,
		handleToggleCollapse,
		hasErrors,
		isCollapsed,
		name,
		referringProvidersList,
		orgReferringProvidersList,
		referringProviderFieldsInfo,
		sectionConfig,
		allowInternalReferringProviders,
		allowExternalReferringProviders,
		allowOrgReferringProviders,
		shouldShowSectionValidation,
		isServerTypeAheadLoading,
		serverTypeAheadMinimumCharacters,
		setOrgProviderIdFromTypeahead,
		setReferringProviderIdFromTypeahead,
		handleReferringProviderServerTypeAheadSearch
	} = props;

	const { headerText, isCollapsable, showHeader } = sectionConfig;
	const { internalReferringProvider, externalReferringProvider, orgReferringProvider } = referringProviderFieldsInfo;

	const referringProvidersOptionList = referringProvidersList.map((item) => buildReferringProviderOptionItem(item));
	const orgReferringProvidersOptionList = orgReferringProvidersList.map((item) =>
		buildReferringProviderOptionItem(item, true),
	);

	return (
		<CollapsablePanelWithSectionHeader
			className="section-sub"
			enableSectionValidationIcons={enableSectionValidationIcons}
			hasErrors={hasErrors}
			isCollapsed={isCollapsed}
			isCollapsable={isCollapsable}
			label={headerText}
			name={name}
			onToggleCollapse={handleToggleCollapse}
			shouldShowSectionValidation={shouldShowSectionValidation}
			showHeader={showHeader}
		>
			<div className="section-row">
				<DisclaimerHtml
					className="form-instructions"
					isVisible={!!sectionConfig.referringProviderConfigInstructions}
					text={sectionConfig.referringProviderConfigInstructions}
				/>
			</div>
			{allowInternalReferringProviders && !enableServerTypeAhead && (
				<div className="section-row">
					<FieldControl.DropdownSelect
						{...internalReferringProvider}
						onChange={handleInputChange}
						onBlur={handleInputBlur}
						onFocus={handleInputFocus}
						optionList={referringProvidersOptionList}
					/>
				</div>
			)}
			{allowInternalReferringProviders && enableServerTypeAhead && (
				<div className="section-row">
					<FieldControl.DropdownWithServerTypeahead
						{...internalReferringProvider}
						id="internalReferringProviderId"
						name="internalReferringProviderId"
						isLoading={isServerTypeAheadLoading}
						labelKey="displayName"
						minLength={serverTypeAheadMinimumCharacters}
						onChange={setReferringProviderIdFromTypeahead}
						onSearch={handleReferringProviderServerTypeAheadSearch}
						options={referringProvidersList}
						placeholder={internalReferringProvider.label}
					/>
				</div>
			)}
			{allowOrgReferringProviders && !enableServerTypeAhead && (
				<div className="section-row">
					<FieldControl.DropdownSelect
						{...orgReferringProvider}
						onChange={handleOrgReferringProviderChange}
						onBlur={handleInputBlur}
						onFocus={handleInputFocus}
						optionList={orgReferringProvidersOptionList}
					/>
				</div>
			)}
			{allowOrgReferringProviders && enableServerTypeAhead && (
				<div className="section-row">
					<FieldControl.DropdownWithServerTypeahead
						{...orgReferringProvider}
						id="referringOrganizationProviderId"
						name="referringOrganizationProviderId"
						isLoading={isServerTypeAheadLoading}
						labelKey="displayName"
						minLength={serverTypeAheadMinimumCharacters}
						onChange={setOrgProviderIdFromTypeahead}
						onSearch={handleOrgProviderServerTypeAheadSearch}
						options={orgReferringProvidersList}
						placeholder={orgReferringProvider.label}
					/>
				</div>
			)}
			{allowExternalReferringProviders && (
				<div className="section-row">
					<FieldControl.TextInput
						{...externalReferringProvider}
						onChange={handleInputChange}
						onBlur={handleInputBlur}
						onFocus={handleInputFocus}
					/>
				</div>
			)}
			{hasErrors && (
				<div>
					<span className="error">{getErrorMessage(sectionConfig)}</span>
				</div>
			)}
		</CollapsablePanelWithSectionHeader>
	);
}
