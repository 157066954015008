import * as actionTypes from './actionTypes';
import config from '../config';
import { APPOINTMENT_CRITERIA } from '../constants/appointmentCriteria';

export function bookAppointmentWithNewPatient(token, details, savePatientCriteria) {
	return {
		type: actionTypes.APPOINTMENT_BOOK_NEW_PATIENT,
		payload: {
			request: {
				method: 'post',
				url: 'appointment/bookWithNewPatient',
				data: {
					bookAppointmentCriteria: {
						appointmentAttachments: [],
						appointmentTypeId: details.appointmentTypeId,
						decisionSupportSessionId: details.decisionSupportSessionId,
						externalReferralOrderId: details.externalReferralOrderId,
						careOrderVisitIdentifier: details.careOrderVisitIdentifier,
						clientIdentifier: details.clientIdentifier,
						confirmationEmail: details.confirmationEmail,
						correlationKey: details.correlationKey,
						customFieldValues: details.customFieldValues,
						emailConfirmation: details.emailConfirmation,
						emailReminder: details.emailReminder,
						emailRemindersHoursBefore: details.emailRemindersHoursBefore,
						homePhoneCallReminder: details.homePhoneCallReminder,
						homePhoneCallReminderNumber: details.homePhoneCallReminderNumber,
						homePhoneRemindersHoursBefore: details.homePhoneRemindersHoursBefore,
						idInsurance: details.idInsurance,
						idInsuranceState: details.idInsuranceState,
						idLanguage: details.idLanguage,
						idPayorType: details.idPayorType,
						idServiceSite: details.idServiceSite,
						idSlot: details.idSlot,
						languageCode: details.idLanguage === config.notificationLanguages.spanish ? 'ES' : 'EN',
						managedReferringServiceId: details.managedReferringServiceId,
						mobilePhoneCallReminder: details.mobilePhoneCallReminder,
						mobilePhoneCallReminderNumber: details.mobilePhoneCallReminderNumber,
						mobilePhoneCallRemindersHoursBefore: details.mobilePhoneCallRemindersHoursBefore,
						mobilePhoneTextReminder: details.mobilePhoneTextReminder,
						mobilePhoneTextReminderNumber: details.mobilePhoneTextReminderNumber,
						mobilePhoneTextRemindersHoursBefore: details.mobilePhoneTextRemindersHoursBefore,
						reasonForAppointment: details.reasonForAppointment,
						referringOrganizationProviderId: details.referringOrganizationProviderId,
						reminderEmail: details.reminderEmail,
						reservationId: details.reservationId,
						slotEndDateTime: details.slotEndDateTime,
						slotStartDateTime: details.slotStartDateTime,
						thirdPartyEmail: details.thirdPartyEmail,
						thirdPartyEmailConfirmation: details.thirdPartyEmailConfirmation,
						unmanagedReferringServiceName: details.unmanagedReferringServiceName,
						workboxId: details.workboxId,
						productInstanceConsumerId: details.productInstanceConsumerId,
						hasRequestedWaitlist: details.hasRequestedWaitlist,
					},
					savePatientCriteria: {
						firstName: savePatientCriteria.firstName,
						middleName: savePatientCriteria.middleName,
						lastName: savePatientCriteria.lastName,
						addressLine1: savePatientCriteria.addressLine1,
						addressLine2: savePatientCriteria.addressLine2,
						cityName: savePatientCriteria.cityName,
						stateId: savePatientCriteria.stateId,
						zipCode: savePatientCriteria.zipCode,
						gender: savePatientCriteria.gender,
						dateOfBirth: savePatientCriteria.dateOfBirth,
						homePhone: savePatientCriteria.homePhone,
						mobilePhone: savePatientCriteria.mobilePhone,
						alternatePhone: savePatientCriteria.alternatePhone,
						email: savePatientCriteria.email,
						memberId: savePatientCriteria.memberId,
						groupNumber: savePatientCriteria.groupNumber,
						guardianName: savePatientCriteria.guardianName,
						referenceId: savePatientCriteria.referenceId,
					},
				},
				headers: {
					token: token,
				},
			},
		},
	};
}

export function cancelAppointment(token, referenceId, cancelRequest) {
	return {
		type: actionTypes.APPOINTMENT_CANCEL,
		payload: {
			request: {
				method: 'post',
				url: `appointment/${referenceId}/cancelbyid`,
				headers: {
					token: token,
				},
				data: {
					reasonForCancellation: cancelRequest.reasonForCancellation,
					sendCancellationEmailToPatient: cancelRequest.sendCancellationEmailToPatient,
					email: cancelRequest.email,
					languageCode: 'EN',
				},
			},
		},
	};
}

export function cancelReservation(token, reservationId) {
	return {
		type: actionTypes.APPOINTMENT_RESERVATION_CANCEL,
		payload: {
			request: {
				method: 'get',
				url: `appointment/reservation/${reservationId}/cancel/`,
				headers: {
					token: token,
				},
				data: {},
			},
		},
	};
}

export function getAppointmentById(referenceId) {
	return {
		type: actionTypes.APPOINTMENT_DETAILS,
		payload: {
			request: {
				method: 'get',
				url: `appointment/${referenceId}/details`,
			},
		},
	};
}

export function getBookingTransitionDetails(bookingTransitionId) {
	return {
		type: actionTypes.GET_BOOKING_TRANSITION_DETAILS,
		payload: {
			request: {
				method: 'get',
				url: `bookingtransition/${bookingTransitionId}/details`,
			},
		},
	};
}

export function getCustomFields(referralSystemId) {
	return {
		type: actionTypes.CUSTOM_FIELDS,
		payload: {
			request: {
				method: 'get',
				url: `appointment/customfields/${referralSystemId}/`,
			},
		},
	};
}

export function getPatientIcsFile(referenceId, token, idProductInstance) {
	return {
		type: actionTypes.APPOINTMENT_PATIENT_ICS,
		payload: {
			request: {
				method: 'get',
				url: `appointment/${referenceId}/patientics/?productInstanceId=${idProductInstance}`,
			},
		},
		headers: {
			token: token,
		},
	};
}

export function getReferringProviders(systemId) {
	return {
		type: actionTypes.GET_REFERRING_PROVIDERS,
		payload: {
			request: {
				method: 'get',
				url: `system/${systemId}/referringProviders`,
			},
		},
	};
}

export function searchReferringProviders(systemId, query) {
	return {
		type: actionTypes.GET_REFERRING_PROVIDERS,
		payload: {
			request: {
				method: 'get',
				url: `system/${systemId}/referringProviders/${query}`,
			},
		},
	};
}

export function getOrgReferringProviders() {
	return {
		type: actionTypes.GET_ORG_REFERRING_PROVIDERS,
		payload: {
			request: {
				method: 'get',
				url: 'organizationReferringProviders',
			},
		},
	};
}

export function searchOrgProviders(query) {
	return {
		type: actionTypes.GET_ORG_REFERRING_PROVIDERS,
		payload: {
			request: {
				method: 'get',
				url: `organizationReferringProviders/${query}`,
			},
		},
	};
}

export function getReservationDetails(fce, serviceSiteId, insuranceProvider, payorType, patientReferenceId) {
	return {
		type: actionTypes.APPOINTMENT_RESERVE_DETAILS,
		payload: {
			request: {
				method: 'post',
				url: `appointment/reservation/details`,
				data: {
					flexCalendarEntryId: fce,
					serviceSiteId: serviceSiteId,
					insuranceProviderId: insuranceProvider,
					payorTypeId: payorType,
					patientReferenceId: patientReferenceId,
				},
			},
		},
	};
}

export function reserveAppointment(timeSlotInfo, reservationDuration, correlationKey) {
	return {
		type: actionTypes.APPOINTMENT_RESERVE,
		payload: {
			request: {
				method: 'post',
				url: 'appointment/reserve',
				data: {
					correlationKey: correlationKey,
					reservationDuration: reservationDuration,
					timeSlotRequestInfo: timeSlotInfo,
				},
			},
		},
	};
}

export function setInitialAppointmentDetails(careOrderAppointmentDetails, decisionSupportAppointmentDetails) {
	return function (dispatch) {
		dispatch(setInitialAppointmentDetailsAction(careOrderAppointmentDetails, decisionSupportAppointmentDetails));
		return Promise.resolve();
	};
}

function setInitialAppointmentDetailsAction(careOrderAppointmentDetails, decisionSupportAppointmentDetails) {
	let result = {
		type: actionTypes.SET_INITIAL_APPOINTMENT_DETAILS,
		externalReferralOrderId:
			decisionSupportAppointmentDetails[APPOINTMENT_CRITERIA.externalReferralOrderId] ||
			careOrderAppointmentDetails[APPOINTMENT_CRITERIA.externalReferralOrderId] ||
			null,
		symptomDetails: {
			reasonForVisit:
				decisionSupportAppointmentDetails[APPOINTMENT_CRITERIA.reasonForVisit] ||
				careOrderAppointmentDetails[APPOINTMENT_CRITERIA.reasonForVisit] ||
				'',
		},
	};

	return result;
}

export function searchAddress(query) {
	return {
		type: actionTypes.APPOINTMENT_SEARCH_ADDRESS,
		payload: {
			request: {
				method: 'post',
				url: 'appointment/address/search',
				data: { query }
			}
		}
	}
}
export function getAddress(addressSearchCriteria) {
	return {
		type: actionTypes.APPOINTMENT_SEARCH_ADDRESS,
		payload: {
			request: {
				method: 'post',
				url: 'appointment/address/get_address',
				data: addressSearchCriteria
			},
		},
	};
}
