import { useDispatch, useSelector } from 'react-redux';
import { decisionSupport as routeToDecisionSupport } from '../../../routes';
import * as Icon from '../../../components/icons';

const BackToGuidedResponseButton = () => {
    const dispatch = useDispatch();
    const routePrefix = useSelector(state => state.config.instance.routePrefix);

    const handleGoBackToGuidedResponse = () => {
        dispatch(routeToDecisionSupport(routePrefix));
    };

    return (
        <button className="back-to-questions" aria-label="Back to Questions" onClick={handleGoBackToGuidedResponse}>
            <span><Icon.IconChevronLeft className="left-arrow-icon" size={"sm"} />Back to Questions</span>
        </button>
    );
};

export default BackToGuidedResponseButton;