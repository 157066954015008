import React from 'react';

import { Alert } from 'reactstrap';
import ActiveErrors from '../misc/activeErrors';
import FormElement from './guidedResponseFormElement';
import InputForm from './guidedResponseInputForm';
import Kickout from './guidedResponseMessage';
import QuestionForm from './guidedResponseQuestionForm';
import { GuidedResponseResults } from './guidedResponseResults';

export function GuidedResponseWorkflow({
	questions,
	isLoading,
	isFlowAtStart,
	isResumingFlow,
	chainedFromAp,
	debugMode,
	className,
	flowSessionResponse,
	handlers: { onChange, onDropdownChange, inputBlur, inputFocus, start, next, previous, complete, getFlowDebugPathFile },
}) {
	const {
		finishedDueToKickout,
		kickoutMessage,
		elementSet,
		hasValidationErrors,
		validationMessages,
		isFlowFinished,
		results,
		supportedFlowInputs,
		isFlowAtInputStage,
		flowSessionId
	} = flowSessionResponse || {};

	let component;

	if (flowSessionResponse === null) {
		component = null;
	} else if (isFlowAtInputStage && supportedFlowInputs?.length > 0) {
		component = <InputForm flowInputs={supportedFlowInputs} handler={start} isLoading={isLoading} />;
	} else if (finishedDueToKickout) {
		component = (
			<FormElement>
				<Kickout 
					className="kickout-message" 
					message={kickoutMessage} 
					enablePreviousButton={flowSessionResponse?.enablePreviousButton}
					handlePrevious={previous}
					isLoading={isLoading}
					debugMode={debugMode}
					getFlowDebugPathFile={getFlowDebugPathFile}
				/>
			</FormElement>
		);
	} else if (isFlowFinished && !finishedDueToKickout) {
		component = (
			<GuidedResponseResults
				debugMode={debugMode}
				isLoading={isLoading}
				isResumingFlow={isResumingFlow}
				results={results}
				nextHandler={complete}
				previousHandler={previous}
				getFlowDebugPathFile={getFlowDebugPathFile}
				flowSessionId={flowSessionId}
			/>
		);
	} else {
		component = (
			<QuestionForm
				elementSet={elementSet}
				questions={questions}
				isLoading={isLoading}
				chainedFromAp={chainedFromAp}
				isFlowAtStart={isFlowAtStart}
				flowSessionId={flowSessionId}
				handlers={{
					onChange: onChange,
					onDropdownChange: onDropdownChange,
					inputBlur: inputBlur,
					inputFocus: inputFocus,
					next: next,
					previous: previous,
					getFlowDebugPathFile: getFlowDebugPathFile
				}}
				debuggerEnabled={debugMode}
			/>
		);
	}

	return (
		<>
			<div className={className}>
				<ActiveErrors />
				{flowSessionResponse && (
					<div className={'guided-response main-content'}>
						{hasValidationErrors && (
							<Alert color="danger">
								<ul>
								{validationMessages.map((message, index) => <li key={`${message}--${index}`}>{message}</li>)}
								</ul>
							</Alert>
						)}
						{component}
					</div>
				)}
			</div>
		</>
	);
}
