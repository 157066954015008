import React, { useEffect, useState } from "react";
import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from "react-router-dom";

export function tagManagerTracker(WrappedComponent, options) {
	const initializeTracker = googleTagManagerId => {
		const tagManagerArgs = {
			gtmId: googleTagManagerId,
			dataLayerName: 'PageDataLayer'
		};
		TagManager.initialize(tagManagerArgs);
	}

	const trackPage = page => {
		const tagManagerArgs = {
			dataLayer: {
				event: 'trackPage',
				page: page,
			},
			dataLayerName: 'PageDataLayer'
		}
		TagManager.dataLayer(tagManagerArgs);
	};

	const HOC = props => {
		const location = useLocation();
		const [trackerInitialized, setTrackerInitialized] = useState(false);

		useEffect(() => {
			if (props.googleTagManagerId && props.googleTagManagerId.trim() !== "" && !trackerInitialized) {
				initializeTracker(props.googleTagManagerId);
				setTrackerInitialized(true);
			}
		}, [props.googleTagManagerId, trackerInitialized, setTrackerInitialized]);

		useEffect(() => {
			if (trackerInitialized) {
				let page = window.location.origin + location.pathname + location.search;
				trackPage(page);
			}
		}, [trackerInitialized, location]);

		return <WrappedComponent {...props} />;
	};

	const mapDispatchToProps = (dispatch) => bindActionCreators({

	}, dispatch);

	const mapStateToProps = (state, ownProps) => {
		return {
			googleTagManagerId: state.config.analytics.googleTagManagerId,
		};
	}

	return connect(mapStateToProps, mapDispatchToProps)(HOC);
};

export default tagManagerTracker;