import React from 'react';

export default function AdditionalInformationDetails(props) {
	const { appointments, labels, isCareOrder } = props;

	const AdditionalInformation = () => {
		return (
			<React.Fragment>
				{appointments && appointments.map(function (appointment, index) {
					let serviceName = formatServiceName(appointment.firstName, appointment.lastName, appointment.assetTag);
					if (appointment.informationForPatient !== '') {
						return (
							<div className="section-body" key={index}>
								{appointments.length > 1 && <h5 className="">{displayProviderNameWithProfessionalDesignation(serviceName, appointment.providerProfessionalDesignation)}</h5>}
								<div className="" dangerouslySetInnerHTML={{
									__html: appointment.informationForPatient
								}} />
							</div>
						)
					} else return null;
				})}
			</React.Fragment>
		)
	}

	const PreVisitInformationForPatient = () => {
		return (
			<React.Fragment>
				{appointments.some(r => r.appointmentTypeInformationForPatient && r.appointmentTypeInformationForPatient.trim() !== "") && 
					<h2 className="section-header">Pre-visit Information for Patient</h2>
				}
				{appointments && appointments.map(function (appointment, index) {
					return (
						<div className="section-body" key={index}>
							{appointment.appointmentTypeInformationForPatient && appointment.appointmentTypeInformationForPatient.trim() !== '' && appointments.length > 1 &&
								<div className="additional-information-text appointment-type-info-for-patient" dangerouslySetInnerHTML={{
									__html: `${appointment.appointmentTypeName}: ${appointment.appointmentTypeInformationForPatient}`
									}} />
							}
							{appointment.appointmentTypeInformationForPatient && appointment.appointmentTypeInformationForPatient.trim() !== '' && appointments.length === 1 &&
								<div className="additional-information-text" dangerouslySetInnerHTML={{
									__html: appointment.appointmentTypeInformationForPatient
								}} />
							}
						</div>
					)
				})}
			</React.Fragment>
		)
	}

	const CareOrderPreVisitInformationForPatient = () => {
		return (
			<React.Fragment>
				{appointments.some(r => r.appointmentDetails.informationForPatient && r.appointmentDetails.informationForPatient.trim() !== '') &&
					<h2 className="section-header">Pre-visit Information for Patient</h2>
				}
				{appointments && appointments.map(function (appointment, index) {
					return (
						<div className="section-body" key={index}>
							{appointment.appointmentDetails.informationForPatient && appointment.appointmentDetails.informationForPatient.trim() !== '' && appointments.length > 1 &&
								<div className="additional-information-text appointment-type-info-for-patient" dangerouslySetInnerHTML={{
									__html: `${appointment.appointmentDetails.appointmentTypeName}: ${appointment.appointmentDetails.informationForPatient}`
									}} />
							}
							{appointment.appointmentDetails.informationForPatient && appointment.appointmentDetails.informationForPatient.trim() !== '' && appointments.length === 1 &&
								<div className="additional-information-text" dangerouslySetInnerHTML={{
									__html: appointment.appointmentDetails.informationForPatient
								}} />
							}
						</div>
					)
				})}
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			<div className="content-section additional-information">
				{labels.additionalInformationLabel.fieldLabel ?
					<div className='section-header' dangerouslySetInnerHTML={{
						__html: labels.additionalInformationLabel.fieldLabel
					}} />
					:
					<div className='section-header'>
						<h4 className='additional-info-title'>Additional Information About Your Appointments:</h4>
					</div>
				}
				<AdditionalInformation />
			</div>
			<div className="content-section pre-visit-information">
				{!isCareOrder ? <PreVisitInformationForPatient /> : <CareOrderPreVisitInformationForPatient />}
			</div>
		</React.Fragment>
	)
}

function formatServiceName(firstName, lastName, assetTag) {
	let fname = firstName ? firstName.trim() : "";
	let lname = lastName ? lastName.trim() : "";
	let fullName = "";
	if (assetTag && assetTag.trim() !== "") {
		fullName = assetTag;
	} else {
		fullName = `${fname} ${lname}`;
	}
	return fullName;
}

function displayProviderNameWithProfessionalDesignation(providerName, professionalDesignation) {
	let fullName = `${providerName}`;
	if (professionalDesignation && professionalDesignation.trim() !== '') {
		fullName += `, ${professionalDesignation}`;
	}
	return fullName;
}
