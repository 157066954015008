import React from 'react';

export function ReasonForVisitDetails(props) {
	const { label, reasonForVisit } = props;
	return (
		<div className="reason-for-visit-details-section">
			<div className="reason-for-visit-details-label">{label}</div>
			<div className="reason-for-visit-details">{reasonForVisit}</div>
		</div>
	);
}
