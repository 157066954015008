const FIELD_ID_SEPERATOR = '__';

export const buildFieldRefFromId = (fieldId) => {
	let splitId = fieldId.split(FIELD_ID_SEPERATOR);
	return {
		fieldId: fieldId,
		fieldGroupName: splitId[0],
		fieldName: splitId[1],
	}
}

export const buildFieldRefFromParts = (fieldGroupName, fieldName) => {
	return {
		fieldId: concatFieldId(fieldGroupName, fieldName),
		fieldGroupName: fieldGroupName,
		fieldName: fieldName,
	}
}

export const concatFieldId = (fieldGroupName, fieldName) => {
	return `${fieldGroupName}${FIELD_ID_SEPERATOR}${fieldName}`;
}

export const filterArray = (newValue, currValue, isMultiSelect, isInteger) => {
	if (isInteger) newValue = parseInt(newValue);

	if (isMultiSelect) {
		const index = getValuesIndex(newValue, currValue);
		const isInArray = index > -1;

		if (isInArray) {
			currValue.splice(index, 1);
		} else {
			currValue.push(newValue);
		}
	} else {
		if (newValue === currValue) {
			currValue = null;
		} else {
			currValue = newValue;
		}
	}

	return currValue;
};

const getValuesIndex = (value, array) => {
	let index = array.findIndex((x) => {
		return x === value;
	});
	return index;
};