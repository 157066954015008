import { changeViewDateThunk, selectPreferredDisplayedCalendarIds } from "../availabilitySlice";
import { useDispatch, useSelector } from "react-redux";
import { selectNotPreferredDisplayedCalendarIds, selectNotPreferredFirstScanFirstAvailabilityDate, selectViewBaseDate } from "../availabilitySlice/availabilitySelectors";
import dayjs from "dayjs";
import DateNav from "./dateNav";
import ResultRow from "./resultRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

const Results = ({ isMobile, selectedSlot, setSelectedSlot }) => {
    const preferredDisplayedCalendarIds = useSelector(selectPreferredDisplayedCalendarIds);
    const notPreferredDisplayedCalendarIds = useSelector(selectNotPreferredDisplayedCalendarIds);

    const viewBaseDate = useSelector(selectViewBaseDate);
    const notPreferredFirstScanFirstAvailabilityDate = useSelector(selectNotPreferredFirstScanFirstAvailabilityDate);

    const hasEarlierThanDisplayedAvailability = notPreferredFirstScanFirstAvailabilityDate
        && dayjs(notPreferredFirstScanFirstAvailabilityDate).diff(viewBaseDate, 'd') < 0;


    return (
        <>
            {notPreferredDisplayedCalendarIds?.length > 0 &&
                <>
                    {preferredDisplayedCalendarIds.length > 0 &&
                        <div className="availability-results-provider-header">
                            <h5>Additional Recommended Providers</h5>
                            {hasEarlierThanDisplayedAvailability &&
                                <HasEarlierAvailabilityIndicator
                                    date={notPreferredFirstScanFirstAvailabilityDate}
                                    isMobile={isMobile}
                                />
                            }
                        </div>
                    }
                    {!isMobile &&
                        <DateNav />
                    }
                    <div className="availability-results-providers">
                        {notPreferredDisplayedCalendarIds.map((calendarId, index, array) =>
                            <ResultRow
                                key={calendarId}
                                calendarId={calendarId}
                                selectedSlot={selectedSlot}
                                setSelectedSlot={setSelectedSlot}
                                isMobile={isMobile}
                            />
                        )}
                    </div>

                </>
            }
        </>
    )
}

const HasEarlierAvailabilityIndicator = ({ date, isMobile }) => {

    const dispatch = useDispatch();

    const commonStyle = {
        marginBottom: "8px",
        fontWeight: "inherit",
        textDecoration: "none",
        fontSize: "13px",
        color: "#ffffff",
        backgroundColor: "var(--primary-button-background-color)",
        border: "none"
    }

    const style = isMobile
        ? {
            ...commonStyle
        }
        : {
            ...commonStyle
        };

    return (
        <button
            style={style}
            className="first-available-button"
            onClick={() => dispatch(changeViewDateThunk(date, true))}
        >
            <FontAwesomeIcon
                icon={faCalendarCheck}
                // bounce
                size="lg"
                style={{
                    color: "#ffffff",
                    marginRight: "6px"
                }}
            />
            Earlier Appointments Available on
            &nbsp;
            <b >
                {dayjs(date).format("MMM D YYYY")}
            </b>
        </button>
    )
}

export default Results;