import React from 'react';
import { AVAILABILITY_HEADER_VARIABLES } from '../../constants/availabilityHeaderVariables';

function formatHeader(activeCareOrderDetails, headerText) {
	let result = headerText;

	AVAILABILITY_HEADER_VARIABLES.forEach(function (variable) {
		switch (variable) {
			case '{appointmentType}':
				if (result.includes(variable) && activeCareOrderDetails.appointments && activeCareOrderDetails.appointments.length === 1) {
					result = result.replace('{appointmentType}', activeCareOrderDetails.appointments[0].appointmentTypeName);
				}
				break;
			default:
				return;
		}
	});
	return result;
}

export function AvailabilityResultsHeader(props) {
	const { activeCareOrderDetails, headerText } = props;

	let header = headerText ? formatHeader(activeCareOrderDetails, headerText) : null;

	if (header)
		return (
			<div className="availability-results-header">
				<h5>
					<p dangerouslySetInnerHTML={{ __html: header }}></p>
				</h5>
			</div>
		);
	else return null;
}
