import React from 'react';
import { GetAppointmentDate, GetAppointmentTime } from '../../lib/dateUtils';

export function AppointmentDateTime(props) {
	const { appointmentDateTime, arrivalTime, showArrivalTime } = props;

	const appointmentDateDisplay = GetAppointmentDate(appointmentDateTime);
	const appointmentTimeDisplay = GetAppointmentTime(appointmentDateTime, showArrivalTime, false);
	const arrivalTimeDisplay = GetAppointmentTime(arrivalTime, showArrivalTime, true);

	return (
		<div className="appointment-date-time">
			<time dateTime={appointmentDateTime}>{appointmentDateDisplay}</time>
			<time dateTime={appointmentDateTime}>{appointmentTimeDisplay}</time>
			{showArrivalTime && <time dateTime={appointmentDateTime}>{arrivalTimeDisplay}</time>}
		</div>
	);
}
