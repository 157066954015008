import React from 'react';
import * as Button from '../buttons';
import * as Icon from '../icons';
import { createUltimatePagination } from 'react-ultimate-pagination';

// PAGE NAV
export function AvailabilityResultsPaginationNavigation(props) {
	const { handleGoToPage, isMobile, numberOfPages, currentPage } = props;

	const Page = ({ value, isActive, disabled, onClick }) => (
		<Button.ButtonCircle
			isSelected={currentPage === value ? true : false}
			style={isActive ? { fontWeight: 'bold' } : null}
			onClick={(e) => handleGoToPage(parseInt(e.target.textContent))}
			disabled={disabled}
			value={value}
		>
			{value}
		</Button.ButtonCircle>
	);

	function Ellipsis(props) {
		return (
			<Button.ButtonCircle className="ellipse-button" onClick={props.onClick} disabled={true}>
				<Icon.IconEllipsisH size={'sm'} />
			</Button.ButtonCircle>
		);
	}

	function PreviousPageLink(props) {
		return (
			<Button.ButtonPill
				className="previous-pill"
				onClick={(e) => handleGoToPage(currentPage - 1)}
				disabled={currentPage === 1}
			>
				<span className="previous-span">
					<Icon.IconChevronLeft size={'sm'} /> Prev
				</span>
			</Button.ButtonPill>
		);
	}

	function NextPageLink(props) {
		return (
			<Button.ButtonPill
				className="next-pill"
				onClick={(e) => handleGoToPage(currentPage + 1)}
				disabled={currentPage === numberOfPages}
			>
				<span className="next-span">
					Next <Icon.IconChevronRight size={'sm'} />
				</span>
			</Button.ButtonPill>
		);
	}

	function Wrapper(props) {
		return <div className="pagination-wrapper">{props.children}</div>;
	}

	const PaginatedPage = createUltimatePagination({
		itemTypeToComponent: {
			PAGE: Page,
			ELLIPSIS: Ellipsis,
			PREVIOUS_PAGE_LINK: PreviousPageLink,
			NEXT_PAGE_LINK: NextPageLink,
		},
		WrapperComponent: Wrapper,
	});

	return (
		<div className={'availability-results-pagination-container' + (isMobile ? '-mobile' : '')}>
			<PaginatedPage
				totalPages={numberOfPages || 1}
				currentPage={currentPage}
				onClick={props.onClick}
				hideFirstAndLastPageLinks={true}
				hidePreviousAndNextPageLinks={isMobile || numberOfPages < 2}
			/>
			{isMobile && numberOfPages > 1 && (
				<div className="mobile-div">
					<Button.ButtonPill
						className="previous-pill"
						onClick={(e) => handleGoToPage(currentPage - 1)}
						disabled={currentPage === 1}
					>
						<span className="previous-span">
							<Icon.IconChevronLeft size={'sm'} /> Prev
						</span>
					</Button.ButtonPill>
					<Button.ButtonPill
						className="next-pill"
						onClick={(e) => handleGoToPage(currentPage + 1)}
						disabled={currentPage === numberOfPages}
					>
						<span className="next-span">
							Next <Icon.IconChevronRight size={'sm'} />
						</span>
					</Button.ButtonPill>
				</div>
			)}
		</div>
	);
}
