import React from 'react';
import { connect } from 'react-redux';

export class SMSTextingTermsOfServiceView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: '',
		};
	}

	render() {
		return (
			<>
				<h3>Experian Health’s Patient Schedule SMS Texting Service</h3>
				<br />
				<div>
					<p>
						1. The SMS service is used to send you healthcare messages, and specifically messages about your healthcare
						appointments.
					</p>
					<p>
						2. You can cancel the SMS service at any time. Just text “STOP” to the short code. After you send the SMS
						message “STOP” to us, we will send you an SMS message to confirm that you have been unsubscribed. After
						this, you will no longer receive SMS messages from us. If you want to join again, just sign up as you did
						the first time and we will start sending SMS messages to you again.
					</p>
					<p>
						3. If you are experiencing issues with the messaging program you can reply with the keyword HELP for more
						assistance, or if you need help please contact us at (629) 777-5552.
					</p>
					<p>4. Carriers are not liable for delayed or undelivered messages.</p>
					<p>
						5. As always, message and data rates may apply for any messages sent to you from us and to us from you.
						Message frequency will vary based upon your specific healthcare needs. If you have any questions about your
						text plan or data plan, it is best to contact your wireless provider.
					</p>
					<p>
						6. If you have any questions regarding privacy, please read our privacy policy:{' '}
						<a href="SMSTextingPrivacyPolicy">SMS Texting Privacy Policy</a>.
					</p>
				</div>
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		correlationKey: state.session.correlationKey,
		isLoading: state.ajaxCallsInProgress > 0,
		token: state.auth.token,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {},
		dispatch,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SMSTextingTermsOfServiceView);
