import React from 'react';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCalendarCheck, faCalendarTimes } from '@fortawesome/free-regular-svg-icons';
import {
	faAngleDown,
	faAngleUp,
	faCheck,
	faChevronLeft,
	faChevronRight,
	faEllipsisH,
	faFilter,
	faMapMarker,
	faTimes,
	faArrowLeft,
	faMobileScreenButton,
} from '@fortawesome/free-solid-svg-icons';

/**
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.disabled
 * @param {Object} props.icon
 * @param {string} props.id
 * @param {Function} props.onClick
 * @param {string} props.size
 * @returns {Object}
 */
export function Icon(props) {
	const { className, disabled, icon, id, onClick, size } = props;
	const iconClassName = classNames('icon', className, { clickable: !!onClick }, { disabled: disabled }, size);

	return <FontAwesomeIcon className={iconClassName} icon={icon} id={id} onClick={onClick} />;
}

/**
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.disabled
 * @param {string} props.id
 * @param {Function} props.onClick
 * @param {string} props.size
 * @returns {Object}
 */
export function IconClose(props) {
	const { className, disabled, id, onClick, size } = props;
	const iconClassName = classNames('icon-close', className);

	return <Icon className={iconClassName} disabled={disabled} icon={faTimes} id={id} onClick={onClick} size={size} />;
}

/**
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.disabled
 * @param {string} props.id
 * @param {Function} props.onClick
 * @param {string} props.size
 * @returns {Object}
 */
export function IconCalendarAlt(props) {
	const { className, disabled, id, onClick, size } = props;
	const iconClassName = classNames('icon-calendar-alt', className);

	return (
		<Icon className={iconClassName} disabled={disabled} icon={faCalendarAlt} id={id} onClick={onClick} size={size} />
	);
}

/**
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.disabled
 * @param {string} props.id
 * @param {Function} props.onClick
 * @param {string} props.size
 * @returns {Object}
 */
export function IconCalendarCheck(props) {
	const { className, disabled, id, onClick, size } = props;
	const iconClassName = classNames('icon-calendar-check', className);

	return (
		<Icon className={iconClassName} disabled={disabled} icon={faCalendarCheck} id={id} onClick={onClick} size={size} />
	);
}

/**
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.disabled
 * @param {string} props.id
 * @param {Function} props.onClick
 * @param {string} props.size
 * @returns {Object}
 */
export function IconCalendarTimes(props) {
	const { className, disabled, id, onClick, size } = props;
	const iconClassName = classNames('icon-calendar-times', className);

	return (
		<Icon className={iconClassName} disabled={disabled} icon={faCalendarTimes} id={id} onClick={onClick} size={size} />
	);
}

/**
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.disabled
 * @param {string} props.id
 * @param {Function} props.onClick
 * @param {string} props.size
 * @returns {Object}
 */
export function IconAngleDown(props) {
	const { className, disabled, id, onClick, size } = props;
	const iconClassName = classNames('icon-angle-down', className);

	return (
		<Icon className={iconClassName} disabled={disabled} icon={faAngleDown} id={id} onClick={onClick} size={size} />
	);
}

/**
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.disabled
 * @param {string} props.id
 * @param {Function} props.onClick
 * @param {string} props.size
 * @returns {Object}
 */
export function IconAngleUp(props) {
	const { className, disabled, id, onClick, size } = props;
	const iconClassName = classNames('icon-angle-up', className);

	return <Icon className={iconClassName} disabled={disabled} icon={faAngleUp} id={id} onClick={onClick} size={size} />;
}

/**
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.disabled
 * @param {string} props.id
 * @param {Function} props.onClick
 * @param {string} props.size
 * @returns {Object}
 */
export function IconCheck(props) {
	const { className, disabled, id, onClick, size } = props;
	const iconClassName = classNames('icon-check', className);

	return <Icon className={iconClassName} disabled={disabled} icon={faCheck} id={id} onClick={onClick} size={size} />;
}

/**
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.disabled
 * @param {string} props.id
 * @param {Function} props.onClick
 * @param {string} props.size
 * @returns {Object}
 */
export function IconChevronLeft(props) {
	const { className, disabled, id, onClick, size } = props;
	const iconClassName = classNames('icon-chevron-left', className);

	return (
		<Icon className={iconClassName} disabled={disabled} icon={faChevronLeft} id={id} onClick={onClick} size={size} />
	);
}

/**
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.disabled
 * @param {string} props.id
 * @param {Function} props.onClick
 * @param {string} props.size
 * @returns {Object}
 */
export function IconChevronRight(props) {
	const { className, disabled, id, onClick, size } = props;
	const iconClassName = classNames('icon-chevron-right', className);

	return (
		<Icon className={iconClassName} disabled={disabled} icon={faChevronRight} id={id} onClick={onClick} size={size} />
	);
}

/**
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.disabled
 * @param {string} props.id
 * @param {Function} props.onClick
 * @param {string} props.size
 * @returns {Object}
 */
export function IconEllipsisH(props) {
	const { className, disabled, id, onClick, size } = props;
	const iconClassName = classNames('icon-ellipsis-h', className);

	return (
		<Icon className={iconClassName} disabled={disabled} icon={faEllipsisH} id={id} onClick={onClick} size={size} />
	);
}

/**
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.disabled
 * @param {string} props.id
 * @param {Function} props.onClick
 * @param {string} props.size
 * @returns {Object}
 */
export function IconFilter(props) {
	const { className, disabled, id, onClick, size } = props;
	const iconClassName = classNames('icon-filter', className);

	return <Icon className={iconClassName} disabled={disabled} icon={faFilter} id={id} onClick={onClick} size={size} />;
}

/**
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.disabled
 * @param {string} props.id
 * @param {Function} props.onClick
 * @param {string} props.size
 * @returns {Object}
 */
export function IconMapMarker(props) {
	const { className, disabled, id, onClick, size } = props;
	const iconClassName = classNames('icon-map-marker', className);

	return (
		<Icon className={iconClassName} disabled={disabled} icon={faMapMarker} id={id} onClick={onClick} size={size} />
	);
}

/**
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.disabled
 * @param {string} props.id
 * @param {Function} props.onClick
 * @param {string} props.size
 * @returns {Object}
 */
export function IconArrowLeft(props) {
	const { className, disabled, id, onClick, size } = props;
	const iconClassName = classNames('icon-chevron-right', className);

	return (
		<Icon className={iconClassName} disabled={disabled} icon={faArrowLeft} id={id} onClick={onClick} size={size} />
	);
}

/**
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.disabled
 * @param {string} props.id
 * @param {Function} props.onClick
 * @param {string} props.size
 * @returns {Object}
 */
export function IconTelephonic(props) {
	const { className, disabled, id, onClick, size } = props;
	const iconClassName = classNames('icon-telephonic', className);

	return (
		<Icon className={iconClassName} disabled={disabled} icon={faMobileScreenButton} id={id} onClick={onClick} size={size} />
	);
}