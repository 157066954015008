import PropTypes from 'prop-types';

const AppliedPathwaysEnums = (props) => {};

const dateFields = {
	Month: 'ad76f288-e107-43ab-9515-41aa0f0c13a2',
	Day: '9dd46a68-9441-4db1-8381-cd8d164ef189',
	Year: '514a662e-07e7-4975-88a8-8c145dc2cb67',
};

const assetTypes = {
	hotspot: 'question/hotspot',
	form: 'form',
	message: 'message',
	recommendation: 'recommendation',
	standard_question: 'question/standard',
	stop: 'stop',
	text_question: 'question/text',
	value_question: 'question/value',
};

const assetSubTypes = {
	yes_no: 'yes_no',
	text: 'default',
	multiple_choice_single_answer: 'multiple_choice_single_answer',
	multiple_choice_multiple_answers: 'multiple_choice_multiple_answers',
};

AppliedPathwaysEnums.propTypes = {
	dateFields: PropTypes.oneOf(Object.keys(dateFields)),
	assetTypes: PropTypes.oneOf(Object.keys(assetTypes)),
	assetSubTypes: PropTypes.oneOf(Object.keys(assetSubTypes)),
};

AppliedPathwaysEnums.dateFields = dateFields;
AppliedPathwaysEnums.assetTypes = assetTypes;
AppliedPathwaysEnums.assetSubTypes = assetSubTypes;

export default AppliedPathwaysEnums;
