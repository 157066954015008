import React from 'react';
import classNames from 'classnames';

/**
* @param {Object} props
* @param {Object} props.children
* @param {string} props.className
* @param {boolean} props.disabled
* @param {string} props.id
* @param {boolean} props.isLoading
* @param {boolean} props.isSelected
* @param {string} props.name
* @param {Function} props.onClick
* @param {string} props.title
* @param {string} props.type
* @param {string} props.size - "sm", "md", "lg"
 * @param {Object} props.value
* @returns {Object}
*/
export function Button(props) {
	const { children, className, disabled, id, isLoading, isSelected, name, onClick, size, title, type, value } = props;
	const buttonClassName = classNames(
		className,
		{ "disabled": disabled },
		{ "isLoading": isLoading },
		{ "selected": isSelected },
		size,
	);

	return (
		<button id={id} title={title} type={(type || "button")} className={buttonClassName} disabled={disabled} name={name} onClick={onClick} value={value}>{children}</button>
	);
};
