import React, { useState } from 'react';
import * as moment from 'moment';
import { ProviderAvailabilitySlots } from '../misc/providerAvailabilitySlots';
import { AvailabilityResultsWeekNav } from './availabilityResultsWeekNav';
import { ProviderNameAndSpecialty } from '../provider/providerNameAndSpecialty';
import { ProviderSiteAddressAndPhone } from '../provider/providerSiteAddressAndPhone';

// AVAILABILITY RESULTS
export function AvailabilitySearchResults(props) {
	moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss';

	const {
		availabilityResults,
		availabilitySearchConfig,
		handleGoToDate,
		handleProviderSelectDate,
		isMobile,
		isLoading,
		handleSelectCalendarDetails,
		handleSelectSlot,
		providerFieldConfig,
		providersSelectedDate,
		schedulingConfig,
		selectedResults,
	} = props;

	const resultsToDisplay = selectedResults ? availabilityResults.selectedResults : availabilityResults.recommendedResults;

	return (
		<div className="availability-results-providers">
			{resultsToDisplay.map((result, i) => {
				return (
					<ProviderAvailabilityRow
						availabilityResults={availabilityResults}
						result={result}
						enableAppointmentTypeTooltips={availabilitySearchConfig.enableAppointmentTypeTooltips}
						handleGoToDate={handleGoToDate}
						handleProviderSelectDate={handleProviderSelectDate}
						hasAvailabilityResultsCurrentWeek={result.hasDisplayableAvailability}
						isMobile={isMobile}
						isLoading={isLoading}
						key={i}
						handleSelectSlot={handleSelectSlot}
						handleSelectCalendarDetails={handleSelectCalendarDetails}
						providerFieldConfig={providerFieldConfig}
						providersSelectedDate={providersSelectedDate}
						searchDaysUsed={availabilityResults.numDaysScanned}
						slotColumnsPerDayMobile={availabilitySearchConfig.slotColumnsPerDayMobile}
						slotsPerDay={availabilitySearchConfig.slotsPerDay}
						slotsPerDayMobile={availabilitySearchConfig.slotsPerDayMobile}
						showDistanceMiles={schedulingConfig.showAvailabilitySearchDistance}
						{...props}
					/>
				);
			})}
		</div>
	);
}

export function ProviderAvailabilityRow(props) {
	const {
		result,
		availabilityResults,
		enableAppointmentTypeTooltips,
		handleGoToDate,
		handleProviderSelectDate,
		handleSelectCalendarDetails,
		hasAvailabilityResultsCurrentWeek,
		isMobile,
		isLoading,
		handleSelectSlot,
		providerFieldConfig,
		providersSelectedDate,
		searchDaysUsed,
		slotColumnsPerDayMobile,
		slotsPerDay,
		slotsPerDayMobile,
		showDistanceMiles,
	} = props;

	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div className="provider-result-row">
			<ProviderDetailsCard
				availabilityResult={result}
				handleSelectCalendarDetails={handleSelectCalendarDetails}
				providerFieldConfig={providerFieldConfig}
				showDistanceMiles={showDistanceMiles}
				{...props}
			/>
			{isMobile && (
				<AvailabilityResultsWeekNav
					availabilityResults={availabilityResults}
					calendarId={result.calendarDetails?.calendarId}
					handleGoToDate={handleGoToDate}
					handleProviderSelectDate={handleProviderSelectDate}
					isMobile={isMobile}
					providersSelectedDate={providersSelectedDate}
					{...props}
				/>
			)}
			<ProviderAvailabilitySlots
				availabilityResult={result}
				enableAppointmentTypeTooltips={enableAppointmentTypeTooltips}
				handleGoToDate={handleGoToDate}
				hasAvailabilityResultsCurrentWeek={hasAvailabilityResultsCurrentWeek}
				isExpanded={isExpanded}
				isMobile={isMobile}
				isLoading={isLoading}
				handleSelectSlot={handleSelectSlot}
				providersSelectedDate={providersSelectedDate}
				searchDaysUsed={searchDaysUsed}
				setIsExpanded={setIsExpanded}
				slotColumnsPerDay={slotColumnsPerDayMobile}
				slotColumnsPerDayMobile={slotColumnsPerDayMobile}
				slotsPerDay={slotsPerDay}
				slotsPerDayMobile={slotsPerDayMobile}
				{...props}
			/>
		</div>
	);
}

export function ProviderDetailsCard(props) {
	const { availabilityResult, handleSelectCalendarDetails, providerFieldConfig, showDistanceMiles } = props;

	const providerDetails = availabilityResult.calendarDetails;
	const shouldShowProviderAddress = providerDetails && !providerDetails.suppressAddress;
	const shouldShowProviderPhone =
		providerFieldConfig.phone &&
		providerFieldConfig.phone.isVisible &&
		providerDetails &&
		!providerDetails.suppressPhoneNumber;
	let providerImage = providerDetails ? (
		<img
			src={providerDetails.imageFile || '/resources/generic-provider-photo.jpg'}
			alt={providerDetails.serviceName + ' Provider'}
		/>
	) : null;

	return (
		<div className="provider-details-card">
			{providerDetails && (
				<>
					<div className="provider-image-container">{providerImage}</div>
					<div className="provider-name-and-details">
						<ProviderNameAndSpecialty
							handleSelectCalendarDetails={handleSelectCalendarDetails}
							isProviderClickable={true}
							providerDisplayName={providerDetails.serviceName}
							providerSpecialty={providerDetails.specialtyName}
							serviceSiteId={providerDetails.calendarId}
							{...props}
						/>
						<ProviderSiteAddressAndPhone
							city={providerDetails.city}
							distanceMiles={providerDetails.distanceInMiles}
							phoneLabel={providerFieldConfig.phone.fieldLabel}
							phoneNumber={providerDetails.phone}
							providerAddressLine1={providerDetails.address1}
							providerAddressLine2={providerDetails.address2}
							providerSiteDisplayName={providerDetails.siteName}
							showAddress={shouldShowProviderAddress}
							showDistanceMiles={showDistanceMiles}
							showPhone={shouldShowProviderPhone}
							state={providerDetails.state}
							zipCode={providerDetails.zipCode}
							{...props}
						/>
					</div>
				</>
			)}
		</div>
	);
}
