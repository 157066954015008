import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import ajaxStatusReducer from './ajaxStatusReducer';
import apiErrorReducer from './apiErrorReducer';
import appliedPathwaysReducer from './appliedPathwaysReducer';
import appointmentReducer from './appointmentReducer';
import authReducer from './authReducer';
import availabilitySearchReducer from './availabilitySearchReducer';
import careOrderReducer from './careOrderReducer';
import configReducer from './configReducer';
import customFieldsReducer from './customFieldsReducer';
import decisionSupportReducer from './decisionSupportReducer';
import guidedResponseReducer from './guidedResponseReducer';
import modalReducer from './modalReducer';
import patientReducer from './patientReducer';
import referringProvidersReducer from './referringProvidersReducer';
import sessionReducer from './sessionReducer';
import surveyOrderReducer from './surveyOrderReducer';
import productAlertConfigReducer from './productAlertConfigReducer';
import workflowReducer from '../features/schedulingWorkflow/schedulingWorkflowSlice';

import availabilityNewReducer from '../features/searchAsync/availabilitySlice';

import * as types from '../actions/actionTypes';
const actionTypes = types;

const sessionReducers = (history) =>
	combineReducers({
		ajaxCallsInProgress: ajaxStatusReducer,
		apiErrors: apiErrorReducer,
		appliedPathways: appliedPathwaysReducer,
		appointment: appointmentReducer,
		auth: authReducer,

		availabilitySearch: availabilitySearchReducer,
		availability: availabilityNewReducer,

		careOrder: careOrderReducer,
		config: configReducer,
		customFields: customFieldsReducer,
		decisionSupport: decisionSupportReducer,
		guidedResponse: guidedResponseReducer,
		modal: modalReducer,
		patient: patientReducer,
		referringProviders: referringProvidersReducer,
		router: connectRouter(history),
		session: sessionReducer,
		surveyOrder: surveyOrderReducer,
		productAlertConfig: productAlertConfigReducer,
		workflow: workflowReducer,
	});

const rootReducer = (history) => (state, action) => {
	if (action.type === actionTypes.LOGOUT_USER || action.type === actionTypes.VERIFY_TOKEN_ERROR) {
		localStorage.clear();
		state = undefined;
	}
	return sessionReducers(history)(state, action);
};

export default rootReducer;
