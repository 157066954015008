import { push } from 'connected-react-router';
import { Route, Switch } from 'react-router';
import NotFound from './components/misc/NotFound';
import InvalidURL from './components/misc/InvalidURL';
import PublicRoute from './components/misc/publicRoute';
import PrivateRoute from './components/misc/privateRoute';

// cd2 views
import AvailabilitySearchView from './views/availability/availabilitySearchView';

//this is intended to be temporary and will replace AvailabilitySearchView once testing has stabalized 
import AvailabilitySearchViewNew from './features/searchAsync/availabilitySearchView';

import ClientInstanceLogin from './views/login/clientInstanceLogin';
import StartBookingRedirector from './views/login/startBookingRedirector';
import StartBookingTransitionRedirector from './views/login/startBookingTransitionRedirector';
import StartCareOrderVisitBookRedirector from './views/login/startCareOrderVisitBookRedirector';

import AppointmentCancelView from './views/appointmentCancel/appointmentCancelView';
import BookAppointmentView from './views/bookAppointment/bookAppointmentView';
import PatientAuthenticationView from './views/patientAuthentication/patientAuthenticationView';
import AppointmentDetailsView from './views/appointmentDetails/appointmentDetailsView';
import DecisionSupportView from './views/decisionSupport/decisionSupportView';
import SMSTextingPrivacyPolicyView from './views/smsTextingPolicy/SMSTextingPrivacyPolicyView';
import SMSTextingTermsOfServiceView from './views/smsTextingPolicy/SMSTextingTermsOfServiceView';
import StartSurveyOrderRedirector from './views/login/startSurveyOrderRedirector';
import SurveyCompleteView from './views/survey/surveyCompleteView';
import SurveyView from './views/survey/surveyView';

import { useSelector } from 'react-redux';
import RouteTemplates from './routeTemplates';

// NOTE: ONLY EXPORT URL FUNCTIONS WHEN NEEDED FOR ANCHOR TAGS

/* * * WARNING - All changes to routes must update the Cd2RouterSwitch, URL Builder and/or Action method * * */

/* Cd2RouteSwitch - builds the react router switch component for CD2 routes */

export const Cd2RouteSwitch = () => {

	const useNewAvailabilitySearchViewByDefault = useSelector((state) => state.config.availabilitySearch.useNewAvailabilitySearchViewByDefault);
	const searchPreferredProvidersFirst = useSelector((state) => state.config.availabilitySearch.searchPreferredProvidersFirst);

	return (
		<Switch>
			{/* Unprotected routes */}
			<Route path="/failedlogin" component={NotFound} />
			<Route path="/invalidUrl" component={InvalidURL} />
			<Route path="/SMSTextingPrivacyPolicy" component={SMSTextingPrivacyPolicyView} />
			<Route path="/SMSTextingTermsOfService" component={SMSTextingTermsOfServiceView} />

			{/* Protected routes */}
			<PrivateRoute
				path={[RouteTemplates.appointmentDetails, RouteTemplates.appointmentDetailsWithRoutePrefix]}
				component={AppointmentDetailsView}
			/>
			<PrivateRoute path={[RouteTemplates.availability, RouteTemplates.availabilityWithRoutePrefix]} component={
				useNewAvailabilitySearchViewByDefault || searchPreferredProvidersFirst
					? AvailabilitySearchViewNew
					: AvailabilitySearchView
			} />
			<PrivateRoute path={[RouteTemplates.bookAppointment, RouteTemplates.bookAppointmentWithRoutePrefix]} component={BookAppointmentView} />
			<PrivateRoute
				path={[RouteTemplates.decisionSupport, RouteTemplates.decisionSupportWithRoutePrefix]}
				component={DecisionSupportView}
			/>
			<PrivateRoute path={['/survey', '/:routePrefix/survey']} component={SurveyView} />
			<PrivateRoute path={['/surveyComplete', '/:routePrefix/survey/complete']} component={SurveyCompleteView} />
			<PrivateRoute
				path={['/patientAuthentication/', '/:routePrefix/patientAuthentication/']}
				component={PatientAuthenticationView}
			/>
			{/* EntryPoint routes */}
			<PrivateRoute
				path={[RouteTemplates.bookAnotherAppointment, RouteTemplates.bookAnotherAppointmentWithRoutePrefix]}
				component={DecisionSupportView}
				setValidEntryPoint={true}
			/>
			<PrivateRoute
				path={[
					'/start/careordervisit/:careOrderVisitId/book',
					'/:routePrefix/start/careordervisit/:careOrderVisitId/book',
				]}
				component={StartCareOrderVisitBookRedirector}
				setValidEntryPoint={true}
			/>
			<PrivateRoute
				path={['/start/surveyorder/:surveyOrderId', '/:routePrefix/start/surveyorder/:surveyOrderId']}
				component={StartSurveyOrderRedirector}
				setValidEntryPoint={true}
			/>
			<PrivateRoute
				path={[
					'/start/bookingtransition/:bookingTransitionId',
					'/:routePrefix/start/bookingtransition/:bookingTransitionId',
				]}
				component={StartBookingTransitionRedirector}
				setValidEntryPoint={true}
			/>
			<PrivateRoute
				path={['/start/booking', '/:routePrefix/start/booking']}
				component={StartBookingRedirector}
				setValidEntryPoint={true}
			/>
			<PrivateRoute
				path={['/appointment/cancel/:referenceId', '/:routePrefix/appointment/cancel/:referenceId']}
				component={AppointmentCancelView}
				setValidEntryPoint={true}
			/>
			<PublicRoute
				exact
				path={['/DecisionSupport', '/:routePrefix/DecisionSupport']}
				component={ClientInstanceLogin}
				setValidEntryPoint={true}
			/>
			<PublicRoute
				exact
				path={['/', '/:routePrefix']}
				component={ClientInstanceLogin}
				setValidEntryPoint={true}
			/>
			<Route component={NotFound} />
		</Switch>
	);
};

/* Route Methods */
function homeUrl(routePrefix) {
	return routePrefix ? `/${routePrefix}` : '/';
}

export function home(routePrefix) {
	return push(homeUrl(routePrefix));
}

function appointmentDetailsUrl(referenceId, routePrefix) {
	return routePrefix ? `/${routePrefix}/appointment/${referenceId}/details` : `/appointment/${referenceId}/details`;
}

export function appointmentDetails(referenceId, routePrefix) {
	return push(appointmentDetailsUrl(referenceId, routePrefix));
}

function appointmentWorkflowUrl(useMultiAppointmentWorkflow, careOrderVisitIdentifier, bookingContext, routePrefix) {
	return useMultiAppointmentWorkflow
		? careOrderVisitUrl(careOrderVisitIdentifier, routePrefix)
		: bookingContext.calendarId
			? providerDetailsUrl(bookingContext.calendarId, routePrefix)
			: routePrefix
				? `/${routePrefix}/availability`
				: '/availability';
}

function bookAppointmentUrl(routePrefix) {
	return routePrefix ? `/${routePrefix}/bookAppointment` : `/bookAppointment`;
}

export function bookAppointment(routePrefix) {
	return push(bookAppointmentUrl(routePrefix));
}

function bookAnotherAppointmentUrl(routePrefix) {
	return routePrefix ? `/${routePrefix}/bookAnotherAppointment` : `/bookAnotherAppointment`;
}

export function bookAnotherAppointment(routePrefix) {
	return push(bookAnotherAppointmentUrl(routePrefix));
}

function bookCareOrderVisitUrl(careOrderVisitId, routePrefix) {
	return routePrefix
		? `/${routePrefix}/careordervisit/multiappointment/${careOrderVisitId}/book`
		: `/careordervisit/multiappointment/${careOrderVisitId}/book`;
}

export function bookCareOrderVisit(careOrderVisitId, routePrefix) {
	return push(bookCareOrderVisitUrl(careOrderVisitId, routePrefix));
}

function bookCareOrderVisitDetailsUrl(careOrderVisitId, routePrefix) {
	return routePrefix
		? `/${routePrefix}/careordervisit/${careOrderVisitId}/appointmentsdetail`
		: `/careordervisit/${careOrderVisitId}/appointmentsdetail`;
}

export function bookCareOrderVisitDetails(careOrderVisitId, routePrefix) {
	return push(bookCareOrderVisitDetailsUrl(careOrderVisitId, routePrefix));
}

function cancelAppointmentUrl(referenceId, routePrefix) {
	return routePrefix ? `/${routePrefix}/appointment/cancel/${referenceId}` : `/appointment/cancel/${referenceId}`;
}

export function cancelAppointment(referenceId, routePrefix) {
	return push(cancelAppointmentUrl(referenceId, routePrefix));
}

function careOrderVisitUrl(careOrderVisitIdentifier, routePrefix) {
	return routePrefix
		? `/${routePrefix}/careordervisit/${careOrderVisitIdentifier}/availability`
		: `/careordervisit/${careOrderVisitIdentifier}/availability`;
}

export function careOrderVisit(careOrderVisitId, routePrefix) {
	return push(careOrderVisitUrl(careOrderVisitId, routePrefix));
}

export function resetWorkflow(url) {
	return push(resetWorkflowUrl(url));
}

function resetWorkflowUrl(url) {
	return url;
}

function decisionSupportUrl(routePrefix) {
	return routePrefix ? `/${routePrefix}/decisionSupport/workflow` : `/decisionSupport/workflow`;
}

export function decisionSupport(routePrefix) {
	return push({
		pathname: decisionSupportUrl(routePrefix),
	});
}

function surveyOrderGuidedResponseUrl(routePrefix) {
	return routePrefix ? `/${routePrefix}/survey` : `/survey`;
}

function failedLoginUrl() {
	return '/failedlogin';
}

export function failedLogin() {
	return push(failedLoginUrl());
}

function patientAuthenticationUrl(routePrefix) {
	return routePrefix ? `/${routePrefix}/patientAuthentication` : `/patientAuthentication`;
}

export function patientAuthentication(routePrefix) {
	return push(patientAuthenticationUrl(routePrefix));
}

function providerDetailsUrl(serviceSiteId, queryString, routePrefix) {
	let qs = queryString != null ? '?' + queryString : '';
	return routePrefix
		? `/${routePrefix}/provider/${serviceSiteId}/details${qs}`
		: `/provider/${serviceSiteId}/details${qs}`;
}

export function providerDetails(serviceSiteId, queryString, routePrefix) {
	return push(providerDetailsUrl(serviceSiteId, queryString, routePrefix));
}

function searchAvailabilityUrl(routePrefix) {
	return routePrefix ? `/${routePrefix}/availability` : `/availability`;
}

export function searchAvailability(routePrefix) {
	return push(searchAvailabilityUrl(routePrefix));
}

function startBookingProcessUrl(
	useDecisionSupport,
	useMultiAppointmentWorkflow,
	careOrderVisitIdentifier,
	bookingContext,
	routePrefix,
) {
	// TODO: get careOrderVisitIdentifier out of booking context at use time
	return useDecisionSupport
		? decisionSupportUrl(routePrefix)
		: appointmentWorkflowUrl(useMultiAppointmentWorkflow, careOrderVisitIdentifier, bookingContext, routePrefix);
}

export function startBookingProcess(
	useDecisionSupport,
	useMultiAppointmentWorkflow,
	careOrderVisitIdentifier,
	bookingContext,
	routePrefix,
) {
	return push(
		startBookingProcessUrl(
			useDecisionSupport,
			useMultiAppointmentWorkflow,
			careOrderVisitIdentifier,
			bookingContext,
			routePrefix,
		),
	);
}

export function startSurveyGRFlowUrl(surveyOrderContext, routePrefix) {
	return surveyOrderGuidedResponseUrl(routePrefix);
}

export function startSurveyGRFlow(surveyOrderContext, routePrefix) {
	return push(startSurveyGRFlowUrl(surveyOrderContext, routePrefix));
}

/* Route Methods - Survey Order */
function startSurveyOrderUrl(surveyOrderId, routePrefix) {
	return routePrefix ? `/${routePrefix}/start/surveyorder/${surveyOrderId}` : `/start/surveyorder/${surveyOrderId}`;
}
export function startSurveyOrder(surveyOrderId, routePrefix) {
	return push(startSurveyOrderUrl(surveyOrderId, routePrefix));
}

export function surveyCompleteUrl(routePrefix) {
	return routePrefix ? `/${routePrefix}/surveyComplete` : `/surveyComplete`;
}

export function surveyComplete(routePrefix) {
	return push(surveyCompleteUrl(routePrefix));
}

export const patientDetails = () => null;
export const requestAppointment = () => null;
export const routeToBooking = (routePrefix) => bookAppointment(routePrefix);
