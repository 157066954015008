import React from 'react';
import { connect } from 'react-redux';
import './InvalidURL.css';

export class InvalidURL extends React.Component {
	render() {
		return (
			<div className="InvalidUrl">
				{this.props.invalidUrlMessage && this.props.invalidUrlMessage.trim() !== '' ? (
					<div
						dangerouslySetInnerHTML={{
							__html: this.props.invalidUrlMessage,
						}}
					/>
				) : (
					<h3>Sorry, you have requested an invalid URL.</h3>
				)}
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		invalidUrlMessage: state.auth.invalidRootUrlMessage,
	};
}

export default connect(mapStateToProps)(InvalidURL);
