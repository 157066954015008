import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Input } from 'reactstrap';

export default class DropdownList extends React.Component {
	constructor(props) {
		super(props);
		this.getOptions = this.getOptions.bind(this);
		this.getValue = this.getValue.bind(this);
		this.selectRef = React.createRef();
	}

	getOptions(list, keyText, valueText) {
		if (list) {
			return list.map((x) => {
				var keys = {
					value: x[valueText],
					label: x[keyText],
				};
				return Object.assign(x, keys);
			});
		}
	}

	getValue(value, list, isDisabled) {
		if (list) {
			if (isDisabled && this.selectRef.current) {
				this.selectRef.current.blur();
				return null;
			}
			var val = list.find((o) => o.value === value);
			return val === undefined ? null : val;
		}
	}

	render() {
		const {
			name,
			placeholder,
			invalid,
			feedback,
			isDisabled,
			ismulti,
			escapeClearsValue,
			value,
			list,
			listkey,
			listvalue,
			maxheight,
			onChange,
			objectReference,
			fieldReference,
			...rest
		} = this.props;

		let clearable = this.props.isClearable;
		if (!clearable) {
			clearable = true;
		}
		let customStyles = {
			option: (provided, state) => ({
				...provided,
			}),
			container: (provided, state) => ({
				...provided,
				borderColor: invalid ? '#dc3545' : 'hsl(0, 0%, 80%)',
			}),
			control: (provided, state) => ({
				...provided,
				cursor: 'pointer',
				borderColor: invalid && !state.isFocused ? '#dc3545' : 'hsl(0, 0%, 80%)',
			}),
		};

		//let classes = "base-style basic-single" + (invalid ? " invalid-select" : "") + (isDisabled ? " dropdown-disabled" : "");
		let optionList = this.getOptions(this.props.list, this.props.listkey, this.props.listvalue);

		return (
			<div>
				<Select
					ref={this.selectRef}
					styles={customStyles}
					name={name}
					value={this.getValue(value, optionList, isDisabled)} /*className={classes}*/
					isDisabled={isDisabled}
					escapeClearsValue={escapeClearsValue ? escapeClearsValue : true}
					isClearable={clearable}
					classNamePrefix="select"
					{...rest}
					placeholder={placeholder}
					isMulti={ismulti}
					onChange={
						objectReference ? onChange.bind(this, name, objectReference, fieldReference) : onChange.bind(this, name)
					}
					options={optionList}
					maxMenuHeight={maxheight}
					menuPlacement="auto"
				/>
				<Input type="hidden" invalid={invalid} />
				<div className="invalid-feedback">{feedback}</div>
			</div>
		);
	}
}

DropdownList.propTypes = {
	feedback: PropTypes.string,
	id: PropTypes.string,
	invalid: PropTypes.bool,
	isDisabled: PropTypes.bool,
	list: PropTypes.arrayOf(
		PropTypes.shape({
			idPgmLanguage: PropTypes.number,
			label: PropTypes.string,
			language: PropTypes.string,
		}),
	),
	listkey: PropTypes.string,
	listvalue: PropTypes.string,
	maxHeight: PropTypes.number,
	name: PropTypes.string,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
};
