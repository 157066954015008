/**
 * @format
 */

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { v4 as uuidv4 } from "uuid";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import {
	searchCalendars,
	scanFirstAvailability,
	scanNextAvailability,
	searchAvailability,
	replaceNextScanResultsWithFirstScanResults
} from '../../actions/availabilitySearchActions';

import { reserveAppointment } from '../../actions/appointmentActions';
import { clearAllErrors } from '../../actions/errorActions';

import {
	routeToBooking,
	patientDetails as routeToPatientDetails,
	requestAppointment as routeToRequestAppointment,
	decisionSupport as routeToDecisionSupport,
} from '../../routes';

import { scrollToPageTop } from '../../lib/misc';
import AvailabilitySearch from '../../components/availability/availabilitySearch';
import { ASC } from '../../constants/availabilitySearchFields';

import {
	shouldShowPreReserveModal,
	mapAvailabilitySearchCriteria,
	mapNextAvailabilityScanCriteria,
	mapFirstAvailabilityScanCriteria,
	mapCalendarSearchCriteria,
	mapResults,
} from '../../lib/availabilityHelper';

const MAX_MOBILE_WIDTH = 992;

export const AvailabilitySearchView = (props) => {
	//#region initialize
	const isMounted = useRef(false);

	// Prob useMemo once we connect to actual props
	const initialInputCriteria = {
		[ASC.appointmentModalityFilter]: '',
		[ASC.appointmentStartDate]: moment(props.availabilitySearchCriteria.minStartDate).startOf('day'), // KEEP THIS A MOMENT DATE, LET THE FORM HANDLE PARSING
		[ASC.providerGender]: props.calendarSearchCriteria.providerCriteria.genderCode,
		[ASC.languagePreference]: props.calendarSearchCriteria.providerCriteria.languageName,
		[ASC.searchRadius]: props.calendarSearchCriteria.distanceFilter.maxDistanceInMiles,
		[ASC.serviceName]: '',
		[ASC.siteFilter]: '',
		[ASC.sortOrder]: props.calendarSearchCriteria.responseConfig.sortOrder,
		[ASC.timeOfDay]: [],
		[ASC.daysOfWeek]: props.availabilitySearchCriteria.daysOfWeek || [],
		[ASC.zipCode]: props.calendarSearchCriteria.distanceFilter.zipCode,
		pageNumber: 1,
		singlePageCalendarId: null,
	};

	const appInsights = new ApplicationInsights({
		config: {
			instrumentationKey: props.applicationInsightsKey
		}
	})
	appInsights.loadAppInsights();
	//#endregion

	//#region useState
	const [isShowing, setIsShowing] = useState({
		calendarBioModal: false,
		preReserveModal: false,
		careOrderDetails: false,
		datePicker: false,
		patientDetails: false,
		searchForm: false,
		filterModal: false,
	});
	const [effectiveInputCriteria, setEffectiveInputCriteria] = useState(initialInputCriteria);
	const [selectedSlot, setSelectedSlot] = useState(null);
	const [errorState, setErrorState] = useState(null);
	const [hasDoneFirstCalendarSearch, setHasDoneFirstCalendarSearch] = useState(false);
	const [sessionId, setSessionId] = useState(uuidv4());
	//#endregion

	//#region useRefs
	const sessionIdRef = useRef(sessionId);
	const startTimeRef = useRef(null);
	const nextScanWasRun = useRef(false);
	const initialSearchCompleted = useRef(false);
	//#endregion

	//#region eventHandlers
	const handleConfirmFilters = (updatedForm) => {
		let newCriteria = { ...updatedForm };
		let requiresCalendarSearch =
			updatedForm[ASC.searchRadius] !== effectiveInputCriteria[ASC.searchRadius] ||
			updatedForm[ASC.zipCode] !== effectiveInputCriteria[ASC.zipCode] ||
			updatedForm[ASC.specialty] !== effectiveInputCriteria[ASC.specialty] ||
			updatedForm[ASC.serviceCategory] !== effectiveInputCriteria[ASC.serviceCategory] ||
			updatedForm[ASC.payorType] !== effectiveInputCriteria[ASC.payorType];

		if (!hasDoneFirstCalendarSearch) {
			requiresCalendarSearch = true;
		}

		let requiresNewScanWithSearch =
			updatedForm[ASC.appointmentStartDate] !== effectiveInputCriteria[ASC.appointmentStartDate] ||
			effectiveInputCriteria[ASC.appointmentType] !== newCriteria[ASC.appointmentType];

		const requiresRePaging =
			requiresCalendarSearch ||
			updatedForm[ASC.siteName] !== effectiveInputCriteria[ASC.siteName] ||
			updatedForm[ASC.serviceName] !== effectiveInputCriteria[ASC.serviceName] ||
			updatedForm[ASC.providerGender] !== effectiveInputCriteria[ASC.providerGender] ||
			updatedForm[ASC.languagePreference] !== effectiveInputCriteria[ASC.languagePreference] ||
			updatedForm[ASC.sortOrder] !== effectiveInputCriteria[ASC.sortOrder] ||
			updatedForm[ASC.appointmentModality] !== effectiveInputCriteria[ASC.appointmentModality];

		if (requiresRePaging) {
			newCriteria.pageNumber = 1;
		}

		resetCriteriaSessionId();

		if (requiresCalendarSearch) {
			searchCalendars(newCriteria);
		} else if (requiresNewScanWithSearch) {
			scanFirstAvailability(newCriteria);
		} else {
			searchAvailability(newCriteria);
		}

		setEffectiveInputCriteria(newCriteria);

		if (isShowing.searchForm) {
			setIsShowing((x) => {
				return { ...x, searchForm: false };
			});
		}
	};

	const handleGoToPage = (pageNumber) => {
		const newValue = { ...effectiveInputCriteria, pageNumber };
		resetCriteriaSessionId();
		searchAvailability(newValue);
		setEffectiveInputCriteria(newValue);
		scrollToPageTop();
	};

	const handleGoToDate = (date) => {
		// EXPECTS a momentjs obj
		const newValue = {
			...effectiveInputCriteria,
			[ASC.appointmentStartDate]: date,
		};
		resetCriteriaSessionId();
		searchAvailability(newValue);
		setEffectiveInputCriteria(newValue);
	};

	const handleImmediateFilter = (name, value) => {
		const newCriteria = { ...effectiveInputCriteria, [name]: value };
		const requiresNewScan = effectiveInputCriteria[ASC.appointmentType] !== newCriteria[ASC.appointmentType];
		const requiresRePaging =
			name === ASC.siteFilter || name === ASC.sortOrder || name === ASC.serviceName || ASC.appointmentModalityFilter;

		if (requiresRePaging) {
			newCriteria.pageNumber = 1;
		}
		resetCriteriaSessionId();
		if(requiresNewScan) {
			scanFirstAvailability(newCriteria);
		} else {
			searchAvailability(newCriteria);
		}
		setEffectiveInputCriteria(newCriteria);
	};

	const handleSelectCalendarDetails = (calId, startDate) => {
		const newValue = {
			...effectiveInputCriteria,
			[ASC.appointmentStartDate]: startDate,
			singlePageCalendarId: calId,
		};
		resetCriteriaSessionId();
		searchAvailability(newValue);
		setEffectiveInputCriteria(newValue);
	};

	const handleSelectSlot = (slotInfo) => {
		setSelectedSlot(slotInfo);
		if (shouldShowPreReserveModal(props, slotInfo.serviceSiteId)) {
			setIsShowing((x) => {
				return { ...x, preReserveModal: true };
			});
		} else {
			reserveAppointmentRequest(slotInfo);
		}
	};

	const handleGoToPatientDetails = () => {
		props.routeToPatientDetails(props.activePatient.details.referenceId);
	};

	const handleRequestAppointment = (e, serviceSiteId) => {
		e.preventDefault();
		props.routeToRequestAppointment(serviceSiteId);
	};

	const handleGoBackToGuidedResponse = () => {
		props.routeToDecisionSupport(props.routePrefix);
	};

	const handleScanNextRange = () => {
		const newCriteria = { ...effectiveInputCriteria };
		newCriteria[ASC.appointmentStartDate] = moment(newCriteria[ASC.appointmentStartDate]).add(props.availabilitySearchConfig.availabilitySearchWindow || 30, "d");
		setEffectiveInputCriteria(newCriteria);

		scanFirstAvailability(newCriteria);
	}
	//#endregion

	//#region useEffect triggers
	/* eslint-disable */
	const initialCalendarSearchTrigger = useEffect(() => {
		startTimeRef.current = moment();
		let { activeCareOrder, decisionSupportConfig } = props;

		const searchWithoutUserInput = (activeCareOrder?.activeCareOrderDetails?.appointments || decisionSupportConfig.useDecisionSupport); 
		if (searchWithoutUserInput) {
			searchCalendars(effectiveInputCriteria);
		} else {
			setIsShowing((x) => {
				return { ...x, searchForm: true };
			});
		}
	}, []);

	const setCareOrderDetailsVisibility = useEffect(() => {
		let { activeCareOrder } = props;
		if (activeCareOrder?.activeCareOrderDetails?.appointments) {
			setIsShowing((x) => {
				return { ...x, careOrderDetails: true };
			});
		}
	}, []);

	const scanAvailabilityTrigger = useEffect(() => {
		if (isMounted.current) {
			// mark first search complete
			if (!hasDoneFirstCalendarSearch && props.calendarSearchResults.hasSearched) {
				// set single calendarId for single result ONLY on first calendar search
				if (props.calendarSearchResults.calendars?.length === 1) {
					const newValue = {
						...effectiveInputCriteria,
						singlePageCalendarId: props.calendarSearchResults.calendars[0].calendarId,
					};
					setEffectiveInputCriteria(newValue);
				}

				setHasDoneFirstCalendarSearch(true);
			}

			if (props.calendarSearchResults.calendars?.length > 0) {
				scanFirstAvailability();
			}
		}
	}, [props.calendarSearchResults.calendars]);
	
	const searchAvailabilityTrigger = useEffect(() => {
		if (isMounted.current) {
			if (props.firstAvailabilityScanResults.calendars?.length > 0) {
				const newValue = { ...effectiveInputCriteria };
				if (
					props.firstAvailabilityScanResults.hasAvailability &&
					props.availabilitySearchConfig.skipToFirstAvailability
				) {
					newValue[ASC.appointmentStartDate] = moment(props.firstAvailabilityScanResults.firstAvailability.date);
					setEffectiveInputCriteria(newValue);
				}
				if(props.firstAvailabilityScanResults.hasAvailability) {
					searchAvailability(newValue);
				}
			}
		}
	}, [props.firstAvailabilityScanResults.calendars]);

	const scanNextAvailabilityTrigger = useEffect(() => {
		if (isMounted.current && !!props.availabilitySearchResults.availSearchBatchClientId) {
			scanNextAvailability();
		}
	}, [props.availabilitySearchResults.availSearchBatchClientId]);

	const nextScanCompletedTrigger = useEffect(() => {
		if(isMounted.current) {
			let nextScanResults = { ...props.nextAvailabilityScanResults };
			if(nextScanWasRun.current && nextScanResults.calendars?.length > 0) {
				const firstScanResultsForMissingCalendars = props.firstAvailabilityScanResults.calendars.filter(calendar => !nextScanResults.calendars.find(cal => calendar.calendarId === cal.calendarId));
				const allCalendarsWithScanResults = nextScanResults.calendars.concat(firstScanResultsForMissingCalendars);
				
				nextScanResults.calendars = allCalendarsWithScanResults;
				props.replaceNextScanResultsWithFirstScanResults(nextScanResults);

				nextScanWasRun.current = false;
			} 

			if(!initialSearchCompleted.current) {
				initialSearchCompleted.current = true;
			}
		}
	}, [props.nextAvailabilityScanResults.calendars])

	const metricsOnCompletionTrigger = useEffect(() => {
		if(isMounted.current) {
			const criteria = props.lastFinishedSearchCriteria;
			if(props.nextAvailabilityScanResults.calendars.length > 0 
				&& criteria.firstScan
				&& criteria.availability) {
				const duration = moment().diff(startTimeRef.current);
				if(!props.firstAvailabilityScanResults.hasAvailability) {
					appInsights.trackTrace({ message: `No availability was found from the initial scan - availability search was not run.` })
				} else {
					if(moment(criteria.firstScan.maxStartDate).isBefore(moment(criteria.availability.maxStartDate)))
					{
						appInsights.trackTrace({ message: `The search dates in availability search exceed the first scan. All applicable calendars will be included in the search.` })
					}
					if(criteria.availability.calendarIds?.length > 0) {
						appInsights.trackTrace({ message: `Availability Search was run for ${criteria.availability.calendarIds?.length} calendars from ${criteria.availability.minStartDate}-${criteria.availability.maxStartDate}.` })
					}

					if(!!criteria.nextScan && shouldRunNextAvailabilityScan(criteria.nextScan)) {
						appInsights.trackTrace({ message: `Next scan was run for ${criteria.nextScan.calendarIds?.length} calendars from ${criteria.nextScan.minStartDate}-${criteria.nextScan.maxStartDate}` });
					} else {
						appInsights.trackTrace({ message: "Next scan was not needed. Next scan results were replaced with first scan results" });
					}
				}
				appInsights.trackTrace({ message: `All searches and scans took ${duration}ms` });
			}
		}
	}, [initialSearchCompleted.current])

	/* eslint-disable */
	const goToBookAppointment = useEffect(() => {
		if (isMounted.current) {
			let timeSlotId = props.reservation?.flexCalendarEntryId;
			if (props.reservation.acknowledge && timeSlotId) {
				props.routeToBooking(props.routePrefix);
			}
		}
	}, [props.reservation, props.routeToBooking, props.activePatient]);

	const setSessionIdRef = useEffect(() => {
		sessionIdRef.current = sessionId;
	}, [sessionId]);

	//must be last useEffect
	const setIsMountedTrigger = useEffect(() => {
		if (!isMounted.current) {
			isMounted.current = true;
		}
	}, [isMounted]);
	/* eslint-enable */
	//#endregion

	//#region searches: calendar and availability
	const searchCalendars = (inputCriteria) => {
		let searchCriteria = mapCalendarSearchCriteria(inputCriteria || effectiveInputCriteria, props, sessionIdRef.current);
		props.searchCalendars(searchCriteria);
	};

	const scanFirstAvailability = (inputCriteria) => {
		let scanCriteria = mapFirstAvailabilityScanCriteria(inputCriteria || effectiveInputCriteria, props, sessionIdRef.current);
		props.scanFirstAvailability(scanCriteria);
	};

	const scanNextAvailability = (inputCriteria) => {
		let scanCriteria = mapNextAvailabilityScanCriteria(inputCriteria || effectiveInputCriteria, props, sessionIdRef.current);
		if(shouldRunNextAvailabilityScan(scanCriteria)) {
			props.scanNextAvailability(scanCriteria);
			nextScanWasRun.current = true;
		} else {
			props.replaceNextScanResultsWithFirstScanResults(props.firstAvailabilityScanResults);
		}
	}

	const searchAvailability = (inputCriteria) => {
		let searchCriteria = mapAvailabilitySearchCriteria(inputCriteria || effectiveInputCriteria, props, sessionIdRef.current);
		if (searchCriteria.calendarIds?.length > 0) {
			props.searchAvailability(searchCriteria);
		}
	};

	const shouldRunNextAvailabilityScan = (scanCriteria) => {
		const minStartDate = moment(scanCriteria.minStartDate);
		const maxStartDate = moment(scanCriteria.maxStartDate);
		const firstAvailabilityDate = moment(props.firstAvailabilityScanResults.firstAvailability?.date);
		return scanCriteria.calendarIds?.length > 0
			&& firstAvailabilityDate.isValid() 
			&& minStartDate.isSameOrBefore(maxStartDate)
	}

	const resetCriteriaSessionId = () => {
		setSessionId(uuidv4());
	}
	//#endregion

	//#region Reservation
	const reserveAppointmentRequest = (timeSlotInfo) => {
		props.reserveAppointment(timeSlotInfo, props.schedulingConfig.reservationDurationInMinutes, props.correlationKey);
	};
	//#endregion

	//#region error handling
	useEffect(() => {
		if (props.errors.length > 0) {
			let activeError = props.errors[0];
			scrollToPageTop();
			setErrorState({
				isErrorState: true,
				error: activeError.error.userMessage
					? activeError.error.userMessage
					: activeError.actionId === 'APPOINTMENT_RESERVE'
					? 'An error occurred while attempting to reserve timeslot.'
					: 'An error occurred while attempting to search.',
			});
		}
	}, [props.errors]);

	const clearError = () => {
		props.clearAllErrors();
		setErrorState(null);
	};
	//#endregion

	const availabilityResults = mapResults(effectiveInputCriteria, props);
	const isMobile = useMediaQuery({ query: `(max-width: ${MAX_MOBILE_WIDTH}px)` });

	const showReturnToQuestionsButton =
		props.availabilitySearchConfig.allowReturnToDecisionSupport &&
		props.grFlowSessionResponse?.flowSessionId?.length > 0 &&
		props.endedInGuidedResponse;

	const showScanNextRangeButton = props.availabilitySearchConfig.showScanNextRangeButton;
	const numberOfDaysToSearch = props.availabilitySearchConfig.availabilitySearchWindow;

	return (
		<AvailabilitySearch
			availabilityResults={availabilityResults}
			effectiveInputCriteria={effectiveInputCriteria}
			errorState={errorState}
			handleConfirmFilters={handleConfirmFilters}
			handleGoToDate={handleGoToDate}
			handleGoToPage={handleGoToPage}
			handleImmediateFilter={handleImmediateFilter}
			handleRequestAppointment={handleRequestAppointment}
			handleSelectCalendarDetails={handleSelectCalendarDetails}
			handleSelectSlot={handleSelectSlot}
			handleGoToPatientDetails={handleGoToPatientDetails}
			hasDoneFirstCalendarSearch={hasDoneFirstCalendarSearch}
			initialInputCriteria={initialInputCriteria}
			isMobile={isMobile}
			clearError={clearError}
			isShowing={isShowing}
			reserveAppointmentRequest={reserveAppointmentRequest}
			selectedSlot={selectedSlot}
			setSelectedSlot={setSelectedSlot}
			setIsShowing={setIsShowing}
			showReturnToQuestionsButton={showReturnToQuestionsButton}
			handleGoBackToGuidedResponse={handleGoBackToGuidedResponse}
			showScanNextRangeButton={showScanNextRangeButton}
			numberOfDaysToSearch={numberOfDaysToSearch}
			handleScanNextRange={handleScanNextRange}
			{...props}
		/>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			reserveAppointment,
			searchCalendars,
			scanFirstAvailability,
			scanNextAvailability,
			searchAvailability,
			routeToBooking,
			clearAllErrors,
			routeToPatientDetails,
			routeToRequestAppointment,
			routeToDecisionSupport,
			replaceNextScanResultsWithFirstScanResults
		},
		dispatch,
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		//Session
		productInstanceId: state.auth.productInstanceId,
		correlationKey: state.session.correlationKey,
		token: state.auth.token,
		errors: state.apiErrors.activeErrors,
		applicationInsightsKey: state.auth.applicationInsightsKey,
		decisionSupportSessionId: state.session.decisionSupportSessionId,
		grFlowSessionResponse: state.guidedResponse.flowSessionResponse,

		//Config
		availabilitySearchConfig: state.config.availabilitySearch,
		availabilitySearchFieldConfig: state.config.availabilitySearch.searchFields,
		availabilitySearchSupportData: state.config.availabilitySearchSupportData,
		clientBrandingConfig: state.config.clientBranding,
		decisionSupportConfig: state.config.decisionSupport,
		patientConfig: state.config.patient,
		providerFieldConfig: state.config.provider,
		schedulingConfig: state.config.scheduling,
		routePrefix: state.config.instance.routePrefix,

		//Context
		activeCareOrder: state.careOrder,
		activePatient: state.patient,
		agentInstructions: state.appointment.agentInstructions,
		endedInGuidedResponse: state.decisionSupport.endedInGuidedResponse,
		schedContext: {
			hasPatient: state.activePatient?.details?.referenceId && state.activePatient.details.referenceId !== '',
			hasCareOrder: !!state.careOrder?.careOrderVisitIdentifier || !!state.careOrder.externalReferralOrderId,
		},

		//Loading
		isLoading: {
			calendarSearch: state.availabilitySearch.isLoading.calendarSearch,
			firstAvailabilityScan: state.availabilitySearch.isLoading.firstAvailabilityScan,
			nextAvailabilityScan: state.availabilitySearch.isLoading.nextAvailabilityScan,
			availabilitySearch: state.availabilitySearch.isLoading.availabilitySearch,

			anySearch:
				state.availabilitySearch.isLoading.calendarSearch ||
				state.availabilitySearch.isLoading.firstAvailabilityScan ||
				state.availabilitySearch.isLoading.nextAvailabilityScan ||
				state.availabilitySearch.isLoading.availabilitySearch,

			isReserving: state.appointment.isLoading.reserveAppointment,
			loadingMessage:
				state.availabilitySearch.isLoading.loadingMessage || state.appointment.isLoading.loadingMessage || '',
		},

		//Search Criteria
		availabilitySearchCriteria: state.availabilitySearch.availabilitySearchCriteria,
		calendarSearchCriteria: state.availabilitySearch.calendarSearchCriteria,
		lastFinishedSearchCriteria: state.availabilitySearch.lastFinishedSearchCriteria,

		//Results
		calendarSearchResults: state.availabilitySearch.calendarSearchResults,
		firstAvailabilityScanResults: state.availabilitySearch.firstAvailabilityScanResults,
		nextAvailabilityScanResults: state.availabilitySearch.nextAvailabilityScanResults,
		availabilitySearchResults: state.availabilitySearch.availabilitySearchResults,

		//Appointment
		reservation: state.appointment.reservation,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilitySearchView);
