import React from 'react';
import { DropdownSelect } from './dropdownSelect';

/**
* @param {Object} props
* @param {string} props.id
* @param {Object} props.error
* @param {string} props.error.message
* @param {boolean} props.disabled
* @param {boolean} props.isFocused
* @param {boolean} props.isRequired
* @param {boolean} props.isVisible - If False Component Returns null
* @param {boolean} props.label
* @param {boolean} props.labelAfter - If true, label is placed after input (default before)
* @param {string} props.name
* @param {Object} props.value
* @param {Function} props.onBlur
* @param {Function} props.onChange
* @param {Function} props.onFocus
* @param {Array} props.optionList
* @param {string} props.size - "sm", "md", "lg"
* @returns {Object}
*/
export function GenderSelections(props) {
	const {
		id,
		error,
		disabled,
		isFocused,
		isRequired,
		isVisible,
		label,
		labelAfter,
		name,
		value,
		onBlur,
		onChange,
		onFocus,
		optionList,
		size,
	} = props;

	return (
		<DropdownSelect
			id={id}
			error={error}
			disabled={disabled}
			isFocused={isFocused}
			isRequired={isRequired}
			isVisible={isVisible}
			label={label}
			labelAfter={labelAfter}
			name={name}
			value={value}
			onBlur={onBlur}
			onChange={onChange}
			onFocus={onFocus}
			optionList={optionList}
			size={size}
		/>
	);
}
