import * as types from '../actions/actionTypes';
import { validateGuid } from '../lib/validation';

const initialState = {
	details: {},
	externalReferralOrderId: null,
	reservation: {},
	reservationDetails: {},
	icsFile: '',
	errorMessage: '',
	symptomDetails: {
		reasonForVisit: '',
	},
	isLoading: {
		cancelAppointment: false,
		reserveAppointment: false,
		loadingMessage: '',
	},
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.APPOINTMENT_CANCEL:
			return {
				...state,
				isLoading: {
					...state.isLoading,
					cancelAppointment: true,
					loadingMessage: 'Attempting to cancel appointment.',
				},
			};

		case types.APPOINTMENT_CANCEL_ERROR:
			return {
				...state,
				isLoading: {
					...state.isLoading,
					cancelAppointment: false,
					loadingMessage: null,
				},
			};

		case types.APPOINTMENT_CANCEL_SUCCESS:
			return {
				...state,
				details: {
					...state.details,
					isCancelled: true,
				},
				isLoading: {
					...state.isLoading,
					cancelAppointment: false,
					loadingMessage: null,
				},
			};

		case types.APPOINTMENT_RESERVE:
			return {
				...state,
				isLoading: {
					...state.isLoading,
					reserveAppointment: true,
					loadingMessage: 'Attempting to reserve timeslot.',
				},
			};

		case types.APPOINTMENT_RESERVE_SUCCESS:
			if (!validateGuid(action.payload.data.reservationId.replace('{', '').replace('}', ''))) {
				throw Error('Sorry, the selected time slot is no longer available. Please select another one.');
			} else {
				return {
					...state,
					reservation: action.payload.data,
					isLoading: {
						...state.isLoading,
						reserveAppointment: false,
						loadingMessage: '',
					},
				};
			}

		case types.APPOINTMENT_RESERVE_ERROR:
			return {
				...state,
				isLoading: {
					...state.isLoading,
					reserveAppointment: false,
					loadingMessage: '',
				},
			};

		case types.APPOINTMENT_RESERVE_DETAILS_SUCCESS:
			return {
				...state,
				reservationDetails: action.payload.data,
			};
		case types.APPOINTMENT_BOOK_NEW_PATIENT_SUCCESS:
			if (!action.payload.data.isAppointmentBooked) {
				return {
					...state,
					errorMessage: action.payload.data.messages[0],
				};
			} else {
				return {
					...state,
					details: action.payload.data,
				};
			}
		case types.APPOINTMENT_BOOK_SUCCESS:
			return {
				...state,
				details: action.payload.data,
			};
		case types.APPOINTMENT_DETAILS_SUCCESS:
			return {
				...state,
				details: action.payload.data,
				reservation: {},
			};
		case types.APPOINTMENT_PATIENT_ICS_SUCCESS:
			return {
				...state,
				icsFile: action.payload.data.Entity,
			};
		case types.GET_BOOKING_TRANSITION_DETAILS:
			return {
				...initialState,
			};
		case types.GET_BOOKING_TRANSITION_DETAILS_SUCCESS:
			return {
				...state,
				reservation: action.payload.data.reservation,
			};
		case types.SET_INITIAL_APPOINTMENT_DETAILS:
			return {
				...state,
				externalReferralOrderId: action.externalReferralOrderId,
				symptomDetails: {
					...state.symptomDetails,
					reasonForVisit: action.symptomDetails.reasonForVisit,
				},
			};
		default:
			return state;
	}
}
