import React from 'react';

export function Modal(props) {
	const { children, containerId, modalId, onClickOffModalHandler } = props;

	return (
		<div className="modal-container" id={containerId} onClick={onClickOffModalHandler}>
			<div className="modal-window" id={modalId}>
				<div className="modal-content">{children}</div>
			</div>
		</div>
	);
}
