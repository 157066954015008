import React from 'react';
import { Modal } from '../../../components/modal/modal';
import ConditionsOfAccess from '../../../components/legal/conditionsOfAccess';
import * as Icon from '../../../components/icons';

export function ConditionsOfAccessModal(props) {
	const { toggleModal } = props;
	return (
		<Modal containerId="disclaimerModalContainer" modalId="disclaimerModal" onClickOffModalHandler={toggleModal}>
			<div className="filter-modal-header">
				<h1>Conditions of Access</h1>
				<Icon.IconClose onClick={toggleModal} />
			</div>
			<div className="row">
				<div className="col scrollable">
					<ConditionsOfAccess />
				</div>
			</div>
		</Modal>
	);
}
