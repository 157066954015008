import React from 'react';
import PropTypes from 'prop-types';

import './instanceUnavailable.css';

export default function InstanceUnavailable(props) {
	const { message } = props;

	return (
		<div className="message instance-unavailable">
			<h4
				dangerouslySetInnerHTML={{
					__html: message && message.trim() !== '' ? message : 'Instance Unavailable',
				}}
			/>
		</div>
	);
}

InstanceUnavailable.propTypes = {
	message: PropTypes.string,
};
