import { INPUT_TYPES } from '../../constants/inputTypes';
import {
	mapDataToFieldConfig_Date,
	mapDataToFieldConfig_Year,
	mapDataToFieldConfig_Zip,
} from '../../lib/fieldConfigUtils';

export const getFieldsConfig = (props) => {
	let config = {
		patientAuth: {
			birthYear: {
				...mapDataToFieldConfig_Year({
					fieldConfig: {
						fieldLabel: props.patientAuth.validateBirthYear.fieldLabel,
						isEnabled: !props.patientAuthContext.isLockedOut && props.patientAuth.validateBirthYear.isEnabled,
						isVisible: !props.patientAuthContext.isLockedOut && props.patientAuth.validateBirthYear.isVisible,
						isEditable: !props.patientAuthContext.isLockedOut && props.patientAuth.validateBirthYear.isEditable,
						isRequired: !props.patientAuthContext.isLockedOut && props.patientAuth.validateBirthYear.isRequired,
					},
					inputType: INPUT_TYPES.YEAR,
				}),
				defaultValue: '',
				sampleValue: '',
			},
			dateOfBirth: {
				...mapDataToFieldConfig_Date({
					fieldConfig: {
						fieldLabel: props.patientAuth.validateDateOfBirth.fieldLabel,
						isEnabled: !props.patientAuthContext.isLockedOut && props.patientAuth.validateDateOfBirth.isEnabled,
						isVisible: !props.patientAuthContext.isLockedOut && props.patientAuth.validateDateOfBirth.isVisible,
						isEditable: !props.patientAuthContext.isLockedOut && props.patientAuth.validateDateOfBirth.isEditable,
						isRequired: !props.patientAuthContext.isLockedOut && props.patientAuth.validateDateOfBirth.isRequired,
					},
					inputType: INPUT_TYPES.DATE,
				}),
				defaultValue: '',
				sampleValue: '',
			},
			zipCode: {
				...mapDataToFieldConfig_Zip({
					fieldConfig: {
						fieldLabel: props.patientAuth.validateZipCode.fieldLabel,
						isEnabled: !props.patientAuthContext.isLockedOut && props.patientAuth.validateZipCode.isEnabled,
						isVisible: !props.patientAuthContext.isLockedOut && props.patientAuth.validateZipCode.isVisible,
						isEditable: !props.patientAuthContext.isLockedOut && props.patientAuth.validateZipCode.isEditable,
						isRequired: !props.patientAuthContext.isLockedOut && props.patientAuth.validateZipCode.isRequired,
					},
					inputType: INPUT_TYPES.ZIP_CODE,
				}),
				defaultValue: '',
				sampleValue: '',
			},
		},
	};

	return config;
};
