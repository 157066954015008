import React, {useState} from 'react';
import { Alert, Button, CardHeader } from 'reactstrap';
import { Accordion, Typography, DataTable, ReadonlyField } from './layouts';
import { isNullOrUndefined } from "is-what";
import { useRef } from 'react';

export function DebugComponent({ flowSessionResponse }) {
	const { eventLog, elementSet, hasValidationErrors, validationMessages } = flowSessionResponse || {};
	const [dataPoolAlphabetized, setDataPoolAlphabetized] = useState(false)
	const dataPoolFields = flowSessionResponse?.dataPoolFields?.filter(
		(dp) => !dp.fieldName.includes('QuestionSelectionOptions'),
	);

	const alphabetizedDataPoolFields = !isNullOrUndefined(dataPoolFields) ? JSON.parse(JSON.stringify(dataPoolFields)).sort((a,b) => a.fieldName > b.fieldName ? 1 : -1) : null;

	const DataPoolHeader  = () => {
		const alphabetize = (e) => {
			e.stopPropagation()
			setDataPoolAlphabetized(!dataPoolAlphabetized);
		}

		return (
			<>
				<span>
					Current Data Pool
				</span>
				<Button onClick={alphabetize} style={{margin: '-.2em 0 0 .75em', padding: '.25em', lineHeight: '1em'}}>
					{dataPoolAlphabetized ? 'Default Order' : 'Sort A-Z'}
				</Button>
			</>
	)};

	return (
		<>
			<div className="decision-support debug">
				<div className={'guided-response main-content'}>
					{hasValidationErrors && (
						<Alert color="danger">
							<ul>
								{validationMessages.map((message, index) => <li key={`${message}--${index}`}>{message}</li>)}
							</ul>
						</Alert>
					)}
					<CardHeader>
						<Typography component="h5">Flow Id: {flowSessionResponse?.flowId}</Typography>
						<Typography component="h5">Current Element Set: {elementSet?.name}</Typography>
					</CardHeader>
					<div>
						<Accordion startOpened header="Event Log">
							<p className="log">Log of expressions that were evaluated since the previous step</p>
							<DebugSectionComponent data={eventLog} className="runtime-log" sectionName="eventLog" />
						</Accordion>
					</div>
					<div>
						<Accordion startOpened header={<DataPoolHeader/>}>
							<DebugSectionComponent data={dataPoolAlphabetized ? alphabetizedDataPoolFields : dataPoolFields} className="data-pool" sectionName="dataPool" />
						</Accordion>
					</div>
				</div>
			</div>
		</>
	);
}

const DataPoolValueField = ({comp, detailInfo}) => {
	const [showDetail, setShowDetail] = useState(false);
	const detailRef = useRef()

	return (
		<span onMouseEnter={() => setShowDetail(true)} onMouseLeave={() => setShowDetail(false)}>
			<ReadonlyField><span ref={detailRef}>{comp}</span>{detailInfo && showDetail ? 
				<div style={{
				position: 'fixed', 
				backgroundColor: '#ecebeb', 
				padding: '.25em', 
				marginRight: '1.5em',
				border: '1px solid black', 
				borderRadius: '2px', 
				boxShadow: '2px 2px 5px black',
				overflowY: 'scroll',
				lineHeight: '1em',
				fontSize: '.9em',
				maxHeight: '10em',
				zIndex: '999',
				top: detailRef.current.getBoundingClientRect().y + detailRef.current.getBoundingClientRect().height
			}}>
					{detailInfo.map((info, index) => {
						const style = index < detailInfo.length -1 ? {padding: '.5em .1em .5em .1em', borderBottom: '1px solid black'} : {padding: '.5em .1em .5em .1em'};
						return <div key={`detail-info--${index}`} style={style}>{info}</div>
					})}
				</div> : ''}
			</ReadonlyField>
		</span>
	)
}

export function DebugSectionComponent({ data, className, sectionName }) {
	let columns;
	if (sectionName === 'dataPool') {
		columns = [
			{
				header: 'Field Name',
				render: 'fieldName',
				sortable: false,
				headerTooltipKey: 'fieldName',
			},
			{
				header: 'Value Type',
				render: ({ valueType, dataSourceType }) => {
					const type =
						valueType === 'ObjectList' || valueType === 'Object' ? `${valueType} - ${dataSourceType}` : valueType;
					return <ReadonlyField>{type}</ReadonlyField>;
				},
				sortable: false,
				headerTooltipKey: 'valueType',
			},
			{
				header: 'Current Value',
				render: ({ valueType, currentValue, dataSourceType }) => {
					let comp;
					let detailInfo;
					if (valueType === 'ObjectList') {
						const valueObjArr = JSON.parse(currentValue)
						let currentValueCount = GetCount(valueObjArr);
						comp = `[...] (${currentValueCount} items)`;
						if(currentValueCount > 0) detailInfo = valueObjArr.map(obj => JSON.stringify(obj, null, 2))
					} else if (valueType === 'Object') {
						let currentValueObj = GetComplexCurrentValue(dataSourceType, currentValue);
						comp = `{${currentValueObj}  ...}`;
						detailInfo = [currentValue]
					} else {
						comp = GetSimpleFormattedValue(currentValue);
					}
					return <DataPoolValueField comp={comp} detailInfo={detailInfo}/>;
				},
				sortable: false,
				headerTooltipKey: 'currentValue',
			},
			{
				header: 'Value History',
				render: ({ dataFieldChangeHistory }) => {
					const history = dataFieldChangeHistory !== '' ? dataFieldChangeHistory : 'Value History is not supported for this Value Type.';
					return <ReadonlyField>{history}</ReadonlyField>;
				},
				sortable: false,
				headerTooltipKey: 'dataFieldChangeHistory',
			}
		];
	} else if (sectionName === 'eventLog') {
		columns = [
			{
				header: 'Type',
				render: ({ elementRef }) => {
					const type = elementRef !== null ? 'Element' : 'Element Set';
					return <ReadonlyField>{type}</ReadonlyField>;
				},
				sortable: false,
				headerTooltipKey: 'type',
			},
			{
				header: 'Name',
				render: ({ elementRef, elementSetRef }) => {
					const type = elementRef !== null ? elementRef : elementSetRef;
					return <ReadonlyField>{type}</ReadonlyField>;
				},
				sortable: false,
				headerTooltipKey: 'name',
			},
			{
				header: 'Expression Type',
				render: 'expressionType',
				sortable: false,
				headerTooltipKey: 'expressionType',
			},
			{
				header: 'Field/Filter Name',
				render: ({ fieldName, filterName }) => {
					const field = filterName ?? fieldName;
					return <ReadonlyField>{field}</ReadonlyField>;
				},
				sortable: false,
				headerTooltipKey: 'fieldName',
			},
			{
				header: 'Value Type',
				render: 'valueType',
				sortable: false,
				headerTooltipKey: 'valueType',
			},
			{
				header: 'Result Value',
				render: 'resultValue',
				sortable: false,
				headerTooltipKey: 'resultValue',
			},

			{
				header: 'Expression Text',
				render: 'expressionText',
				sortable: false,
				headerTooltipKey: 'expressionText',
			},
		];
	}

	return (
		<div className={className}>
			<DataTable striped bordered columns={columns} loading={!data} data={data ?? []} />
		</div>
	);
}

export function GetSimpleFormattedValue(currentValue) {
	var value;
	switch (currentValue) {
		case null:
			value = 'null';
			break;
		case 'True':
			value = 'true';
			break;
		case 'False':
			value = 'false';
			break;
		default:
			value = currentValue;
			break;
	}
	return value;
}

export function GetCount(currentValue) {
	if (currentValue === null || currentValue === undefined) return null;

	let count = currentValue.length;

	return count;
}

export function GetComplexCurrentValue(dataSourceType, currentValue) {
	let currentValueObj;
	let parsedValue = JSON.parse(currentValue);
	switch (dataSourceType) {
		case 'AppointmentType':
			currentValueObj = parsedValue?.AppointmentTypeName ?? null;
			break;
		case 'Calendar':
			currentValueObj = parsedValue?.CalendarId ?? null;
			break;
		case 'Service':
			currentValueObj = parsedValue?.ServiceName ?? null;
			break;
		case 'Specialty':
			currentValueObj = parsedValue?.SpecialtyName ?? null;
			break;
		case 'Site':
			currentValueObj = parsedValue?.SiteName ?? null;
			break;
		case 'State':
			currentValueObj = parsedValue?.StateCode ?? null;
			break;
		case 'System':
			currentValueObj = parsedValue?.SystemName ?? null;
			break;
		case 'Insurance':
			currentValueObj = parsedValue?.InsuranceProvider ?? null;
			break;
		case 'Custom':
			currentValueObj = parsedValue?.DisplayName ?? null;
			break;
		case null:
		case undefined:
		default:
			currentValueObj = null;
			break;
	}
	return currentValueObj;
}
