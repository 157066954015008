import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AppliedPathwaysQuestion_Text extends Component {
	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.asset === prevState.asset) {
			return null;
		}
		if (nextProps.asset.value === prevState.value) {
			return null;
		}
		return {
			asset: nextProps.asset,
			value: nextProps.asset.value || '',
		};
	}

	constructor(props) {
		super(props);
		this.state = {
			asset: {},
			value: '',
		};
	}

	componentDidMount() {
		this.setState({ asset: this.props.asset, value: this.props.asset.value || '' });
	}

	handleChange = ({ target: { name, value } }) => {
		const { onChange } = this.props;
		this.setState({ value });
		return onChange({ target: { name, value } });
	};

	render() {
		return (
			<input
				className="ap-question inputfield form-control"
				type="text"
				id={this.props.asset.id}
				name={this.props.asset.id}
				required={this.props.asset.is_required}
				maxLength={this.props.asset.max_length}
				value={this.state.value}
				onChange={this.handleChange}
			/>
		);
	}
}

AppliedPathwaysQuestion_Text.propTypes = {
	asset: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default AppliedPathwaysQuestion_Text;
