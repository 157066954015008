import * as actionTypes from './actionTypes';
import moment from 'moment';

moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss';

export function getSurveyOrder(referenceId) {
	return {
		type: actionTypes.GET_SURVEY_ORDER,
		payload: {
			request: {
				method: 'get',
				url: `surveyorder/${referenceId}`,
			},
		},
	};
}

export function getFullSurveyOrder(referenceId) {
	return {
		type: actionTypes.GET_FULL_SURVEY_ORDER_DETAILS,
		payload: {
			request: {
				method: 'get',
				url: `surveyorder/${referenceId}/details`,
			},
		},
	};
}

export function startSurveyOrder(referenceId) {
	return {
		type: actionTypes.START_SURVEY_ORDER,
		payload: {
			request: {
				method: 'put',
				url: `surveyorder/${referenceId}/status`,
				data: {
					statusReason: 'Started',
					notes: 'The survey started by consumer.',
				},
			},
		},
	};
}

export function completeSurveyOrder(referenceId, decisionSupportSessionId) {
	return {
		type: actionTypes.COMPLETE_SURVEY_ORDER,
		payload: {
			request: {
				method: 'put',
				url: `surveyorder/${referenceId}/status`,
				data: {
					decisionSupportSessionId: decisionSupportSessionId,
					statusReason: 'Completed',
					notes: 'The survey completed by consumer.',
				},
			},
		},
	};
}
