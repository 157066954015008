//3rd party
import React from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

//actions
import { initializeSurveyOrderProcess, setEntryPointUrl, redirectToSurveyProcess } from '../../actions/workflowActions';
import { getSurveyOrder, getFullSurveyOrder } from '../../actions/surveyOrderActions';

//constants
import * as flowTypes from '../../constants/flowTypeEnum';

//lib

//comps
import { LoadingIndicatorModal } from '../../components/loadingIndicator/loadingIndicatorModal';

const DEFAULT_INVALID_SURVEY_MESSAGE = 'This survey is expired or is no longer active.';

export class StartSurveyOrderRedirector extends React.Component {
	constructor(props) {
		super(props);
		this.surveyOrderIdentifier = this.props.match.params.surveyOrderId;
		this.state = {
			alertMessage: '',
			showLoadingIndicator: true,
			validSurveyLoaded: false,
			showAlert: false,
		};
	}

	dismissAlert = () => {
		this.setState({ showAlert: false });
	};

	componentDidMount() {
		if (!this.props.config.surveyOrder.enableSurveyOrders) {
			this.setState({
				showLoadingIndicator: false,
				alertMessage: 'Survey orders are not enabled for this system.',
			});
		} else if (this.props.config.patientAuth.enablePatientAuth) {
			this.props.setEntryPointUrl(flowTypes.SURVEY_ORDER_FLOW, this.surveyOrderIdentifier);
			this.loadSurveyContextForPatientAuth(this.surveyOrderIdentifier);
		} else {
			this.loadFullSurveyOrderAndRedirect(this.surveyOrderIdentifier);
		}
	}

	loadSurveyContextForPatientAuth = (surveyOrderId) => {
		this.setState({ showLoadingIndicator: true }, () => {
			this.props
				.getSurveyOrder(surveyOrderId)
				.then((response) => {
					if (response.error) {
						this.setState({
							showLoadingIndicator: false,
							alertMessage: 'An error occurred while trying to retrieve survey details.',
						});
					} else {
						if (response.payload.data.statusCode === 'C' || response.payload.data.expirationDate < moment.utc()) {
							let invalidSurveyMessage = this.props.config.surveyOrder.invalidSurveyOrderMessage
								? this.props.config.surveyOrder.invalidSurveyOrderMessage
								: DEFAULT_INVALID_SURVEY_MESSAGE;
							this.setState({
								showAlert: true,
								showLoadingIndicator: false,
								alertMessage: invalidSurveyMessage,
							});
						} else {
							this.setState({ showLoadingIndicator: false, validSurveyLoaded: true });
						}
					}
				})
				.catch((err) => {
					this.setState({
						alertMessage: 'An error occurred while trying to retrieve survey details.',
						showLoadingIndicator: false,
					});
				});
		});
	};

	loadFullSurveyOrderAndRedirect = (referenceId) => {
		this.setState({
			showLoadingIndicator: true,
		});
		this.props
			.getFullSurveyOrder(referenceId)
			.then((response) => {
				if (response.error) {
					this.setState({
						showLoadingIndicator: false,
						alertMessage: 'An error occurred while trying to retrieve survey details.',
					});
				} else {
					if (
						response.payload.data.surveyOrderDetails.statusCode === 'C' ||
						response.payload.data.surveyOrderDetails.expirationDate < moment.utc()
					) {
						let invalidSurveyMessage = this.props.config.surveyOrder.invalidSurveyMessage
							? this.props.config.surveyOrder.invalidSurveyMessage
							: DEFAULT_INVALID_SURVEY_MESSAGE;
						this.setState({
							showAlert: true,
							showLoadingIndicator: false,
							alertMessage: invalidSurveyMessage,
						});
					} else {
						this.setState({
							showLoadingIndicator: false,
							validSurveyLoaded: true,
						});
						this.props.redirectToSurveyProcess(
							this.props.patientAuthContext,
							this.props.surveyOrderContext,
							this.props.config.instance.routePrefix,
						);
					}
				}
			})
			.catch((err) => {
				this.setState({
					isVerifying: false,
				});
			});
	};

	render() {
		let { showLoadingIndicator, validSurveyLoaded } = this.state;

		if (this.props.surveyOrderId && !this.props.patientAuthContext.isVerified && validSurveyLoaded) {
			this.props.initializeSurveyOrderProcess(
				this.props.patientAuthContext,
				this.props.surveyOrderId,
				this.props.config.instance.routePrefix,
			);
			return null;
		} else {
			return (
				<div>
					{!showLoadingIndicator && !validSurveyLoaded && (
						<main className={'main-content'}>
							<div className="div-availability-survey-header auth-error">
								<div>
									<h4 className="availability-survey-header">Survey Unavailable</h4>
								</div>
								<Row>
									<Col>
										<p className="error-message">
											<span dangerouslySetInnerHTML={{ __html: this.state.alertMessage }} />
										</p>
									</Col>
								</Row>
							</div>
						</main>
					)}
					{showLoadingIndicator && <LoadingIndicatorModal loadingMessage="Fetching Survey Data..." />}
				</div>
			);
		}
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.auth,
		config: state.config,
		correlationKey: state.session.correlationKey,
		guidedResponseFlowId: state.surveyOrder.surveyFlowRefValue,
		patientAuthContext: state.patient.patientAuthContext,
		surveyOrderId: state.surveyOrder.referenceId,
		token: state.auth.token,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setEntryPointUrl,
			getSurveyOrder,
			getFullSurveyOrder,
			initializeSurveyOrderProcess,
			redirectToSurveyProcess,
		},
		dispatch,
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(StartSurveyOrderRedirector);
