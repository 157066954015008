import dayjs from 'dayjs';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from '../features/searchAsync/availabilitySlice';

export const nullIfZero = (value) => {
	if (value === 0)
		return null;
	else
		return value;
}

export const deepCopy = (value) => JSON.parse(JSON.stringify(value));

export const filterArrayDuplicatesByPropName = (array, propName) => {
	const uniqueResults = [];
	const map = new Map();
	for (const item of array) {
		if (!map.has(item[propName])) {
			map.set(item[propName], true);    // set any value to Map
			uniqueResults.push({
				...item
			});
		}
	}
	return uniqueResults;
}

export function resolveGenderList(allowUnknownGender, unknownGenderOptionText) {
	let genderOptions = [{ id: "M", value: "M", label: "Male", name: "Male" }, { id: "F", value: "F", label: "Female", name: "Female" }];
	if (allowUnknownGender) {
		genderOptions.push({ id: "U", value: "U", label: `${unknownGenderOptionText}`, name: `${unknownGenderOptionText}` });
	};
	return genderOptions;
}

export const scrollToPageTop = () => {
	window.scrollTo({ top: 0, behavior: 'smooth' });
}

export const sortKeys = (obj) => {
	return Object.keys(obj).sort().reduce((r, k) => { r[k] = obj[k]; return r; }, {})
}

export const stringContains = (searchIn, searchFor) => {
	return searchIn.toLowerCase().indexOf(searchFor.toLowerCase()) !== -1;
};


export function displayFullName(firstName, lastName) {
	let fullName = `${firstName && firstName.trim() !== '' ? firstName : ''} ${lastName && lastName.trim() !== '' ? lastName : ''}`;
	return fullName;
}

export function displayProviderNameWithProfessionalDesignation(providerName, professionalDesignation) {
	let fullName = `${providerName}`;
	if (professionalDesignation && professionalDesignation.trim() !== '') {
		fullName += `, ${professionalDesignation}`;
	}
	return fullName;
}

export function genderCodeToDisplayName(genderCode) {
	let genderDisplay;
	if (genderCode && genderCode.toUpperCase() === 'M') {
		genderDisplay = 'Male';
	} else if (genderCode && genderCode.toUpperCase() === 'F') {
		genderDisplay = 'Female';
	} else if (genderCode && genderCode.toUpperCase() === 'U') {
		genderDisplay = 'Unknown';
	} else {
		genderDisplay = '-';
	}
	return genderDisplay;
}

export function formatCityStateZip(city, stateCode, zip) {
	return `${city}, ${stateCode} ${zip}`;
}

export function formatPhone(phone, prependLabel) {
	let result = '';
	if (phone && phone.trim() !== '') {
		const cleanPhone = phone.replace(/\D/g, '');
		let match = cleanPhone.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (prependLabel) {
			result = `${prependLabel} `;
		}
		if (match) {
			result += '(' + match[1] + ') ' + match[2] + '-' + match[3];
		}
	}
	return result;
}

export function formatPhoneNoLabel(phone) {
	let result = '';
	if (phone && phone.trim() !== '') {
		const cleanPhone = phone.replace(/\D/g, '');
		let match = cleanPhone.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			result = '(' + match[1] + ') ' + match[2] + '-' + match[3]
		};
	}
	return result;
}

export function formatServiceName(firstName, lastName, assetTag) {
	let fname = firstName ? firstName.trim() : "";
	let lname = lastName ? lastName.trim() : "";
	let fullName = "";
	if (assetTag && assetTag.trim() !== "") {
		fullName = assetTag;
	} else {
		fullName = `${fname} ${lname}`;
	}
	return fullName;
}

export function verifyFuture(date) {
	return moment.utc(date).isAfter();
}

export function replaceLineFeedsWithBreakRegex(string) {
	const lineFeedsRegex = new RegExp(/(\r\n?|\n)/g);
	return string.replace(lineFeedsRegex, "<br />");
}

export function isEmpty(obj) {
	return Object.keys(obj).length === 0;
}

export const generateDateArray = (minDate, maxDate) => {
	const dateArray = [];
	for (let start = dayjs(minDate); start.diff(maxDate, 'd') <= 0; start = start.add(1, 'd')) {
		dateArray.push(start.format(DEFAULT_DATE_FORMAT))
	}
	return dateArray;
}
