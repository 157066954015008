/**
 * @format
 */

import React, { useState } from 'react';
import { ProviderNameAndSpecialty } from '../provider/providerNameAndSpecialty';
import { ProviderSiteAddressAndPhone } from '../provider/providerSiteAddressAndPhone';
import { SiteNameAndDistance } from '../misc/siteNameAndDistance';
import { ProviderAvailabilitySlots } from '../misc/providerAvailabilitySlots';
import * as Button from '../buttons';

export function ProviderDetailsAndSlots(props) {
	const {
		availabilityResult,
		availabilityResults,
		availabilitySearchConfig,
		handleSelectSlot,
		handleSelectCalendarDetails,
		providerFieldConfig,
		calendarSearchCriteria,
		calendarSearchResults,
		schedulingConfig,
		isMobile,
		toggleBioModal,
		handleGoBackToGuidedResponse,
		showReturnToQuestionsButton,
	} = props;

	const calendarDetails = availabilityResults.singleCalendarDetails;
	const shouldShowProviderAddress = calendarDetails && !calendarDetails.suppressAddress;
	const shouldShowProviderPhone =
		providerFieldConfig.phone &&
		providerFieldConfig.phone.isVisible &&
		calendarDetails &&
		!calendarDetails.suppressPhoneNumber;
	let providerImage = calendarDetails ? (
		<img
			src={calendarDetails.imageFile || '/resources/generic-provider-photo.jpg'}
			alt={calendarDetails.serviceName + ' Provider'}
		/>
	) : null;

	const shouldShowBackButton = calendarSearchResults.calendars && calendarSearchResults.calendars.length > 1;
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div className="provider-and-slots-container">
			<SiteNameAndDistance
				distanceMiles={calendarDetails?.distanceInMiles}
				providerSiteDisplayName={calendarDetails?.siteName}
				zipCode={calendarSearchCriteria.distanceFilter.zipCode}
				showDistanceMiles={schedulingConfig.showProviderDetailsDistance}
			/>
			<div className="provider-details-container">
				<div className="provider-image-container">{providerImage}</div>
				<div>
					<ProviderNameAndSpecialty
						isProviderClickable={false}
						providerDisplayName={calendarDetails?.serviceName}
						providerSpecialty={calendarDetails?.specialtyName}
						serviceSiteId={calendarDetails?.calendarId}
						showBiographyLink={!!calendarDetails?.bio}
						toggleBioModal={toggleBioModal}
					/>
					<ProviderSiteAddressAndPhone
						city={calendarDetails?.city}
						phoneLabel={providerFieldConfig.phone.fieldLabel}
						phoneNumber={calendarDetails?.phone}
						providerAddressLine1={calendarDetails?.address1}
						providerAddressLine2={calendarDetails?.address2}
						providerSiteDisplayName={calendarDetails?.siteName}
						showAddress={shouldShowProviderAddress}
						showPhone={shouldShowProviderPhone}
						state={calendarDetails?.state}
						zipCode={calendarDetails?.zipCode}
					/>
				</div>
			</div>
			<ProviderAvailabilitySlots
				availabilityResult={availabilityResult}
				enableAppointmentTypeTooltips={availabilitySearchConfig.enableAppointmentTypeTooltips}
				isExpanded={isExpanded}
				isMobile={isMobile}
				handleSelectSlot={handleSelectSlot}
				setIsExpanded={setIsExpanded}
				slotColumnsPerDay={
					availabilitySearchConfig.slotColumnsPerDay || availabilitySearchConfig.slotColumnsPerDayMobile
				}
				slotColumnsPerDayMobile={
					availabilitySearchConfig.slotColumnsPerDayMobile || availabilitySearchConfig.slotColumnsPerDay
				}
				slotsPerDay={availabilitySearchConfig.slotsPerDay}
				slotsPerDayMobile={availabilitySearchConfig.slotsPerDayMobile}
				showDistanceMiles={schedulingConfig.showAvailabilitySearchDistance}
				showSingleCalendar={availabilityResults.showSingleCalendar}
				{...props}
			/>
			<div className="back-to-search-results-container">
				{shouldShowBackButton && (
					<Button.ButtonSecondary
						className="back-to-search-results-btn"
						onClick={() => handleSelectCalendarDetails(null, availabilityResults.minAllowedMonthStartDate)}
					>
						Return to Search Results
					</Button.ButtonSecondary>
				)}
				{showReturnToQuestionsButton && (
					<Button.ButtonSecondary
						className="return-to-questions-btn"
						onClick={handleGoBackToGuidedResponse}
					>
						Return to Questions
					</Button.ButtonSecondary>
				)}
			</div>
		</div>
	);
}
