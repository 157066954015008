import { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-solid-svg-icons";

export function PanelWithMobileCollapse(props) {
	const { alwaysVisibleContent, conditionalContent, handleToggleCollapse, name } = props;
	const conditionalClassName = props.conditionalClassName ? props.conditionalClassName : 'doesCollapse';
	const dragHandleClassName = props.dragHandleClassName ? props.dragHandleClassName : 'drag';
	const sectionClassName = props.sectionClassName ? props.sectionClassName : '';
	const [isContentCollapsed, setIsContentCollapsed] = useState(true);
	return (
		<section className={sectionClassName} onClick={() => { setIsContentCollapsed(!isContentCollapsed); handleToggleCollapse(name) }}>
			<button className={dragHandleClassName}>
				{isContentCollapsed ? (
					<FontAwesomeIcon icon={faChevronUp} size="lg" />
				) : (
					<FontAwesomeIcon icon={faChevronDown} size="lg" />
				) }
			</button>
			{alwaysVisibleContent}
			<div className={conditionalClassName}>{conditionalContent}</div>
		</section>
	);
}
