import * as types from '../actions/actionTypes';

//TODO: move all config to new reducer
const initialState = {
	activeErrors: [],
	bookingErrorCorrelationKey: null,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.PUBLISH_ERROR: {
			let mergedErrors = JSON.parse(JSON.stringify(state.activeErrors));
			mergedErrors.push({ actionId: action.actionId, error: action.error });
			return {
				...state,
				activeErrors: mergedErrors,
			};
		}
		case types.CLEAR_ERROR: {
			let mergedErrors = JSON.parse(JSON.stringify(state.activeErrors));
			mergedErrors = mergedErrors.filter((value) => {
				return value.actionId !== action.actionId;
			});

			return {
				...state,
				activeErrors: mergedErrors,
				bookingErrorCorrelationKey: null,
			};
		}
		case types.CLEAR_ALL_ERRORS: {
			return {
				...state,
				activeErrors: initialState.activeErrors,
				bookingErrorCorrelationKey: null,
			};
		}

		case types.APPOINTMENT_BOOK_NEW_PATIENT_ERROR: {
			return {
				...state,
				bookingErrorCorrelationKey: action?.meta?.previousAction?.payload?.request?.data?.bookAppointmentCriteria?.correlationKey,
			}
		}
		default:
			return state;
	}
}
