import React from 'react';
import moment from 'moment';

export const GetAppointmentDate = (dateTime) => {
	let year = moment(dateTime).format('YYYY');
	let monthLong = moment(dateTime).format('MMMM');
	let dayOfMonth = moment(dateTime).format('D');
	let dayOfWeekLong = moment(dateTime).format('dddd');
	let appointmentDateString = `${dayOfWeekLong}, ${monthLong} ${dayOfMonth}, ${year}`;
	let formattedString = (
		<>
			<span>{appointmentDateString}</span>
		</>
	);

	return formattedString;
};

export const GetAppointmentTime = (dateTime, showArrivalTime, isArrivalTime) => {
	let time = moment(dateTime).format('LT');
	let label =
		showArrivalTime && !isArrivalTime
			? ' (Appointment Time)'
			: showArrivalTime && isArrivalTime
				? ' (Arrival Time)'
				: '';
	let appointmentTimeString = (
		<>
			<span>{time}</span>
			{label && <span className="time-label">{label}</span>}
		</>
	);

	return appointmentTimeString;
};

export const lesserOfTwoDates = (date1, date2) => {
	if (!date1 && !date2) return null;

	if (!!date1 && !date2) {
		return date1;
	} else if (!!date2 && !date1) {
		return date2;
	} else if (!date1 && date2) {
		return null;
	} else if (moment(date1).isSameOrBefore(date2, 'days')) {
		return date1;
	} else {
		return date2;
	}
};

export const greaterOfTwoDates = (date1, date2) => {
	if (!date1 && !date2) return null;

	if (!!date1 && !date2) {
		return date1;
	} else if (!!date2 && !date1) {
		return date2;
	} else if (!date1 && date2) {
		return null;
	} else if (moment(date1).isSameOrAfter(date2, 'days')) {
		return date1;
	} else {
		return date2;
	}
};

export const lesserOfTwoTimes = (time1, time2) => {
	var a = time1.split(':');
	var b = time2.split(':');
	if (!a.length || !b.length) return time2;
	if (parseInt(a[0]) < parseInt(b[0])) return time1;
	else if (parseInt(a[0]) === parseInt(b[0]) && a[1] && b[1] && parseInt(a[1]) < parseInt(b[1])) return time1;
	else return time2;
};

export const greaterOfTwoTimes = (time1, time2) => {
	var a = time1.split(':');
	var b = time2.split(':');
	if (!a.length || !b.length) return time2;
	if (parseInt(a[0]) > parseInt(b[0])) return time1;
	else if (parseInt(a[0]) === parseInt(b[0]) && a[1] && b[1] && parseInt(a[1]) > parseInt(b[1])) return time1;
	else return time2;
};

// TODO: implement these functions to standardize date formatting
// Get Standard Date Format
//export const GetStandardDateFormat(date) {
//	//date.isMoment() ? if not... make a moment then format
//}
// Get Display Date Format
