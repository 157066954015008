import ReadonlyField from './readonlyField';
import { EditorState } from 'draft-js';
import React from 'react';
import * as richText from './richText';
/**
 * @param {{
 *  label?: import('react').ReactNode,
 *  value: string | import('draft-js').EditorState
 * }} props
 */
export default function ReadonlyHtml({ label, value }) {
	const __html =
		value instanceof EditorState ? richText.toHtmlString(value) : value;

	const checkingForVisibleContentWithDiv = document.createElement('div');
	checkingForVisibleContentWithDiv.innerHTML = __html;

	const hasVisibleContent = !!checkingForVisibleContentWithDiv.innerText?.trim();

	return (
		<ReadonlyField label={label}>
			{hasVisibleContent && <span dangerouslySetInnerHTML={{ __html }} />}
		</ReadonlyField>
	);
}
