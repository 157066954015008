import React, { Component } from 'react';

export default class AppliedPathwaysMultipleChoiceSingleAnswer extends Component {
	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.asset === prevState.asset) {
			return null;
		}
		var selectedAnswer = AppliedPathwaysMultipleChoiceSingleAnswer.getSelectedAnswer(nextProps.asset);
		if (selectedAnswer === prevState.value) {
			return null;
		}
		return {
			asset: nextProps.asset,
			value: selectedAnswer,
		};
	}

	static getSelectedAnswer(asset) {
		var selectedAnswer = '';
		asset.ap_answers.map((item) => {
			if (item.value) {
				selectedAnswer = item.id;
			}
			return null;
		});
		return selectedAnswer;
	}

	constructor(props) {
		super(props);
		this.state = {
			asset: {},
			value: '',
		};
	}

	componentDidMount() {
		var selectedAnswer = AppliedPathwaysMultipleChoiceSingleAnswer.getSelectedAnswer(this.props.asset);
		this.setState({ asset: this.props.asset, value: selectedAnswer });
	}

	handleChange = (event) => {
		const { name, value } = event.target;
		const { onChange } = this.props;
		this.setState({ value });
		return onChange({ target: { name, value } });
	};

	render() {
		var questionControlName = this.props.asset.id;
		var isRequired = this.props.asset.is_required;
		var className = 'ap-question inputfield form-control';

		let visibleAnswers = this.props.asset.ap_answers.filter(function (item, index, arr) {
			return item.is_hidden === false;
		});
		let answers = visibleAnswers.map((item, key) => {
			return (
				<option
					key={key}
					value={item.id}
					dangerouslySetInnerHTML={{
						__html: item.ap_literacy.base,
					}}
				/>
			);
		});
		if (isRequired) {
			answers.unshift(
				<option key="blank" value="">
					Please select an option
				</option>,
			);
		}

		let refValue = this.props.ref;
		return (
			<select
				id={this.props.asset.id}
				name={questionControlName}
				required={isRequired}
				size="1"
				className={className}
				value={this.state.value}
				ref={refValue}
				onChange={this.handleChange}
			>
				{answers}
			</select>
		);
	}
}
