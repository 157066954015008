import React from 'react';
import FieldControl from './fieldControl';

import { ButtonSelectionOption } from '../buttons/buttonSelectionOption';

/**
* A row of buttons without space in between, can be single or multi select
* @param {Object} props
* @param {string} props.className
* @param {string} props.id
* @param {Object} props.error
* @param {string} props.error.message
* @param {boolean} props.disabled
* @param {boolean} props.isFocused
* @param {boolean} props.isMultiSelect - Allows multiple options to be selected at once/ value is array
* @param {boolean} props.isRequired
* @param {boolean} props.isVisible - If False Component Returns null
* @param {boolean} props.label 
* @param {boolean} props.labelAfter - If true, label is placed after input (default before)
* @param {string} props.name
* @param {Object} props.value
* @param {Function} props.onChange
* @param {Array} props.optionList - List of Id/Name Pairs
* @param {string} props.size - "sm", "md", "lg"
* @returns {Object}
*/
export function ButtonOptionsRow(props) {
	const {
		className,
		id,
		error,
		disabled,
		isFocused,
		isMultiSelect,
		isRequired,
		isVisible,
		label,
		labelAfter,
		name,
		value,
		onChange,
		optionList,
		size,
	} = props;

	const hasValue = value && (value.length > 0);
	const inputType = "buttonRow";

	return (

		<FieldControl
			className={className}
			disabled={disabled}
			error={error}
			hasValue={hasValue}
			isFocused={isFocused}
			isRequired={isRequired}
			id={id}
			inputType={inputType}
			isVisible={isVisible}
			label={label}
			labelAfter={labelAfter}
			size={size}
		>
			<div className="button-options">
				{optionList && optionList.map(x => {
					const isSelected = isMultiSelect ? value.findIndex((y) => { return y === x.id; }) > -1 : x.id === value;
					return (
						<ButtonSelectionOption
							key={x.id}
							disabled={false}
							isLoading={false}
							isSelected={isSelected}
							name={name}
							onClick={onChange}
							value={x.id}
						>
							{x.name}
						</ButtonSelectionOption>
					)
				})}
			</div>
		</FieldControl>
	);
}
