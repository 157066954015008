import React from 'react';
import { Row, Col } from 'reactstrap';
import './UnsupportedBrowser.css'
export default function UnsupportedBrowser() {
	return (
		<Row id="divUnsupportedBrowserMsg">
			<Col>
				<h3>It looks like you may be using a web browser version that we don't support. Your experience may not be optimal or you may not be able to use certain tools on this website. Try using the most recent version of these supported browsers for the best experience:</h3>
				<ul>
					<li>Chrome</li>
					<li>Firefox</li>
					<li>Microsoft Edge</li>
					<li>Safari</li>
				</ul>
			</Col>
		</Row>
	)
};