import React, { useEffect } from 'react';
import Loadable from 'react-loadable';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { compose } from 'redux';
import { clearAllErrors } from './actions/errorActions';
import { getProductAlertConfig } from './actions/productAlertConfigActions';
import { resetWorkflow } from './actions/workflowActions';
import analyticsTracker from './components/hoc/analyticsTracker';
import tagManagerTracker from './components/hoc/tagManagerTracker';
import withSupportedBrowser from './components/hoc/withSupportedBrowser';
import Footer from './components/misc/footer';
import Header from './components/misc/header';
import InstanceUnavailable from './components/misc/instanceUnavailable';
import ProductAlertBanner from './components/misc/productAlertBanner';
import TelemetryProvider from './components/misc/telemetry-provider';
import config from './config';
import { productIds } from './constants/productAlertConfigValues';
import * as routes from './routes';
import SchedulingWorkflowStepper from './features/schedulingWorkflow/schedulingWorkflowStepper';

const mhdBlue = '#5577a3';

const AppCss = Loadable({
    loader: () => import('./AppCss'),
    loading() {
        return null;
    },
});

function getLoadableCssComponent(pathname, isAuthenticated) {
    let conditionalCssComponent;
    if (isAuthenticated) {
        // must defer non-route based CSS loading until after authentication to prevent loading multiple conflicting stylesheets
        conditionalCssComponent = <AppCss />;
    } else {
        conditionalCssComponent = null;
    }

    return conditionalCssComponent;
}

function changeFavicon(src) {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = src;
}

export const CD2Header = (props) => {
    const auth = props.auth;
    let headerColor = props.auth.headerColor ?? mhdBlue;

    let header = auth.isAuthenticated ? (
        <Row noGutters className="flex-shrink-0 page-header nh-header">
            <Col style={{ backgroundColor: headerColor }}>
                <Header auth={auth} onLogoClick={props.onLogoClick} />
            </Col>
        </Row>
    ) : (
        ''
    );
    return header;
};

export const CD2Footer = (props) => {
    let footer = '';
    let auth = props.auth;
    let headerColor = props.auth.headerColor ?? mhdBlue;
    let version = props.auth.version;
    let footerLogo = props.auth.footerLogo;

    if (auth.isAuthenticated) {
        footer = (
            <Row noGutters className="flex-shrink-0 page-footer nh-footer nh-copyright">
                <Col style={{ backgroundColor: headerColor }}>
                    <div className="text-center nh-footer nh-poweredBy">
                        <img src={footerLogo} alt="Experian Health Logo" className="eh-footer-img" />
                    </div>
                    <div style={{ display: 'none' }} className="text-center versionNumber nh-footer nh-version">
                        <p>Version: {version} </p>
                    </div>
                    <Footer fontColor={auth.headerFooterFontColor} />
                </Col>
            </Row>
        );
    }

    return footer;
};

export const CD2Body = (props) => {
    const { isAuthenticated, backgroundColor, backgroundImageFileName } = props.auth;
    const instance = props.instance;

    let { Cd2RouteSwitch } = routes;

    let contentCol = '';
    let routeSwitchContainer = '';
    if (props.isAuthenticated) {
        contentCol += 'contentCol';
        routeSwitchContainer = 'routeSwitchContainer';
    }

    const effectiveBackgroundColor = backgroundImageFileName ? 'rgba(0, 0, 0, 0)' : backgroundColor;
    const shouldShowProductAlertBanner = () => {
        return props.productAlertConfig.config?.isEnabled &&
            props.productAlertConfig.config?.alertMessage &&
            props.productAlertConfig.isActive;
    }

    let body = (
        <Row noGutters={!isAuthenticated} className="flex-fill d-flex justify-content-start contentContainer2 nh-content">
            <Col className={contentCol} style={{ backgroundColor: effectiveBackgroundColor }}>
                <div className={routeSwitchContainer + ' nh-content-main'}>
                    {shouldShowProductAlertBanner() && (
                        <div className='alertBanner'>
                            <ProductAlertBanner config={props.productAlertConfig.config} />
                        </div>
                    )}

                    {instance && instance.isInstanceUnavailable && props.location.pathname !== '/logout' ? (
                        <InstanceUnavailable message={props.instance.instanceUnavailableText} />
                    ) : (
                        <>
                            {props.config.scheduling.showProgressBar && <SchedulingWorkflowStepper />}
                            <Cd2RouteSwitch />
                        </>
                    )}
                </div>
            </Col>
        </Row>
    );

    return body;
};

export const App = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            dispatch(clearAllErrors());
        });

        return () => {
            unlisten();
        };
    });

    useEffect(() => {
        dispatch(getProductAlertConfig(productIds.CD2));
    }, [dispatch]);

    const resetFlow = () => {
        let { auth, session, token, reservationId } = props;
        let id = reservationId ?? reservationId;

        if (auth.overrideSiteHomeUrl && auth.siteHomeUrl) {
            dispatch(resetWorkflow(token, id, auth.siteHomeUrl, true));
            window.location.href = auth.siteHomeUrl;
            return null;
        } else {
            dispatch(resetWorkflow(token, id, session.entryPointUrl, false));
        }
    };

    const { pathname } = props.location;
    const { isAuthenticated, backgroundImageFileName, faviconFileName } = props.auth;

    let conditionalAppCss = getLoadableCssComponent(pathname, isAuthenticated, false);

    // Setting Client Style Configs or Default:
    let headerColor = props.auth.headerColor ? props.auth.headerColor : '';
    let customStyles = props.auth.customStyles ? props.auth.customStyles : '';

    let backgroundStyle = backgroundImageFileName
        ? {
            backgroundImage: `url(${backgroundImageFileName})`,
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
        }
        : { backgroundColor: headerColor };

    if (faviconFileName) {
        changeFavicon(faviconFileName);
    }

    let layout = (
        <TelemetryProvider instrumentationKey={props.applicationInsightsKey} auth={props.auth} session={props.session}>
            <Container fluid className="d-flex h-100 flex-column px-0 siteContainer2">
                {conditionalAppCss}
                <div className="d-flex flex-column main-content-view-window" style={backgroundStyle}>
                    {customStyles && <style>{customStyles}</style>}
                    <CD2Header auth={props.auth} onLogoClick={resetFlow} />
                    <CD2Body {...props} />
                    <CD2Footer version={config.version} auth={props.auth} />
                </div>
            </Container>
        </TelemetryProvider>
    );

    return layout;
};

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth,
        applicationInsightsKey: state.auth.applicationInsightsKey,
        config: state.config,
        instance: state.config.instance,
        isAuthenticated: state.auth.isAuthenticated,
        reservationId: state.appointment.reservation.reservationId,
        productAlertConfig: state.productAlertConfig,
        session: state.session,
        token: state.auth.token,
    };
}

export default compose(
    withRouter,
    withSupportedBrowser,
    analyticsTracker,
    tagManagerTracker,
    connect(mapStateToProps),
)(App);
