import { useSelector } from "react-redux";
import {
    createCalendarDateAvailabilitySelector,
    selectFirstDisplayableDateWithSlotForCalendarById,
    selectIsCalendarScanningById,
    selectIsCalendarSearchingById
} from "../availabilitySlice";
import { useMemo, useState } from "react";
import {
    selectMaxSlotCountAnyDisplayedDate,
    selectShouldSuppressSaturday,
    selectShouldSuppressSunday,
} from "../availabilitySlice/availabilitySelectors";
import classNames from "classnames";
import LoadingSpinner from "../../../components/loadingIndicator/spinner";
import { MoreLessAvailabilityToggler, NoAvailabilityPane, Slot } from "./availability";
import { nullifySuppressedDates } from "../../../lib/date";

const AvailabilityMobile = ({ calendarId, date }) => {
    const [isExpandedToShowAllSlots, setIsExpandedToShowAllSlots] = useState(false);

    const isSearching = useSelector(state => selectIsCalendarSearchingById(state, calendarId));
    const isScanning = useSelector(state => selectIsCalendarScanningById(state, calendarId));
    const isScanningOrSearching = isSearching || isScanning;

    const initialSlotsPerDay = useSelector((state) => state.config.availabilitySearch.slotsPerDayMobile);
    const slotColumnsPerDay = useSelector((state) => state.config.availabilitySearch.slotColumnsPerDayMobile);

    const selectCalendarAvailabilityByDate = useMemo(createCalendarDateAvailabilitySelector, []);
    //This creates a unique selector instance to cache by calendarId/date
    //https://redux.js.org/usage/deriving-data-selectors#creating-unique-selector-instances
    const slots = useSelector((state) => selectCalendarAvailabilityByDate(state, calendarId, date));

    const firstDisplayableDateWithSlot = useSelector(state => selectFirstDisplayableDateWithSlotForCalendarById(state, calendarId));
    const maxSlotCountAnyDisplayedDate = useSelector((state) => selectMaxSlotCountAnyDisplayedDate(state, calendarId));

    const suppressSaturday = useSelector(selectShouldSuppressSaturday);
    const suppressSunday = useSelector(selectShouldSuppressSunday);

    const hasMoreAvailabilityThanInitialLimit = slots.length > initialSlotsPerDay;

    const availabilityRowWeekClassName = classNames('availability-row-week', {
        'no-availability': !maxSlotCountAnyDisplayedDate,
    });

    return (
        <div className="availability-row-container">
            <div className="availability-row-spacer" />
            <div className={availabilityRowWeekClassName}>
                {!!slots.length && !isSearching && (
                    <div className={'availability-row'}>
                        {nullifySuppressedDates(date, suppressSaturday, suppressSunday)
                            ? <AvailabilityDateMobile
                                key={date}
                                slots={slots}
                                isExpanded={isExpandedToShowAllSlots}
                                numSlotColumns={slotColumnsPerDay}
                                initialSlotsPerDay={initialSlotsPerDay}
                            />
                            : <AvailabilityDateMobileDisabled />
                        }

                    </div>
                )}
                {(isSearching || (isScanning && !firstDisplayableDateWithSlot)) && (
                    <LoadingSpinner message="Loading availability..." />
                )}
                {hasMoreAvailabilityThanInitialLimit &&
                    <MoreLessAvailabilityToggler
                        isExpanded={isExpandedToShowAllSlots}
                        setIsExpanded={setIsExpandedToShowAllSlots}
                    />
                }
                {!slots.length && !isScanningOrSearching && (
                    <NoAvailabilityPane
                        calendarId={calendarId}
                    />
                )}
            </div>
            <div className="availability-row-spacer" />
        </div>
    )
}

const AvailabilityDateMobileDisabled = () => {
    const availabilityDayClassName = classNames(
        'availability-day',
        'mobile',
        'no-availability',
        'disabled',
    );

    return (
        <div className={availabilityDayClassName}></div>
    )
}

const AvailabilityDateMobile = ({ slots, isExpanded, initialSlotsPerDay, numSlotColumns }) => {

    const hasMoreAvailabilityThanInitialLimit = slots.length > initialSlotsPerDay;
    const numSlotsToShow =
        isExpanded || !hasMoreAvailabilityThanInitialLimit ? slots.length : initialSlotsPerDay;
    const slotsToShow = slots.slice(0, numSlotsToShow);
    const slotsToShowByRow = [];
    const slotsPerColumn = Math.ceil(numSlotsToShow / numSlotColumns);
    const numRowsToShow = slotsPerColumn;

    for (let i = 0; i < numRowsToShow; i++) {
        const rowIndex = i * numSlotColumns;
        const nextRowIndex = rowIndex + numSlotColumns;
        const rowSlots = slotsToShow.slice(rowIndex, nextRowIndex);
        slotsToShowByRow.push(rowSlots);
    }

    const availabilityDayClassName = classNames(
        'availability-day',
        'mobile',
        { 'no-availability': !slots.length },
    );

    return (
        <div className={availabilityDayClassName}>
            {slotsToShowByRow.map((row, i) => {
                return (
                    <div className="slot-row" key={i}>
                        {row.map((appt, i) => <Slot appointment={appt} key={i} />)}
                    </div>
                );
            })
            }
        </div>
    )
}

export default AvailabilityMobile;