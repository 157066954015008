import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { scrollToPageTop } from '../../lib/misc';

import {
	fetchWorkboxWithRule,
	fetchWorkbox,
	fetchWorkboxNext,
	fetchWorkboxPrevious,
} from '../../actions/appliedPathwaysActions';

import AppliedPathwaysWorkflow from './appliedPathwaysWorkflow';
import ActiveErrors from '../misc/activeErrors';
import { SmsTermsAndPolicies } from '../misc/smsTermsAndPolicies';

export class AppliedPathwaysComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: '',
		};
	}

	handleComplete = (subpoints) => {
		scrollToPageTop();
		if (subpoints === null) {
			return;
		}
		let ruleToken = '';
		let chainToGuidedResponseFlow = false;

		subpoints.map((subpoint) => {
			switch (subpoint.key) {
				case 'redirecttoalgo':
					ruleToken = subpoint.value;
					break;
				case 'chainedflowuniquetoken':
					chainToGuidedResponseFlow = true;
					break;
				default:
					break;
			}
			return '';
		});

		if (chainToGuidedResponseFlow) {
			//Redirect to Guided Response Workflow View
			this.props.onChainFlow(subpoints);
		} else {
			if (ruleToken !== '') {
				this.props.fetchWorkboxWithRule(
					this.props.contextData,
					this.props.correlationKey,
					this.props.decisionSupportConfig,
					ruleToken,
					subpoints,
				);
			} else {
				this.props.onCompleted(subpoints); // raise callback to parent?
			}
		}
	};

	handleCurrent = () => {
		this.fetchWorkbox();
	};

	fetchWorkbox = () => {
		this.props
			.fetchWorkbox(this.props.contextData, this.props.correlationKey, this.props.decisionSupportConfig)
			.then((response) => {
				if (response.error) {
					this.setState({
						error: 'An error occurred while trying to retrieve workbox.',
					});
				}
			})
			.catch((err) => {
				this.setState({
					error: 'An error occurred while trying to retrieve workbox.',
				});
			});
	};

	handleNext = (data) => {
		scrollToPageTop();
		this.props.fetchWorkboxNext(data);
	};

	handlePrevious = (data) => {
		scrollToPageTop();
		this.props.fetchWorkboxPrevious(data);
	};

	render() {
		return (
			<>
				<div className="view-content decision-support">
					<ActiveErrors />
					<main className={'main-content'}>
						<AppliedPathwaysWorkflow
							appliedPathwaysState={this.props.appliedPathways.appliedPathwaysState}
							error={this.state.error}
							isLoading={this.props.isLoading}
							onComplete={this.handleComplete}
							onCurrent={this.handleCurrent}
							onNext={this.handleNext}
							onPrevious={this.handlePrevious}
							patient={this.props.patient}
							workbox={this.props.appliedPathways.workbox}
						/>
					</main>
				</div>
				{this.state.onFirstDecisionSupportPage && <SmsTermsAndPolicies />}
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		appliedPathways: state.appliedPathways,
	};
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			fetchWorkboxWithRule,
			fetchWorkbox,
			fetchWorkboxNext,
			fetchWorkboxPrevious,
		},
		dispatch,
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AppliedPathwaysComponent);
