/**
 * @format
 */

//3rd party
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

//actions
import { initializeBookingProcess, redirectToBookingProcess, setBookingContext } from '../../actions/workflowActions';
import { storeTokenLinkParameters } from  '../../actions/sessionActions';

//constants

//lib

//comps
import { LoadingIndicatorModal } from '../../components/loadingIndicator/loadingIndicatorModal';

export function StartBookingRedirector(props) {
	const dispatch = useDispatch();
	const location = useLocation();

	/* eslint-disable */
	useEffect(() => {
		dispatch(
			initializeBookingProcess(
				props.bookingProps.useDecisionSupport,
				props.correlationKey,
				props.activeCareOrder,
				props.decisionSupportOutput,
				props.availabilitySearchConfig,
				props.activePatient,
			),
		);

		return () => {};
	}, [dispatch, props.bookingProps]);
	/* eslint-enable */

	useEffect(() => {
		if (props.isBookingInitialized) {
			let queryString =
				location.state && location.state.from
					? location.state.from.search.toLowerCase()
					: location.search.toLowerCase();
			let optionalParams = qs.parse(queryString, { ignoreQueryPrefix: true });

			if (props.bookingProps.auth.allowStartBookingUrlAdditionalInputs) {
				let tokenLinkParams = qs.parse(location.state && location.state.from ? location.state.from.search : location.search, { ignoreQueryPrefix: true });

				dispatch(
					storeTokenLinkParameters(
						tokenLinkParams.inputValueType ? tokenLinkParams.inputValueType : 'String',
						tokenLinkParams.inputName,
						tokenLinkParams.inputValue
					)
				);
			}

			dispatch(
				setBookingContext({
					npi: optionalParams.npi,
					siteId: optionalParams.siteid,
					specialtyId: optionalParams.specialtyid,
					specialtyName: optionalParams.specialtyname,
				}),
			);

			dispatch(
				redirectToBookingProcess(
					props.bookingProps.auth,
					props.bookingProps.patientAuthContext,
					props.bookingProps.useDecisionSupport,
					props.bookingProps.bookingContext,
					props.config.instance.routePrefix,
				),
			);
		}
	}, [dispatch, location, props.bookingProps, props.isBookingInitialized, props.config]);

	return (
		<div className="view-content">
			<LoadingIndicatorModal />
		</div>
	);
}
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const mapStateToProps = (state, ownProps) => {
	return {
		activeCareOrder: state.careOrder,
		activePatient: state.patient,
		availabilitySearchConfig: state.config.availabilitySearch,
		bookingProps: {
			auth: state.auth,
			bookingContext: state.session.bookingContext,
			useDecisionSupport: state.config.decisionSupport.useDecisionSupport,
			patientAuthContext: state.patient.patientAuthContext,
		},
		config: state.config,
		correlationKey: state.session.correlationKey,
		decisionSupportOutput: state.decisionSupport,
		isBookingInitialized: state.session.bookingInitialized,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StartBookingRedirector);
